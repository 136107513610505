import React, { useEffect, useState, useRef } from "react";
import "../../assets/css/navigation.css";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logout } from "../../actions/auth";
import { getUserDetails, getUserRoles } from "../../actions/navbar";
import Notification from "./Notification";
import { useHistory } from "react-router-dom";
import Event from "../Tracking";
// import { getUserToken, getUserPrevilage } from '../../services/AuthService';
// import changePassword from '../../actions/common/ChangePasswordAction';


//import { Pointer } from "highcharts";
import dummyProfile from "../../images/Group1857.png";
import logo from "../../images/MicrosoftTeams-image.png";
import DocketryLogo from "../../../src/images/DocketrySVG.svg"

const userDetails = JSON.parse(localStorage.getItem("userDetails"));
const NavBar = ({
  changePassword,
  logout,
  getUserDetails,
  userDetails,
  getUserRoles,
  userRoles,
}) => {
  const signOut = (e) => {
    localStorage.clear();
    window.location.href = "/";
  };

  const dataRecognitionRef = useRef();

  

  const useOutsideClick = (ref, callback) => {
    const handleClick = (evt) => {
      if (ref.current && !ref.current.contains(evt.target)) {
        callback();
      }
    };

    useEffect(() => {
      document.addEventListener("click", handleClick);
      return () => {
        document.removeEventListener("click", handleClick);
      };
    });
  };

  useOutsideClick(dataRecognitionRef, () => {
    if (showPopOver) {
      togglePopOver(!showPopOver);
    }
  });

  const [showPopOver, togglePopOver] = useState(false);

  let history = useHistory();
  console.log('history log in navigation: ', history)

  const user = JSON.parse(localStorage.getItem('token')) || {};
  const {role, token, status } = JSON.parse(localStorage.getItem('userDetails')) || {}

  console.log('user role: ', role)
  useEffect(() => {
    // getUserDetails(role);
    // getUserRoles(token)
  }, []);

  const goTo = (path) => {
    console.log('inside goTo: ', path)
    history.push(path);
  };
  // highligting active tabs
  const [toggleTab, setToggleTab] = useState(0);
  const toggleActive = (index) => {
    setToggleTab(index)
  }
  const dontToggle = () => {
    // setToggleTab(3)
  }
  useEffect(() => {
    // if (window.location.pathname === "/dashboard" || window.location.pathname === "/superadmin-dashboard") {
    //   setToggleTab(1);
    // }

    if (window.location.pathname === "/dashmin") {
      setToggleTab(1);
    }
    if (window.location.pathname === "/chatbot") {
      setToggleTab(9);
    }
    else if (window.location.pathname === "/non-templatedflow") {
      setToggleTab(2);
    }

    // else if (window.location.pathname === "data-recognition" ||
    //   window.location.pathname === "/intelligent-select" || window.location.pathname === "/createtemplate-standard" || window.location.pathname === "/non-templatedflow") {
    //   setToggleTab(2);
    // }


    else if (window.location.pathname === "/dynamic-form") {
      setToggleTab(4);
    }
    else if (window.location.pathname === "/documentsearch") {
      setToggleTab(5);
    }
    else if (window.location.pathname === "/") {
      setToggleTab(6);
    }
  } , []);
  return (
    <div className="topNavigation">
      <header>
        <nav
          className="navbar navbar-expand-md navbar-light"
        >
          {/* <div 
            style={{
              display: 'Flex', flexDirection: 'row', justifyContent: 'center', alignContent: 'stretch' }}> */}
          <div>
          <a href="/">
            <div className="row" style={{height: 'inherit'}}>
              <img className="ml-4" src={DocketryLogo} alt="logo" style={{ width: '100px', height: '80px' }} />
              {/* <h5 style={{ marginBlockStart: "auto", fontSize: '10px', fontWeight: 'bold', marginLeft: "11px" }}>
                NuOCR
              </h5> */}
            </div>
            
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarTogglerDemo02"
            aria-controls="navbarTogglerDemo02"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          </div>
          <div
            className=" navbar-collapse navbar-menu-sec"
            id="navbarTogglerDemo02"
            style={{ alignContent: 'right', width: 'auto'}}
          >
            {userDetails?.role === 'SuperAdmin' ? (
              <ul className="navbar-nav">
                <li className={toggleTab === 6 ? "nav-item active" : "nav-item"}
                  onClick={() => {
                    toggleActive(1);
                    // Event("Navbar_Super-Dashboard", "Super Admin Dashboard clicked");
                  }}
                >
                  <a title="Dashmin" className="nav-link" href="/dashmin">
                    Dashmin
                    {/* <span className="sr-only">(current)</span> */}
                  </a>
                </li>
                <li className={toggleTab === 2 ? "nav-item active" : "nav-item"}
                  onClick={() => {
                    toggleActive(2);
                    // Event("Navbar_Standard", "Standard Templates clicked");
                  }}
                >
                  <a title="Dashboard" className="nav-link" href="/createtemplate-standard">
                    Standard Templates
                    {/* <span className="sr-only">(current)</span> */}
                  </a>
                </li>
              </ul>
            ) : (
              <ul className="navbar-nav">
                <li className={toggleTab === 6 ? "nav-item active" : "nav-item"}
                  onClick={() => {
                    toggleActive(4);
                    // Event("Home", "Home clicked");
                  }}>
                  <a
                    title="Home"
                    className="nav-link"
                    href="/"
                  >
                    Home
                  </a>
                </li>

                {/* <li className={toggleTab === 1 ? "nav-item active" : "nav-item"}
                  onClick={() => {
                    toggleActive(1);
                    Event("Navbar_Dashboard", "Dashboard clicked");
                  }}
                >
                  <a title="Dashboard" className="nav-link" href="/dashboard">
                    Dashboard
                    <span className="sr-only">(current)</span> 
                  </a>
                </li> */}
                <li className={toggleTab === 1 ? "nav-item active" : "nav-item"}
                  onClick={() => {
                    toggleActive(1);
                    // Event("Navbar_Dashboard", "Dashboard clicked");
                  }}
                >
                  <a title="Dashmin" className="nav-link" href="/dashmin">
                    Dashmin
                    {/* <span className="sr-only">(current)</span> */}
                  </a>
                </li>
                <li className={toggleTab ===9  ? "nav-item active" : "nav-item"}
                  onClick={() => {
                    toggleActive(9);
                    // Event("Navbar_Dashboard", "Dashboard clicked");
                  }}
                >
                  <a title="Chatbot" className="nav-link" href="/chatbot">
                    Chatbot
                    {/* <span className="sr-only">(current)</span> */}
                  </a>
                </li>
                <li
                  ref={dataRecognitionRef}
                  className={toggleTab === 2 ? "nav-item active" : "nav-item"}
                >
                  <a
                    title="Data Recognition"
                    className="nav-link btn dropdown-toggle"
                    
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Data Recognition
                  </a>
                  <div
                    className="dropdown-menu dropdown-menu-right"
                    onClick={() => {
                      toggleActive(2);
                      // Event("Navbar_DataRecognition", "DataRecognition clicked");
                    }}
                  >
                    <button
                      className="dropdown-item"
                      type="button"
                      onClick={() => goTo("/data-recognition")}
                    >
                      Templated Flow
                    </button>

                    <button
                      className="dropdown-item"
                      type="button"
                      onClick={() => goTo("/non-templatedflow")}
                    >
                      Non-Templated Flow
                    </button>

                    {/* <button
                      className="dropdown-item"
                      type="button"
                      onClick={() => goTo("/IntelligentMed")}
                    >
                      Intelligent
                    </button> */}
                    {/* {userRoles?.rolename === "Admin" && (
                    <button
                      className="dropdown-item"
                      type="button"
                      onClick={() => goTo("/intelligent-select")}
                    >
                      Intelligent
                    </button>)} */}
                  </div>
                </li>
                {/* <li className="nav-item">
                  <a
                    title="Templates"
                    className="nav-link"
                    href="/templates"
                  >
                   Templates
                  </a>
                </li> */}
                {/*<li className="nav-item"
                  className={toggleTab === 4 ? "nav-item active" : "nav-item"}
                  onClick={() => {
                    toggleActive(4);
                    Event("Dynamic Form", "Dynamic Form clicked");
                  }}>
                  <a
                    title="Dynamic Form"
                    className="nav-link"
                    href="/dynamic-form"
                  >
                    Dynamic Form
                  </a>
                </li> */}
                {userDetails?.role === 'Admin' ? (
                  <div></div>
                ) : (
                  <>
                    <li
                      onClick={() => {
                        toggleActive(5);
                        // Event("Navbar_Super-Dashboard", "Search Dashboard clicked");
                      }}
                      className={toggleTab === 5 ? "nav-item active" : "nav-item"}
                    >
                      <a
                        href="/documentsearch"
                        title="Document Search"
                        className="nav-link"
                      // className="nav-link header-menu-icon search-icon"
                      >Search</a>
                    </li>

                    {/* <li className='nav-item mob-notif-li notification-ico' onClick={dontToggle}>
                <Notification />
              </li> */}
              <li className='nav-item'>
                    <a
                      title="Settings"
                      className="nav-link header-menu-icon setting-icon btn dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    ></a>
                    <div className="dropdown-menu dropdown-menu-right">
                      {/* {userDetails?.role === "Admin" && ( */}
                        <>
                          <button
                            className="dropdown-item"
                            type="button"
                            onClick={() => goTo("/manageuser")}
                          >
                            Users
                          </button>
                          {/* <button
                       className="dropdown-item"
                       type="button"
                       onClick={() => goTo("/activeconnections")}
                     >
                       Active Connections
                     </button> */}
                        </>
                       {/*)}*/}
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() => goTo("/ApiKey")}
                      >
                        API Key
                      </button>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() => goTo("/templates")}
                      >
                        Templates
                      </button>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() => window.open('https://plydishapi.nuvento.com/doc/')}
                      >
                        API Documentation
                      </button>
                    </div>
                  </li>
                  <li className='nav-item'>
                    <div className='btn-group'>
                      <button
                        type="button"
                        className="btn dropdown-toggle"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img src={dummyProfile} alt="Group 1110@2x" />
                      </button>
                      <div className="dropdown-menu dropdown-menu-right">
                        {/* <button
                          title="Profile : Coming Soon"
                          className="dropdown-item"
                          type="button"
                        >
                          Profile
                        </button> */}
                        <button
                          className="dropdown-item"
                          type="button"
                          //onClick={(e) =>changePassword(e)}
                          onClick={() => goTo('/changePassword')}
                        >
                          Change Password
                        </button>
                        <button
                          className="dropdown-item"
                          type="button"
                          onClick={(e) => signOut(e)}
                        >
                          Logout
                        </button>
                      </div>
                    </div>
              </li>
                  </>
                )}
              </ul>
            )}
          </div>
          {/* <div className='static-header-wrap'>
            <ul className='m-0 p-0'>
              

              {
              // (userDetails?.role === 'Admin' 
              //   userDetails?.role === 'SuperUser') && (
                  
                // )
                }

              
            </ul>
          </div> */}
          {/* </div> */}
        </nav>
      </header>
    </div>

  );
};

// NavBar.propTypes = {
//   userDetails: PropTypes.object,
//   userDetailsLoading: PropTypes.bool,
//   userError: PropTypes.object,
//   userRoles: PropTypes.object,
//   userRolesLoading: PropTypes.bool,
//   userRolesError: PropTypes.object,
// };
// const mapStateToProps = (state) => {
//   console.log('state: ', state)
//   return ({
//   userDetails: state.navbar.userDetails,
//   userDetailsLoading: state.navbar.userDetailsLoading,
//   userError: state.navbar.userError,
//   // userRoles: state.navbar.userRoles,
//   userRoles: userDetails.role,
//   userRolesLoading: state.navbar.userRolesLoading,
//   userRolesError: state.navbar.userRolesError,
// })};

// export default connect(mapStateToProps, {
//   logout,
//   getUserDetails,
//   getUserRoles,
// })(NavBar);

export default NavBar
