import React from 'react';
import { useHistory } from 'react-router-dom';

const IntelligentSelect = () => {
  const history = useHistory();
  return (
    <div className='dynamic-form-div' >

      <div className="page-header mb-3">
        <h3 className='col-12 col-md-12 p-1'>Intelligent Templates</h3>
        {/*<div className='row'>
          <form className='col-12 col-md-6 d-flex mb-2'>
            <div className='col-6 p-1'>
              <input className='form-control' name='id' placeholder='Name' />
            </div>
          </form>
          <div className='col-12 col-md-6 mt-1 text-center text-md-right mb-2'></div>
        </div>*/}
      </div>
      <hr className='m-1' />
      <ul className='dynamic-list'>
        <li className='mb-1'>
          <div
            style={{ cursor: 'pointer' }}
            className='p-1'
            onClick={() => history.push('/intelligent-upload/cheque')}
          >

            <p>

              <div className="font">Cheque Leaf</div>
            </p>
            <p className='' style={{ marginLeft: "3%" }}>
              <span className='d-block ml-1 nurse'>Liberty Insurance</span>
              <span className='d-block calendar'>03 April 2021</span>
            </p>
          </div>

         
          <div
            style={{ cursor: 'pointer' }}
            className='p-1'
            onClick={() => history.push('/intelligent-upload/license')}
          >
            <p>
              <div className="font">Delhi NCT Driving Licence</div>
            </p>
            <p className='' style={{ marginLeft: "3%" }}>
              <span className='d-block ml-1 nurse'>Intelligent Layout</span>
              <span className='d-block calendar'>12 June 2021</span>
            </p>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default IntelligentSelect;
