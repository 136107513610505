import {TOKEN_RESOLVED, SUBSCRIPTION_ACTIVATED,SUBSCRIPTION_ERROR} from '../actions/azure';

const initialState = {
    data: null,
    loading: true,
    activated: false,
    activationmsg:null
}

export default (state=initialState, action) => {
    switch(action.type) {
        case TOKEN_RESOLVED: return {
            ...state,
            data: action.payload,
            loading: false
        }
        case SUBSCRIPTION_ACTIVATED: return {
            ...state,
            activationmsg: action.payload,
            activated:true
        }
        case SUBSCRIPTION_ERROR: return {
            ...state,
            activationmsg: action.payload,
            activated:false
        }
        default: return state;
    }
}