import {
    FORGOT_PASSWORD_BEGIN,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAILURE,
  } from '../../actions/common/Forgotpassword';
  
  const initstate = {
    forgotpassword: [],
    error:null
  };
  
  const forgotPasswordReducer = (state = initstate, action) => {
    switch (action.type) {
      case FORGOT_PASSWORD_BEGIN:
        return {
          ...state,
          loading: true,
          error: null,
        };
  
      case FORGOT_PASSWORD_SUCCESS:
        return {
          ...state,
          loading: false,
          forgotpassword: action.payload,
        };
  
      case FORGOT_PASSWORD_FAILURE:
       
        return {
            
          ...state,
          
          loading: false,
          error: action.payload.error,
         
        };
  
      default:
        return state;
    }
  };
  export default forgotPasswordReducer;
  