import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import FormScheduler from "./FormScheduler";
import { toast } from "react-toastify";
import FormPreview from "./FormPreview";
import { checkFormName, clearNameState } from "../../actions/DynamicFormActions/GetDynamicFormsNamesAction";
import {
  close_circle,
  Group2086,
  Group2336,
  LeftArrow,
} from "../../constants/Constants";
import Modal from "react-bootstrap/Modal";
import _ from "lodash";
// import EntityMapping from "./EntityMapping";
// make sure the inputData should add to formData on save
// fix deep copy issue  for delete and add options

const FormBuilder = (props) => {
  const checkName = useSelector(
    (state) => state.dynamicFormsListReducer.checkName,
    shallowEqual
  );
  const dispatch = useDispatch();

  const [showFormBuilder, setShowFormBuilder] = useState(true);
  const [openScheduler, setOpenScheduler] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [showEntity, setShowEntity] = useState(false);
  const [formData, setFormData] = useState(props.clone);
  const [newField, setNewField] = useState(null);
  const [inputData, setInputData] = useState({});
  const [formFields, setFormFields] = useState([]);
  const [inputOptions, setInputOptions] = useState({});
  const [inputEditOptions, setInputEditOptions] = useState({});
  const toggle = useRef(false);

  useEffect(() => {
    if (!_.isEmpty(formData.formdata)) {
      setFormFields(formData.formdata);
    }
  }, []);

  useEffect(() => {
    if (checkName) {
      if (checkName.result) {
        toast.warn("Form name already exists. Duplicate names are not allowed");
      } else {
        toggle.current = true;
        setShowFormBuilder(!showFormBuilder);
        setShowEntity(!showEntity);
      }
    }
  }, [checkName]);

  const toggleFormBuilder = () => {
    setShowFormBuilder(!showFormBuilder);
  };
  const togglePreview = () => {
    setShowPreview(!showPreview);
  };
  const toggleScheduler = () => {
    setOpenScheduler(!openScheduler);
  };
  const toggleEntity = () => {
    setShowEntity(!showEntity);
  };
  const handleTitleChange = (e) => {
    toggle.current = false;
    dispatch(clearNameState());
    let title = e.target.value;
    setFormData({ ...formData, form_name: title });
  };
  const handleInputType = (e) => {
    setNewField(e.target.value);
    e.target.options[0].selected = true;
    if (Object.keys(inputData).length > 0) {
      formFields.push(inputData);
      setFormFields(formFields);
      setInputData({});
    }
  };
  const handleNewInputChange = (e) => {
    let key = e.target.name;
    inputData[key] = key === "required" ? e.target.checked : e.target.value;
    inputData["type"] = newField;
    setInputData(inputData);
  };
  const handleNewInputOptionChange = (e) => {
    let key = e.target.name;
    inputOptions[key] = e.target.value;
    setInputOptions(inputOptions);
  };

  const addOption = () => {
    let options = inputData.options || [];
    let exist = options.filter((item) => item.name === inputOptions.name);
    if (exist.length > 0) {
      toast.warn(
        "Same name not allowed. Please choose a different name for each option"
      , {
        autoClose: 4000,
      });
    } else {
      options = [...options, inputOptions];
      inputData["options"] = options;
      setInputData(inputData);
      setInputOptions({});
    }
  };
  const deleteOption = (key) => {
    inputData["options"] = inputData.options.filter((v, i) => {
      return i != key;
    });
    setInputData(inputData);
    setInputOptions({});
  };
  const deleteEditOption = (key, fieldKey) => {
    let item = formFields[fieldKey];
    item["options"] = item.options.filter((v, i) => {
      return i != key;
    });
    formFields[fieldKey] = item;
    setFormFields(formFields);
    setInputEditOptions({});
  };

  const scheduleBack = () => {
    toggleScheduler();
    togglePreview();
  };

  const previewBack = () => {
    if (Object.keys(inputData).length > 0) {
      formFields.push(inputData);
      setFormFields(formFields);
      setInputData({});
    }
    setNewField(null);
    let tmpFormData = { ...formData, form_fields: formFields };
    setFormData(tmpFormData);
    if (tmpFormData.form_name && tmpFormData.form_fields.length > 0) {
      togglePreview();
      toggleFormBuilder();
    } else {
      toast.warn("Add Form title and fields to continue", {
        autoClose: 4000,
      });
    }
  };

  const entityBack = () => {
    if (Object.keys(inputData).length > 0) {
      formFields.push(inputData);
      setFormFields(formFields);
      setInputData({});
    }
    setNewField(null);
    let tmpFormData = { ...formData, form_fields: formFields };
    setFormData(tmpFormData);
    // use tmpFormDAta
    if (tmpFormData.form_name && tmpFormData.form_fields.length > 0) {
      if(toggle.current) {
        toggleFormBuilder();
      toggleEntity();
      } else {
        dispatch(checkFormName({ formName: tmpFormData.form_name }));
      }
    } else {
      toast.warn("Add Form title and fields to continue.");
    }
  };

  const closeModal = () => {
    dispatch(clearNameState());
    props.close()
  }

  const saveFormData = () => {
    toggleEntity();
    togglePreview();
  };

  const updateFormData = (data) => {
    setFormData(data);
  };

  const TypElement = ({ type }) => {
    let element = inputData.options.map((value, key) => {
      return (
        <div className="form-group d-flex" key={key}>
          <div className="form-check w-100">
            {inputData.type != "dropdown" && (
              <input
                className="form-check-input"
                type={type}
                name="exampleRadios"
                defaultValue={value.name}
              />
            )}
            <label className="form-check-label">{value.label}</label>
          </div>
          <label onClick={() => deleteOption(key)}>
            <img
              src={close_circle}
              className="delete-student-img"
              alt="celete-logo-img"
            />
          </label>
        </div>
      );
    });
    return element;
  };
  const NewFieldForm = () => {
    if (newField === "text" || newField === "email" || newField === "date") {
      return (
        <div className="form-group">
          <div className="form-group">
            <textarea
              className="form-control bottom-line-input txt-area-custom"
              name="label"
              placeholder="Label"
              onChange={handleNewInputChange}
            ></textarea>
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              name="placeholder"
              placeholder="Placeholder"
              onChange={handleNewInputChange}
            />
          </div>
          <div className="form-check d-flex">
            <div className="new-input-right">
              <input
                type="checkbox"
                className="form-check-input"
                name="required"
                id="exampleCheck1"
                onChange={handleNewInputChange}
              />
              <label
                className="form-check-label cursor-custom"
                htmlFor="exampleCheck1"
              >
                Required
              </label>
            </div>
            <div className="new-input-left">
              <label
                className="cursor-custom"
                onClick={() => {
                  setNewField(null);
                }}
              >
                {" "}
                Delete
              </label>
            </div>
          </div>
        </div>
      );
    } else if (newField === "file") {
      return (
        <div className="form-group">
          <div className="form-group">
            <textarea
              className="form-control bottom-line-input txt-area-custom"
              name="label"
              placeholder="Label"
              onChange={handleNewInputChange}
            ></textarea>
          </div>
          <div className="form-group">
            <input type="file" className="form-control-file" />
          </div>
          <div className="form-check d-flex">
            <div className="new-input-right">
              <input
                type="checkbox"
                className="form-check-input"
                name="required"
                id="exampleCheck1"
                onChange={handleNewInputChange}
              />
              <label
                className="form-check-label cursor-custom"
                htmlFor="exampleCheck1"
              >
                Required
              </label>
            </div>
            <div className="new-input-left">
              <label
                className="cursor-custom"
                onClick={() => {
                  setNewField(null);
                }}
              >
                {" "}
                Delete
              </label>
            </div>
          </div>
        </div>
      );
    } else if (
      newField === "radio" ||
      newField === "checkbox" ||
      newField === "dropdown"
    ) {
      return (
        <div className="form-group">
          <div className="form-group pt-2">
            <textarea
              className="form-control txt-area-custom"
              name="label"
              placeholder="Group Name"
              defaultValue={inputData.label}
              onChange={handleNewInputChange}
            ></textarea>
          </div>
          {inputData.options && inputData.options.length > 0 && (
            <TypElement type={newField} />
          )}
          <div className="form-group d-flex">
            <input
              type="text"
              className="form-control bottom-line-input mr-2"
              name="label"
              placeholder="Label"
              onChange={handleNewInputOptionChange}
            />
            <input
              type="text"
              className="form-control bottom-line-input mr-2"
              name="name"
              placeholder="Name"
              onChange={handleNewInputOptionChange}
            />
            <label onClick={addOption}>
              <img
                src={Group2086}
                className="delete-student-img cursor-custom"
                alt="celete-logo-img"
              />
            </label>
          </div>
          <div className="form-check d-flex">
            <div className="new-input-right">
              <input
                type="checkbox"
                className="form-check-input"
                name="required"
                id="exampleCheck1"
                onChange={handleNewInputChange}
              />
              <label
                className="form-check-label cursor-custom"
                htmlFor="exampleCheck1"
              >
                Required
              </label>
            </div>
            <div className="new-input-left">
              <label
                className="cursor-custom"
                onClick={() => {
                  setNewField(null);
                }}
              >
                {" "}
                Delete
              </label>
            </div>
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  };
  const OptionElements = (prop) => {
    let element = prop.data.options.map((value, key) => {
      return (
        <div className="form-group d-flex" key={key}>
          <div className="form-check w-100">
            {prop.data.type != "dropdown" && (
              <input
                className="form-check-input"
                type={prop.data.type}
                name=""
                defaultValue={value.name}
              />
            )}
            <label className="form-check-label">{value.label}</label>
          </div>
          <label onClick={() => deleteEditOption(key, prop.index)}>
            <img
              src={close_circle}
              className="delete-student-img cursor-custom"
              alt="celete-logo-img"
            />
          </label>
        </div>
      );
    });
    return element;
  };
  const handleEditInputChange = (e, key) => {
    let item = formFields[key];
    let name = e.target.name;
    item[name] = name === "required" ? e.target.checked : e.target.value;
    formFields[key] = item;
    setFormFields(formFields);
  };
  const handleEditInputOptionChange = (e, key) => {
    let name = e.target.name;
    inputEditOptions[name] = e.target.value;
    setInputEditOptions(inputEditOptions);
  };
  const addEditOption = (key) => {
    let item = formFields[key];
    let tmpOption = item["options"] || [];
    item["options"] = [...tmpOption, inputEditOptions];
    formFields[key] = item;
    setInputEditOptions({});
    setFormFields(formFields);
  };
  const deleteFormField = (key) => {
    let items = [...formFields];
    items.splice(key, 1);
    setFormFields(items);
  };
  const AddedFormFields = () => {
    let formElements = formFields.map((data, key) => {
      if (
        data.type === "text" ||
        data.type === "email" ||
        data.type === "date"
      ) {
        return (
          <div className="new-filed-container" key={key}>
            <div className="form-group">
              <div className="form-group">
                <textarea
                  className="form-control bottom-line-input txt-area-custom"
                  name="label"
                  placeholder="Label"
                  defaultValue={data.label}
                  onChange={(e) => {
                    handleEditInputChange(e, key);
                  }}
                ></textarea>
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  name="placeholder"
                  defaultValue={data.placeholder}
                  onChange={(e) => {
                    handleEditInputChange(e, key);
                  }}
                />
              </div>
              <div className="form-check d-flex">
                <div className="new-input-right">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="required"
                    id="exampleCheck1"
                    defaultChecked={data.required}
                    onChange={(e) => {
                      handleEditInputChange(e, key);
                    }}
                  />
                  <label
                    className="form-check-label cursor-custom"
                    htmlFor="exampleCheck1"
                  >
                    Required
                  </label>
                </div>
                <div className="new-input-left cursor-custom">
                  <label
                    className="cursor-custom"
                    onClick={() => {
                      deleteFormField(key);
                    }}
                  >
                    {" "}
                    Delete
                  </label>
                </div>
              </div>
            </div>
          </div>
        );
      } else if (data.type === "file") {
        return (
          <div className="new-filed-container" key={key}>
            <div className="form-group">
              <div className="form-group">
                <textarea
                  className="form-control bottom-line-input txt-area-custom"
                  name="label"
                  placeholder="Label"
                  defaultValue={data.label}
                  onChange={(e) => {
                    handleEditInputChange(e, key);
                  }}
                ></textarea>
              </div>
              <div className="form-group">
                <input type={data.type} className="form-control-file" />
              </div>
              <div className="form-check d-flex">
                <div className="new-input-right">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="required"
                    id="exampleCheck1"
                    defaultChecked={data.required}
                    onChange={(e) => {
                      handleEditInputChange(e, key);
                    }}
                  />
                  <label
                    className="form-check-label cursor-custom"
                    htmlFor="exampleCheck1"
                  >
                    Required
                  </label>
                </div>
                <div className="new-input-left cursor-custom">
                  <label
                    className="cursor-custom"
                    onClick={() => {
                      deleteFormField(key);
                    }}
                  >
                    {" "}
                    Delete
                  </label>
                </div>
              </div>
            </div>
          </div>
        );
      } else if (
        data.type === "radio" ||
        data.type === "checkbox" ||
        data.type === "dropdown"
      ) {
        return (
          <div className="new-filed-container" key={key}>
            <div className="form-group">
              <div className="form-group pt-2">
                <textarea
                  className="form-control"
                  name="label"
                  placeholder="Group Name"
                  defaultValue={data.label}
                  onChange={(e) => {
                    handleEditInputChange(e, key);
                  }}
                ></textarea>
              </div>
              {data.options && data.options.length > 0 && (
                <OptionElements data={data} index={key} />
              )}
              <div className="form-group d-flex">
                <input
                  type="text"
                  className="form-control bottom-line-input mr-2"
                  name="label"
                  placeholder="Label"
                  onChange={(e) => {
                    handleEditInputOptionChange(e, key);
                  }}
                />
                <input
                  type="text"
                  className="form-control bottom-line-input mr-2"
                  name="name"
                  placeholder="Name"
                  onChange={(e) => {
                    handleEditInputOptionChange(e, key);
                  }}
                />
                <label
                  onClick={(e) => {
                    addEditOption(key);
                  }}
                >
                  <img
                    src={Group2086}
                    className="delete-student-img cursor-custom"
                    alt="celete-logo-img"
                  />
                </label>
              </div>
              <div className="form-check d-flex">
                <div className="new-input-right">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="required"
                    id="exampleCheck1"
                    defaultValue={data.required}
                    onChange={(e) => {
                      handleEditInputChange(e, key);
                    }}
                  />
                  <label
                    className="form-check-label cursor-custom"
                    htmlFor="exampleCheck1"
                  >
                    Required
                  </label>
                </div>
                <div className="new-input-left">
                  <label
                    className="cursor-custom"
                    onClick={() => {
                      deleteFormField(key);
                    }}
                  >
                    {" "}
                    Delete
                  </label>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        return <div></div>;
      }
    });
    return formElements;
  };
  return (
    <div>
      <Modal.Header>
        <a className="closebtn" onClick={closeModal}>
          &times;
        </a>
        {/* <div className="form-container"> */}
        <h5>
          {showFormBuilder && "Create New Form"}
          {/* {showEntity && (
            <>
              <img
                src={LeftArrow}
                alt="Left Arrow"
                onClick={entityBack}
                className="custom-left"
              />{" "}
              Entity Mapping({formData.form_name}){" "}
              <img
                src={Group2336}
                className="view-icon-img preview-icon mx-2 custom-class-img"
                alt="view-logo-img"
                onClick={saveFormData}
              />
            </>
          )} */}
          {openScheduler && (
            <>
              <img
                src={LeftArrow}
                alt="Left Arrow"
                onClick={scheduleBack}
                className="custom-left"
              />{" "}
              Schedule({formData.form_name})
            </>
          )}
          {showPreview && (
            <>
              <img
                src={LeftArrow}
                alt="Left Arrow"
                onClick={previewBack}
                className="custom-left"
              />{" "}
              Preview({formData.form_name})
            </>
          )}
        </h5>
      </Modal.Header>
      <Modal.Body className="body-overflow">
        <div className="form-content">
          {openScheduler && (
            <FormScheduler
              data={formData}
              closeModal={closeModal}
              preview={togglePreview}
              schedule={toggleScheduler}
            />
          )}
          {showPreview && (
            <FormPreview
              data={formData}
              schedule={toggleScheduler}
              preview={togglePreview}
              closeModal={closeModal}
            />
          )}
          {/* {showEntity && (
            <EntityMapping
              data={formData}
              closeModal={closeModal}
              preview={togglePreview}
              entity={toggleEntity}
              update={updateFormData}
            />
          )} */}
          {showFormBuilder && (
            <>
              <div className="form-row">
                <div className="form-group col-md-8">
                  <input
                    type="type"
                    className="form-control"
                    placeholder="Title"
                    onChange={handleTitleChange}
                    value={formData.form_name}
                  />
                </div>
                <span className="required-asterix">*</span>
                <div className="form-group col-md-4">
                  <select
                    className="form-control ml-5 float-right"
                    name="dropdown"
                    onChange={handleInputType}
                  >
                    <option value="">Add New Field +</option>
                    <option value="text">Text</option>
                    <option value="email">Email</option>
                    <option value="date">Date</option>
                    <option value="file">File</option>
                    <option value="radio">Radio</option>
                    <option value="checkbox">Checkbox</option>
                    <option value="dropdown">SelectBox</option>
                  </select>
                </div>
                {
                  (Object.keys(props.clone).length === 0) ?
                    (<span className="required-asterix">*</span>)
                    :
                    (null)
                } 
              </div>
              <hr></hr>
              <div className="container-form">
                {formFields.length > 0 && (
                  <>
                    <AddedFormFields />
                    <hr></hr>
                  </>
                )}
                {newField && (
                  <div className="new-filed-container">
                    <NewFieldForm />
                  </div>
                )}
              </div>
              <div className="form-inline">
                <div className="main-bottom top-btns-div mt-5 d-flex">
                  <button
                    type="button"
                    className="btn btn-primary mr-2"
                    onClick={previewBack}
                  >
                    Next
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary cancek-btn"
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </Modal.Body>
    </div>
  );
};
export default FormBuilder;
