import {
    
    TEMPLATETYPES_SUCCESS,
    TEMPLATETYPES_BEGIN,
    TEMPLATETYPES_FAILED,

} from "../actions/templatetypes";

const initialState = {
    data: null,
    loading: false,
    activated: false,
    error: null,
    templatetypes: null,
    templatetypesloading: false,
}

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
       

        case TEMPLATETYPES_BEGIN: return {
            ...state,

            templatetypesloading: true
        }
        case TEMPLATETYPES_SUCCESS:

            return {
                ...state,
                templatetypes: payload,

                templatetypesloading: false
            }

        case TEMPLATETYPES_FAILED: return {
            ...state,

            templatetypesloading: false
        }
        default: return state;
    }
}
