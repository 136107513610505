import {
    ADDUSER_SUCCESS,
    ADDUSER_BEGIN,
    ADDUSER_FAILED,
    ROLES_SUCCESS,
    ROLES_BEGIN,
    ROLES_FAILED,
    USERLIST_SUCCESS,
    USERLIST_BEGIN,
    USERLIST_FAILED,
    SET_USER_OPTIONS,
    SET_ROLE_OPTIONS,
    EDITUSER_SUCCESS,
    EDITUSER_BEGIN,
    EDITUSER_FAILED,
    ACT_DEACT_BEGIN,
    ACT_DEACT_SUCCESS,
    ACT_DEACT_FAILED,
  

} from "../actions/adduser";
const initialState = {
    data: null,
    loading: false,
    activated: false,
    error: null,
    roles: null,
    rolesloading: false,
    userlisterror: false,
    users: null,
    usersloading: false,
    options: [],
    roleoptions: [],
    editsuccess: null,
    editfail: null,
    editloading: false,
    activateloading:false,
    activate:null,
  
}

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case ADDUSER_BEGIN: return {
            ...state,
            data:null,
            loading: true
        }
        case ADDUSER_SUCCESS: return {
            ...state,
            data: payload,
            activated: true,
            loading: false
        }
        case ADDUSER_FAILED: return {

            ...state,
            error: payload,
            loading: false
        }

        case ROLES_BEGIN: return {
            ...state,

            rolesloading: true
        }
        case ROLES_SUCCESS:

            return {
                ...state,
                roles: payload,

                rolesloading: false
            }

        case ROLES_FAILED: return {
            ...state,

            rolesloading: false
        }
        case USERLIST_BEGIN: return {
            ...state,

            usersloading: true
        }
        case USERLIST_SUCCESS:

            return {
                ...state,
                users: payload,

                usersloading: false
            }

        case USERLIST_FAILED: return {
            ...state,

            usersloading: false
        }
        case SET_USER_OPTIONS:

            return {
                ...state,
                options: payload

            }
        case SET_ROLE_OPTIONS:

            return {
                ...state,
                roleoptions: payload

            }
        case EDITUSER_BEGIN: return {
            ...state,
            editsuccess:null,
            editloading: true
        }
        case EDITUSER_SUCCESS: return {
            ...state,
            editsuccess: payload,
           
            editloading: false
        }
        case EDITUSER_FAILED: return {

            ...state,
            editfail: payload,
            editloading: false
        }
        case ACT_DEACT_BEGIN: return {
            ...state,
            activate:null,
            activateloading: true
        }
        case ACT_DEACT_SUCCESS: return {
            ...state,
            activate: payload,
           
            activateloading: false
        }
        case ACT_DEACT_FAILED: return {

            ...state,
           
            activateloading: false
        }
       


        default: return state;
    }
}
