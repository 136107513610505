// import { post, get } from '../../services/Http';

import axios from 'axios';

export const FETCH_FORM_BEGIN = "FETCH_FORM_BEGIN";
export const FETCH_FORM_SUCCESS = "FETCH_FORM_SUCCESS";
export const FETCH_FORM_FAIL = "FETCH_FORM_FAIL";
export const POST_FORM_BEGIN = "POST_FORM_BEGIN";
export const POST_FORM_SUCCESS = "POST_FORM_SUCCESS";
export const POST_FORM_FAIL = "POST_FORM_FAIL";
export const fetchFormDataBegin = () => ({
    type: FETCH_FORM_BEGIN,
});

export const fetchFormDataSuccess = (formInfo) => ({
    type: FETCH_FORM_SUCCESS,
    payload: formInfo,
});

export const fetchFormDataFailure = (error) => ({
    type: FETCH_FORM_FAIL,
    payload: { error },
});
export const postFormDataBegin = () => ({
    type: POST_FORM_BEGIN,
});

export const postFormDataSuccess = (formInfo) => ({
    type: POST_FORM_SUCCESS,
    payload: formInfo,
});

export const postFormDataFailure = (error) => ({
    type: POST_FORM_FAIL,
    payload: { error },
});

export function fetchFormDataById(params) {
    return (dispatch) => {
        dispatch(fetchFormDataBegin());
        return axios.post(`/dynamic-forms/show`, params)
            // .then(handleErrors)
            // .then((res) => res.json())
            .then((json) => {
                dispatch(fetchFormDataSuccess(json.data.data));
            }).catch((error) => {
                dispatch(fetchFormDataFailure(error.message))
            });
    };
}
export function postUserFormData(params) {
    return (dispatch) => {
        dispatch(postFormDataBegin());
        return axios.post('/dynamic-forms/submit', params)
            // .then(handleErrors)
            // .then((res) => res.json())
            .then((json) => {
                dispatch(postFormDataSuccess(json));
            }).catch((error) => {
                dispatch(postFormDataFailure(error.message))
            });
    };
}
function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}