import axios from 'axios';
export const TEMPLATETYPES_SUCCESS = "TEMPLATETYPES_SUCCESS";
export const TEMPLATETYPES_BEGIN = "TEMPLATETYPES_BEGIN";
export const TEMPLATETYPES_FAILED = "TEMPLATETYPES_FAILED";

export const TemplateTypesList = () => async dispatch => {
 
    
    try {
        dispatch({
            type: TEMPLATETYPES_BEGIN
        })
        
        const res = await axios.get('/templatetypes')
       
        dispatch({
            type: TEMPLATETYPES_SUCCESS,
            payload: res.data
        })
      
    } catch (err) {
      
        dispatch({
            type: TEMPLATETYPES_FAILED,
            payload: err
        })
    }
}
