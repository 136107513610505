import pass from "../images/Path-462.png"
import visibility from "../images/visibility_off-24px(1).png";
import close from "../images/noun_Close_1808841.png";
import Searchgrayicon from '../images/search-gray-icon.png'
import viewicon from '../images/icons8-view-64.png'
import editicon from '../images/icons8-edit-64 (1).png'

import Group2086 from "../images/Group 2086.png";
import more from "../images/more.png";
import Group2336 from "../images/Group 2336@2x.png";
import LeftArrow from "../images/left-arrow.png"
import close_circle from "../images/Group 2657@2x.png";
import close1 from "../images/close1.png";


export const userToken = "token";
export const loggedInUserBasePath = "/ocr";
export const apiDomainDevelop = "http://localhost:80";
export const bloburl="https://nuocrstracc.blob.core.windows.net/nuocrtemplates/"




export{
    pass,
    visibility,
    close,
    Searchgrayicon,
    viewicon,
    editicon,
    Group2086, 
    more,
    Group2336,
    LeftArrow,
    close_circle,
    close1
}
