import React, { useState, useEffect, Fragment, useRef } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Modal from "@mui/material/Modal";
import Tooltip from "@mui/material/Tooltip";

const CopyJSONModal = ({ itemClicked }) => {
  const [open, setOpen] = React.useState(false);
  const [JSONText, setJSONText] = useState("");
  const JSONMaker = (key, val) => {
    console.log("inside JSONMaker: ", key, val);
    return JSON.stringify([{ key: key, value: val }]);
  };
  const handleOpen = (el) => {
    console.log("inside handleOpen: ", el.key, el.value);
    setJSONText(JSONMaker(el.key, el.value));
    navigator.clipboard.writeText(JSONMaker(el.key, el.value));
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "70%",
    // transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: "background.paper",
    border: "2px solid #000",
    opacity: 2,
    boxShadow: 12,
    p: 2,
  };
  return (
    <div>
      <Tooltip title="Copy to Clipboard" arrow>
        <Button onClick={() => handleOpen(itemClicked)}>
          <ContentCopyIcon />
        </Button>
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            JSON Copied to Clipboard
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
            component="h3"
          >
            {JSONText}
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default CopyJSONModal;
