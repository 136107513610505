import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../../actions/auth";
import Event from "../Tracking";
import logo from "../../images/MicrosoftTeams-image.png";
import DocketryLogo from "../../../src/images/DocketrySVG.svg"

const Navbar = ({ auth: { isAuthenticated }, logout }) => {
   
    return (
        <div className="logodiv">
            <div className="container">
                <div className="row">

                    <div className="col-md-4">
                       
                            <div className="row">
                                <img className="ml-4" src={DocketryLogo} alt="logo" style={{ width: '100px', height: '80px'}} />
                                {/* <h5 style={{ marginBlockStart: "auto", fontSize: '10px', fontWeight: 'bold', marginLeft: "11px" }}>
                                    NuOCR
                                </h5> */}
                            </div>
                       
                    </div>

                    <div className="col-md-8">
                        <div className="headermenu">
                            <ul>
                               
                                    <Link to="/login" className="home"><h5>Login</h5></Link>
                              
                            </ul>

                        </div>
                    </div>

                    
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, { logout })(Navbar);
