import axios from 'axios';
import { toast } from "react-toastify";

export const CHANGE_PASSWORD_BEGIN = 'CHANGE_PASSWORD_BEGIN';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

export const changePassword = (data, header) => async dispatch => {

    console.log('inside changePasswordAction: ', data, header)
    try {
       
        dispatch({
            type: CHANGE_PASSWORD_BEGIN
        })

        // const urlChangePassword = `http://104.43.211.217:8000/change_password`
        // const urlChangePassword = `http://20.230.189.213:8000/change_password`
        const urlChangePassword = `https://dev-docketry-api.nuvento.com/change_password`
       
         await axios.post(urlChangePassword, data, header).then((res)=>{
            
                dispatch({
                            type: CHANGE_PASSWORD_SUCCESS,
                            payload: res.data,
                        })
                    
                        toast.success(res.data,{autoClose:4000})
                        localStorage.clear();
                        window.location.href = "/";
            
        })
  
    } catch (err) {       
        dispatch({
            type: CHANGE_PASSWORD_FAILURE,
            payload: err.response.data
        })
        toast.error(err.response.data.err,{autoClose:4000})
    }
}