import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router} from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { Provider } from "react-redux";
import store from "./store";
import { createBrowserHistory } from "history";
import {logout} from './actions/auth'

import ReactGa from 'react-ga';
import { getUserId } from "./utils/functions";
import 'react-toastify/dist/ReactToastify.css';

import './assets/css/dynamic-form.css';
import "react-datepicker/dist/react-datepicker.css";
import BotEngine from "./components/chatBot/BotEngine";

const customHistory = createBrowserHistory();

console.ignoredYellowBox = ["Warning"];

function initializeAnalytics() {
  ReactGa.initialize("UA-167118937-1");
  ReactGa.pageview(window.location.pathname + window.location.search);
  customHistory.listen((location) => {
    const idVal = getUserId();
    ReactGa.set({ page: `userId:${idVal}/${location.pathname}` }); // Update the user's current page
    ReactGa.pageview(`userId:${idVal}/${location.pathname}`); // Record a pageview for the given page
    // ReactGa.set({ page: location.pathname });
    // ReactGa.pageview(location.pathname);
  })
}
// initializeAnalytics();

const notify = (text, category) => {
  if (category === "success") {
    toast.success(text,{autoClose:4000});
  } else {
    toast.error(text,{autoClose:4000})
  }
};

axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL;

axios.interceptors.request.use((config) => {
  // console.log('config: ', config)
  // console.log('localStorage: ', localStorage)
  if (localStorage.getItem("token")) {
    const token = localStorage.getItem("token") ? JSON.parse(localStorage.getItem("token")).token : '';
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

// axios.interceptors.response.use(response => response, error => {
//   console.log('error response: ', error)
//   if (error.response.status === 401) {
//     store.dispatch(logout());
//     customHistory.push('/login')
//     notify("Session expired. Please login again")
//   }
//   return Promise.reject(error)
// })



ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router history={customHistory}>
      <ToastContainer autoClose={false} className="toasterVision" />
        <App />
      </Router>
    </Provider>
    <BotEngine />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
