import axios from 'axios';
export const NON_TEMPLATED_FORM_FETCH_BEGIN = "NON_TEMPLATED_FORM_FETCH_BEGIN";
export const NON_TEMPLATED_FORM_FETCH_FAILED = "NON_TEMPLATED_FORM_FETCH_FAILED";
export const NON_TEMPLATED_FORM_FETCH_SUCCESS = "NON_TEMPLATED_FORM_FETCH_SUCCESS";
export const NON_TEMPLATED_BEGIN = "NON_TEMPLATED_BEGIN";
export const NON_TEMPLATED_FAILED = "NON_TEMPLATED_FAILED";
export const NON_TEMPLATED_SUCCESS = "NON_TEMPLATED_SUCCESS";
export const TEMPLATE_LOADED = 'TEMPLATE_LOADED';
export const NON_TEMPLATED_FORM_CLEAR = 'NON_TEMPLATED_FORM_CLEAR';


export const loadTemplate = (data) => (dispatch) => {
    dispatch({
        type: TEMPLATE_LOADED,
        payload: data,
    });
};

// export const fetchToken = () => async () => {
    
//     return tokenToSend
// }



export const clearRecdata = () => (dispatch) => {
    dispatch({
        type: NON_TEMPLATED_FORM_CLEAR,
        payload: null,
    });
};

export const getnontemplatedforms = (data) => async dispatch => {
    
    // try {
    //     dispatch({
    //         type: NON_TEMPLATED_FORM_FETCH_BEGIN
    //     })
    //     const res = await axios.get('/nontemplatetypes/GetNonTemplateTypes', data);
    //     dispatch({
    //         type: NON_TEMPLATED_FORM_FETCH_SUCCESS,
    //         payload: res.data
    //     })
    // } catch (err) {
    //     dispatch({
    //         type: NON_TEMPLATED_FORM_FETCH_FAILED,
    //         payload: err
    //     })
    // }
}

export const getMediAssitForms = (data) => async dispatch => {
    // try {
    //     dispatch({
    //         type: NON_TEMPLATED_FORM_FETCH_BEGIN
    //     })
    //     const res = await axios.get('/nontemplatetypes/GetMedAssistForms', data);
    //     // console.log("res", res.data);
    //     dispatch({
    //         type: NON_TEMPLATED_FORM_FETCH_SUCCESS,
    //         payload: res.data
    //     })
    // } catch (err) {
    //     dispatch({
    //         type: NON_TEMPLATED_FORM_FETCH_FAILED,
    //         payload: err
    //     })
    // }
}

export const setExcelData = (data,fileName,formType) => async dispatch => {
    dispatch({
        type: NON_TEMPLATED_SUCCESS,
        payload: data,
        fileName:fileName,
        nufarm: "",
        dossdemo: "",
        formType:formType.toLowerCase()
    })
}

export const setZipData = (data,fileName,formType) => async dispatch => {
    console.log("data",data)
    dispatch({
        type: NON_TEMPLATED_SUCCESS,
        payload: data,
        fileName:fileName,
        nufarm: "",
        dossdemo: "",
        formType:formType
    })
}
export const setReadData = (data,fileName,formType,documentUrl) => async dispatch => {
    console.log("data",data)
    dispatch({
        type: NON_TEMPLATED_SUCCESS,
        payload: data,
        fileName:fileName,
        nufarm: "",
        dossdemo: "",
        formType:'read',
        documentUrl:documentUrl
    })
}
export const setGePowerData = (data,fileName) => async dispatch => {
    console.log("data",data)
    dispatch({
        type: NON_TEMPLATED_SUCCESS,
        payload: data,
        fileName:fileName,
        nufarm: "",
        dossdemo: "",
        formType:"gepowerconversion"
    })
}


export const getnontemplatedResult = (data) => async dispatch => {
    const tokenToSend = await JSON.parse(localStorage.getItem("token"))
    const userNameRecorded = await JSON.parse(localStorage.getItem("userDetails")).username;
    // const userName = await JSON.parse(localStorage.getItem('username'))
    console.log('getnontemplatedResult payload data: ', data)
    let payLoad = ((data.extractionType === "nufarm") ? {
        "fileName": data.filename,
        "rawJson": false,
        "base64": data.base64
    } : ((data.userName==="dossdemo") ? {
        "base64": data.base64,
        "docType": data.extractionType,
        "returnBase64": true
    } : ((data.extractionType==="dg poc") ? {
        "base64": data.base64,
        "returnBase64": true,
        "inputType": "base64",
        "mimeType": "application/pdf",
        "rawJson": true,
        "filename": data.filename
    } : {
        "inputType": "base64",  //ok
        "base64": data.base64,  //ok
        "extractionType": (data.extractionType === "us driver's license" || data.extractionType === "us passport") ? 'id-document' : data.extractionType,
        // "mimeType": data.type,  //ok
        "mimeType": data.type,
        "rawJson": true,  //ok
        "fileName": data.filename  //ok
        // "headers": 
        // "X-Access-Token": tokenToSend
    })))
    // const baseURL = `http://20.230.189.213:8000`
    const baseURL = `${process.env.REACT_APP_DOCKETRY_DEV_BASE_URL}`
    const nufarmTargetURL = `${process.env.REACT_APP_NUFARM_TARGET_URL}`
    const dgpocTargetURL = `${process.env.REACT_APP_DG_POC_TARGET_URL}`
    const dossdemoTargetURL = `${process.env.REACT_APP_DOSS_DEMO_TARGET_URL}`
    // const baseURL = `https://104.43.211.217:8000`

    console.log('payload after updation: ', payLoad)
    
    try {
        dispatch({
            type: NON_TEMPLATED_BEGIN
        })
        // const res = await axios.post('/recognition/nonTemplatedRec', data);
        // let url = `https://dev-nuocr-api.azurewebsites.net/extract`
        // let url = data.extractionType ==="nufarm" ? `https://nuocr-api.azurewebsites.net/poc/nufarm_extract` : `https://nuocr-api.azurewebsites.net/extract`
        let url = ((data.userName === "dossdemo") ? `${dossdemoTargetURL}` : ((data.extractionType ==="nufarm") ? `${nufarmTargetURL}` : (data.extractionType==="dg poc" ? `${dgpocTargetURL}` :`${baseURL}/extract`)))    // VM ip address
        
        // let url = 'https://nuocr-api.azurewebsites.net/extract/formrecognizer'   //cloud flask server URL
        // let url = 'http://localhost:5000/extract/formrecognizer'   //local flask server URL
        // if(data.extractionType === 'invoice' || data.extractionType === 'receipt' || data.extractionType === `id-document` || data.extractionType === `form`){
        //     url = 'https://nuocr-api.azurewebsites.net/extract/formrecognizer'
        // }
        // if(data.extractionType === 'vin'){
        //     // url = 'https://nuocr-api.azurewebsites.net/extract/vinnumber'
        //     url = 'https://nuocr-api.azurewebsites.net/extract/vinnumber'  //latest 20221206
        // }
        // if(data.extractionType === 'bank statement' || data.extractionType === 'insurance claim'){
        //     url = 'https://nuocr-api.azurewebsites.net/extract/documentextractor'
        // }
        console.log({'payLoad': payLoad, 'url' : url})
        // let tokenToSend = fetchToken()
        // let headerToBeIncluded = {
        //     headers: {
        //         'authorization': tokenToSend,
        //         'Accept' : 'application/json',
        //         'Content-Type': 'application/json'
        //     }
        // }
        // console.log('headerToBeIncluded: ', headerToBeIncluded)
        const res = await axios.post(url, payLoad, data.userName !== "dossdemo" ? { 
            headers: {"X-Access-Token": tokenToSend} 
        } : {headers: {"content-type": "application/json"}});
        // console.log("response of non templated recognition: ", res.data);
        dispatch({
            type: NON_TEMPLATED_SUCCESS,
            payload: res.data,
            nufarm: data.extractionType === "nufarm",
            dossdemo: userNameRecorded === "dossdemo",
            formType:""
        })
        console.log('check userName: ', data.userName)
    } catch (err) {
        // console.log('in error block')
        dispatch({
            type: NON_TEMPLATED_FAILED,
            payload: err
        })
    }
}