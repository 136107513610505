import React, { useEffect, useRef, useState } from 'react';
const MyComponent = ({ data }) => {
  const { ComplianceVerficationResults, FileBlobURL } = data;
  const [language, setLanguage] = useState('English');
  const [tableHeight, setTableHeight] = useState(0);
  const iframeRef = useRef(null);

  
  useEffect(() => {
    const table = document.querySelector('table'); // Assuming there's only one table in the component
    if (table) {
      setTableHeight(table.clientHeight);
    }
  }, [ComplianceVerficationResults]); // Update table height when ComplianceVerficationResults change

  useEffect(() => {
    if (iframeRef.current) {
      iframeRef.current.style.height = `${tableHeight + 20}px`; // Adjust height with some extra padding
    }
  }, [tableHeight]); // Update iframe height when table height changes
  console.log("url",`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
    FileBlobURL
  )}`)
  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };

  return (
    <div style={{ display: 'flex' }}>
      {/* Left-hand side: Compliance Verification Results */}
      <div style={{ flex: 1, padding: '10px' }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <h4>Compliance Verification Results</h4>
          <select value={language} onChange={handleLanguageChange} style={{ padding: '5px' }}>
            <option value="English">English</option>
            <option value="Portugese">Portuguese</option>
          </select>
        </div>
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
          <tbody>
            {ComplianceVerficationResults?.[language]?.map((result, index) => (
              <tr key={index}>
                <td style={{ padding: '10px', borderBottom: '1px solid black' }}>{result.ComplianceType}</td>
                {/* Add more table cells as needed */}
                <td style={{ padding: '10px', borderBottom: '1px solid black' }}>{JSON.stringify(result.is_followed)}</td>
                <td style={{ padding: '10px', borderBottom: '1px solid black' }}>{result.Explanation}</td>
                <td style={{ padding: '10px', borderBottom: '1px solid black', backgroundColor: '#f5f247', fontWeight: 'bold' }}>{result.Suggestion}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Right-hand side: Blob URL */}
      <div style={{ flex: 1, padding: '1px' }}>
        <iframe
          ref={iframeRef}
          src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
            FileBlobURL
   )}`}
          // src={`https://docs.google.com/gview?url=${FileBlobURL}&embedded=true`}
          width="100%"
          height="450" // Initial height, will be updated dynamically
          title="Document Preview"
        />
        
      </div>
    </div>
  );
};

export default MyComponent;
