import {
  USER_DETAILS_FETCH_BEGIN,
  USER_DETAILS_FETCH_SUCCESS,
  USER_DETAILS_FETCH_FAILURE,
  USER_ROLES_FETCH_BEGIN,
  USER_ROLES_FETCH_SUCCESS,
  USER_ROLES_FETCH_FAILURE,
} from '../actions/navbar';

const initialState = {
  userDetails: null,
  userDetailsLoading: false,
  userError: null,
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case USER_DETAILS_FETCH_BEGIN:
      return {
        ...state,
        userDetailsLoading: true,
        userError: null,
      };
    case USER_DETAILS_FETCH_SUCCESS:
      return {
        ...state,
        userDetailsLoading: false,
        userDetails: payload?.data?.userDetails,
        userError: null,
      };
    case USER_DETAILS_FETCH_FAILURE:
      return {
        ...state,
        userError: payload,
      };
    case USER_ROLES_FETCH_BEGIN:
      return {
        ...state,
        userRolesLoading: true,
        userRolesError: null,
      };
    case USER_ROLES_FETCH_SUCCESS:
      return {
        ...state,
        userRolesLoading: false,
        userRoles: payload?.data?.userRoles,
        userError: null,
      };
    case USER_ROLES_FETCH_FAILURE:
      return {
        ...state,
        userRoles: payload,
      };

    default:
      return state;
  }
};
