import {
  SCAN,
  UPDATE_RESULT,
  TEMPLATE_LOADED,
  SAVE_PDF_BLOB,
  SCAN_LOADING,
  DEMO_SCAN,
  DEMO_SCAN_LOADING,
} from '../actions/scan';

const initialState = {
  data: null,
  template: null,
  loading: true,
  convertedPdfData: null,
  scanloading: false,
  demoData: null,
  demoLoading: true,
  demoScanloading: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case TEMPLATE_LOADED:
      return {
        ...state,
        template: payload,
      };
    case SCAN_LOADING:
      return {
        ...state,
        data : null,
        scanloading: true,
        loading : true,
      };
    case SCAN:
      return {
        ...state,
        data: payload,
        loading: false,
        scanloading: false,
      };

    case DEMO_SCAN_LOADING:
      return {
        ...state,
        demoData: null,
        demoLoading: true,
        demoScanloading: true,
      };
    case DEMO_SCAN:
      console.log('payload', payload);
      return {
        ...state,
        demoData: payload,
        demoLoading: false,
        demoScanloading: false,
      };

    case UPDATE_RESULT:
      return {
        ...state,
        data: payload,
        loading: false,
      };
    case SAVE_PDF_BLOB:
      return {
        ...state,
        convertedPdfData: payload,
        loading: false,
      };
    default:
      return state;
  }
};
