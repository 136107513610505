import React, { useEffect, Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
// import { setAuthToken, setBaseUrl } from "./utils/setAxiosConfig";
import NotFound from './components/layout/NotFound';
import Home from './components/home/Home';
//import Login from './components/auth/Login';
import CreateTemplate from './components/template/CreateTemplate';
import CreateTemplateStandard from './components/template/CreateTemplateStandard'
import EditTemplate from './components/template/EditTemplate';
// import Dashboard from './components/dashboard/Dashboard';
import Dashboard from './components/dashboard/AdminDashboard';
import SuperAdminDashboard from './components/dashboard/SuperAdminDashboard';
//import Scan from './components/template/Scan';
import Scan from './components/template/Scan1';
import Result from './components/result/Result';
import Results from './components/result/Results';
import PrivateRoute from './components/routing/PrivateRoute';
import ViewResult from './components/result/viewResult';
import CallViaAPI from './viajsApiCalls';
import CallViaAPI_Standard from './viajsApiCallsStandard'
import './App.css';
import ErrorBoundary from './ErrorBoundary';
import Template from './components/template/Template';
import DataRecognition from './components/data_recognition/DataRecognition';
//import TemplateReview from './components/template-review/TemplateReview';

import DynamicFormBuilder from "./components/DynamicForm/DynamicFormBuilder";
import DynamicFormView from "./components/common/DynamicFormView";

//Redux
import store from './store';
import { loadUser } from './actions/auth';
import ForgotPassword from './components/common/forgotpassword1';
import ChangePassword from './components/common/ChangePassword';
import MailResetPassword from './components/common/mailresetpassword1';
import ApiKey from './components/common/ApiKey';

import TemplateDetails from './components/template-details/templateDetails';
import TemplateEdit from './components/template-details/templateEdit';
import ManageUser from './components/admin_v2/Manageuser';
import UploadFile from './components/data_recognition/Uploadfiles';
import TemplateReview from './components/template-review/TemplateReview';
import Extracting from './components/data_recognition/extracting';
import Login from './components/auth/login1';
import DocumentSearch from './components/documentSearch';
import Intelligent from './components/intelligent/intelligent';
import NonTemplatedflow from './components/NonTemplatedflow/nontemplatedflow';
import NonTemplateRecognition from './components/nontemplatedRecognition/NonTemplateRecognition';
import IntelligentMed from './components/IntelligentFlow_MedAssist/MedAssist';
import IntelligentSelect from './components/Intelligent_select/intelligent_select';
import IntelligentRecognition from './components/intelligentRecognition/intelligentRecognition';
import Activeconnction from './components/admin_v2/activeconnections';
import Confirm from './components/admin_v2/connectionconfirm';
import Dashmin from './components/dashmin/Dashmin';
import IPFSChatBot from './components/chatBot/ipfs-chatboat/IPFS-chatboat';


//--------------------------------------------new

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);
  return (
    <Fragment>
      <ErrorBoundary>
        <Switch>
          <Route exact path='/login' component={Login} />
          <Route exact path='/' component={Home} />
          {/* <Route exact path='/LandingPage' component={Home} /> */}

          <PrivateRoute
            exact
            path='/createtemplate'
            component={CreateTemplate}
          />
          <PrivateRoute
            exact
            path='/createtemplate-standard'
            component={CreateTemplateStandard}
          />
          <PrivateRoute exact path='/edit-template' component={EditTemplate} />
          {/* <PrivateRoute exact path="/dashboard" component={Dashboard} /> */}
          {/* <PrivateRoute exact path='/ocr' component={Scan} /> */}
          {/* <PrivateRoute exact path='/scan' component={Scan} /> */}
          <PrivateRoute exact path='/scan' component={Scan} />
          <PrivateRoute exact path='/data-recognition' component={DataRecognition} />
          <PrivateRoute exact path='/non-templatedflow' component={NonTemplatedflow} />

          <PrivateRoute exact path='/IntelligentMed' component={IntelligentMed} />
          <PrivateRoute
            exact
            path='/intelligent-select'
            component={IntelligentSelect}
          />

          <PrivateRoute
            exact
            path='/intelligent-upload/:type'
            component={Intelligent}
          />

          <PrivateRoute
            exact
            path='/intelligent-recognition'
            component={IntelligentRecognition}
          />
          <PrivateRoute
            exact
            path='/nontemplate-recognition'
            component={NonTemplateRecognition}
          />
          
          {/* <PrivateRoute exact path='/template-review' component={TemplateReview} /> */}

          <PrivateRoute exact path='/templates' component={Template} />
          <PrivateRoute exact path='/template-edit' component={TemplateEdit} />
          <PrivateRoute
            exact
            path='/template-review'
            component={TemplateReview}
          />

          <PrivateRoute exact path='/result/:id' component={ViewResult} />
          <PrivateRoute exact path='/result' component={Result} />
          <PrivateRoute exact path='/results' component={Results} />
          {/* <Route exact path='/login' component={Login} /> */}

          {/* <PrivateRoute exact path='/dashboard' component={Dashboard} /> */}
          <PrivateRoute exact path='/dashmin' component={Dashmin} />
          <PrivateRoute exact path='/chatbot' component={IPFSChatBot} />
          <PrivateRoute exact path='/superadmin-dashboard' component={SuperAdminDashboard} />
          <Route exact path='/forgotpassword' component={ForgotPassword} />
          <PrivateRoute exact path='/callApi' component={CallViaAPI} />
          <PrivateRoute exact path='/callApi-standard' component={CallViaAPI_Standard} />
          
          {/* Dynamic form */}
          <PrivateRoute exact path='/dynamic-form' component={DynamicFormBuilder} />
          <Route exact path='/dynamic-form/:id' component={DynamicFormView} />

          <PrivateRoute
            exact
            path='/changepassword'
            component={ChangePassword}
          />
          <Route
            exact
            path='/mail-reset-password/:Id'
            component={MailResetPassword}
          />
          <PrivateRoute exact path='/ApiKey' component={ApiKey} />
          <PrivateRoute exact path='/ApiKey' component={ApiKey} />

          <PrivateRoute
            exact
            path='/templatedetails'
            component={TemplateDetails}
          />
         
          <PrivateRoute exact path='/manageuser' component={ManageUser} />
          <PrivateRoute exact path='/uploadfile' component={UploadFile} />
          <PrivateRoute exact path='/extracting' component={Extracting} />
          <PrivateRoute
            exact
            path='/documentsearch'
            component={DocumentSearch}
          />
           <PrivateRoute
            exact
            path='/activeconnections'
            component={Activeconnction}
          />
            <Route
            exact
            path='/connectionconfirm/:id'
            component={Confirm}
          />

          <Route component={NotFound} />
        </Switch>
      </ErrorBoundary>
    </Fragment>
  );
};

export default App;
