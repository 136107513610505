/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */

import { userToken } from '../constants/Constants';

export const logout = (props) => {
  localStorage.removeItem(userToken);
  props.history.push('/login');
};

export const signIn = (userToken, props, path) => {
  localStorage.setItem('token', JSON.stringify(userToken));
  props.history.push(path);
};

export const getUserName = () => {
  const userTkn = JSON.parse(localStorage.getItem(userToken)) || {};
  const userName = (Object.entries(userTkn).length !== 0 && !ifTokenExpired(userTkn)) ? userTkn.userName : '';
  return userName;
};

export const getTenantType = () => {
  const userTkn = JSON.parse(localStorage.getItem(userToken)) || {};
  const tenantType = (Object.entries(userTkn).length !== 0 && !ifTokenExpired(userTkn)) ? userTkn.tenantType : '';
  return tenantType;
};

export const getCandidateId = () => {
  const userTkn = JSON.parse(localStorage.getItem(userToken)) || {};
  const candidateId = (Object.entries(userTkn).length !== 0 && !ifTokenExpired(userTkn)) ? userTkn.candidateId : '';
  return candidateId;
};
export const getUserTenant = () => {
  const userTkn = JSON.parse(localStorage.getItem(userToken)) || {};
  const userTenant = (Object.entries(userTkn).length !== 0 && !ifTokenExpired(userTkn)) ? userTkn.userTenant : '';
  return userTenant;
};
export const getUserId = () => {
  const userTkn = JSON.parse(localStorage.getItem(userToken)) || {};
  const userId = (Object.entries(userTkn).length !== 0 && !ifTokenExpired(userTkn)) ? userTkn.userId : '';
  return userId;
};

export const getUserRole = () => {
  const userTkn = JSON.parse(localStorage.getItem(userToken)) || {};
  const userRole = (Object.entries(userTkn).length !== 0 && !ifTokenExpired(userTkn)) ? userTkn.role : '';
  return userRole;
};


export const getUserToken = () => {
  const userTkn = JSON.parse(localStorage.getItem(userToken)) || {};
  const token = (Object.entries(userTkn).length !== 0 && !ifTokenExpired(userTkn)) ? userTkn : '';
  return token;
};

export const getUserPrevilage = () => {
 const userTkn = JSON.parse(localStorage.getItem(userToken)) || {};
  const previlage = (Object.entries(userTkn).length !== 0 && !ifTokenExpired(userTkn)) ? userTkn.previlage : '';
  return previlage;
};

export const isAuthenticatedUser = () => {
  const userTkn = JSON.parse(localStorage.getItem(userToken)) || {};
  if (Object.entries(userTkn).length === 0 || ifTokenExpired(userTkn)) {
    return false;
  }
  return true;
};

const ifTokenExpired = (userToken) => {
  const expiry = Date.parse(userToken.expiration);
  if (expiry > Date.now()) {
    return false;
  }
  return true;
};

export const getUserFirstName = () => {
  const userTkn = JSON.parse(localStorage.getItem(userToken)) || {};
  const userName = (Object.entries(userTkn).length !== 0 && !ifTokenExpired(userTkn)) ? userTkn.firstname : '';
  return userName;
};
export const getUserSecondName = () => {
  const userTkn = JSON.parse(localStorage.getItem(userToken)) || {};
  const userName = (Object.entries(userTkn).length !== 0 && !ifTokenExpired(userTkn)) ? userTkn.lastname : '';
  return userName;
};


export const getSchoolID = () => {
  const userTkn = JSON.parse(localStorage.getItem(userToken)) || {};
  const getSchoolID = (Object.entries(userTkn).length !== 0 && !ifTokenExpired(userTkn)) ? userTkn.schoolId : '';
  return getSchoolID;
};
export const getSchoolIDS = () => {
  const userTkn = JSON.parse(localStorage.getItem(userToken)) || {};
  const getSchoolID = (Object.entries(userTkn).length !== 0 && !ifTokenExpired(userTkn)) ? userTkn.schools : [];
  return getSchoolID;
};