export const styles = {
    chatWithMeButton: {
        cursor: 'pointer',
        boxShadow: '0px 0px 16px 6px rgba(0, 0, 0, 0.33)',
        // Border
        borderRadius: '50%',
        // Background 
        // backgroundImage: `url(https://cdn.pixabay.com/photo/2019/03/21/15/51/chatbot-4071274__340.jpg)`,
        backgroundImage: `url(https://www.internetandtechnologylaw.com/files/2019/06/iStock-872962368-chat-bots.jpg)`,
        // backgroundImage: `url(https://chat-engine-assets.s3.amazonaws.com/tutorials/my-face-min.png)`,
        // backgroundImage: `url(https://media.istockphoto.com/photos/robot-woman-scifi-android-female-artificial-intelligence-3d-render-picture-id1189902622?k=20&m=1189902622&s=612x612&w=0&h=2JA_5PsE7d7JEHHEUDmVqjrptTUF118Mz-RbMR47-aE=)`,
        // backgroundImage: 'bot.png',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: '48px',
        // Size
        width: '55px',
        height: '55px',
    },
    avatarHello: { 
        // Position
        position: 'absolute', 
        left: 'calc(-100% - 40px - 35px)', 
        top: 'calc(50% - 18px)', 
        // Layering
        zIndex: 1000,
        boxShadow: '0px 0px 16px 6px rgba(0, 0, 0, 0.33)',
        // Border
        padding: '6px 6px 6px 8px',
        borderRadius: '24px', 
        // Color
        backgroundColor: '#f9f0ff',
        color: 'black',
    },
    chatWindow: {
        // Position
        position: 'fixed',
        bottom: '77px',
        right: '24px',
        // Size
        width: '25%',
        height: '60%',
        maxWidth: 'calc(100% - 48px)',
        maxHeight: 'calc(100% - 48px)',
        backgroundColor: 'white',
        // Border
        borderRadius: '12px',
        border: `2px solid #a8a8a8`,
        overflow: 'hidden',
        // Shadow
        boxShadow: '0px 0px 16px 6px rgba(0, 0, 0, 0.33)',
        zIndex: 1000
    },
    chatWindowNotInUse: {
        // Position
        position: 'fixed',
        bottom: '70px',
        right: '24px',
        // Size
        width: '0%',
        height: '0%',
        maxWidth: 'calc(100% - 48px)',
        maxHeight: 'calc(100% - 48px)',
        backgroundColor: 'white',
        // Border
        borderRadius: '12px',
        border: `2px solid #7a39e0`,
        overflow: 'hidden',
        // Shadow
        boxShadow: '0px 0px 16px 6px rgba(0, 0, 0, 0.33)',
    },
    chatBotButtons: {
        backgroundColor: 'transparent',
        backgroundRepeat: 'no-repeat',
        border: 'none',
        cursor: 'pointer',
        overflow: 'hidden',
        outline: 'none',
        color: 'blue',
        padding: '5px 5px'
    }
}