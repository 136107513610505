
import { toast } from "react-toastify";
import axios from 'axios';
export const MAILRESETPASSWORD_BEGIN = 'MAILRESETPASSWORD_BEGIN';
export const MAILRESETPASSWORD_SUCCESS = 'MAILRESETPASSWORD_SUCCESS';
export const MAILRESETPASSWORD_FAILURE = 'MAILRESETPASSWORD_FAILURE';



export const postMailResetPassword = (data) => async dispatch => {
   
  try {
      dispatch({
          type: MAILRESETPASSWORD_BEGIN
      })
      
      const res = await axios.post('/mail-reset-password',data)
   
      dispatch({
          type: MAILRESETPASSWORD_SUCCESS,
          payload: res.data.message
      })
    res.data.message==="success"?toast.success(res.data.success,{autoClose:4000}):toast.error(res.data.error,{autoClose:4000})

     
  } catch (err) {
   
      dispatch({
          type: MAILRESETPASSWORD_FAILURE,
          payload: err
      })
     
      toast.error("Failed to send reset mail",{autoClose:4000})
  }

}