import {
    MAILRESETPASSWORD_BEGIN,
    MAILRESETPASSWORD_SUCCESS,
    MAILRESETPASSWORD_FAILURE,
  } from '../../actions/common/mailresetpassword1';
  
  const initstate = {
    mailresetpassword: [],
  };
  const mailResetpasswordReducer = (state = initstate, action) => {
    switch (action.type) {
      case MAILRESETPASSWORD_BEGIN:
        return {
          ...state,
          loading: true,
          error: null,
        };
  
      case MAILRESETPASSWORD_SUCCESS:
        return {
          ...state,
          loading: false,
          mailresetpassword: action.payload,
        };
  
      case MAILRESETPASSWORD_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload.error,
        };
  
      default:
        return state;
    }
  };
  export default mailResetpasswordReducer;
  