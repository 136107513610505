import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  loadTemplate,
} from '../../actions/scan';
import axios from 'axios';
import { Fragment } from 'react';
import { getMediAssitForms, getnontemplatedResult, clearRecdata} from "../../actions/getNontemplateform";


const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const Intelligent = (props) => {

  const dispatch = useDispatch();
  const { formdata, error, loading } = useSelector((state) => ({
    formdata: state.getNontemplateform.data,
    error: state.getNontemplateform.error,
    loading: state.getNontemplateform.loading
  }), shallowEqual);

  const [state, setState] = useState({
    template: null,
    prevImgSource: null,
    uploadedFile: [],
    height: null,
    width: null,
    typeofFile: null,
    imgSasUrl: null,
    Base64String: null,
    imgLoaded: false,
    imgUploadLoaded: true,
    multipageData: [],
    selectedTemp: false,
    loading: false,
    filename: null,
    formtype: null,
    selectedFormType: null
  });

  const history = useHistory();
  useEffect(() => {
  }, [state.uploadedFile]);


  useEffect(() => {
    dispatch(getMediAssitForms());
  }, []);

  /*----------------------------closing function for file button---------------------------*/
  // const closeFile=()=>{
  //   setState({
  //     uploadedFile:null,
  //     prevImgSource:null
  //   })
  // }
  /*-----------------------------------*/
  const handleFileChoice = async (e) => {
    const { uploadedFile } = state;
    if (e.target.files[0] != null) {
      setState({
        ...state,
        imgUploadLoaded: false,

      });
      const file = URL.createObjectURL(e.target.files[0]);
      const type = e.target.files[0].type;
      const filename = e.target.files[0].name;
      const fileuploaded = e.target.files[0];
      const uploadedFileBase64 = await toBase64(e.target.files[0]);
      if (type == 'application/pdf') {

        const data = {
          Pdfbase64: uploadedFileBase64,
          fileType: type,
          fileName: filename,
        };
        try {
          uploadedFile.push(fileuploaded);
          axios
            .post('/recognition/pdfBlobSave', data)
            .then(function (response) {
              if (response.status == 200) {
                // console.log('res from axios pdfBlobSave ', response.data);
                // console.log('res from axios pdfBlobSave ', response.data.base64);
                // console.log('uploadedFileBase64 ', uploadedFileBase64);

                setState({
                  ...state,
                  imgSasUrl: response.data.imgSasUrl,
                  Base64String: uploadedFileBase64,
                  imgLoaded: true,
                  typeofFile: type,
                  imgUploadLoaded: true,
                  prevImgSource: response.data[0].imgSasUrl,
                  multipageData: response.data,
                  filename: filename
                });
                // uploadedFile.push(fileuploaded);
              }
            })
            .catch(function (error) {
              console.log('Error in axios', error);
            });
        } catch (err) {
          console.error(err);
        }
      } else {
        setState({
          ...state,
          prevImgSource: file,
          imgUploadLoaded: true,
          filename: filename,
          Base64String: uploadedFileBase64
        });
        uploadedFile.push(fileuploaded);
      }
    }
  };

  const handleChange = (e) => {
    setState({
      ...state,
      selectedFormType: e.target.value
    });
    //dispatch(clearRecdata());
  };


  const handleScan = async (e) => {
    e.preventDefault();
    try {
      const { Base64String, filename, selectedFormType,prevImgSource } = state;
      if(selectedFormType!==null)
      {
        let tempObj = {
          base64: Base64String,
          filename: filename,
          formtype: selectedFormType
        }
        console.log("tempObj", tempObj);
        dispatch(getnontemplatedResult(tempObj));
  
        let data = {
          templateData: prevImgSource,
          imgData: {
            y_scale: 700 / state.height,
            x_scale: 700 / state.width,
          },
          userFileName:filename,
          type:selectedFormType
          
        };
  
        console.log("data",data);
        dispatch(loadTemplate(data));
        history.push({ pathname: '/nontemplate-recognition' });
        
      }
      else{
        alert("Please select form type")
      }
      
    } catch (err) {
      console.error(err);
    }

  };

  const onImgLoad = ({ target: img }) => {
    setState({
      ...state,
      height: img.naturalWidth,
      width: img.naturalHeight,
    });
  };

  return (
    <div className='app-content container1'>
      {/* <h1 className='page-header mb-3'>
        Upload document <br />
        <small>
          Upload your files containing data to be extracted and we will do the
          rest.
        </small>
      </h1> */}
      <div className="row">
        <div className="col-xl-6">
          <div className="row">
            <div className="col-xl-9">
              <h1 className="page-header mb-3">
                Upload document<br /><small>Upload your files containing data to be extracted and we will do the
                  rest. </small></h1>
            </div>
            <div className="col-xl-3 start_reg">
              {state.uploadedFile.length !== 0
                ? (
                  (
                    <div className="addtemplatediv">

                      <a href="#"
                        className="btn btn-yellow btn-rounded btn-sm ps-5 pe-5 pt-2 pb-2 fs-14px font-weight-600"
                        onClick={(e) => handleScan(e)}
                      > Submit</a>

                    </div>
                  ))
                : (
                  (<Fragment></Fragment>))}
            </div>
          </div>
        </div>
        <div className="col-xl-6">
        </div>
      </div>
      <div className='row'>
        <div className='col-xl-6'>
          <div className='dragdropmaindivNonTemplated'>
            <form>
              <input
                type='file'
                className='NuOcrfile'
                onChange={handleFileChoice}
              />

              <p>Drag your files here or click in this area.</p>
            </form>
          </div>
          <span>{!state.imgUploadLoaded ? <p>File uploading please wait...</p> : null}</span>
          {state.uploadedFile.length !== 0 ? (
            <>
              <div className='fileslistaindiv'>
                <h3 className='page-header mb-4'>Uploaded Files </h3>
              </div>
              <div className='documentlist'>
                {state.uploadedFile &&
                  state.uploadedFile.map((item) => (
                    <ul>
                      <li className='selectfile'>
                        {item.name}
                        <span /* onClick={()=>closeFile()}*/>
                          <i className='fas fa-times'></i>
                        </span>
                      </li>
                      {/* <li>Document 1 <span><i className="fas fa-times"></i></span></li>
                                    <li>Document 1 <span><i className="fas fa-times"></i></span></li> */}
                    </ul>
                  ))}
              </div>
            </>
          ) : null}
          <div className='fileslistaindiv'>
            <h3 className='page-header mb-4'>Form Type </h3>
          </div>
          <div >
            <div class="search_categories">
              <div class="select">
                <select name="search_categories" id="search_categories" onChange={handleChange}>
                  <option value="1" selected="selected">Select a Value</option>
                  {formdata && (
                    <>
                      {formdata.map((item, key) => (
                        <option key={key} value={item.templatename}>
                          {item.templatename}
                        </option>
                      ))}
                    </>
                  )}
                </select>
              </div>
            </div>

          </div>
        </div>

        <div className="col-xl-6">
          <div className="scandoumentdiv">
            <img
              className="scandoumentdiv"
              src={state.prevImgSource}
              onLoad={onImgLoad}
              href="#"
            // alt="Loading..."
            />
          </div>
        </div>
      </div>
      <div className='Sspace'></div>

      {/* {state.uploadedFile && state.uploadedFile.length > 0 && (
        <div className='addtemplatediv'>
          <a
            href='#'
            className='btn btn-yellow btn-rounded btn-sm ps-5 pe-5 pt-2 pb-2 fs-14px font-weight-600'
            onClick={(e) => handleScan(e)}
          >
            {' '}
            Start Recognition
          </a>
        </div>
      )} */}
    </div>
  );
};


export default Intelligent
