import React, { useEffect } from 'react'
import { Redirect, useHistory } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const notify = (text, category) => {
  if (category === "success") {
    toast.success(text, {
      autoClose: 4000,
    });
  } else {
    toast.error(text, {
      autoClose: 4000,
    })
  }
};

function CreateTemplate() {
  let history = useHistory();

  useEffect(() => {
    const jsondata = localStorage.getItem('JsonBody');
    const Bearertoken = localStorage.getItem('token');
    const imageData = localStorage.getItem('imageData');

    if (jsondata != undefined) {
      axios.post('/templates/create', {
        body: {
          jsondata: JSON.parse(jsondata),
          token: JSON.parse(Bearertoken)
        },
      }).then(responseJson => {
        notify("Saved successfully", "success")
        localStorage.removeItem('JsonBody');
        history.push("/dashboard")
      })
        .catch((error) => {
          console.log(" ERROR: ", error);
        });
    }

    if (imageData != undefined) {
      // axios.post('/templates/uploadTemplateImage', {
      //   body: {
      //     imageData: JSON.parse(imageData),
      //     token: JSON.parse(Bearertoken)
      //   },
      // }).then(responseJson => {
      //   localStorage.removeItem('JsonBody');
      // })
      //   .catch((error) => {
      //     console.log(" ERROR: ", error);
      //   });
    }

  }, [])

  /*<Redirect to='/dashboard'></Redirect>*/
  const btngoback = (e) => {
    history.push("/ocr");
    window.location.reload(false);
  }


  return (
    <>
      <div>
        {btngoback}
      </div>
    </>
  )
}

export default CreateTemplate;
