import axios from 'axios';
export const SCAN = 'SCAN';
export const SCAN_LOADING = 'SCAN_LOADING';
export const DEMO_SCAN = 'DEMO_SCAN';
export const DEMO_SCAN_LOADING = 'DEMO_SCAN_LOADING';
export const UPDATE_RESULT = 'UPDATE_RESULT';
export const TEMPLATE_LOADED = 'TEMPLATE_LOADED';
export const SAVE_PDF_BLOB = 'SAVE_PDF_BLOB';

export const loadTemplate = (data) => (dispatch) => {
  console.log('loadTemplate data: ', data)
  dispatch({
    type: TEMPLATE_LOADED,
    payload: data,
  });
};

export const documentScan = (base64, documentId, documentName, fileName) => async (dispatch) => {
    console.log({'base64': base64, 'documentId': documentId, 'documentName': documentName, 'fileName': fileName})
    try {
      let body = {
        fileName: fileName,
        documentName: documentName,
        documentId: documentId,
        base64: base64.split(',')[1],
        readStatus: true
      };
      dispatch({
        type: SCAN_LOADING,
      });
      const userToken = JSON.parse(localStorage.getItem('token'))
      console.log('payload from documentScan: ', body)
      const templatedExtractionURL = `${process.env.REACT_APP_DOCKETRY_DEV_BASE_URL}/template/extract_template`
      const res = await axios.post(templatedExtractionURL, body, {headers: {"X-Access-Token": userToken}});
      console.log('documentScan Success res.data: ', res.data)
      dispatch({
        type: SCAN,
        payload: res.data,
      });

    } catch (err) {
      console.error(err);
    }
  };

export const demoDocumentScan =
  (file, annotationJson, type, filename) => async (dispatch) => {
    try {
      let body = {
        // ...template,
        annotationJson: annotationJson,
        base64: file,
        type,
        fileN: filename
      };
      dispatch({
        type: DEMO_SCAN_LOADING,
      });
      const res = await axios.post('/recognition/intelligent', body);
      dispatch({
        type: DEMO_SCAN,
        payload: res.data,
      });
    } catch (err) {
      console.error(err);
    }
  };

export const updateResult = (newData) => (dispatch) => {
  dispatch({
    type: UPDATE_RESULT,
    payload: newData,
  });
};

export const pdfBlobInsert = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SCAN_LOADING,
    });
    const res = await axios.post('/recognition/pdfBlobSave', data);
    dispatch({
      type: SCAN,
      payload: res,
    });
  } catch (err) {
    console.error(err);
  }
};
