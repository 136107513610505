import React, { useState, useEffect } from 'react';
import loadingimg from '../../images/Docketry_Recognizing.gif';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
const Extracting = (props) => {

  return (
    <>
      <div className='app-content container1'>
        {!props.isIntelligent && (
          <div className='breadcrumbmaindiv'>
            <a href='/data-recognition'>Choose Template </a> -
            <a href='/uploadfile'> Upload Document </a> -
            <a href='#'> Extract </a>
          </div>
        )}
       
        <div className="page-header mb-3">
        <h3>Data Extraction in Progress.....</h3 > 
        <span className="page-subtitle " >
        {/* <h4>Your document is being recognized by Docketry.ai</h4>  */}
                      <h4>Please wait..</h4>
            <br /> <br /> <h3>Do not press refresh or back button</h3>
          </span> 
        </div>
                    
        <div className='row'>
          <div className='col-xl-12'>
            <div className='loadingmaindiv'>
              <div className='loadingdiv'>
                <img src={loadingimg} alt='loading' />
              </div>
            </div>
          </div>
        </div>

        <div className='Sspace'></div>
      </div>
    </>
  );
};
export default Extracting;
