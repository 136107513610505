import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { saveAs } from 'file-saver';

import UTIF from "utif";
import {
  loadTemplate,
} from '../../actions/scan';
import axios from 'axios';
import { Fragment } from 'react';
import { getUserToken } from "../../services/AuthService";
import { getnontemplatedforms, getnontemplatedResult, clearRecdata, setExcelData, setZipData, setGePowerData, setReadData } from "../../actions/getNontemplateform";
import TiffViewer from './TiffViewer';
import FilePreview from '../common/FilePreview';
// const docTypes = ["Insurance Claim", "Bank Statement", "Receipt", "Invoice", "Form", "US Passport", "US Driver's License", "VIN", "Nufarm"]



const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const NonTemplatedflow = (props) => {

  const userName = JSON.parse(localStorage.getItem("username"));

  const dispatch = useDispatch();
  const { formdata, error, loading } = useSelector((state) => ({
    formdata: state.getNontemplateform.data,
    error: state.getNontemplateform.error,
    loading: state.getNontemplateform.loading
  }), shallowEqual);
  const [documentUrl, setDocumentUrl] = useState('');
  const [documentType, setDocumentType] = useState('');
  const [blobUrl, setBlobUrl] = useState('');
  const [state, setState] = useState({
    filetype2: null,
    template: null,
    prevImgSource: null,
    uploadedFile: [],
    height: null,
    width: null,
    typeofFile: null,
    imgSasUrl: null,
    Base64String: null,
    imgLoaded: false,
    imgUploadLoaded: true,
    multipageData: [],
    selectedTemp: false,
    loading: false,
    filename: null,
    formtype: null,
    selectedFormType: null,
    // documentUrl:""
  });

  const history = useHistory();
  useEffect(() => {
  }, [state.uploadedFile]);

  // console.log('history: ', history)
  const [docTypes, setDocTypes] = useState([])

  const tokenToSend = JSON.parse(localStorage.getItem("token"))
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_DOCKETRY_DEV_BASE_URL}/util/list`, { headers: { "X-Access-Token": tokenToSend } }).then((res) => setDocTypes(res.data))
    dispatch(getnontemplatedforms());
  }, []);

  /*----------------------------closing function for file button---------------------------*/
  // const closeFile=()=>{
  //   setState({
  //     uploadedFile:null,
  //     prevImgSource:null
  //   })
  // }
  /*-----------------------------------*/
  const handleFileChoice = async (e) => {
    console.log('file uploaded e.target.files: ', e?.target && e.target?.files[0].type)
    const { uploadedFile } = state;
    if (e && e?.target && e.target?.files[0] !== null) {
      setState({
        ...state,
        imgUploadLoaded: false,

      });
      let file = ''
      let type = ''
      let filename = ''
      let fileuploaded = ''
      let uploadedFileBase64 = ''
      if (e && e?.target && e.target?.files[0].type === 'image/tiff') {
        file = URL.createObjectURL(e.target.files[0]);;
        type = e.target.files[0].type;
        filename = e.target.files[0].name;
        fileuploaded = e.target?.files[0];
        uploadedFileBase64 = await toBase64(e.target.files[0]);
        localStorage.setItem('tiff', file)
      }
      else {
        file = URL.createObjectURL(e.target.files[0]);
        type = e.target.files[0].type;
        filename = e.target.files[0].name;
        fileuploaded = e.target.files[0];
        uploadedFileBase64 = await toBase64(e.target.files[0]);
        
      }
      localStorage.setItem("upLoadedFileUrl",file)
      localStorage.setItem("fileType",e?.target && e.target?.files[0].type)
      if (type === 'application/pdf'||type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'||type === 'application/msword') {
        
        if(file){
          setDocumentUrl(file)
          
        }
          
        const data = {
          Pdfbase64: uploadedFileBase64,
          fileType: type,
          fileName: filename,
        };
        console.log('data pdf uploaded: ', data)
        try {
          uploadedFile.push(fileuploaded);
          // console.log('before /recognition/pdfBlobSave')
          // if (state.selectedFormType !== "dandb"&&state.selectedFormType !== "nufarm"&&state.selectedFormType.replace(/\s/g, '').toLowerCase()!=="ameristarusareport") 
            // localStorage.setItem('base64', JSON.stringify(data.Pdfbase64))
          if (type === 'application/pdf'||type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'||type === 'application/msword') {
            setState((oldstate) => {
              return ({
                ...oldstate,
                // imgSasUrl: response.data.imgSasUrl,
                Base64String: uploadedFileBase64,
                imgLoaded: true,
                typeofFile: type,
                imgUploadLoaded: true,
                // uploadedFile:file,
                // prevImgSource: response.data[0].imgSasUrl,
                // multipageData: response.data,
                filename: filename,
                // documentUrl:file
              })
            });
            // uploadedFile.push(fileuploaded);
          }

        } catch (err) {
          console.error(err);
        }
      } else {
        // console.log('base64', uploadedFileBase64)
        // if(state.selectedFormType !== "dandb"&&state.selectedFormType.replace(/\s/g, '').toLowerCase()!=="ameristarusareport")
          // localStorage.setItem('base64', JSON.stringify(uploadedFileBase64))
        setState((oldState) => {
          return ({
            ...oldState,
            prevImgSource: file,
            imgUploadLoaded: true,
            filename: filename,
            Base64String: uploadedFileBase64,
            filetype2: type
          })
        });
        let temp_uploadedFile = [...state.uploadedFile]
        temp_uploadedFile.push(fileuploaded)
        setState((oldState) => {
          return ({
            ...oldState,
            uploadedFile: temp_uploadedFile
          })
        })
      }
    }
  };

  const handleChange = (e) => {
    setDocumentType(e.target.value)
    setState({
      ...state,
      selectedFormType: e.target.value
  
    });
    dispatch(clearRecdata());
  };
  const saveApiContentAsExcel = async (apiResponse, fileName) => {
    const blob = new Blob([apiResponse], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    // Use FileSaver.js to save the Blob
    saveAs(blob, fileName);
  };
 const getExcelContentForOptimumnet=(bas64,filename,formType)=>{
  const tokenToSend = JSON.parse(localStorage.getItem("token"))
  const baseURL = `${process.env.REACT_APP_DOCKETRY_DEV_BASE_URL}`
  const nufarmTargetURL = `${process.env.REACT_APP_NUFARM_TARGET_URL}`
  const dgpocTargetURL = `${process.env.REACT_APP_DG_POC_TARGET_URL}`
  const dossdemoTargetURL = `${process.env.REACT_APP_DOSS_DEMO_TARGET_URL}`
  const userName= JSON.parse(localStorage.getItem("userDetails")).username
let data = JSON.stringify({
  "fileName": filename,
  "inputType": "base64",
  "base64": `${bas64}`,
  "mimeType": "application/pdf",
  "extractionType": formType.toLowerCase(),
  "rawJson": true
});
let url = ((userName === "dossdemo") ? `${dossdemoTargetURL}` : ((data.extractionType ==="nufarm") ? `${nufarmTargetURL}` : (data.extractionType==="dg poc" ? `${dgpocTargetURL}` :`${baseURL}/extract`)))    // VM ip address

let config = {
  method: 'post',
  maxBodyLength: Infinity,
  url: url,
  headers: { 
    'accept': 'application/json', 
    'X-Access-Token': tokenToSend, 
    'Content-Type': 'application/json'
  },
  responseType: 'blob',  
  data : data
};

axios.request(config)
.then((response) => {
  // console.log(JSON.stringify(response.data));
  try {
    dispatch(setExcelData(response,filename,formType));
  } catch (error) {
    console.log('Error parsing JSON:', error);
  }
  // saveApiContentAsExcel(response.data,'optimumtest.xlsx')
  
})
.catch((error) => {
  console.log(error);
});

 }
 const getReadValues=(bas64,filename,formType)=>{
  const tokenToSend = JSON.parse(localStorage.getItem("token"))
  const baseURL = `${process.env.REACT_APP_DOCKETRY_DEV_BASE_URL}`
  const nufarmTargetURL = `${process.env.REACT_APP_NUFARM_TARGET_URL}`
  const dgpocTargetURL = `${process.env.REACT_APP_DG_POC_TARGET_URL}`
  const dossdemoTargetURL = `${process.env.REACT_APP_DOSS_DEMO_TARGET_URL}`
  const userName= JSON.parse(localStorage.getItem("userDetails")).username
let data = JSON.stringify({
  "fileName": filename,
  "inputType": "base64",
  "base64": `${bas64}`,
  "mimeType": "application/pdf",
  "extractionType": formType.toLowerCase(),
  "rawJson": true
});
let url = baseURL + '/extract';

let config = {
  method: 'post',
  maxBodyLength: Infinity,
  url: url,
  headers: { 
    'accept': 'application/json', 
    'X-Access-Token': tokenToSend, 
    'Content-Type': 'application/json'
  },  
  data : data
};

axios.request(config)
.then((response) => {
  // console.log(JSON.stringify(response.data));
  try {
    dispatch(setReadData(response,filename,formType,bas64));
  } catch (error) {
    console.log('Error parsing JSON:', error);
  }
  // saveApiContentAsExcel(response.data,'optimumtest.xlsx')
  
})
.catch((error) => {
  console.log(error);
});

 }
 const getZipContent=(bas64,filename,formType)=>{
  const tokenToSend = JSON.parse(localStorage.getItem("token"))
  const baseURL = `${process.env.REACT_APP_DOCKETRY_DEV_BASE_URL}`
  const nufarmTargetURL = `${process.env.REACT_APP_NUFARM_TARGET_URL}`
  const dgpocTargetURL = `${process.env.REACT_APP_DG_POC_TARGET_URL}`
  const dossdemoTargetURL = `${process.env.REACT_APP_DOSS_DEMO_TARGET_URL}`
  const userName= JSON.parse(localStorage.getItem("userDetails")).username
  let data = JSON.stringify({
    "fileName": filename,
    "inputType": "base64",
    "base64": `${bas64}`,
    "mimeType": "application/pdf",
    "extractionType":formType,
    "rawJson": true
  });
let url = ((userName === "dossdemo") ? `${dossdemoTargetURL}` : ((data.extractionType ==="nufarm") ? `${nufarmTargetURL}` : (data.extractionType==="dg poc" ? `${dgpocTargetURL}` :`${baseURL}/extract`)))    // VM ip address

let config = {
  method: 'post',
  maxBodyLength: Infinity,
  url: url,
  headers: { 
    'accept': 'application/json', 
    'X-Access-Token': tokenToSend, 
    'Content-Type': 'application/json'
  },
  responseType: 'blob',  
  data : data
};

axios.request(config)
.then((response) => {
  // console.log(JSON.stringify(response.data));
  try {
    dispatch(setZipData(response,filename,formType));
  } catch (error) {
    console.log('Error parsing JSON:', error);
  }
  // saveApiContentAsExcel(response.data,'optimumtest.xlsx')
  
})
.catch((error) => {
  console.log(error);
});

 }
 const getGePowerData=(tempObj)=>{
  const tokenToSend = JSON.parse(localStorage.getItem("token"))
  const baseURL = `${process.env.REACT_APP_DOCKETRY_DEV_BASE_URL}`
  const nufarmTargetURL = `${process.env.REACT_APP_NUFARM_TARGET_URL}`
  const dgpocTargetURL = `${process.env.REACT_APP_DG_POC_TARGET_URL}`
  const dossdemoTargetURL = `${process.env.REACT_APP_DOSS_DEMO_TARGET_URL}`
  const userName= JSON.parse(localStorage.getItem("userDetails")).username
  let data = JSON.stringify({
    "fileName": tempObj.filename,
    "inputType": "base64",
    "base64": `${tempObj.base64}`,
    "mimeType": tempObj.type,
    "extractionType": tempObj.extractionType,
    "rawJson": true
  });
let url = `${baseURL}/extract`

console.log("url=========", url)

let config = {
  method: 'post',
  maxBodyLength: Infinity,
  url: url,
  headers: { 
    'accept': 'application/json', 
    'X-Access-Token': tokenToSend, 
    'Content-Type': 'application/json'
  }, 
  data : data
};

axios.request(config)
.then((response) => {
  // console.log(JSON.stringify(response.data));
  try {
    dispatch(setGePowerData(response,filename));
  } catch (error) {
    console.log('Error parsing JSON:', error);
  }
  // saveApiContentAsExcel(response.data,'optimumtest.xlsx')
  
})
.catch((error) => {
  console.log(error);
});

 }
 
 
  const handleScan = async (e) => {
    e.preventDefault();
    try {
      const { Base64String, filename, selectedFormType, prevImgSource, typeofFile } = state;

      let userData = JSON.parse(localStorage.getItem('token'));
      // console.log("userData",userData.useremail);

      let formType = selectedFormType

      if (userData.useremail == "DemoAdmin123@ocra.com" && selectedFormType == null) {
        formType = "No Type"
      }

      // if(selectedFormType === null){
      //   formType = "No Type"

      // }


      // if (formType !== null) {
      // console.log('inside handleScan')
      let tempObj = {
        base64: Base64String.split(',')[1],  // ok
        filename: filename,  // ok
        extractionType: (formType === `us passport` || formType === `us driver's license`) ? `id-document` : formType === null ? '' : formType,  // ok
        rawJson: true,  // ok
        type: Base64String.split(',')[0].split(';')[0].split(':')[1] === 'image/jpg' ? 'image/jpeg' : Base64String.split(',')[0].split(';')[0].split(':')[1],
        inputType: "base64",  // ok
        userName: JSON.parse(localStorage.getItem("userDetails")).username
      }
      console.log('before getnontemplatedResult: ', tempObj)
      // console.log('Base64String: ', Base64String)
      const base64=Base64String.split(',')[1]
      try{
        if(formType.toLowerCase()!=="optimumnet"&&formType.toLowerCase()!=="optimumnetconsent"&&formType.replace(/\s/g, '').toLowerCase()!=="nonstandardoptimumnetconsent"&&formType.toLowerCase()!=="dandb"){
          if(formType.toLowerCase()==="bajaj"||formType.toLowerCase()==="wondercement"||formType.toLowerCase()==="canara"){
            getZipContent(base64,filename,formType.toLowerCase())
          }else if(formType.replace(/\s/g, '').toLowerCase()==="gepowerconversion"){
            getGePowerData(tempObj)
          }else if(formType.replace(/\s/g, '').toLowerCase()==="ameristarusareport"){
            getExcelContentForOptimumnet(base64,filename,formType.replace(/\s/g, '').toLowerCase())
          }else if(formType.replace(/\s/g, '').toLowerCase()==="read"){
            getReadValues(base64,filename,formType.replace(/\s/g, '').toLowerCase())
          }
          else{
            dispatch(getnontemplatedResult(tempObj))
          }
        }else{
          getExcelContentForOptimumnet(base64,filename,formType.replace(/\s/g, '').toLowerCase())
        }
      }catch(err){
         console.log("err",err)
      }
      
      

      let data = {
        templateData: prevImgSource,
        imgData: {
          y_scale: 700 / state.height,
          x_scale: 700 / state.width,
        },
        userFileName: filename,
        type: formType
      };
      // console.log('data passed to loadTemplate: ', data)
      dispatch(loadTemplate(data));
      history.push({ pathname: '/nontemplate-recognition' });

      // }
      // else {
      // let classifierPayload = {
      //   inputType: "base64",
      //   base64: Base64String.split(',')[1]
      // }

      // console.log('classifierPayload: ', classifierPayload)

      // await axios.post(`https://nuocr-api.azurewebsites.net/extract/classify`, classifierPayload).then((res) => {
      //   let payloadObj = {
      //     base64: Base64String.split(',')[1],
      //     filename: filename,
      //     extractionType: res.data.type,
      //     rawJson: true,  // ok
      //     type: Base64String.split(',')[0].split(';')[0].split(':')[1] === 'image/jpg' ? 'image/jpeg' : Base64String.split(',')[0].split(';')[0].split(':')[1],
      //     inputType: "base64"  // ok
      //   }
      //   console.log('payloadObj: ', payloadObj)
      //   dispatch(getnontemplatedResult(payloadObj));

      // let data = {
      //   templateData: prevImgSource,
      //   imgData: {
      //     y_scale: 700 / state.height,
      //     x_scale: 700 / state.width,
      //   },
      //   userFileName: filename,
      //   type: res.data.type
      // };
      // // console.log('data passed to loadTemplate: ', data)
      // dispatch(loadTemplate(data));
      // history.push({ pathname: '/nontemplate-recognition' });
      // })
      // alert("Please select form type")
      // }

    } catch (err) {
      console.error(err);
    }

  };

  const onImgLoad = ({ target: img }) => {
    console.log('inside onImgLoad: ', img.naturalHeight, img.naturalWidth)
    localStorage.setItem('imgHt', JSON.stringify(img.naturalHeight))
    localStorage.setItem('imgLen', JSON.stringify(img.naturalWidth))
    setState({
      ...state,
      // height: img.naturalWidth,
      // width: img.naturalHeight,
      height: 3000,
      width: 1688,
    });
  };

  
  const { Base64String, filename, selectedFormType, prevImgSource, typeofFile } = state;
  console.log("state",state)
  return (
    <div className='app-content container1'>
      <div className="row">
        <div className="col-xl-6">
          <div className="row">
            <div className="col-xl-9">
              <h1 className="page-header mb-3">
                Upload document<br /><small>Upload your files containing data to be extracted and we will do the
                  rest. </small></h1>
            </div>

          </div>
        </div>
        <div className="col-xl-6">
        </div>
      </div>
      <div className='row'>
        <div className='col-xl-6'>
          <div className='fileslistaindiv' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <h3 className='page-header mb-4'>Form Type </h3>
            <div className="col-xl-3 start_reg">
              {state.uploadedFile.length !== 0
                ? (
                  (
                    <div className="addtemplatediv">

                      <a href="#"
                        className="btn btn-yellow btn-rounded btn-sm ps-5 pe-5 pt-2 pb-2 fs-14px font-weight-600"
                        onClick={(e) => handleScan(e)}
                      > Submit</a>

                    </div>
                  ))
                : (
                  (<Fragment></Fragment>))}
            </div>
          </div>
          <div >
            <div class="search_categories">
              <div class="select">
                <select name="search_categories" id="search_categories" onChange={handleChange}>
                  <option value="1" selected="selected">Select a Value</option>
                  {/* {formdata && (
                    <>
                      {formdata.map((item, key) => (
                        <option key={key} value={item.templatename}>
                          {item.templatename}
                        </option>
                      ))}
                    </>
                  )} */}
                  {docTypes.map((item, key) => {
                    return (<option key={key} value={item.toLowerCase()}>{item}</option>)
                  })}
                </select>
              </div>
            </div>

          </div>
          {
            // state.selectedFormType !==null && 
            <div><div className='dragdropmaindivNonTemplated' style={{ paddingTop: '10px' }}>
              <form>
                <input
                  type='file'
                  accept={(state.selectedFormType === 'insurance claim' || state.selectedFormType === 'nufarm') ? 'application/pdf' : ".jpg,.jpeg,.png,.pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword"}
                  className='NuOcrfile'
                  onChange={handleFileChoice}
                />

                <p>Drag your files here or click in this area.</p>
                <p><h6><em>{state.selectedFormType === 'insurance claim' ? `(Supported filetypes: pdf)` : `(Supported filetypes: jpg/jpeg/png/pdf/tiff)`}</em></h6>
                </p>
              </form>
            </div>
              {/* <span>{!state.imgUploadLoaded ? <p>File uploading please wait...</p> : null}</span> */}
              {state.uploadedFile.length !== 0 ? (
                <>
                  <div className='fileslistaindiv'>
                    <h3 className='page-header mb-4'>Uploaded Files </h3>
                  </div>
                  <div className='documentlist'>
                    {state.uploadedFile &&
                      state.uploadedFile.map((item) => (
                        <ul>
                          <li className='selectfile'>
                            {item.name}
                            <span /* onClick={()=>closeFile()}*/>
                              <i className='fas fa-times'></i>
                            </span>
                          </li>
                          {/* <li>Document 1 <span><i className="fas fa-times"></i></span></li>
                                    <li>Document 1 <span><i className="fas fa-times"></i></span></li> */}
                        </ul>
                      ))}
                  </div>
                </>
              ) : null}</div>}

        </div>

        <div className="col-xl-6">
          {documentType!==""&&documentType?.replace(/\s/g, '').toLowerCase()!=="gepowerconversion"&&
          <div className="scandoumentdiv">
            {/* {state.filetype2 !== 'image/tiff'? */}
            {typeofFile !== 'application/pdf'&&typeofFile !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'&&typeofFile !== 'application/msword'&&<img
              className="scandoumentdiv"
              src={state.prevImgSource}
              onLoad={onImgLoad}
              href="#"
            // alt="Loading..."
            />}
            {typeofFile === 'application/pdf'&& <embed
              // file={prevImgSource}
              src={documentUrl}
              width="100%"
              height="600"
              type={state.typeofFile}
              // onLoadSuccess={onImgLoad}
              onLoad={onImgLoad}
            >
              {/* <Page pageNumber={pageNumber} /> */}
            </embed>}
            
            {state.typeofFile === 'image/tiff' && 
            <TiffViewer
              className="scandoumentdiv"
              src={state.prevImgSource}
              onLoad={onImgLoad}
              href="#"
            // alt="Loading..."
            /> }

            {/* // <TiffViewer
            //   className="scandoumentdiv"
            //   src={state.prevImgSource}
            //   onLoad={onImgLoad}
            //   href="#"
            // // alt="Loading..."
            // /> */}

          </div>}
        </div>
      </div>
      <div className='Sspace'></div>

      {/* {state.uploadedFile && state.uploadedFile.length > 0 && (
        <div className='addtemplatediv'>
          <a
            href='#'
            className='btn btn-yellow btn-rounded btn-sm ps-5 pe-5 pt-2 pb-2 fs-14px font-weight-600'
            onClick={(e) => handleScan(e)}
          >
            {' '}
            Start Recognition
          </a>
        </div>
      )} */}
    </div>
  );
};


export default NonTemplatedflow
