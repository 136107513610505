import axios from 'axios';

export const USER_SCANNED_RESULTS_FETCH_BEGIN =
  'USER_SCANNED_RESULTS_FETCH_BEGIN';
export const USER_SCANNED_RESULTS_FETCH_SUCCESS =
  'USER_SCANNED_RESULTS_FETCH_SUCCESS';
export const USER_SCANNED_RESULTS_FETCH_FAILURE =
  'USER_SCANNED_RESULTS_FETCH_FAILURE';
export const USER_SCANNED_RESULTS_SEARCH_BEGIN =
  'USER_SCANNED_RESULTS_SEARCH_BEGIN';
export const USER_SCANNED_RESULTS_SEARCH_SUCCESS =
  'USER_SCANNED_RESULTS_SEARCH_SUCCESS';
export const USER_SCANNED_RESULTS_SEARCH_FAILURE =
  'USER_SCANNED_RESULTS_SEARCH_FAILURE';
export const ACTIVITY_lOG_BEGIN =
  'ACTIVITY_lOG_BEGIN';
export const ACTIVITY_lOG_SUCCESS =
  'ACTIVITY_lOG_SUCCESS';
export const ACTIVITY_lOG_FAILURE =
  'ACTIVITY_lOG_FAILURE';



export const DASHBOARD_DATA_SUCCESS = 'DASHBOARD_DATA_SUCCESS';
export const DASHBOARD_DATA_FAILURE = 'DASHBOARD_DATA_FAILURE';

export const getScannedResultsList = (id) => async (dispatch) => {
  try {
    dispatch({
      type: USER_SCANNED_RESULTS_FETCH_BEGIN,
    });
    const res = await axios.get(`/dashboard/scannedresults/${id}`);
  
    dispatch({
      type: USER_SCANNED_RESULTS_FETCH_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
   
    dispatch({
      type: USER_SCANNED_RESULTS_FETCH_FAILURE,
      payload: error.message,
    });
  }
};

export const searchDocument = (type, term) => async (dispatch) => {
  try {
    dispatch({
      type: USER_SCANNED_RESULTS_SEARCH_BEGIN,
    });
    const res = await axios.post(`/dashboard/scannedresults/search`, {
      type,
      term,
    });
   
    dispatch({
      type: USER_SCANNED_RESULTS_SEARCH_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
   
    dispatch({
      type: USER_SCANNED_RESULTS_SEARCH_FAILURE,
      payload: error.message,
    });
  }
};



//activity log ..........new action...........independenet of getscancount
export const getscancounts = (id, data) => async (dispatch) => {
  
    try {
      dispatch({
        type: ACTIVITY_lOG_BEGIN,
      });
      const res = await axios.post(`/dashboard/activityLog`, data);
      dispatch({
        type: ACTIVITY_lOG_SUCCESS,
        payload: res,
      });
    } catch (error) {
      dispatch({
        type: ACTIVITY_lOG_FAILURE,
        payload: error.message,
      });
    }
  };

export const dashboardData = () => async (dispatch) => {
  
  await axios.get('/dashboard/content').then((res) => {
    if(res.status === 200){
      console.log("content api response:",res.data);
    dispatch({
        type: DASHBOARD_DATA_SUCCESS,
        payload: res.data.data
    })
  }
}).catch((err) => {
    dispatch({
        type: DASHBOARD_DATA_FAILURE,
        payload: err
    })
})
  
}