import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
// import MultiSelect from "react-multi-select-component";
import { toast } from "react-toastify";
import { getUserToken } from "../../services/AuthService";
import { postDynamicFormData, fetchDynamicFormList } from "../../actions/DynamicFormActions/GetDynamicFormsAction";
// import { fetchPrivilegeUserDetailsInfo } from "../../actions/AdminAction/GetPrivilegeUserDetailsAction";
// import { fetchSchoolGrades } from "../../actions/AdminAction/GetSchoolGradesAction";
import _ from "lodash";
import moment from "moment";
import DatePicker from "react-datepicker";

const FormScheduler = (props) => {
  const dispatch = useDispatch();
  // const schoolID = getUserToken().schoolId;
  // const schools = getUserToken().schools;
  const [formData, setFormData] = useState(props.data);
  const [days, setDays] = useState({});
  const [selectedSchool, setSelectedSchool] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState([]);
  const [selectedRole, setSelectedRole] = useState([]);
  const [textEmails, setTextEmails] = useState("");
  const [emailVal, setEmailVal] = useState("");
  const [count, setCount] = useState(0);
  // const schoolOptions = schools.map((school) => ({
  //   label: school.schoolname,
  //   value: school.fk_school,
  // }));
  const [schoolKeyword, setSchoolKeyword] = useState("School");
  const [gradeKeyword, setGradeKeyword] = useState("Grade");

  // const [grades, setGrades] = useState(getUserToken().schoolgrades);
  // const [gradeOptions, setGradeOptions] = useState([]);

  const { postResponse, postError } = useSelector(
    (state) => ({
      postResponse: state.dynamicFormsListReducer.postResponse,
      postError: state.dynamicFormsListReducer.postError,
    }),
    shallowEqual
  );

  // const { roles } = useSelector(
  //   (state) => ({
  //     roles: state.privilegeUserDetailsReducer.privilegeUserDetails,
  //   }),
  //   shallowEqual
  // );

  useEffect(() => {
    if (localStorage.getItem("whitelabelled") === "true") {
      JSON.parse(localStorage.getItem("keywords")).map((item) => {
        if (item.keyword === "School") {
          setSchoolKeyword(item.replacevalue);
        } else if (item.keyword === "Grade") {
          setGradeKeyword(item.replacevalue);
        }
      });
    }
  }, []);

  // useEffect(() => {
  //   if (!roles)
  //     dispatch(
  //       fetchPrivilegeUserDetailsInfo({ schoolID: getUserToken().parentId })
  //     );
  // }, [roles]);

  // const { grades } = useSelector(
  //   (state) => ({
  //     grades: state.schoolGradesReducer.schoolGrades,
  //   }),
  //   shallowEqual
  // );

  // const roleOptions = roles
  //   ? roles.map((role) => ({
  //       label: role.roleName[0],
  //       value: role.fk_roledetails[0],
  //     }))
  //   : [];
  // const roleOptions = roles
  //   ? [
  //     {
  //       //To be replaced with above code after initial review
  //       label: "Parent",
  //       value: "Parent",
  //     },
  //   ]
  //   : [];

  // useEffect(() => {
  //   if (!grades) dispatch(fetchSchoolGrades());
  // }, [grades]);

  // useEffect(() => {
  //   const arr = selectedSchool.map((item) => item.value);
  //   const options = grades.filter((item) => arr.includes(item.fk_school._id));
  //   const gradesOp = options.map((item) => ({
  //     label: item.fk_grade.grade,
  //     value: item.fk_grade._id,
  //   }));
  //   const list = gradesOp.filter(
  //     (item, index, self) =>
  //       index === self.findIndex((i) => i.label === item.label)
  //   );
  //   setGradeOptions(list);
  //   // dispatch(fetchSchoolGrades({ fk_school: arr }));
  // }, [selectedSchool]);

  // const gradeOptions = grades
  //   ? grades.map((grade) => ({ label: grade.grade, value: grade._id }))
  //   : [];

  useEffect(() => {
    if (count !== 0) {
      toast.error(postError, {
        autoClose: 4000,
      });
      setCount(0);
    }
  }, [postError]);

  useEffect(() => {
    if (postResponse && count !== 0) {
      toast.success(postResponse.data.message, {
        autoClose: 4000,
      });
      setCount(0);
    }
  }, [postResponse]);

  useEffect(() => {
    if (formData["recurrence"] === "one_time") {
      formData["recurrence_type"] = null;
      formData["recurrence_monthly"] = "";
      formData["recurrence_yearly"] = "";
    }
  }, [formData]);

  // useEffect(() => {
  //   if (!_.isEmpty(formData.fk_schools) && _.isEmpty(selectedSchool)) {
  //     let arr = schoolOptions.filter((item) => {
  //       if (formData.fk_schools.includes(item.value)) {
  //         return item;
  //       }
  //     });
  //     setSelectedSchool(arr);
  //   }
  //   if (!_.isEmpty(formData.fk_roledetails) && _.isEmpty(selectedRole)) {
  //     let arr = roleOptions.filter((item) => {
  //       if (formData.fk_roledetails.includes(item.value)) {
  //         return item;
  //       }
  //     });
  //     setSelectedRole(arr);
  //   }
  //   if (!_.isEmpty(formData.fk_grades) && _.isEmpty(selectedGrade)) {
  //     let arr = gradeOptions.filter((item) => {
  //       if (formData.fk_grades.includes(item.value)) {
  //         return item;
  //       }
  //     });
  //     setSelectedGrade(arr);
  //   }
  //   if (
  //     !_.isEmpty(formData.startdate) &&
  //     !_.isEmpty(formData.starttime) &&
  //     !_.isEmpty(formData.enddate)
  //   ) {
  //     let temp = { ...formData };
  //     temp["f_date"] = new Date();
  //     temp["from_time"] = moment(formData.starttime).format("HH:mm");
  //     temp["t_date"] = moment(formData.enddate).toDate();
  //     temp["days"] = formData.sharedoccurs;
  //     let obj = { ...days };
  //     temp["days"].map((item) => {
  //       obj[item] = true;
  //     });
  //     temp["recurrence"] = formData.recurrence
  //       ? formData.recurrence
  //       : "one_time";
  //     temp["recurrence_type"] = formData.recurrence_type
  //       ? formData.recurrence_type
  //       : null;
  //     temp["reminder"] = formData.reminder ? formData.reminder : false;
  //     temp["reminder_days"] =
  //       formData.reminder && formData.reminder_days
  //         ? formData.reminder_days
  //         : "1";
  //     temp["recurrence_monthly"] = formData.recurrence_monthly
  //       ? formData.recurrence_monthly
  //       : "";
  //     temp["recurrence_yearly"] = formData.recurrence_yearly
  //       ? formData.recurrence_yearly
  //       : "";
  //     setFormData(temp);
  //     setDays(obj);
  //   } else {
  //     let temp = { ...formData };
  //     temp["f_date"] = new Date();
  //     temp["from_time"] = "";
  //     temp["t_date"] = "";
  //     temp["recurrence"] = "one_time";
  //     temp["days"] = [];
  //     setFormData(temp);
  //   }
  // }, [roles]);

  const handleDateTimeChange = (e) => {
    let tmp = { ...formData };
    let name = e.target.name;
    tmp[name] = e.target.value;
    setFormData(tmp);
  };
  const handleDaysChange = (e) => {
    let daysArr = [];
    let tmDays = { ...days };
    let key = e.target.name;
    tmDays[key] = e.target.checked;
    setDays(tmDays);
    Object.keys(tmDays).map((v, i) => {
      if (tmDays[v]) daysArr.push(v);
    });
    setFormData({ ...formData, days: daysArr });
  };

  const handleSubmit = async () => {
    setCount(count + 1);
    // let seletedschools = selectedSchool.map((opt) => opt.value);
    // let roles = selectedRole.map((role) => role.value);
    // let grades = selectedGrade.map((grade) => grade.value);
    let tmpFormData = { ...formData };
    // tmpFormData["schools"] = seletedschools;
    // tmpFormData["grades"] = grades;
    // tmpFormData["roles"] = roles;
    tmpFormData["url"] = window.location.href;
    tmpFormData["from_time"] = moment(tmpFormData["f_time"], "HH:mm");
    tmpFormData["from_date"] = moment(tmpFormData["f_date"]).format(
      "YYYY-MM-DD"
    );
    tmpFormData["to_date"] = moment(tmpFormData["t_date"]).format("YYYY-MM-DD");
    tmpFormData["email"] = textEmails.length > 0 ? true: false
    tmpFormData["emaillist"] = textEmails;
    setFormData(tmpFormData);
    // if (
    //   seletedschools.length === 0 ||
    //   grades.length === 0 ||
    //   roles.length === 0
    // ) {
    //   toast.warn("Please add Audience Details to continue!");
    // } else 
    if (
      !tmpFormData.f_date ||
      !tmpFormData.from_time ||
      !tmpFormData.t_date ||
      (tmpFormData.recurrence_type === "weekly" &&
        (!tmpFormData.days || tmpFormData.days.length === 0))
    ) {
      toast.warn("Please add Date and Time details to proceed!", {
        autoClose: 4000,
      });
    } else {
      await dispatch(postDynamicFormData({ formData: tmpFormData }));
      // await dispatch(
      //   fetchDynamicFormList({ schoolID: schoolID, schools: schools })
      // );
      await dispatch(fetchDynamicFormList({ id: "", status: ""}));
      props.closeModal();
    }
  };

  const handleEmailChange = (e) => {
    let temp = { ...formData };
    temp[e.target.name] = e.target.checked;
    setFormData(temp);
  };

  const handleMailChange = (e) => {
    let temp = { ...formData };
    temp[e.target.name] = e.target.value;
    setFormData(temp);
  };

  const handleRadioChange = (e) => {
    let temp = { ...formData };
    temp[e.target.name] = e.target.id;
    setFormData(temp);
  };

  const handleStartDateChange = (val) => {
    let temp = { ...formData };
    temp["f_date"] = val;
    setFormData(temp);
  };

  const handleEndDateChange = (val) => {
    let temp = { ...formData };
    temp["t_date"] = val;
    setFormData(temp);
  };

  const validateEmails = () => {
    let arr = [];
    let flag = false;
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (textEmails.length > 0) {
      let emails = textEmails.split(",");
      emails.map((item) => {
        if (!re.test(item.trim())) {
          arr.push(item);
        }
      });
      arr = arr.join(", ");
      if (arr.length > 0) {
        flag = true;
      }
    }
    if (flag) {
      setEmailVal(arr);
      toast.warn("One or more emails are invalid!",{
        autoClose: 4000,
      });
    } else {
      handleSubmit();
    }
  };

  return (
    <form className="dynamicFormMaster">
      <p>Date &amp; Time Details</p>
      <div className="form-row">
        <div className="form-group col-md-6">
          <label>From Date<span className="required-asterix">*</span></label>
          <DatePicker
            className="form-control datepicker border"
            name="f_date"
            dateFormat="dd MMM yyyy"
            onChange={handleStartDateChange}
            selected={formData["f_date"]}
            minDate={new Date()}
            placeholderText="Select a date"
          />
        </div>
        <div className="form-group col-md-6">
          <label>Time<span className="required-asterix">*</span></label>
          <input
            type="time"
            className="form-control"
            name="f_time"
            onChange={handleDateTimeChange}
            value={formData["f_time"]}
          />
        </div>
      </div>
      <div className="form-row">
        {formData["f_date"] && formData["f_time"] && (
          <div className="form-group col-md-6">
            <label>To Date<span className="required-asterix">*</span></label>
            <DatePicker
              className="form-control datepicker border"
              name="t_date"
              dateFormat="dd MMM yyyy"
              onChange={handleEndDateChange}
              selected={formData["t_date"]}
              minDate={formData["f_date"]}
              placeholderText="Select a date"
            />
          </div>
        )}
      </div>
      {/* Recurrence */}
      <div className="form-group d-block">
        <div className="form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name="recurrence"
            id="one_time"
            onChange={handleRadioChange}
            checked={
              formData["recurrence"] && formData["recurrence"] === "one_time"
            }
          />
          <label className="form-check-label label-size" htmlFor="one_time">
            One Time
          </label>
        </div>
        <div className="form-check-inline" style={{width: "29%"}}>
          <input
            className="form-check-input chk-cus2"
            type="radio"
            name="recurrence"
            id="recurrence"
            onChange={handleRadioChange}
            checked={
              formData["recurrence"] && formData["recurrence"] === "recurrence"
            }
          />
          <label className="form-check-label label-size" htmlFor="recurrence">
            Recurrence Pattern
          </label>
        </div>
      </div>
      <div className="form-group d-block" style={{marginLeft:'150px'}}>
        {formData["recurrence"] && formData["recurrence"] === "recurrence" && (
          <>
            <div className="form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                id="daily"
                name="recurrence_type"
                checked={
                  formData["recurrence_type"] &&
                  formData["recurrence_type"] === "daily"
                }
                onChange={handleRadioChange}
              />
              <label className="form-check-label label-size">Daily</label>
            </div>
            <div className="form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                id="weekly"
                name="recurrence_type"
                checked={
                  formData["recurrence_type"] &&
                  formData["recurrence_type"] === "weekly"
                }
                onChange={handleRadioChange}
              />
              <label className="form-check-label label-size">Weekly</label>
            </div>
            {/* <div className="form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                id="monthly"
                name="recurrence_type"
                checked={
                  formData["recurrence_type"] &&
                  formData["recurrence_type"] === "monthly"
                }
                onChange={handleRadioChange}
              />
              <label className="form-check-label label-size">Monthly</label>
            </div>
            <div className="form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                id="yearly"
                name="recurrence_type"
                checked={
                  formData["recurrence_type"] &&
                  formData["recurrence_type"] === "yearly"
                }
                onChange={handleRadioChange}
              />
              <label className="form-check-label label-size">Yearly</label>
            </div> */}
          </>
        )}
      </div>
      {formData["recurrence"] &&
        formData["recurrence"] === "recurrence" &&
        formData["recurrence_type"] === "weekly" && (
          <div className="form-group d-block" style={{marginLeft:'266px'}}>
            <label className="w-100">Days</label>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                name="Sunday"
                onChange={handleDaysChange}
                checked={
                  formData["days"] &&
                  formData["days"].some((o) => o === "Sunday")
                }
              />
              <label className="form-check-label">Sunday</label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                name="Monday"
                onChange={handleDaysChange}
                checked={
                  formData["days"] &&
                  formData["days"].some((o) => o === "Monday")
                }
              />
              <label className="form-check-label">Monday</label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                name="Tuesday"
                onChange={handleDaysChange}
                checked={
                  formData["days"] &&
                  formData["days"].some((o) => o === "Tuesday")
                }
              />
              <label className="form-check-label">Tuesday</label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                name="Wednesday"
                onChange={handleDaysChange}
                checked={
                  formData["days"] &&
                  formData["days"].some((o) => o === "Wednesday")
                }
              />
              <label className="form-check-label">Wednesday</label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                name="Thursday"
                onChange={handleDaysChange}
                checked={
                  formData["days"] &&
                  formData["days"].some((o) => o === "Thursday")
                }
              />
              <label className="form-check-label">Thursday</label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                name="Friday"
                onChange={handleDaysChange}
                checked={
                  formData["days"] &&
                  formData["days"].some((o) => o === "Friday")
                }
              />
              <label className="form-check-label">Friday</label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                name="Saturday"
                onChange={handleDaysChange}
                checked={
                  formData["days"] &&
                  formData["days"].some((o) => o === "Saturday")
                }
              />
              <label className="form-check-label">Saturday</label>
            </div>
          </div>
        )}
      {formData["recurrence"] &&
        formData["recurrence"] === "recurrence" &&
        formData["recurrence_type"] === "monthly" && (
          <div className="form-group d-block">
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Select Date</label>
                <select
                  className="form-control"
                  name="recurrence_monthly"
                  onChange={handleDateTimeChange}
                  value={formData["recurrence_monthly"]}
                >
                  {_.range(1,32).map((num) => (
                    <option value={num}>{num}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        )}
      {formData["recurrence"] &&
        formData["recurrence"] === "recurrence" &&
        formData["recurrence_type"] === "yearly" && (
          <div className="form-group d-block">
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Select Date</label>
                <select
                  className="form-control"
                  name="recurrence_monthly"
                  onChange={handleDateTimeChange}
                  value={formData["recurrence_monthly"]}
                >
                  <option value="">Select</option>
                  {_.range(
                    1,
                    parseInt(moment(formData["t_date"]).format("DD")) + 1
                  ).map((num) => (
                    <option value={num}>{num}</option>
                  ))}
                </select>
              </div>
              <div className="form-group col-md-6">
                <label>Select Month</label>
                <select
                  className="form-control"
                  name="recurrence_yearly"
                  onChange={handleDateTimeChange}
                  value={formData["recurrence_yearly"]}
                >
                  <option value="">Select</option>
                  {moment.months().map((item) => (
                    <option value={item}>{item}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        )}

      {/* 
      This feature is pending, code to be uncommented after initial review
        */}
      <div className="form-group d-block">
        
          <div className="form-row">
            <div className="form-group col-md-12">
              <textarea
                rows={3}
                placeholder="Enter emails separated by commas"
                className="form-control"
                value={textEmails}
                onChange={(e) => setTextEmails(e.target.value)}
              ></textarea>
            </div>
          </div>
        {!_.isEmpty(emailVal) && textEmails.length > 0 && (
          <>
            <label className="form-check-label label-size">
              Invalid Email(s)
            </label>
            <div className="form-row">
              <div className="form-group col-md-12 textAreaColor">
                <textarea
                  rows={3}
                  className="form-control"
                  value={emailVal}
                  readOnly
                ></textarea>
              </div>
            </div>
          </>
        )}
      </div> 
      {/* <div className="form-check form-check-inline">
        <input
          className="form-check-input chk-cus"
          type="checkbox"
          name="reminder"
          onChange={handleEmailChange}
          checked={formData["reminder"]}
          disabled={
            formData["recurrence"] &&
            formData["recurrence"] === "recurrence" &&
            (formData["recurrence_type"] === "daily" ||
              (formData["recurrence_type"] === "weekly" &&
                formData.days &&
                formData.days.length > 1))
          }
        />
        <label className="form-check-label label-size">
          If not filled, send Reminder
        </label>
      </div>
      <div className="form-group d-block">
        {formData["reminder"] &&
          !(
            formData["recurrence_type"] === "daily" ||
            (formData["recurrence_type"] === "weekly" &&
              formData.days.length > 1)
          ) && (
            <div className="form-row">
              <div className="form-group col-md-6 div-cus">
                Daily for next
                <select
                  className="form-control select-cus"
                  name="reminder_days"
                  value={formData["reminder_days"]}
                  onChange={handleDateTimeChange}
                >
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                </select>
                days
              </div>
            </div>
          )}
      </div>
      <p>Audience Details<span className="required-asterix">*</span></p>
      <div className="form-row">
        <div className="form-group col-md-6">
          <label>{schoolKeyword}</label>
          <MultiSelect
            options={schoolOptions}
            value={selectedSchool}
            onChange={setSelectedSchool}
            labelledBy={"Select"}
          />
        </div>
        {selectedSchool.length > 0 && (
          <div className="form-group col-md-6">
            <label>Grade</label>
            <MultiSelect
              options={gradeOptions}
              value={selectedGrade}
              onChange={setSelectedGrade}
              labelledBy={"Select"}
            />
          </div>
        )}
        <div className="form-group col-md-6">
          <label>Role</label>
          <MultiSelect
            options={roleOptions}
            value={selectedRole}
            onChange={setSelectedRole}
            labelledBy={"Select"}
          />
        </div>
      </div>
      <p>Mail Template</p>
      <div className="form-row">
        <div className="form-group col-md-6">
          <label>Alert Mail Subject</label>
          <input
            type="text"
            name="alertMailSubject"
            className="form-control"
            onChange={handleMailChange}
          />
        </div>
        <div className="form-group col-md-6">
          <label>Alert Mail Body</label>
          <textarea
            name="alertMailBody"
            className="form-control"
            onChange={handleMailChange}
          />
        </div>
        {formData["reminder"] && (
          <>
            <div className="form-group col-md-6">
              <label>Reminder Mail Subject</label>
              <input
                type="text"
                name="reminderMailSubject"
                className="form-control"
                onChange={handleMailChange}
              />
            </div>
            <div className="form-group col-md-6">
              <label>Reminder Mail Body</label>
              <textarea
                name="reminderMailBody"
                className="form-control"
                onChange={handleMailChange}
              />
            </div>
          </>
        )}
      </div> */}
      <div className="form-inline">
        <div className="main-bottom top-btns-div mt-5 d-flex">
          <button
            type="button"
            className="btn btn-primary mr-2"
            onClick={validateEmails}
          >
            Save
          </button>
          <button
            type="button"
            className="btn btn-primary cancek-btn"
            onClick={props.closeModal}
          >
            Cancel
          </button>
        </div>
      </div>
    </form>
  );
};
export default FormScheduler;
