import React, {Fragment} from 'react';
import spinner from '../../images/loading.gif'

export default function Spinner() {
    return (
        <div  style={{ width: "200px", height: "200px", margin: "auto", display: "block" }} >
           <img src={spinner} style={{ width:"20%",height:"20%"}}alt="Loading...." /> 
        </div>
    )
}
