import axios from 'axios';
import {post,get} from '../services/Http'
import { toast } from "react-toastify";
export const ADDUSER_SUCCESS = "ADDUSER_SUCCESS";
export const ADDUSER_BEGIN = "ADDUSER_BEGIN";
export const ADDUSER_FAILED = "ADDUSER_FAILED";


export const ROLES_SUCCESS = "ROLES_SUCCESS";
export const ROLES_BEGIN = "ROLES_BEGIN";
export const ROLES_FAILED = "ROLES_FAILED";


export const USERLIST_SUCCESS = "USERLIST_SUCCESS";
export const USERLIST_BEGIN = "USERLIST_BEGIN";
export const USERLIST_FAILED = "USERLIST_FAILED";
export const SET_USER_OPTIONS="SET_USER_OPTIONS"
export const SET_ROLE_OPTIONS="SET_ROLE_OPTIONS";
export const EDITUSER_SUCCESS = "EDITUSER_SUCCESS";
export const EDITUSER_BEGIN = "EDITUSER_BEGIN";
export const EDITUSER_FAILED = "EDITUSER_FAILED";
export const ACT_DEACT_SUCCESS = "ACT_DEACT_SUCCESS";
export const ACT_DEACT_BEGIN = "ACT_DEACT_BEGIN";
export const ACT_DEACT_FAILED = "ACT_DEACT_FAILED";
export const STATUS_BEGIN = "STATUS_BEGIN";
export const STATUS_SUCCESS = "STATUS_SUCCESS";
export const STATUS_FAIL = "STATUS_FAIL"

export const addUser = (data) => async dispatch => {
   console.log('adduser: ', data)
    try {
        dispatch({
            type: ADDUSER_BEGIN
        })
        
        const res = await axios.post('/user',data)
     
        dispatch({
            type: ADDUSER_SUCCESS,
            payload: res.data.message
        })
        toast.success(res.data.message,{autoClose:4000})
    } catch (err) {
     
        dispatch({
            type: ADDUSER_FAILED,
            payload: err
        })
       
        toast.error(err,{autoClose:4000})
    }

}

export const roleList = () => async dispatch => {
 
    
    try {
        dispatch({
            type: ROLES_BEGIN
        })
        
        const res = await axios.get('/user')
       
        dispatch({
            type: ROLES_SUCCESS,
            payload: res.data
        })
      
    } catch (err) {
      
        dispatch({
            type: ROLES_FAILED,
            payload: err
        })
    }

}
export const setStaffFilterOptions = (options) => ({
    type: SET_USER_OPTIONS,
    payload: options,
  });
  export const setRoleFilterOptions = (roleoptions) => ({
    type: SET_ROLE_OPTIONS,
    payload: roleoptions,
  });
export const userList = (data) => async dispatch => {
 
   
    try {
        dispatch({
            type: USERLIST_BEGIN
        })
        
        const res = await axios.post('/userlist',data)
       
        dispatch({
            type: USERLIST_SUCCESS,
            payload: res.data
        })
        if (data.userid === "" && data.roleid === "") {
            let options = res.data.map((user) => {
              return {
                label: [user.useremail].filter(s => s != null),
                value: user._id
              }
            })
            options = [{ label: "Select All", value: '' }].concat(options);
            let unique=res.data.filter((v,i,a)=>a.findIndex(t=>(t.roleid === v.roleid && t.rolename===v.rolename))===i)
           let roleoptions = unique.map((user) => {
                return {
                  label: [user.rolename].filter(s => s != null),
                  value: user.roleid
                }
              })
              roleoptions = [{ label: "Select All", value: '' }].concat(roleoptions);
              dispatch(setRoleFilterOptions(roleoptions))
            dispatch(setStaffFilterOptions(options));
           }
    } catch (err) {
      
        dispatch({
            type: USERLIST_FAILED,
            payload: err
        })
    }

}

export const editUser = (data) => async dispatch => {
   
    try {
        dispatch({
            type: EDITUSER_BEGIN
        })
        
        const res = await axios.post('/user/edituser',data)
       
        dispatch({
            type: EDITUSER_SUCCESS,
            payload: res.data.message
        })
        toast.success(res.data.message,{autoClose:4000})
    } catch (err) {
     
        dispatch({
            type: EDITUSER_FAILED,
            payload: err
        })
        toast.error("failed to edit user",{autoClose:4000})
    }

}

export const ActivateorDeactivateUser = (data) => async dispatch => {
   
    try {
        dispatch({
            type: ACT_DEACT_BEGIN
        })
        
        const res = await axios.post('/user/activateOrDeactivateUser',data)
       
        dispatch({
            type: ACT_DEACT_SUCCESS,
            payload: res.data.message
        })
        toast.success(res.data.message,{autoClose:4000})
    } catch (err) {
     
        dispatch({
            type: ACT_DEACT_FAILED,
            payload: err
        })
        toast.error("Could not modify the status Please try again later",{autoClose:4000})
    }

}

export const userstatus = () => async dispatch => {
 
    
    try {
        dispatch({
            type: STATUS_BEGIN
        })
        
        const res = await axios.get('/user/getstatus')
       
        dispatch({
            type: STATUS_SUCCESS,
            payload: res.data
        })
      
    } catch (err) {
      
        dispatch({
            type: STATUS_FAIL,
            payload: err
        })
    }

}

