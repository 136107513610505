import React, { Component } from 'react'
import {connect} from 'react-redux'
import classes from './API.module.css'
import {removeKey, generateKey} from '../../actions/apikey/apikey';


class API extends Component {

    constructor(props){
        super(props);
        this.state = {
            isNotGenerated: true
        }
    }

    generateKey = () => {
        this.props.generateKey()
        this.setState({ isNotGenerated: false})
    }

    removeKey = () => {
        this.props.removeKey(this.props.api.key)
        this.setState({isNotGenerated: true})
    }

    render() {
       
        const isNotGenerated = !this.props.api;
        return (

            <>
            <div className="app-content container m-auto" style={{height:"80vh"}} >
                <div className="row justify-content-center" id="apiKeyGenBox">
                   <span className="mt-4"><strong>API KEY: </strong>{this.props.api?this.props.api:'*No key generated*'}</span>
                   <div className="d-flex text-center justify-content-center" style={{width:"100%"}}>
                   <button id="generateBtn" onClick={this.generateKey} className={classes.GenerateButton} disabled={!isNotGenerated}>Generate Key</button>
                   <button id="removeBtn" onClick={this.removeKey} className={classes.RemoveButton} disabled={isNotGenerated}>Remove Key</button>
                    
               
                </div>
                </div>
            </div>
            </>
        )
    }
}

//export default API

 const mapStateToProps = state => ({
    api: state.apiKeyReducer.key,

})

export default connect(mapStateToProps,{removeKey, generateKey})(API);