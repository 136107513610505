import React, { Component } from "react";
import { pass, visibility, close } from "../../constants/Constants"
import logo from "../../images/MicrosoftTeams-image.png"
import { postMailResetPassword } from "../../actions/common/mailresetpassword1";
import { connect } from "react-redux";
//import toaster from "toasted-notes";
import { Redirect, Link } from "react-router-dom";
import moment from "moment";

class ChangePassword extends Component {
    state = {
        password: "",
        id: 0,
        data: "",
        pass: true,
        pass2: true,
        confirmpassword: "",
        errors: { password: "", confirmpassword: "" },
        mailresetpassword: "",
        buttonDisable: false,
        loading: false,
        loginMessage: false,
        message:
            "You've successfully updated your password.We are taking you to your 'the work stock exchange' login page now",
        conmessage: "New password and confirm password do not match",
    };

    componentWillReceiveProps = (props) => {
        this.setState({
            loading: true,
        });
        if (props.loading === false) {
            this.setState({
                loading: false,
                buttonDisable: true,
                password: "",
                confirmpassword: ""
            });
        }

    };

    validatePassword() {
        const regex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,20})");
        const errors = {};
        let passwordIsValid = true;
        if (!this.state.password) {
            passwordIsValid = false;
            errors.password = "*Please enter new password.";
        }
        if (this.state.password !== "") {
            if (
                !(regex.test(this.state.password))
            ) {
                passwordIsValid = false;
                errors.password =
                    "Weak Password!. Your password should contain minimum 8 characters with uppercase, lowercase, special characters and numbers";
            } else {
                if (!this.state.confirmpassword) {
                    passwordIsValid = false;
                    errors.confirmpassword = "*Please confirm new password";
                } else {
                    passwordIsValid = true;
                }
            }
        }

        this.setState({
            errors,
        });
        return passwordIsValid;
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value,
            buttonDisable: false
        });
        this.setState({ errors: { password: "", confirmpassword: "" }, })
    };

    togglePassword1 = () => {
        var x = document.getElementById("password");
        var img = document.getElementsByClassName("pass-img")[0];
        if (x.type === "password") {
            x.type = "text";
            this.setState({ pass: !this.state.pass });
        } else {
            x.type = "password";
            this.setState({ pass: !this.state.pass });
        }
    };

    togglePassword2 = () => {
        var x = document.getElementById("confirmpassword");
        var img = document.getElementsByClassName("pass-img")[0];
        if (x.type === "password") {
            x.type = "text";
            this.setState({ pass2: !this.state.pass2 });
        } else {
            x.type = "password";
            this.setState({ pass2: !this.state.pass2 });
        }
    };

    handlePassword = (e) => {
        e.preventDefault();
        if (this.validatePassword()) {
            if (
                this.state.password === this.state.confirmpassword &&
                this.state.password !== "" &&
                this.state.confirmpassword !== ""
            ) {
                this.setState({ isLoading: true });
                let Id = window.location.href.toLowerCase();
                // Id = Id.replace(/%/g, "#")
                Id = Id.split("/")[4];
                const resetObj = {
                    UUiD: Id,
                    password: this.state.password,
                };
                this.props.dispatch(
                    postMailResetPassword({
                        resetObj,
                    })
                );
            }
            setTimeout(() => {
                this.setState({ isLoading: false });
            }, 2000);
        }
        this.setState({ loginMessage: true, id: 1 });
    };

    render() {
        let Ex = window.location.href.toLowerCase();
        const Expir = Ex.split("?")[1];
        const Expiry = new Date(parseInt(Expir));
        let tDate = new Date();
        return (
            <>
                <div className="topNavigation">
                    <header>
                        <nav
                            className="navbar navbar-expand-md navbar-light"
                        >
                            <a className="navbar-brand" href="/" style={{ marginLeft: "15px" }}>
                                <div className="row">
                                    <img className="ml-4" src={logo} alt="logo" style={{ width: '70px', height: '70px' }} />
                                    <h5 style={{ marginBlockStart: "auto", fontSize: '10px', fontWeight: 'bold', marginLeft: "11px" }}>
                                        NuOCR
                                    </h5>
                                </div>
                            </a>
                        </nav>
                    </header>
                </div>
                <div className="app-content container1">
                        <form
                            className="signin-form needs-validation mr-5 col-md-4"
                            id="main-form"
                            noValidate
                            style={{height:'75vh'}}
                        >
                            {tDate < Expiry ? (
                                <>
                                    <h2>Reset Password</h2>
                                    <div className="form-group form-position" style={{ position: "relative" }}>
                                        <label htmlFor="inputpassone">New Password</label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            id="password"
                                            name="password"
                                            value={this.state.password}
                                            onChange={this.handleChange}

                                            maxLength={20}
                                            placeholder="Enter new password"
                                            required
                                            autoFocus
                                        />
                                        <div
                                            className="errorMsg"
                                            style={{ color: "red", fontSize: 12 }}
                                        >
                                            {this.state.errors.password}
                                        </div>
                                        <img
                                            src={this.state.pass ? visibility : pass}
                                            onClick={this.togglePassword1}
                                            className="pass-img"
                                            alt="pass-img"
                                        />
                                    </div>
                                    <div
                                        style={{ position: "relative", marginBottom: "0.5rem" }}
                                        className="form-group"
                                    >
                                        <label htmlFor="inputpasstwo">Confirm Password</label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            id="confirmpassword"
                                            name="confirmpassword"
                                            value={this.state.confirmpassword}
                                            onChange={this.handleChange}
                                            maxLength={20}

                                            placeholder="Confirm new password"
                                            required
                                        />
                                        <div

                                            className="errorMsg"
                                            style={{ color: "red", fontSize: 12 }}
                                        >

                                            {this.state.errors.confirmpassword}
                                        </div>
                                        <img
                                            src={this.state.pass2 ? visibility : pass}
                                            onClick={this.togglePassword2}
                                            className="pass-img"
                                            alt="pass-img"
                                        />
                                    </div>
                                    {this.state.password !== this.state.confirmpassword &&
                                        this.state.password !== "" &&
                                        this.state.confirmpassword !== "" ? (
                                        <div
                                            className="errorMsg"
                                            style={{ color: "red", fontSize: 12 }}
                                        >
                                            {this.state.conmessage}
                                        </div>
                                    ) : null}
                                    <button
                                        type="submit"
                                        className="btn submit-button signin-submit-btn btn-primary"
                                        onClick={this.handlePassword}
                                        disabled={this.state.buttonDisable}
                                    >
                                        Submit
                                        {this.props.loading && (
                                            <i className="fa fa-spinner fa-spin"></i>
                                        )}
                                    </button>
                                </>
                            ) : (
                                <h2>Your link has been expired.</h2>
                            )}
                            <small id="text-bottom" className="form-text text-center mt-2">
                                <Link className="sign-txt" to={"/login"}>Go Back to Sign in</Link>
                            </small>
                        </form>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    mailresetpassword: state.mailresetPassReducer.mailresetpassword,
    error: state.mailresetPassReducer.error,
    loading: state.mailresetPassReducer.loading,
});

export default connect(mapStateToProps)(ChangePassword);
