import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
// import Navbar from "../public/NavBar";
// import AdvancSearch from "./../public/AdvancedSearch";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  fetchFormDataById,
  postUserFormData,
} from "../../actions/DynamicFormActions/DyamicFormAction";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { close1 } from "../../constants/Constants";
import Modal from "react-bootstrap/Modal";
import { userToken } from "../../constants/Constants";
import { getUserToken } from "../../services/AuthService";
import DatePicker from "react-datepicker";
import NavBar from "../layout/navigation";

const FormView = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  // const schools = getUserToken().schools;
  // const students = getUserToken().student;
  // const role = getUserToken().role;
  // const grades = getUserToken().schoolgrades.map((item) => item.fk_grade._id);
  const {
    fetchLoading,
    error,
    formData,
    postFormLoading,
    postError,
    postStatus,
  } = useSelector(
    (state) => ({
      fetchLoading: state.dynamicFormReducer.fetchLoading,
      error: state.dynamicFormReducer.error,
      formData: state.dynamicFormReducer.formData,
      postFormLoading: state.dynamicFormReducer.postFormLoading,
      postError: state.dynamicFormReducer.postError,
      postStatus: state.dynamicFormReducer.postStatus,
    }),
    shallowEqual
  );

  const [formValue, setFormValue] = useState({});
  const [focusVar, setFocusVar] = useState({});
  const [flag, setFlag] = useState(true);
  const [show, setShow] = useState(false);
  const [fileVal, setFileVal] = useState(["doc", "docx", "pdf", "jpeg", "jpg"]);
  const [valid, setValid] = useState(false);

  useEffect(() => {
    if (!formData && !error) {
      // let fk_schools = schools.map((item) => item.fk_school);
      dispatch(
        fetchFormDataById({
          id: id,
          // students: students,
          // schools: fk_schools,
          // grades: grades,
        })
      );
    } else if (error && error != "Bad Request") {
      toast.error("Oops, something went wrong, please try again", {
        autoClose: 4000,
      });
    } else {
      setFormValue(_.cloneDeep(formData));
      let temp = { ...focusVar };
      formData &&
        formData.formdata.map((item, index) => {
          temp[index] = false;
        });
      setFocusVar(temp);
    }
  }, [formData, error]);

  useEffect(() => {
    if (postError) {
      toast.error(postError, {
        autoClose: 4000,
      });
    }
    if (postStatus) {
      toast.success(postStatus.message, {
        autoClose: 4000,
      });
      setShow(true);
    }
  }, [postError, postStatus]);

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleInputChange = async (e, index) => {
    let type = e.target.type;
    let fields = { ...formValue };
    let currentfield = fields.formdata[index];
    if (
      type === "text" ||
      type === "email" ||
      type === "date" ||
      type === "select-one" ||
      type === "radio" ||
      type === "select"
    ) {
      currentfield["value"] = e.target.value;
    } else if (type === "file") {
      let file = e.target.files[0];
      if (!fileVal.includes(file.name.split(".")[1])) {
        toast.warn(
          "File type not supported. Supported types are pdf, doc, docx, jpeg and jpg",
          {
            autoClose: 4000,
          }
        );
      } else {
        let base64File = await toBase64(file);
        currentfield["value"] = {
          base64: base64File,
          fileType: file.type,
          fileName: file.name,
        };
      }
    } else if (type === "checkbox") {
      let checkedValues = currentfield.value || [];
      if (e.target.checked) {
        checkedValues.push(e.target.value);
      } else {
        let filteredAry = checkedValues.filter((val) => val !== e.target.value);
        checkedValues = filteredAry;
      }
      currentfield["value"] = checkedValues;
    }
    setFormValue(fields);
  };

  const onDateChange = (date, index) => {
    let fields = { ...formValue };
    let currentfield = fields.formdata[index];
    currentfield["value"] = date;
    setFormValue(fields);
  };

  useEffect(() => {
    if(formValue.formdata){
      formValue.formdata.map((item) => {
      if(item.value!==undefined){
        setValid(true)
      }
    })
    }
  }, [formValue]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let params = {
      fk_dynamicform: formValue._id,
      // schools: schools,
      // students: students,
      form_data: formValue.formdata.map((data) => ({
        name: data.label,
        value: data.value,
        // createentity: data.createentity ? data.createentity : "",
        // createschema: data.createschema ? data.createschema : "",
        type: data.type,
      })),
    };
    
    if(valid){
      dispatch(postUserFormData(params));
    }else{
      toast.error("Please enter details", {
        autoClose: 4000,
      });
    }
  };

  const setFocus = (index) => {
    if (flag) {
      let temp = { ...focusVar };
      temp[index] = true;
      setFocusVar(temp);
      setFlag(false);
    }
  };

  const setBlur = (index) => {
    if (!flag) {
      let temp = { ...focusVar };
      temp[index] = false;
      setFocusVar(temp);
      setFlag(true);
    }
  };

  const redirect = () => {
    toast.success(
      "Successfully submitted",
      {
        autoClose: 4000,
      }
    );
    history.push("/dashboard");
  };

  const logoutredirect = () => {
    localStorage.removeItem(userToken);
    history.push("/");
  };
  const InputTypeElement = ({ data, index, typ, val }) => {
    let type = typ;
    if (type === "text" || type === "email") {
      return (
        <input
          type={type}
          className="form-control"
          placeholder={data.placeholder}
          name={data.label}
          required={data.required}
          onChange={(e) => handleInputChange(e, index)}
          value={formValue.formdata[index].value}
          autoFocus={focusVar[index]}
          onFocus={() => setFocus(index)}
          onBlur={() => setBlur(index)}
        />
      );
    }
    if (type === "date") {
      return (
        <DatePicker
          dateFormat="dd MMM yyyy"
          required={data.required}
          name={data.label}
          placeholderText={data.placeholder}
          className="form-control datepicker"
          onChange={(date) => onDateChange(date, index)}
          selected={formValue.formdata[index].value}
        />
      );
    }
    if (type === "file") {
      return (
        <input
          type={type}
          className="form-control"
          onChange={(e) => handleInputChange(e, index)}
        />
      );
    } else if (type === "radio" || type === "checkbox") {
      return (
        <>
          {data.options.map((item, key) => (
            <div className="form-check mr-2" key={key}>
              <input
                className="form-check-input h-auto"
                type={type}
                name={data.label}
                value={item.name}
                onChange={(e) => handleInputChange(e, index)}
                required={
                  _.isEmpty(formValue.formdata[index].value)
                    ? data.required
                    : false
                }
                checked={
                  type === "radio"
                    ? formValue.formdata[index].value === item.name
                    : formValue.formdata[index].value &&
                      formValue.formdata[index].value.includes(item.name)
                }
              />
              <label className="form-check-label">{item.label}</label>
            </div>
          ))}
        </>
      );
    } else {
      return (
        <>
          <select
            className="form-control"
            name={data.label}
            onChange={(e) => handleInputChange(e, index)}
            required={data.required}
            value={formValue.formdata[index].value}
          >
            <option>Select..</option>
            {data.createentity
              ? data.options.map((item, key) => (
                  <option key={key} value={item.value}>
                    {item.label}
                  </option>
                ))
              : data.options.map((item, key) => (
                  <option key={key}>{item.label}</option>
                ))}
          </select>
        </>
      );
    }
  };
  return (
    <div className="page dashboard-page">
      <NavBar />
      {/* <AdvancSearch /> */}
      <div className="dynamicForm p-5">
        <div className="dynamic-form-header mb-3">
          <h5 className="section-title mt-4 mb-5">Submit Form</h5>
          <a href="/dashboard">
            <img src={close1} alt="close" className="close" />
          </a>
        </div>
        {error && error === "Bad Request" ? (
          <h3 className="text-center mt-5 mb-5">
            {" "}
            Access restricted. Please contact the administrator
          </h3>
        ) : (
          <>
            {!_.isEmpty(formValue) && formValue.dfstatus === 6 ? (
              <div className="dynamic-form-container" style={{minHeight:"200px",width:'50%'}}>
                <h5 className="text-center">{formValue.formname}</h5>
                <form id="userForm" onSubmit={handleSubmit}>
                  <div className="form-content d-flex flex-wrap justify-content-center mt-4">
                    {formValue.formdata.length > 0 && (
                      <>
                        {formValue.formdata.map((item, key) => (
                          <div className="form-group" key={key}>
                            <label className="label-custom">{item.label}</label>
                            {item.required && (
                              <span className="required-asterix">*</span>
                            )}
                            <div
                              className={
                                item.type === "radio" ||
                                item.type === "checkbox"
                                  ? "form-inline"
                                  : ""
                              }
                            >
                              {
                                <InputTypeElement
                                  data={item}
                                  typ={item.type}
                                  index={key}
                                  val={item.value}
                                />
                              }
                              {item.type === "file" &&
                                formValue.formdata[key].value && (
                                  <span>
                                    {formValue.formdata[key].value.fileName}
                                  </span>
                                )}
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                  <br/>
                  <div className="main-bottom col-md-12 d-flex justify-content-center text-white mb-5">
                    <button
                      type="submit"
                      style={{width:'120px',border:'1ps solid transparent',padding:'5px'}}
                      disabled={postFormLoading}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            ) : (
              "This form is unavailable. Please contact the administrator"
            )}
          </>
        )}
      </div>
      <Modal show={show}>
        <Modal.Body> Would you like to return to the dashboard?</Modal.Body>
        <Modal.Footer>
          <button type="submit" className="addSchoolbutton" onClick={redirect}>
            Yes
          </button>
          <button
            type="submit"
            className="addSchoolbutton"
            onClick={logoutredirect}
          >
            No
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default FormView;
