import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { getUserToken } from "../../services/AuthService";
import {
  fetchDynamicFormList,
  deleteDynamicForm,
} from "../../actions/DynamicFormActions/GetDynamicFormsAction";
import { more } from "../../constants/Constants";
import FormBuilder from "./FormBuilder";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import _ from "lodash";
import { toast } from "react-toastify";
import './DynamicPage.css';

const DynamicForm = () => {
  const dispatch = useDispatch();
  const schools = getUserToken().schools;
  const [openPreview, setOpenPreview] = useState(false);
  //const [openFormBuilder, setOpenFormBuilder] = useState(false);
  const [previewFomData, setPreviewFormData] = useState({ name: "", data: "" });
  const [params, setParams] = useState({ id: "", status: "", schools: schools });
  const [show, setShow] = useState(false);
  const [clone, setClone] = useState({});

  const { loading, formList, error, options, deleteResponse } = useSelector(
    (state) => ({
      loading: state.dynamicFormsListReducer.loading,
      formList: state.dynamicFormsListReducer.formList,
      error: state.dynamicFormsListReducer.error,
      options: state.dynamicFormsListReducer.options,
      deleteResponse: state.dynamicFormsListReducer.deleteResponse,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (!formList && !error) {
      dispatch(fetchDynamicFormList(params));
    }
  }, [formList]);

  useEffect(() => {
    toast.success(deleteResponse.message, {
      autoClose: 4000,
    });
  }, [deleteResponse]);

  const handleFilterChnage = (e) => {
    let param = { ...params };
    param[e.target.name] = e.target.value;
    setParams(param);
    dispatch(fetchDynamicFormList(param));
  };

  const handlePreviewOpen = (data) => {
    setPreviewFormData({
      name: data.formName,
      data: JSON.parse(data.formData),
    });
    setOpenPreview(!openPreview);
  };
  const handlePreviewClose = () => {
    setOpenPreview(!openPreview);
  };
  const toggleCreateForm = () => {
    //setOpenFormBuilder(!openFormBuilder);
    setShow(!show);
    setClone({});
  };
  const RenderFormElement = () => {
    let element = previewFomData.data.map((data, key) => {
      if (data.type === "radio" || data.type === "checkbox") {
        return (
          <div className="form-group">
            <label>Example label</label>
            {previewFomData.data.map((data, key) => {
              return (
                <div className="form-check" key={"1" + key}>
                  <input
                    className="form-check-input"
                    type="radio"
                    name="gridRadios"
                    id="gridRadios2"
                    defaultValue="option2"
                  />
                  <label className="form-check-label" htmlFor="gridRadios2">
                    Second radio
                  </label>
                </div>
              );
            })}
          </div>
        );
      } else if (data.type === "selectBox") {
        return (
          <div className="form-group">
            <label>Disabled select menu</label>
            <select className="form-control">
              <option>Disabled select</option>
            </select>
          </div>
        );
      } else {
        return (
          <div className="form-group" key={key}>
            <label>{data.name}</label>
            <input
              type={data.type}
              className="form-control"
              placeholder="Example input"
            />
          </div>
        );
      }
    });
    return element;
  };

  const duplicateForm = (data) => {
    let temp = _.cloneDeep(data);
    let keys = [
      "_id",
      "createby",
      "updateby",
      "fk_user",
      "createdAt",
      "updatedAt",
      "__v",
      "formname",
    ];
    keys.map((key) => {
      delete temp[key];
    });
    setClone(temp);
    setShow(true);
  };

  const deleteForm = async (data) => {
    await dispatch(deleteDynamicForm({ id: data._id }));
    await dispatch(fetchDynamicFormList(params));
  };

  return (
    <div className="app-content container1">
      <div className="page-header mb-3">
        <h3>Dynamic Form Builder</h3 >
      </div>

      <div className="dyForm border p-5">
        <div className="dyFilterForm">
          <p><b>Filter</b></p>
          <div className="row">
            <form className="col-12 col-md-6 d-flex mb-2">
              <div className="col-6 p-1">
                <select
                  name="id"
                  onChange={handleFilterChnage}
                  className="p-2 col-12 col-md-12"
                >
                  <option value="">Name</option>
                  {options.map((item, key) => (
                    <option key={key} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-6 p-1">
                <select
                  name="status"
                  onChange={handleFilterChnage}
                  className="p-2 col-12 col-md-12"
                >
                  <option value="">Status</option>
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
              </div>
            </form>
            <div className="col-6 d-flex">
              <button id="createNewFormBtn" onClick={toggleCreateForm}>
                {" "}
                + Create New Form
              </button>
            </div>
          </div>
        </div>
        <hr className="mb-3" />
        <br />
        <ul className="dyList">
          {loading && (
            <li className="text-center">
              <h4>Loading...</h4>
            </li>
          )}
          {error && (
            <li className="text-center mt-5 mb-5">
              <h6>Failed to fetch form list</h6>
            </li>
          )}
          {formList && formList.length === 0 && (
            <li className="text-center mt-5 mb-5">
              <h6> No Records Found!!!</h6>
            </li>
          )}
          {formList && formList.length > 0 && (
            <>
              {formList.map((data, key) => {
                return data.dfstatus === 6 ? (
                  <li className="mb-4 d-flex justify-content-between border-bottom" key={key}>
                    <div>
                      <p>
                        <a href={`/dynamic-form/${data._id}`}
                        >
                          {data.formname}
                        </a>
                        {moment(data.enddate).diff(moment(), 'days') >= 0 ? (
                          <span className="active" >Active</span>
                        ) : (
                          <span className="inactive">Inactive</span>
                        )}
                      </p>
                      <p>
                        <span className="d-block calendar">
                          {moment(data.startdate).format("D MMM, YYYY")}
                          {" "} | {" "}
                          {moment(data.enddate).format("D MMM, YYYY")}
                        </span>
                      </p>
                      <p className="d-flex flex-wrap mb-1 mt-2">
                        {data.sharedoccurs.length === 7 ? (
                          <span className="mb-1">Daily</span>
                        ) : (
                          <>
                            {data.sharedoccurs.map((day, key) => (
                              <span className="mb-1 mr-3" style={{ border:"1px solid rgb(245, 195, 195)",
                                background: "rgb(245, 195, 195)",
                                borderRadius:" 15px",
                                fontSize:"12px",
                                padding:"3px"}} key={key}>{day}</span>
                            ))}
                          </>
                        )}
                      </p>

                    </div>
                    <div className="dyDropMain">
                      <button
                        type="button"
                        className="dyListDropBtn"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img src={more} alt="more" />
                      </button>
                      <div
                        className="dropdown-menu dropdown-menu-right"
                        id="dyDropItem"
                      >
                        <button
                          className="dropdown-item"
                          type="button"
                          onClick={() => duplicateForm(data)}
                        >
                          Clone Form
                        </button>
                        <button
                          className="dropdown-item deleteForm"
                          type="button"
                          onClick={() => deleteForm(data)}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </li>
                ) : null;
              })}
            </>
          )}
        </ul>
      </div>
      <Modal
        show={show}
        onHide={toggleCreateForm}
        className="overlay modal common-modal fade modalZIndex"
      >
        <FormBuilder close={toggleCreateForm} clone={clone} />
      </Modal>
    </div>
  );
};
export default DynamicForm;
