
import axios from 'axios';
import { toast } from "react-toastify";


export const SET_OPTIONS = "SET_OPTIONS"
export const SET_TEMPLATETYPE_OPTIONS = "SET_TEMPLATETYPE_OPTIONS"
export const SET_INDUSTRYTYPE_OPTIONS = "SET_INDUSTRYTYPE_OPTIONS"
export const SET_USERS_OPTIONS = "SET_USERS_OPTIONS"

export const DOCUMENT_SEARCH_BEGIN = 'DOCUMENT_SEARCH_BEGIN';
export const DOCUMENT_SEARCH_SUCESS = 'DOCUMENT_SEARCH_SUCESS';
export const DOCUMENT_SEARCH_FAILURE = 'DOCUMENT_SEARCH_FAILURE';


export const DATA_EDIT_BEGIN = 'DATA_EDIT_BEGIN';
export const DATA_EDIT_SUCESS = 'DATA_EDIT_SUCESS';
export const DATA_EDIT_FAILURE = 'DATA_EDIT_FAILURE';



export const fetchDocumentSearch = (data) => async dispatch => {
  dispatch({
    type: DOCUMENT_SEARCH_BEGIN,

  })
  await axios.post("/document-search/search-document", data).then((res) => {



    dispatch({
      type: DOCUMENT_SEARCH_SUCESS,
      payload: res.data
    })

  }).catch((err) => {
    dispatch({
      type: DOCUMENT_SEARCH_FAILURE,
      payload: err
    })
  })


}

export const saveEdited = (data) => async dispatch => {

  dispatch({
    type: DATA_EDIT_BEGIN,

  })
  await axios.post("/document-search/editrecognitiondata", data).then((res) => {



    dispatch({
      type: DATA_EDIT_SUCESS,
      payload: res.data
    })
    alert("Data updated successfully")

  }).catch((err) => {
    dispatch({
      type: DATA_EDIT_FAILURE,
      payload: err
    })
    alert("failed to update data")
  })


}



export const documentnameoptions = (data) => async dispatch => {

  await axios.post("/document-search/documentnames", data).then((res) => {
    let options = [{ label: ["All Documents"], value: '', name: "documentid" }]
    dispatch({
      type: SET_OPTIONS,
      payload: options.concat(res.data.documentname.filter((v, i, a) => a.findIndex(t => (t.imagefilename === v.imagefilename)) === i).map((document) => {
        return {
          label: [document.imagefilename].filter(s => s != null),
          value: document.imagefilename,
          name: "documentid"
        }
      }))
    })

  }).catch((err) => {
    dispatch({
      type: SET_OPTIONS,
      payload: null
    })
  })


}

export const templatenameoptions = (data) => async dispatch => {

  await axios.post("/document-search/templatenames", data).then((res) => {
    let options = [{ label: ["All templates"], value: '', name: "templateid" }]
    dispatch({
      type: SET_TEMPLATETYPE_OPTIONS,
      payload: options.concat(res.data.templatename
        // .filter((v, i, a) => a.findIndex(t => (t.templatename === v.templatename && t._id === v._id)) === i)
        .map((document) => {
          return {
            label: [document.templatename].filter(s => s != null),
            value: document.templatename,
            name: "templateid"
          }
        }))
    })

  }).catch((err) => {
    dispatch({
      type: SET_TEMPLATETYPE_OPTIONS,
      payload: null
    })
  })


}
export const industrytypeoptions = (data) => async dispatch => {

  await axios.post("/document-search/industrytypes", data).then((res) => {
    let options = [{ label: ["All industries"], value: '', name: "industrytypeid" }]
    dispatch({
      type: SET_INDUSTRYTYPE_OPTIONS,
      payload: options.concat(res.data.industrytype.filter((v, i, a) => a.findIndex(t => (t.industrytype === v.industrytype && t._id === v._id)) === i).map((document) => {
        return {
          label: [document.typename].filter(s => s != null),
          value: document._id,
          name: "industrytypeid"
        }
      }))
    })

  }).catch((err) => {
    dispatch({
      type: SET_INDUSTRYTYPE_OPTIONS,
      payload: null
    })
  })


}
export const useroptions = (data) => async dispatch => {

  await axios.post("/document-search/users", data).then((res) => {
    let options = [{ label: ["All users"], value: '', name: "userid" }]
    dispatch({
      type: SET_USERS_OPTIONS,
      payload: options.concat(res.data.users.filter((v, i, a) => a.findIndex(t => (t.useremail === v.useremail && t._id === v._id)) === i).map((document) => {
        return {
          label: [document.useremail].filter(s => s != null),
          value: document._id,
          name: "userid"
        }
      }))
    })

  }).catch((err) => {
    dispatch({
      type: SET_USERS_OPTIONS,
      payload: null
    })
  })
}




