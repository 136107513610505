import React, { useState, Component } from 'react'

import { Link } from 'react-router-dom'
import logo from "../../images/MicrosoftTeams-image.png"
import { postforgotpassword } from "../../actions/common/Forgotpassword";

import { connect } from "react-redux";

class ForgotPassword extends Component {

  state = {
    email: "",
    errors: {},
    loading: false,
    RetrunText: true,
    buttonDisable: false,
  };

  componentWillReceiveProps = (props) => {
    if (
      this.props.forgotpassword.message === "success"
    ) {
      this.setState({ buttonDisable: true });
    }
    if (
      this.props.error !== undefined
    ) {
      this.setState({ buttonDisable: true });
    }
  };

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
      buttonDisable: false
    });

    const urlLink = `${window.location.protocol}//${window.location.host}`

  };

  handleKeyDown = (event) => {
    if (this.state.loading === false) {
      if (event.key === "Enter") {
        this.handleReset(event);
      }
    }
  };

  handleReset = (e) => {
    e.preventDefault();
    if (this.validateEmail()) {
      if (this.state.email !== "") {
        const urlLink = `${window.location.protocol}//${window.location.host}`
        this.props.dispatch(postforgotpassword({ userEmail: this.state.email, urlLink: urlLink }));
        this.setState({ email: "" })
      }
    }
    this.setState({ loginMessage: true, message: "" });
  };

  validateEmail() {
    const errors = {};
    let emailIsValid = true;
    if (!this.state.email) {
      emailIsValid = false;
      errors.email = "*Please enter your email.";
    }

    // if (typeof this.state.email !== "undefined") {
    //   let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    //   if (!pattern.test(this.state.email)) {
    //     emailIsValid = false;
    //     errors.email = "*Please enter valid email.";
    //   }
    // }

    this.setState({
      errors,
    });
    return emailIsValid;
  }



  render() {

    return (<>
       <div className="topNavigation">
        <header>
          <nav
            className="navbar navbar-expand-md navbar-light"
          >
            <a className="navbar-brand" href="/" style={{ marginLeft: "15px" }}>
              <div className="row">
                <img className="ml-4" src={logo} alt="logo" style={{ width: '70px', height: '70px' }} />
                <h5 style={{ marginBlockStart: "auto", fontSize: '10px', fontWeight: 'bold', marginLeft: "11px" }}>
                  NuOCR
                </h5>
              </div>
            </a>
          </nav>
        </header>
        </div>
        <div className="app-content container1">
          <form
            action="forgot_code.html"
            className="signin-form needs-validation mr-5 col-md-4"
            novalidate
            id="main-form"
            style={{height:'75vh'}}
          >
            <div className="page-header">
              <h2>Forgot Password</h2>
              <span className="page-subtitle " >
                Enter the email address associated with your Docketry account. We will email you the instructions on how to reset it.
              </span>
            </div>

            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                placeholder="Enter Email"
                value={this.state.email}
                onChange={this.handleChange}
                autoComplete="on"
                onKeyDown={this.handleKeyDown}
                autoFocus />
              <div className="Sspace">
                <p className="text-danger"><small>{this.state.errors.email}</small></p>
              </div>
            </div>
            <button
              type="submit"
              className="btn submit-button signin-submit-btn btn-primary"
              disabled={this.state.buttonDisable}
              onClick={this.handleReset}
            >
              Send{this.props.loading && <i className='fa fa-spinner fa-spin'></i>}
            </button>
            <small id="text-bottom" className="form-text text-center mt-2">
              <a className="sign-txt">
                <Link to={"/login"}>Go Back to Sign in</Link>
              </a>
            </small>
          </form>
        </div>
    </>)
  }
}


const mapStateToProps = (state) => ({
  forgotpassword: state.forgotPasswordReducer.forgotpassword,
  error: state.forgotPasswordReducer.error,
  loading: state.forgotPasswordReducer.loading,
});
export default connect(mapStateToProps)(ForgotPassword);