import React, { useState, useEffect } from 'react';
import { userList, roleList, addUser, editUser, ActivateorDeactivateUser } from '../../actions/adduser'
import { useDispatch, useSelector, shallowEqual } from "react-redux";

import Select from 'react-select';
import { getUserToken } from "../../services/AuthService";
import Modal from "react-bootstrap/Modal";
import '../../assets/css/navigation.css'
import 'react-toastify/dist/ReactToastify.css'
import { propTypes } from 'react-bootstrap/esm/Image';

const ManageUser = () => {
    const dispatch = useDispatch();

    const loggedinuser = JSON.parse(localStorage.getItem("token"))
    const [formData, setFormData] = useState({ email: "", role: "" });
    const [params, setParams] = useState({ userid: "", roleid: "" });
    const [editMode, setEditMode] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedoptionRole, setselectedoptionRole] = useState(null);
    const [show, setShow] = useState(false);
    const [errors, setError] = useState({});
    const [userid, setEdituser] = useState("")
    const [buttonDisable, setButtondisable] = useState(false)


    const { userlisterror,
        users,
        usersloading, data,
        loading,
        activated,
        error, options, roleoptions, roles, rolesloading, editsuccess, editloading, editfail, activate } = useSelector(state => ({
            usersloading: state.AddUserReducer.usersloading,
            users: state.AddUserReducer.users,
            userlisterror: state.AddUserReducer.userlisterror,
            data: state.AddUserReducer.data,
            loading: state.AddUserReducer.loading,
            activated: state.AddUserReducer.activated,
            error: state.AddUserReducer.error,
            options: state.AddUserReducer.options,
            roleoptions: state.AddUserReducer.roleoptions,
            roles: state.AddUserReducer.roles,
            rolesloading: state.AddUserReducer.rolesloading,
            editsuccess: state.AddUserReducer.editsuccess,
            editloading: state.AddUserReducer.editloading,
            editfail: state.AddUserReducer.editfail,
            activate: state.AddUserReducer.activate



        }), shallowEqual);

    useEffect(() => {
        if (!users) dispatch(userList(params));
    }, [users])
    const addNewStaff = () => {
        setFormData({})
        dispatch(roleList())
        setEditMode(false);
        setButtondisable(true)
        setShow(true);
    }


    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.id]: e.target.value });
        setButtondisable(false)
    };

    useEffect(() => {

        if (data !== null) {

            setFormData({});
            dispatch(userList(params))
            setShow(false);
            setButtondisable(true)
        }
        if (editsuccess !== null) {

            setButtondisable(true)
            setFormData({});
            dispatch(userList(params))
            setShow(false);
        }
        if (activate !== null) {
            dispatch(userList(params))
        }
    }, [data, editsuccess, activate])

    const handleSubmit = (e) => {
        e.preventDefault();

        const isvalidfun = isValid();

        if (isvalidfun) {

            if (editMode) {
                let editdetails = {
                    userid: userid,
                    roleid: formData.role
                }

                dispatch(editUser(editdetails))



            } else {


                const userdetails = {
                    fk_roles: formData.role,
                    status: "active",
                    useremail: formData.email,

                }

                dispatch(addUser(userdetails))


            }

        }
    }

    const handleSearch = (option) => {
        setselectedoptionRole(option)
    }
    const filterList = () => {
        params['userid'] = (selectedOption ? selectedOption.value : "");
        params['roleid'] = (selectedoptionRole ? selectedoptionRole.value : "");
        setParams(params)
        dispatch(userList(params))
    }


    const handleChange = (option) => {
        setSelectedOption(option)

    }


    const isValid = () => {
        let formIsValid = true;
        let errordata = {};
        let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (formData.email) {
            if (!pattern.test(formData.email)) {
                formIsValid = false;
                errordata["useremail"] = "Invalid Email Address*";
            }

        }
        setError(errordata);
        return formIsValid;
    };

    const handleClose = () => {

        setShow(false);
        let r = window.confirm("Are you sure you want to clear data?");
        if (r == true) {
            setShow(false);
            setFormData({})
        }
        else {
            setShow(true);
        }
    }
    const Close = () => {


        setShow(false);
        let r = window.confirm("All the unsaved changes will be lost?");
        if (r == true) {
            setShow(false);
            setFormData({})
        }
        else {
            setShow(true);
        }
    }
    const editStaff = (user) => {

        dispatch(roleList())

        setButtondisable(true)
        let tmpdata = { ...formData }
        tmpdata["email"] = user.useremail
        setFormData(tmpdata)
        setEdituser(user._id)
        setEditMode(true);
        setShow(true);


    }
    const customFilter = (option, inputValue) => {
        const reg = new RegExp(`^${inputValue}`, "i");
        return reg.test(option.label);
    };
    // const  getOptionLabel = option => option.label;
    // const getOptionValue = option => option.id;
    const getDate = (datetime) => {
        let date = new Date(datetime)
        let day = date.getDate()
        let year = date.getFullYear()
        let v = date.toLocaleString('default', { month: 'short' })
        let currentdate = `${v} ${day} ${year}`
        return currentdate
    }

    const Activation = (e, id) => {
        let value = e.target.value
        if (value === "inactive") {
            dispatch(ActivateorDeactivateUser({ userid: id, status: "active" }))
        }
        else if (value === "active") {
            dispatch(ActivateorDeactivateUser({ userid: id, status: "inactive" }))
        }

    }
    return (
        <>

         
            <div class="app-content container1 main-div">
                <h1 class='page-header mb-3'>
                    Manage User <br />

                </h1>
                
         <div className="row no-gutters align-items-center" style={{ display: "flex", flexWrap: "wrap", paddingTop: "4px", paddingBottom: "10px" }}>
                         <div className="col" >
                            <div className="" style={{ fontSize: "12px" }}>
                            <Select
                                    value={selectedOption}
                                    onChange={handleChange}
                                    options={options}
                                    placeholder="Select User Email"
                                    className="custom-select-filterstaff"
                                    menuPlacement="auto"
                                    menuPosition="fixed"
                                    filterOption={customFilter}



                                />

 </div>
                        </div>

                        <div className="col" >
                            <div className="ml-2" style={{ fontSize: "12px" }}>
                               
                                 <Select
                                    value={selectedoptionRole}
                                    onChange={handleSearch}
                                    options={roleoptions}
                                    placeholder="Select Role"
                                    className="custom-select-filterstaff"
                                    menuPlacement="auto"
                                    menuPosition="fixed"
                                    filterOption={customFilter}
                                
                                 />
                            </div>
                             </div>
<div className="col ">
                            <div className="ml-2" style={{ fontSize: "14px" }}>
<button className="border-radius-0 addstaffsearch-button" onClick={ filterList }>
                                    Search
                               </button>

                            </div>
                        </div>
                        <div className="col">
                            <div className="float-right ml-2">
                                <button type="button"
                                    className="addstaffsearch-button"
                                    data-toggle="modal" data-target="#manageStaff" onClick={addNewStaff}>
                                    + New User
                                </button>
                            </div>
                        </div>

                    </div>


                    <div className="col-12 mt-3 audit-wrap">
                        <table className="table audit-table table-striped mb-0">
                            <thead className="p-thead">
                                <tr>
                                    <th className="w-10" scope="col">User Email</th>
                                    <th className="w-10" scope="col">Role</th>
                                    <th className="w-20" scope="col">Created At</th>
                                    <th className="w-20" scope="col">Updated At</th>
                                    <th className="w-20" scope="col" >Status</th>

                                </tr>
                            </thead>
                            {(users && users.length > 0) &&
                                <tbody className='p-tbody staff-body'>
                                    {users.map((item, key) => {
                                        return (
                                            <tr key={key}>
                                                <td className="text-left"><label className="mb-0" onClick={() => editStaff(item)} data-toggle="modal" data-target="#manageStaff">
                                                    {item.useremail || null}
                                                </label></td>
                                                <td>
                                                    {item.rolename}
                                                </td>
                                                <td>{getDate(item.createdAt)}</td>
                                                <td>{getDate(item.updatedAt)}</td>
                                                <td > <div className="row">
                                                    <div className="col" style={{fontSize: "14px"}}>
                                                        {item.status === "active" ? "Active" : "Inactive"}
                                                    </div>
                                                    <div className="col">
                                                        <label class="switch">
                                                            <input
                                                                type="checkbox"
                                                                checked={item.status === "active" ? "true" : ""}
                                                                value={item.status}


                                                                onChange={(e) =>
                                                                    Activation(e, item._id)
                                                                }
                                                            />
                                                            <span class="slider round" ></span>
                                                        </label></div></div></td>

                                            </tr>
                                        )
                                    })
                                    }
                                </tbody>
                            }
                        </table>
                        {(users && users.length === 0) &&
                            <p className="text-center mt-5 mb-5"> No Records Found!!!</p>
                        }
                        {usersloading &&
                            <div className="spinner-border text-dark load-spinner" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        }
                        {error &&
                            <h3 className="text-center"> {error}</h3>
                        }
                        {userlisterror &&
                            <h3 className="text-center"> {userlisterror}</h3>
                        }
                    </div>
                {/* </div> */}
            </div>
            <Modal className="modal health-form-modal health-view-modal medication-view-modal parent-modal staff-modal fade"
                id="manageStaff"
                show={show}
                onHide={handleClose}
                dialogClassName="modal-190w"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <Modal.Header>
                    <Modal.Title id="exampleModalLabel" className="modal-header d-flex">
                        <b className="modal-title" id="exampleModalLabel">
                            {editMode ? "Edit Role" : "Add New User"}</b>
                    </Modal.Title>
                    <button type="button" title="Close"  className="close-staff-close" aria-label="Close" onClick={handleClose}>
                        <span aria-hidden="true" style={{fontSize:"25px"}}>×</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-body pt-0">
                        <form id="staffEntityForm" onSubmit={(e) => handleSubmit(e)}>
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group">
                                        <label>Role<span className="text-danger">*</span></label>
                                        <select className="form-control" id="role" name="role"
                                            onChange={(e) => handleInputChange(e)} required>
                                            <option value="">Select..</option>
                                            {roles &&
                                                <>
                                                    {roles.filter(item => item.rolename !== loggedinuser.rolename).map((item, key) =>
                                                        <option key={key} value={item._id}>{item.rolename}</option>
                                                    )}
                                                </>
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className="form-group">
                                        <label>Email<span className="text-danger">*</span></label>
                                        {editMode ? <input type="email" id="email" className="form-control" name="useremail"
                                            value={formData.email} required autoComplete="off" readOnly
                                        />
                                            : <input type="email" id="email" className="form-control" name="useremail" placeholder="Enter the email id"
                                                value={formData.email || ""} required autoComplete="off"
                                                onChange={(e) => handleInputChange(e)} />}
                                        <div className="color">{errors["useremail"]}</div>

                                    </div>
                                </div>


                                <div className="form-footer ml-2 pl-1">
                                    <button type="submit" className="btn btn-secondary" disabled={buttonDisable}>Submit
                                        {(loading || editloading) ? (
                                            <i className="fa fa-spinner fa-spin" height='15' width='15'></i>

                                        ) : (
                                            ''
                                        )}

                                    </button>
                                    {
                                        editMode ? (
                                            <button type="button" className="btn btn-primary cancel-button" data-dismiss="modal" onClick={Close}>Cancel</button>
                                        ) : (
                                            <button type="button" className="btn btn-primary cancel-button" data-dismiss="modal" onClick={handleClose} >Cancel</button>
                                        )
                                    }
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default ManageUser;