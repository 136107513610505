import{
    EMAIL_SUCCESS,
    EMAIL_BEGIN,
    EMAIL_FAILED,
    ADD_SUCCESS,
    ADD_BEGIN,
    ADD_FAILED,
    CONFIG_ADD_SUCCESS,
    CONFIG_ADD_BEGIN,
    CONFIG_ADD_FAILED,
    CONFIG_SUCCESS,
    CONFIG_BEGIN,
    CONFIG_FAILED,
    STATUS_EDIT_SUCCESS,
    STATUS_EDIT_BEGIN,
    STATUS_EDIT_FAILED,
    CONFIRM_BEGIN,
    CONFIRM_SUCCESS,
    CONFIRM_FAILED,
    DELETE_BEGIN,
    DELETE_SUCCESS,
    DELETE_FAILED,
    TEMPLATE_BEGIN,
    TEMPLATE_SUCCESS,
    TEMPLATE_FAILED

} from '../actions/emailconfig'

const initialState={
    emails:null,
    loading:false,
    error:null,
    added:null,
    addloading:false,
    addderror:false,
    configadded:null,
    configaddloading:false,
    configerror:null,
    configlist:null,
    listloading:false,
    listerror:null,
    statuschange:null,
    editloading:false,
    editerror:null,
    confirm:null,
    confirmloading:false,
    confirmerr:null,
    deletedata:null,
    deleteloading:false,
    templatedata:null,
    templateloading:false,
}

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
      case EMAIL_BEGIN:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case EMAIL_SUCCESS:
        return {
          ...state,
          loading: false,
          emails: payload,
          error: null,
        };
      case EMAIL_FAILED:
        return {
          ...state,
          loading:false,
          error: payload,
        };
        case ADD_BEGIN:
          return {
            ...state,
            addloading: true,
            adderror: false,
            added:null
          };
        case ADD_SUCCESS:
          return {
            ...state,
            addloading: false,
            added:payload,
            adderror: false,
          };
        case ADD_FAILED:
          return {
            ...state,
            addloading:false,
            adderror: true,
            
          };
          case CONFIG_ADD_BEGIN:
            return {
              ...state,
              configaddloading: true,
              configerror: false,
              configadded:null
            };
          case CONFIG_ADD_SUCCESS:
            return {
              ...state,
              configaddloading: false,
              configadded:payload,
              configerror: false,
            };
          case CONFIG_ADD_FAILED:
            return {
              ...state,
              configaddloading:false,
              configerror: true,
              
            };
            case CONFIG_BEGIN:
              return {
                ...state,
                listloading: true,
                listerror: false,
               
              };
            case CONFIG_SUCCESS:
              return {
                ...state,
                listloading: false,
                configlist:payload,
                listerror: false,
              };
            case CONFIG_FAILED:
              return {
                ...state,
                listloading:false,
                listerror: true,
                
              };
              case STATUS_EDIT_BEGIN:
                return {
                  ...state,
                  editloading: true,
                 
                 
                };
              case STATUS_EDIT_SUCCESS:
                return {
                  ...state,
                  editloading: false,
                  statuschange:payload,
                  
                };
              case STATUS_EDIT_FAILED:
                return {
                  ...state,
                 
                  editerror:payload
                };
                case CONFIRM_BEGIN:
                  return {
                    ...state,
                    confirmloading: true,
                    confirm:null
                   
                  };
                case CONFIRM_SUCCESS:
                  return {
                    ...state,
                    confirmloading: false,
                    confirm:payload,
                    
                  };
                case CONFIRM_FAILED:
                  return {
                    ...state,
                    confirmloading:false,
                    confirmerr:payload
                  };
                  case DELETE_BEGIN:
                    return {
                      ...state,
                      deleteloading: true,
                      deletedata:null
                     
                    };
                  case DELETE_SUCCESS:
                    return {
                      ...state,
                      deleteloading: false,
                      deletedata:payload,
                      
                    };
                  case DELETE_FAILED:
                    return {
                      ...state,
                      deleteloading:false,
                     deletedata:null
                    };

                    case TEMPLATE_BEGIN:
                      return {
                        ...state,
                        templateloading: true,
                        templatedata:null
                       
                      };
                    case TEMPLATE_SUCCESS:
                      return {
                        ...state,
                        templateloading: false,
                        templatedata:payload,
                        
                      };
                    case TEMPLATE_FAILED:
                      return {
                        ...state,
                        templateloading:false,
                        templatedata:null
                      };
        
  
      default:
        return state;
    }
  };

