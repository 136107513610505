import React, { useEffect, useRef } from 'react';
import Tiff from 'tiff.js';

const TiffViewer = ({ tiffUrl }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
   
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    const xhr = new XMLHttpRequest();
    xhr.responseType = 'arraybuffer';

    xhr.onload = () => {
      const tiff = new Tiff({ buffer: xhr.response });
      const canvasData = tiff.toCanvas();
      ctx.putImageData(canvasData, 0, 0);
    };

    xhr.open('GET', tiffUrl, true);
    xhr.send();
  }, [tiffUrl]);
  console.log('tiffUrl',tiffUrl)
  return  (
    <div>Hello  check</div>
  );
};

export default TiffViewer;
