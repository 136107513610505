import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PieChart from './PieChart';
import searchImage from '../../images/dashboard-leftimg.png';
import targetImage from '../../images/target.svg';
import clockImage from '../../images/clock.png';
import { getScannedResultsList, searchDocument, getscancounts, dashboardData } from '../../actions/dashboard';
//import { fetchoptions } from '../../actions/DocumentSearchAction'
// import { connect } from "react-redux";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import _ from "lodash";
import Pagination from "react-pagination-js";
import "react-pagination-js/dist/styles.css";
import moment from 'moment';
import axios from "axios";
import '../../assets/css/navigation.css'
import { documentnameoptions, templatenameoptions, industrytypeoptions } from '../../actions/DocumentSearchAction';


import Spinner from '../layout/Spinner'
export const SuperAdminDashboard = ({
  scannedResultsList,
  scannedResultsListLoading,
  scannedResultsListError,
  getScannedResultsList,
  searchDocument,
  getscancounts,
  scancountsData,
  scancountsError,
  scancountsLoading,
  dashboardData,
  noOfRecognition,
  netConfidencelevel,
  total,
  searchFlagR,
  options2, options3, options5,
  options1,
  documentnameoptions,
  templatenameoptions, industrytypeoptions
}) => {
  const [radio, setRadio] = useState();
  const [term, setTerm] = useState('');
  const userId = JSON.parse(localStorage.getItem('token')).id;
  const [params, setParams] = useState({
    documentname: "", templatename: "",
    templatetype: "", industrytypeid: "", pagenumber: 1, documentid: "", templateid: "",
    currentPage: "1",
    enddate: null, startdate: null, userid: "", templatetype: "",
    country: "", value: ""
  });
  const templateType = [
    {
      label: "All types",
      name: "templatetype",
      value: ""
    },
  
    {
      label: "Own",
      name: "templatetype",
      value: false,
      setValue: "own"
    },

 {
      label: "Standard",
      name: "templatetype",
      value: true,
      setValue: "standard"

    },
   
  ]
  const [currentPage, setCurrentpage] = useState(1)
  const [startdate, setstartDate] = useState(null);
  const [enddate, setEndDate] = useState(null);
  const [selectedOptionForActivityLogIndustryType, setSelectedOptionForActivityLogIndustryType] = useState("");
  const [selectedOptionForActivityLogTemplateName, setSelectedOptionForActivityLogTemplateName] = useState("");
  const [selectedOptionForActivityLogDocumentName, setselectedOptionForActivityLogDocumentName] = useState("");
  const [templatetype, setTemplatetype] = useState("");
  const [stdTemp, setStdTemp] = useState("");
  const [ownTemp, setOwnTemp] = useState("");
  const [avgTimeSet, setAvgTimeSet] = useState("");
  const [avgTime, setAvgTime] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    dashboardData()
    getScannedResultsList(userId);
  }, []);

  useEffect(() => {
    var total = 0;
    var count = 0;
    if (avgTimeSet) {
      avgTimeSet.map((item) => {
        if (item.timetaken != null) {
          const arr = (item.timetaken).split(":");
          count++;
          total = total + Number(moment(item.timetaken).format("ss")) + (arr[1] * 60);
        }
      })
      setAvgTime(Math.round(total / count));
    }
  }, [avgTimeSet]);

  useEffect(() => {
    // dispatch(fetchoptions({ id: userId }));
    getscancounts(userId, params);
    documentnameoptions();
    templatenameoptions();
    industrytypeoptions();
    axios.get('dashboard/tempcount')
      .then(function (response) {
        setStdTemp(response.data.stdCount);
        setOwnTemp(response.data.tenantCount);
        setAvgTimeSet(response.data.getScanCount);
      })
  }, []);

  // useEffect(() => {
  //   // dispatch(fetchoptions({ id: userId }));
  //   getscancounts(userId, params);

  // }, []);
  //activity log search
  const handleChange = (e) => {

    if (e.name == "industrytypeid") {

      setSelectedOptionForActivityLogIndustryType(e.value)
    } if (e.name == "templateid") {
      setSelectedOptionForActivityLogTemplateName(e.value)
    } if (e.name == "documentid") {
      setselectedOptionForActivityLogDocumentName(e.value)
    } if (e.name == "templatetype") {


      setTemplatetype(e.setValue)
    }

  }



  const filterList = () => {
   
    setCurrentpage(1)
    let obj = {
    
      startdate: startdate === null ? startdate : moment(startdate).format('YYYY-MM-DD HH:mm:ss'),
      enddate: enddate === null ? enddate : moment(enddate).format('YYYY-MM-DD HH:mm:ss'),
      templateid: selectedOptionForActivityLogTemplateName,
      industrytypeid: selectedOptionForActivityLogIndustryType,
     // currentPage: currentPage,
      currentPage: 1,
      documentid: selectedOptionForActivityLogDocumentName,
      userid: "",
      templatetype: templatetype,
      country: ""
    }

    getscancounts(userId, obj)



  }
  const getDate = (datetime) => {
    let date = new Date(datetime)
    let day = date.getDate()
    let hours = date.getHours()
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    let minutes = date.getMinutes()
    minutes = minutes < 10 ? '0' + minutes : minutes;
    let v = date.toLocaleString('default', { month: 'short' })
    let currentdate = `${v} ${day}  ${hours}:${minutes} ${ampm}`

    return currentdate

  }
  const customFilter = (option, inputValue) => {
    const reg = new RegExp(`^${inputValue}`, "i");
    return reg.test(option.label);
  };
  const changeCurrentPage = async (number) => {
    setCurrentpage(number);
    let obj = {
      startdate: startdate === null ? startdate : moment(startdate).format('YYYY-MM-DD HH:mm:ss'),
      enddate: enddate === null ? enddate : moment(enddate).format('YYYY-MM-DD HH:mm:ss'),
      templateid: selectedOptionForActivityLogTemplateName,
      industrytypeid: selectedOptionForActivityLogIndustryType,
      currentPage: number,
      documentid: selectedOptionForActivityLogDocumentName,
      userid: "",
      templatetype: templatetype,
      country: ""
    };
    await getscancounts(userId, obj);
  };


 

  const stylesdocumentname = {

    menu: base => ({
      ...base,
      width:`${(8*Math.max(...options1&&options1.map(itm=>itm.label[0].length)))+30}px`,
   
    }),
    menuList: (base) => ({
      ...base,
     padding: 0,
     background: "#fff",
   
   "::-webkit-scrollbar": {
       width: "9px"
   
      },

     "::-webkit-scrollbar-track": {
       background: "#fff"
     },
     "::-webkit-scrollbar-thumb": {
       background: "#888"
     },
     "::-webkit-scrollbar-thumb:hover": {
       background: "#555"
     }
  })}

  const stylestemplate = {

    menu: base => ({
      ...base,
      width:`${(8*Math.max(...options2&&options2.map(itm=>itm.label[0].length)))+30}px`,
 
    }),
    menuList: (base) => ({
      ...base,
     padding: 0,
     background: "#fff",
  
   "::-webkit-scrollbar": {
       width: "9px"
   
      },

     "::-webkit-scrollbar-track": {
       background: "#fff"
     },
     "::-webkit-scrollbar-thumb": {
       background: "#888"
     },
     "::-webkit-scrollbar-thumb:hover": {
       background: "#555"
     }
  })}

  const stylesindustry = {

    menu: base => ({
      ...base,
      width:`${(8*Math.max(...options3&&options3.map(itm=>itm.label[0].length)))+30}px`,
  
    }),
    menuList: (base) => ({
      ...base,
     padding: 0,
     background: "#fff",
   
   "::-webkit-scrollbar": {
       width: "9px"
   
      },

     "::-webkit-scrollbar-track": {
       background: "#fff"
     },
     "::-webkit-scrollbar-thumb": {
       background: "#888"
     },
     "::-webkit-scrollbar-thumb:hover": {
       background: "#555"
     }
  })}

  
 

 const stylestypes = {
    menu: base => ({
      ...base,
      width:`${(8*Math.max(...templatetype&&templatetype.map(itm=>itm.label[0].length)))+30}px`,
   
    }),
    menuList: (base) => ({
      ...base,
     padding: 0,
     background: "#fff",
   
   "::-webkit-scrollbar": {
       width: "9px"
   
      },

     "::-webkit-scrollbar-track": {
       background: "#fff"
     },
     "::-webkit-scrollbar-thumb": {
       background: "#888"
     },
     "::-webkit-scrollbar-thumb:hover": {
       background: "#555"
     }
  })}


  return (
    <>
      <div class='app-content container1'>
        <h1 class='page-header mb-3'>
          Super Admin Dashboard <br />
        </h1>
        <div className="row">
          <div className="col-md-4">
            <div className="dashmetricsdiv">
              <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                <tbody>
                  <tr>
                    <td width="20%">
                      <div className="metricsicon">
                        <i className="fas fa-chart-bar"></i>
                      </div>
                    </td>
                    <td>
                      <div className="dashmetricvalue">
                        {stdTemp == "" ?
                          <span
                            className='spinner-grow'
                            role='status'
                            style={{ color: "#aaa", border: "8px solid #bbb" }}
                          ></span>
                          :
                          <h2>{stdTemp}</h2>}
                        <p>Industry Standard Template</p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>  
        </div>
        <div className="Sspace"></div>
        <div class='row'>
        </div>
        <div class='Mspace'></div>

        <div className="Sspace"></div>
      </div>
    </>
  );
};

SuperAdminDashboard.propTypes = {
  scannedResultsList: PropTypes.object,
  scannedResultsListLoading: PropTypes.bool,
  scannedResultsListError: PropTypes.object,

  scancountsData: PropTypes.object,
  scancountsError: PropTypes.object,
  scancountsLoading: PropTypes.bool,

  noOfRecognition: PropTypes.string,
  netConfidencelevel: PropTypes.object,

  options1: PropTypes.object,
  options2: PropTypes.object,
  options3: PropTypes.object,
  total: PropTypes.string,
  searchFlagR: PropTypes.string
};

const mapStateToProps = (state) => ({
  scannedResultsList: state.dashboard.scannedResultsList,
  scannedResultsListLoading: state.dashboard.scannedResultsListLoading,
  scannedResultsListError: state.dashboard.scannedResultsListError,

  scancountsData: state.dashboard.scancountsData,
  scancountsError: state.dashboard.scancountsError,
  scancountsLoading: state.dashboard.scancountsLoading,

  noOfRecognition: state.dashboard.noOfRecognition,
  netConfidencelevel: state.dashboard.netConfidencelevel,




  total: state.dashboard.total,
  options1: state.DocumentSearchReducer.options1,
  options2: state.DocumentSearchReducer.options2,
  options3: state.DocumentSearchReducer.options3,
  searchFlagR: state.dashboard.searchFlagR,
});

export default connect(mapStateToProps, {
  getScannedResultsList,
  searchDocument,
  getscancounts,
  dashboardData,
  documentnameoptions,
  templatenameoptions,
  industrytypeoptions
})(SuperAdminDashboard);
