import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  demoDocumentScan,
  loadTemplate,
  pdfBlobInsert,
} from '../../actions/scan';
import { isObjEmpty } from '../../utils/functions';
import Select from 'react-select';
import Event from '../Tracking';
import axios from 'axios';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { ToastContainer, toast } from 'react-toastify';
import { connect } from 'react-redux';
import { Fragment } from 'react';

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const Intelligent = (props) => {
  const [state, setState] = useState({
    ownTemplates: [],
    isIndustrySelected: false,
    industry: null,
    currentIndustryTemplates: null,
    template: null,
    prevImgSource: null,
    uploadedFile: [],
    industryTemplateName: null,
    templateName: null,
    templatename: null,
    templateid: null,
    templatetype: null,
    height: null,
    width: null,
    typeofFile: null,
    imgSasUrl: null,
    pdfImageB64: null,
    imgLoaded: false,
    imgUploadLoaded: true,
    multipageData: [],
    selectedTemp: false,
    loading: false,
  });

  const history = useHistory();
  const location = useLocation();
  const selectedTemplate = location.imageurl;
  const annotationJ = location.annotationJson;
  const { template, prevImgSource } = useSelector(
    () => ({
      template: selectedTemplate,
      prevImgSource: selectedTemplate,
    }),
    shallowEqual
  );
  useEffect(() => {
  }, [state.uploadedFile]);

  /*----------------------------closing function for file button---------------------------*/
  // const closeFile=()=>{
  //   setState({
  //     uploadedFile:null,
  //     prevImgSource:null
  //   })
  // }
  /*-----------------------------------*/
  const handleFileChoice = async (e) => {
    const { uploadedFile } = state;
    if (e.target.files[0] != null) {
      setState({
        ...state,
        imgUploadLoaded: false,
      });
      const file = URL.createObjectURL(e.target.files[0]);
      const type = e.target.files[0].type;
      const filename = e.target.files[0].name;
      const fileuploaded = e.target.files[0];
      if (type == 'application/pdf') {
        const uploadedFileBase64 = await toBase64(e.target.files[0]);
        const data = {
          Pdfbase64: uploadedFileBase64,
          fileType: type,
          fileName: filename,
        };
        try {
          uploadedFile.push(fileuploaded);
          axios
            .post('/recognition/pdfBlobSave', data)
            .then(function (response) {
              if (response.status == 200) {
                console.log('res from axios pdfBlobSave ', response.data);

                setState({
                  ...state,
                  imgSasUrl: response.data.imgSasUrl,
                  pdfImageB64: response.data.base64,
                  imgLoaded: true,
                  typeofFile: type,

                  imgUploadLoaded: true,
                  prevImgSource: response.data[0].imgSasUrl,
                  multipageData: response.data,
                });
                // uploadedFile.push(fileuploaded);
              }
            })
            .catch(function (error) {
              console.log('Error in axios', error);
            });
        } catch (err) {
          console.error(err);
        }
      } else {
        setState({
          ...state,
          prevImgSource: file,
          imgUploadLoaded: true,
        });
        uploadedFile.push(fileuploaded);
      }
    }
  };

  const handleScan = async (e) => {
    e.preventDefault();
    const template = [];
    const templateData_ = [];
    const { uploadedFile, typeofFile, multipageData } = state;
    const uploadedFileType = window.location.href.split('/')[4];
    const len = uploadedFile.length
    for (let i = 0; i < len; ++i) {
      let annotationJson =
        uploadedFileType === 'cheque'
          ? {
            _via_settings: {
              ui: {
                annotation_editor_height: 25,
                annotation_editor_fontsize: 0.8,
                leftsidebar_width: 18,
                image_grid: {
                  img_height: 80,
                  rshape_fill: 'none',
                  rshape_fill_opacity: 0.3,
                  rshape_stroke: 'yellow',
                  rshape_stroke_width: 2,
                  show_region_shape: true,
                  show_image_policy: 'all',
                },
                image: {
                  region_label: '__via_region_id__',
                  region_color: '__via_default_region_color__',
                  region_label_font: '10px Sans',
                  on_image_annotation_editor_placement: 'NEAR_REGION',
                },
              },
              core: { buffer_size: 18, filepath: {}, default_filepath: '' },
              project: { name: 'OCR_project_17Jun2021_11h20m' },
            },
            _via_img_metadata: {
              '201120070219100177800000 Rollover-15.jpg242606': {
                filename: '201120070219100177800000 Rollover-15.jpg',
                size: 242606,
                regions: [
                  {
                    shape_attributes: {
                      name: 'rect',
                      x: 39,
                      y: 39,
                      width: 2255,
                      height: 1578,
                    },
                    region_attributes: { Name: 'Bank Cheque' },
                  },
                ],
                file_attributes: {},
              },
            },
            _via_attributes: {
              region: {
                Name: { type: 'text', description: '', default_value: '' },
              },
              file: {},
            },
          }
          : {
            _via_settings: {
              ui: {
                annotation_editor_height: 25,
                annotation_editor_fontsize: 0.8,
                leftsidebar_width: 18,
                image_grid: {
                  img_height: 80,
                  rshape_fill: 'none',
                  rshape_fill_opacity: 0.3,
                  rshape_stroke: 'yellow',
                  rshape_stroke_width: 2,
                  show_region_shape: true,
                  show_image_policy: 'all',
                },
                image: {
                  region_label: '__via_region_id__',
                  region_color: '__via_default_region_color__',
                  region_label_font: '10px Sans',
                  on_image_annotation_editor_placement: 'NEAR_REGION',
                },
              },
              core: { buffer_size: 18, filepath: {}, default_filepath: '' },
              project: { name: 'OCR_project_17Jun2021_11h16m' },
            },
            _via_img_metadata: {
              '201120070219100177800000 Rollover-11.jpg253130': {
                filename: '201120070219100177800000 Rollover-11.jpg',
                size: 253130,
                regions: [
                  {
                    shape_attributes: {
                      name: 'rect',
                      x: 35,
                      y: 42,
                      width: 1592,
                      height: 2134,
                    },
                    region_attributes: { Name: 'Driving Licence' },
                  },
                ],
                file_attributes: {},
              },
            },
            _via_attributes: {
              region: {
                Name: { type: 'text', description: '', default_value: '' },
              },
              file: {},
            },
          };
      let data_ = {
        typeoffile: typeofFile,
        // uploadedfile: item.name,
        uploadedfile: uploadedFile[i].name,
      };
      if (typeofFile == 'application/pdf' && multipageData != null) {
        try {
          const bs64Arr = [];
          // let userFileName = item.name;
          let userFileName = uploadedFile[i].name;
          let pdfImgB64 = '';
          let pdfImageArr = [];

          multipageData.map((item) => {
            pdfImgB64 = item.base64;
            const b64 = pdfImgB64.split(',')[1];
            bs64Arr.push(b64);
            pdfImageArr.push(pdfImgB64);
          });
          props.demoDocumentScan(bs64Arr, annotationJson, uploadedFileType, userFileName);

          history.push({ pathname: '/intelligent-recognition' });
        } catch (err) {
          console.error(err);
        }
      } else {
        try {
          const pageCount = 1;
          const bs64Arr = [];
          let ImageArr = [];
          let b64Image = await toBase64(uploadedFile[i]);
          let userFileName = uploadedFile[i].name;
          const b64 = b64Image.split(',')[1];
          bs64Arr.push(b64);
          ImageArr.push(b64Image);
          props.demoDocumentScan(bs64Arr, annotationJson, uploadedFileType, userFileName);
          if (state.isIndustrySelected) {
            let data = {
              templateData: ImageArr,
              imgData: {
                y_scale: 700 / state.height,
                x_scale: 700 / state.width,
              },
              template,
              isIndustry: state.isIndustrySelected,
              userFileName,
              pageCount,
              formtype:uploadedFileType
            };
            props.loadTemplate(data);
          } else {
            let data = {
              templateData: ImageArr,
              isIndustry: state.isIndustrySelected,
              imgData: {
                y_scale: 700 / state.height,
                x_scale: 700 / state.width,
              },
              userFileName,
              pageCount,
              formtype:uploadedFileType
            };
            props.loadTemplate(data);
          }
          history.push({ pathname: '/intelligent-recognition' });
        } catch (err) {
          console.error(err);
        }
      }
      //  });
    }
  };

  const onImgLoad = ({ target: img }) => {
    setState({
      ...state,
      height: img.naturalWidth,
      width: img.naturalHeight,
    });
  };

  return (
    <div className='app-content container1'>
      {/* <h1 className='page-header mb-3'>
        Upload document <br />
        <small>
          Upload your files containing data to be extracted and we will do the
          rest.
        </small>
      </h1> */}
      <div className="row">
        <div className="col-xl-6">
          <div className="row">
            <div className="col-xl-9">
              <h1 className="page-header mb-3">
                Upload document<br /><small>Upload your files containing data to be extracted and we will do the
                  rest. </small></h1>
            </div>
            <div className="col-xl-3 start_reg">
              {state.uploadedFile.length !== 0
                ? (
                  (
                    <div className="addtemplatediv">

                      <a href="#"
                        className="btn btn-yellow btn-rounded btn-sm ps-5 pe-5 pt-2 pb-2 fs-14px font-weight-600"
                        onClick={(e) => handleScan(e)}
                      > Start
                        Recognition</a>

                    </div>
                  ))
                : (
                  (<Fragment></Fragment>))}
            </div>
          </div>
        </div>
        <div className="col-xl-6">
        </div>
      </div>
      <div className='row'>
        <div className='col-xl-6'>
          <div className='dragdropmaindiv'>
            <form>
              <input
                type='file'
                className='NuOcrfile'
                onChange={handleFileChoice}
              />

              <p>Drag your files here or click in this area.</p>
            </form>
          </div>
          {state.uploadedFile.length !== 0 ? (
            <>
              <div className='fileslistaindiv'>
                <h2 className='page-header mb-4'>Uploaded Files </h2>
              </div>
              <div className='documentlist'>
                {state.uploadedFile &&
                  state.uploadedFile.map((item) => (
                    <ul>
                      <li className='selectfile'>
                        {item.name}
                        <span /* onClick={()=>closeFile()}*/>
                          <i className='fas fa-times'></i>
                        </span>
                      </li>
                      {/* <li>Document 1 <span><i className="fas fa-times"></i></span></li>
                                    <li>Document 1 <span><i className="fas fa-times"></i></span></li> */}
                    </ul>
                  ))}
              </div>
            </>
          ) : null}
        </div>
        <div className="col-xl-6">
          <div className="scandoumentdiv">
            <img
              className="scandoumentdiv"
              src={state.prevImgSource}
              onLoad={onImgLoad}
              href="#"
              // alt="Loading..."
            />
          </div>
        </div>
      </div>
      <div className='Sspace'></div>

      {/* {state.uploadedFile && state.uploadedFile.length > 0 && (
        <div className='addtemplatediv'>
          <a
            href='#'
            className='btn btn-yellow btn-rounded btn-sm ps-5 pe-5 pt-2 pb-2 fs-14px font-weight-600'
            onClick={(e) => handleScan(e)}
          >
            {' '}
            Start Recognition
          </a>
        </div>
      )} */}
    </div>
  );
};
const mapStateToProps = (state) => ({
  ownTemplateDetails: state.template.ownTemplates,
  industryTemplates: state.template.industryTemplates,
  convertedPdfData: state.scan.convertedPdfData,
});

export default connect(mapStateToProps, {
  demoDocumentScan,
  loadTemplate,
  pdfBlobInsert,
})(Intelligent);



