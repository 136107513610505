import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import NavBar from '../layout/navigation'
import {userstatus} from '../../actions/adduser'
import { useDispatch } from "react-redux";
import  { useEffect } from 'react';
import {logout} from '../../actions/auth'

import { useHistory } from 'react-router-dom'
const PrivateRoute = ({
  component: Component,
  auth: { isAuthenticated,status},
  logout,
 
  
  ...rest
}) => {
  const  dispatch = useDispatch();
  const history = useHistory() 
  useEffect (() => {
    // fetchstatus()  /* commented fetch status for nuocr-api dashboard */
  history.listen(() => { 
    // fetchstatus()   /* commented fetch status for nuocr-api dashboard */
  }) 
    
   
}, [status,history])
/* commented fetch status for nuocr-api dashboard */
async function fetchstatus(){
  await dispatch(userstatus())
}

return(
  
  <>

    <NavBar/>
    <Route
      {...rest}
      render={(props) => {
        return <Component {...props} />;
        // if(status&&status!==null){
        //   logout()
        //  return <Redirect to='/login' />;
        // } else {
        //   return <Component {...props} />;
        // }
        
       
      //   if (isAuthenticated === true&&(status&&status==="active")) {
      //    return <Component {...props} />;
      
      //  }else{
          
         
        
        // }
      }}
    ></Route>
  </>
)};

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired,

  
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  
});

export default connect(mapStateToProps,{userstatus,logout})(PrivateRoute);
