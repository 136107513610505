import React, { useState, useEffect, Fragment, useRef } from "react";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CopyJSONModal from "./CopyJSONModal";

const DataDisplay = ({ DataToDisplay, displayBox, imageAtDataDisplay, docType }) => {
  // console.log('DataToDisplay: ', DataToDisplay, 'displayBox: ', displayBox)
  useEffect(() => {
    displayBox();
  }, []);

 
  return (
    <div
      style={{
        display: "Flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <div
        className="anotateinputdiv"
        onClickCapture={(event) => displayBox(DataToDisplay)}
      >
        <Card sx={{ minWidth: 275 }}>
          <CardContent>
            <Typography
              sx={{
                fontSize: 14,
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                alignSelf: "flex-start",
              }}
              color="text.secondary"
            // gutterBottom
            >
              {DataToDisplay?.key}
            </Typography>
            {!imageAtDataDisplay && <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <Typography variant="h6" component="div" sx={{ width: "60%" }}>
                <span style={{ padding: '5px 10px' }}>{DataToDisplay?.value || ""}</span>
              </Typography>
              <CopyJSONModal itemClicked={DataToDisplay} />
            </div>}
            {docType && docType === "GATI Document" && DataToDisplay &&(DataToDisplay.hasOwnProperty("confidence")) && <> <Typography
              sx={{
                fontSize: 14,
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                alignSelf: "flex-start",
              }}
              color="text.secondary"
            // gutterBottom
            >
              Confidence
            </Typography>

              {!imageAtDataDisplay && <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                }}
              >
                <Typography variant="h6" component="div" sx={{ width: "60%" }}>
                  <span style={{ padding: '5px 10px' }}>{DataToDisplay && DataToDisplay.confidence ? DataToDisplay.confidence * 100 + ' %' : ""}</span>
                </Typography>

              </div>}

            </>}

            {imageAtDataDisplay && <div>
              <img
                src={`data:image/png;base64, ${DataToDisplay.base64}`}
                style={{ width: "100%" }}
              />
            </div>}
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default DataDisplay;
