import axios from 'axios';
import {generatePassword} from '../utils/functions'

export const TOKEN_RESOLVED = "TOKEN_RESOLVED";
export const SUBSCRIPTION_ACTIVATED = "SUBSCRIPTION_ACTIVATED"
export const SUBSCRIPTION_ERROR = "SUBSCRIPTION_ERROR"

export const resolveToken = (token) => async dispatch => {
    try {
     
        let body = {marketPlaceToken:token}
        let res = await axios.post('/marketplace/resolveToken',body);
        dispatch({
            type: TOKEN_RESOLVED,
            payload: res.data
        })
    } catch (err) {
        console.error(err);
    }
}

export const activateSubscription = (data,email) => async dispatch => {
    try {
       
        let body = {
          planDetails: {
            planId: data.SubscriptionDetail.planId,
            quantity: "",
          },
          tenantId:data.SubscriptionDetail.subscription.beneficiary.tenantId,
          subscriptionId: data.SubscriptionDetail.id,
          email:email,
          password: generatePassword()
        };
        let res = await axios.post('/marketplace/activateSubscription', body);
        console.log("res.data@35",res.data);
        if(res.data ==="Mail sent successfully")
        {
            dispatch({
                type: SUBSCRIPTION_ACTIVATED,
                payload: res.data
            })
        }
        else{
            dispatch({
                type: SUBSCRIPTION_ERROR,
                payload: res.data
            })
        }

    } catch (err) {
        console.error(err);
    }
}