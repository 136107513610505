import React, {useState} from 'react'
import './botstyles.css'
import {styles} from './styles'

const SittingBot = ({style, onClick}) => {
  const [hovered, setHovered] = useState(false)

  return (
    <div style={style}>
        <div
          style={{
            ...styles.avatarHello,
            ...{opacity: hovered ? '1' : '0'}
          }}
        >Can I Help You !!!</div>

        <div
          className='transition-1'
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          onClick={() => onClick && onClick()}
          style={{
            ...styles.chatWithMeButton,
            ...{border: hovered ? '4px solid #f9f0ff' : '4px solid #a8a8a8'}
          }}
        ></div>
    </div>
  )
}

export default SittingBot