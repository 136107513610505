// Chatbot.js

import React,{useState} from 'react';
import ChatBotClientComponent from './ChatBotClientComponent';
const axios = require('axios');
const IPFSChatBot = () => {
  const [messages, setMessages] = useState([]);
  // const [chatloading, setChatLoading] = useState(false);


  // Function to handle sending messages
  const getResponse = (msg,message) => {
    // Simulate bot response from API after 3 seconds
    // setTimeout(() => {
    //   // Replace the empty bot message with the actual response received from the API
      // const updatedMessages = msg.map((message) => {
      //   if (message.sender === "bot" && message.text === "") {
      //     // Replace empty bot message with the actual response
      //     return { ...message, text: "Bot response from API" };
      //   } else {
      //     return message;
      //   }
      // });
  
    //   // Update the messages state with the updated messages
    //   setMessages(updatedMessages);
    // }, 3000);
    let data = JSON.stringify({
      "query": message
    });
    let url = `${process.env.REACT_APP_DOCKETRY_DEV_BASE_URL}/hdfcergo`
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: url,
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    
    axios.request(config)
    .then((response) => {
      const updatedMessages = msg.map((message) => {
        if (message.sender === "bot" && message.text === "") {
          // Replace empty bot message with the actual response
          return { ...message, text: response?.data?.bot };
        } else {
          return message;
        }
      });
      setMessages(updatedMessages);
      console.log(JSON.stringify(response.data));
    })
    .catch((error) => {
      console.log(error);
    });
    
  };
  const callResponse=(msg,message)=>{
    const msgs=[...msg, { text: "", sender: 'bot' }]

    // setInterval(() => {
      // Simulate bot response after 3 seconds
      setMessages(msgs);
      getResponse(msgs,message)
    // }, 3000);

    // return () => clearInterval(interval);
    // console.log("@@@@@@@@@",msg)
  }
  const sendMessage = (message,from) => {
    // Implement sending logic
    const msg=[...messages, { text: message, sender: 'user' }]
    setMessages(msg);
    // setChatLoading(true)
    callResponse(msg,message)
  };
  return (
    
    // <div >
    // <iframe src="https://www.cyanarrow.com/supportagent/854a104e-f2da-468c-8323-c70f0ebe2e5d?i=1" width="100%" height="650px"></iframe>
    // </div>
    <div className="main-page">
      <div className="content-left">
        {/* Add headings, subheadings, descriptions */}
        <h1 style={{color:"#007bff"}}>Digital Bot</h1>
        <h2 style={{color:"#20c997"}}>Cognizant reaction for all your queries</h2>
        {/* <p>Description Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
        {/* <img src="./chatboat.webp" alt="Bot Image" style={{ maxWidth: "80%", height: "auto" }} /> */}
      </div>
      {/* Add Chat component */}
      <ChatBotClientComponent messages={messages} onSendMessage={sendMessage}/>
    </div>
  );
};

export default IPFSChatBot;
