import React, { useEffect, useState } from 'react';
import { pass, visibility, close } from "../../constants/Constants"
import { Redirect, Link } from 'react-router-dom';
import Event from '../Tracking';
import { connect,useDispatch } from 'react-redux';
import { login } from '../../actions/auth';
import './loginStyle.css';
import loginImage from '../../images/login_bg.png';




const Login = ({ auth: { isAuthenticated, loading, error, status, role }, login }) => {

 
  const [formData, setFormData] = useState({ username: '', password: '' });
  const [formError, setFormError] = useState({ username: '', password: '' });

  const [showpass, setPass] = useState(true)
  const [buttonDisable, setButtondisable] = useState(false);
  const Validation = () => {
    let isValid = true
    let error = {}
    let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    if (formData.username === "") {
      error.username = "The username field cannot be empty"
      isValid = false
    }
    // else if (!pattern.test(formData.username)) {
    //   error.username = "Invalid email!!"
    //   isValid = false
    // }

    if (formData.password === "") {
      error.password = "The password field cannot be empty"
      isValid = false
    }
    // if(formData.username !== "admindemo"){
    //   isValid = false;
    //   error.username = "Incorrect Credentials"
    // } 
    // if(formData.password !== "OCRA@2022"){
    //   isValid = false;
    //   error.password = "Incorrect Password"
    // }
    setFormError(error)
    return isValid
  }

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value }, setButtondisable(false));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isvalidfun = Validation();
    if (isvalidfun) {
      // const loginCredentials = {
      //   userName: formData.username,
      //   password: formData.password,
      // };
      const loginCredentials = {
        "username": formData.username,
        "password": formData.password,
      };
        login(loginCredentials);
      
      
    }
  };


  if (isAuthenticated === true && status === "active") {
    if (role === 'SuperAdmin') {
      return <Redirect to='/superadmin-dashboard' />
    } else {
      return <Redirect to='/' />;
    }
  }

  const togglePassword = () => {
    var x = document.getElementById("password");
    var img = document.getElementsByClassName("pass-img")[0];
    if (x.type === "password") {
      x.type = "text";
      setPass(!showpass);
    } else {
      x.type = "password";
      setPass(!showpass)
    }
  };

  return (

    <>
      <div className="password-pages" class="container">
        <div class="row">
          <div class="col-2"  >
            <br /><br /><br /><br />
            <img src={loginImage} alt="" width="600 px" height="400 px" />
          </div>
          <div class="col" >
            {/* login modal..starts.. */}
            <div className="form-div"  >
              <form className="signin-form needs-validation" id="main-form" noValidate onSubmit={(e) => handleSubmit(e)}>
                <h2>Login</h2>
                <div className="form-group" id="styleFormGroup">
                  <label htmlFor="inputemail">User Name</label>
                  <input type="text" className="form-control emailone" id="username"
                    onChange={(e) => handleChange(e)} required autoFocus placeholder="User Name"
                    maxLength={50} />
                  <span style={{ color: "red", fontSize: 12 }}>
                    {formError.username}
                  </span>

                </div>
                <div style={{ position: "relative", marginBottom: "0.5rem" }} className="form-group">
                  <label htmlFor="inputpass">Password</label>
                  <input type="password"
                    className="form-control"
                    id="password"
                    placeholder="Password"
                    onChange={(e) => handleChange(e)}
                    required
                    maxLength={20} />
                  <span style={{ color: "red", fontSize: 12 }}>
                    {formError.password}
                  </span>
                  <img onClick={(e) => togglePassword(e)}
                    title={showpass ? "Show Password" : "Hide Password"}
                    src={showpass ? visibility : pass}
                    className="pass-img"
                    alt="pass-img" />

                </div>
                <small id="text-bottom" className="form-text m-0 text-right">
                  <a className="sign-txt text-right"><Link to="/forgotpassword">Forgot Password?</Link></a>
                </small>
                <button type="submit" className="btn submit-button signin-submit-btn btn-primary" onClick={() =>
                  Event('login_Login', 'Login button clicked')} disabled={buttonDisable}>
                  Submit
                  {loading ? (
                    <i className="fa fa-spinner fa-spin" height='15' width='15'></i>

                  ) : (
                    ''
                  )}
                </button>
              </form>
            </div>
            {/* login modal..ends.. */}
          </div>
        </div>
      </div>
    </>)
}
const mapStateToProps = (state) => ({
  auth: state.auth
});

export default connect(mapStateToProps, { login})(Login);
