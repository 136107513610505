import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { documentScan, loadTemplate, pdfBlobInsert } from "../../actions/scan";
import { isObjEmpty } from "../../utils/functions";
import Select from "react-select";
import Event from "../Tracking";
import axios from "axios";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { ToastContainer, toast } from "react-toastify";
import { connect } from "react-redux";
import { Fragment } from "react";
import Spinner from "../layout/Spinner";


const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const UploadFile = (props) => {
  console.log('UploadFile: ', props)
  const [state, setState] = useState({
    ownTemplates: [],
    isIndustrySelected: false,
    industry: null,
    currentIndustryTemplates: null,
    template: null,
    prevImgSource: null,
    uploadedFile: [],
    industryTemplateName: null,
    templateName: null,
    templatename: null,
    templateid: null,
    templatetype: null,
    height: null,
    width: null,
    typeofFile: [],
    imgSasUrl: null,
    pdfImageB64: null,
    imgLoaded: false,
    imgUploadLoaded: true,
    multipageData: [],
    selectedTemp: false,
    multiDoc: [],
    loading: [],
    pdfbs64: null,
    imagebs64: null,
    documentId: props.location.documentId,
    documentName: props.location.templatename,
    fileName: null,
    base64: props.location.imageBase64Url
  });
  
  const [fileName, setFileName] = useState("")
  const history = useHistory();
  const location = useLocation();
  const selectedTemplate = location.imageBase64Url;
  // const annotationJ = location.annotationJson;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [fileData, setFileData] = useState(null)
  const [fileSpec, setFileSpec] = useState({});
  const { template, prevImgSource } = useSelector(
    (state) => ({
      template: selectedTemplate,
      prevImgSource: selectedTemplate,
    }),
    shallowEqual
  );

  useEffect(() => {}, [state.uploadedFile]);
  // useEffect(() => {console.log('fileData: ', fileData)}, [fileData])
  useEffect(() => {console.log('updated state: ', state)}, [state])


  /*----------------------------closing function for file button---------------------------*/
  // const closeFile=()=>{
  //   setState({
  //     uploadedFile:null,
  //     prevImgSource:null
  //   })
  // }
  /*-----------------------------------*/
  const handleFileChoice = async (e) => {
    const { uploadedFile } = state;
    let allTargets = [];
    allTargets.push(e.target.files);
    var files = e.target.files;
    console.log('length: ', e.target.files.length)
    console.log((
      {'type': e.target?.files[0].type, 
      'filename': e.target?.files[0].name, 
      'fileuploaded': e.target?.files[0]}))
    setFileSpec((oldSpec) => {
      return (
        {...oldSpec,
        'type': e.target?.files[0].type, 
        'filename': e.target?.files[0].name, 
        'fileuploaded': e.target?.files[0].fileuploaded})})
    console.log('fileName: ', e.target.files[0].name)
      let fileData = await toBase64(e.target.files[0])
      console.log('fileData: ', fileData)
      setFileData((oldState) => (fileData))
    
    // setFileName(e.target.files[0].name)
    for (let i = 0; i < files.length; i++) {
      if (files[i] != null) {
        setState((oldState) => {return ({
          ...oldState,
          imgUploadLoaded: false,
        })});

        const file = URL.createObjectURL(files[i]);
        const type = files[i].type;
        const filename = files[i].name;
        const fileuploaded = files[i];
        console.log({'type':type, 'filename': filename, 'fileuploaded': fileuploaded})
        setState((oldState) => {
          return ({...oldState, fileName: fileName})})
        if (type === "application/pdf") {
          const uploadedFileBase64 = await toBase64(files[i]);
          const data = {
            Pdfbase64: uploadedFileBase64,
            fileType: type,
            fileName: filename,
          };
          try {
          let temp_uploadedFile = [...state.uploadedFile];
          temp_uploadedFile.push(fileuploaded)
          let temp_typeofFile = [...state.typeofFile];
          temp_typeofFile.push(type)
          let temp_loading = [...state.loading]
          temp_loading.push({
            Loading: false,
          });
          setState((prevState) => {
            return ({
            ...prevState,
            loading: [...prevState.loading, ...temp_loading],
            uploadedFile: [...prevState.uploadedFile, ...temp_uploadedFile],
            typeofFile: [...prevState.typeofFile, ...temp_typeofFile],
          })});
            let j = state.loading.length - 1;
            // axios
            //   .post("/recognition/pdfBlobSave", data)
            //   .then(function (response) {
            //     if (response.status === 200) {
            //       console.log("/recognition/pdfBlobSave return 200: ", response.data)
            //       const resp = response.data;
            //       let tempmultiDoc = [];
            //       tempmultiDoc.push(resp)
            //       // state.multiDoc.push(resp);

            //       setState((oldState) => {
            //         return {
            //         ...oldState,
            //         imgSasUrl: response.data.imgSasUrl,
            //         pdfImageB64: response.data.base64,
            //         imgLoaded: true,
            //         pdfbs64: uploadedFileBase64,
            //         imgUploadLoaded: true,
            //         prevImgSource: response.data[0].imgSasUrl,
            //         multipageData: response.data,
            //         multiDoc: tempmultiDoc,
                    
            //       }});
            //       state.loading[j].Loading = false;
            //       setState(state);
            //     }
            //   })
            //   .catch(function (error) {
            //     console.log("Error in axios", error);
            //   });
          } catch (err) {
            console.error(err);
          }
        } else {
          let temp_loading = [...state.loading]
          temp_loading.push({
            Loading: false,
          });
          let temp_uploadedFile = [...state.uploadedFile];
          temp_uploadedFile.push(fileuploaded)
          let temp_typeofFile = [...state.typeofFile];
          temp_typeofFile.push(type)
          let temp_multiDoc = [...state.multiDoc];
          temp_multiDoc.push("image temp")
          setState((prevState) => {
            console.log('prevState: ', prevState)
            return ({
            ...prevState,
            prevImgSource: file,
            imgUploadLoaded: true,
            loading: [...prevState.loading, ...temp_loading],
            uploadedFile: [...prevState.uploadedFile, ...temp_uploadedFile],
            typeofFile: [...prevState.typeofFile, ...temp_typeofFile],
            multiDoc: [...prevState.multiDoc, ...temp_multiDoc],
          })});
          // uploadedFile.push(fileuploaded);
          // state.typeofFile.push(type);
          // state.multiDoc.push("image temp");
          console.log("uploadedFile", uploadedFile);
          console.log('state: ', state)
        }
      }
    }
  };

  const closeFile = async (index) => {
    console.log('in closeFile: ', index)
    const newF = [...state.uploadedFile];
    if (state.uploadedFile.length !== 1) {
      newF.splice(index, 1);
      setState((existingState) => {
        return {...existingState,
        uploadedFile: newF}
      });
    } else {
      setState({
        ...state,
        uploadedFile: [],
      });
    }
  };

  const handleScan = async (e) => {
    e.preventDefault();
    let template = [];
    let templateData_ = [];
    const {
      uploadedFile,
      industryTemplateName,
      templateName,
      templatename,
      templateid,
      templatetype,
      typeofFile,
      multipageData,
      multiDoc,
    } = state;
    console.log('state inside handleScane: ',state)
    // uploadedFile && uploadedFile.map(async (item) => {
    for (let i = 0; i < uploadedFile.length; i++) {
      const template_ = location.templates;
      const templatename_ = location.templatename;

      const templatetype_ = location.templateType;
      const templateid_ = location.templateid;
      if (multipageData.length === 1 || templatetype_ !== "application/pdf") {
        templateData_ = [];
        template = [];
      }
      let data_ = {
        templatename: templatename_,
        templatetype: templatetype_,
        templateid: templateid_,
        typeoffile: typeofFile[i],
        uploadedfile: uploadedFile[i].name,
      };
      templateData_.push(data_);
      // template = annotationJ;

      if (typeofFile[i] === "application/pdf" && multipageData !== null) {
        console.log('inside handleScan')
        try {
          const bs64Arr = [];
          let userFileName = uploadedFile[i].name;
          let pdfImgB64 = "";
          let pdfImageArr = [];
          for (let j = 0; j < multiDoc.length; j++) {
            if (pdfImageArr.length == 0) {
              multiDoc[i].map((item) => {
                console.log('item: ', item)
                pdfImgB64 = item.base64
                const b64 = pdfImgB64.split(",")[1];
                bs64Arr.push(b64);
                pdfImageArr.push(pdfImgB64);
              });
            }
          }

          // multipageData.map((item) => {
          //   pdfImgB64 = item.base64
          //   const b64 = pdfImgB64.split(",")[1];
          //   bs64Arr.push(b64);
          //   pdfImageArr.push(pdfImgB64);
          // });
          console.log('in handleScan')
          // console.log({'bas64Arr': bs64Arr, 'template': template, 'templateData_': templateData_})
          console.log({base64: state.base64, documentId: state.documentId, documentName: state.documentName, fileName: state.fileName})

          props.documentScan(fileData, state.documentId, state.documentName, fileSpec.fileName)
          // props.documentScan(bs64Arr, template, templateData_);
          let pageCount = pdfImageArr.length;
          if (state.isIndustrySelected) {
            let data = {
              templateData: pdfImageArr,
              industryTemplateName,
              templateName,
              templatename,
              templateid,
              templatetype,
              imgData: {
                y_scale: 900 / state.height,
                x_scale: 1200 / state.width,
              },
              template,
              isIndustry: state.isIndustrySelected,
              userFileName,
              pageCount,
            };
            props.loadTemplate(data);
          } else {
            let data = {
              templateData: pdfImageArr,
              isIndustry: state.isIndustrySelected,
              industryTemplateName: templateName,
              templateName,
              templatename: templatename_,
              templateid: templateid_,
              templatetype: templatetype_,
              imgData: {
                y_scale: 900 / state.height,
                x_scale: 1200 / state.width,
              },
              userFileName,
              pageCount,
            };
            props.loadTemplate(data);
          }
          history.push({
            pathname: "/template-review",
            templatetype: templatetype_,
            templatename: templatename_,
            templateid: templateid_,
            pdfbs64: state.pdfbs64,
          });
        } catch (err) {
          console.error(err);
        }
      } else {
        try {
          const pageCount = 1;
          const bs64Arr = [];
          let ImageArr = [];
          let b64Image = await toBase64(uploadedFile[i]);
          let userFileName = uploadedFile[i].name;
          const b64 = b64Image.split(",")[1];
          bs64Arr.push(b64);
          ImageArr.push(b64Image);
          // props.documentScan(bs64Arr, template, templateData_);
          console.log({base64: fileData, documentId: state.documentId, documentName: state.documentName, fileName: fileSpec.filename})
          props.documentScan(fileData, state.documentId, state.documentName, fileSpec.filename)
          if (state.isIndustrySelected) {
            let data = {
              templateData: ImageArr,
              industryTemplateName,
              templateName,
              templatename,
              templateid,
              templatetype,
              imgData: {
                y_scale: 900 / state.height,
                x_scale: 1200 / state.width,
              },
              template,
              isIndustry: state.isIndustrySelected,
              userFileName,
              pageCount,
            };
            props.loadTemplate(data);
          } else {
            let data = {
              templateData: ImageArr,
              isIndustry: state.isIndustrySelected,
              industryTemplateName: templateName,
              templateName,
              templatename: templatename_,
              templateid: templateid_,
              templatetype: templatetype_,
              imgData: {
                y_scale: 900 / state.height,
                x_scale: 1200 / state.width,
              },
              userFileName,
              pageCount,
            };
            props.loadTemplate(data);
          }
          history.push({
            pathname: "/template-review",
            templatetype: templatetype_,
            templatename: templatename_,
            templateid: templateid_,
            pdfbs64: state.pdfbs64,
          });
        } catch (err) {
          console.error(err);
        }
      }
    }
    // });
  };

  const onImgLoad = ({ target: img }) => {
    console.log('Uploadfiles - img: ', img)
    // console.log('Uploadfiles - numPages: ', numPages)
    // const onImgLoad = ({ numPages }) => {
    setState((oldState) => {
      return {...oldState,
      height: img.naturalWidth,
      width: img.naturalHeight}
    });
    // setNumPages(numPages);
  };

  // function onDocumentLoadSuccess({ numPages }) {
  //   setNumPages(numPages);
  // }

  return (
    <div className="app-content container1">
      <div className="breadcrumbmaindiv">
        <a href="/data-recognition">Choose Template </a> -
        <a href="#"> Upload Document </a>
      </div>
      <div className="row">
        <div className="col-xl-6">
          <div className="row">
            <div className="col-xl-9">
              <h1 className="page-header mb-3">
                Upload document<br /><small>Upload your files containing data to be extracted and we will do the
                  rest. </small></h1>
            </div>
            
          </div>
        </div>
        <div className="col-xl-6">
        </div>
      </div>
      <div className='row'>
        <div className='col-xl-6'>
        <div className='fileslistaindiv' style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
            <div className="col-xl-3 start_reg">
              {state.uploadedFile.length !== 0
                ? (
                  (
                    <div className="addtemplatediv">

                      <a href="#"
                        className="btn btn-yellow btn-rounded btn-sm ps-5 pe-5 pt-2 pb-2 fs-14px font-weight-600"
                        onClick={(e) => handleScan(e)}
                      > Submit</a>

                    </div>
                  ))
                : (
                  (<Fragment></Fragment>))}
            </div>
          </div>
          
          {
          // state.selectedFormType !==null && 
          <div><div className='dragdropmaindivNonTemplated' style={{paddingTop: '10px'}}>
            <form>
              <input
                type='file'
                accept={state?.template?.split(";")[0].split(":")[1]==="application/pdf" ? "application/pdf" : ".jpg,.jpeg,.png"}
                // accept={(state.selectedFormType==='insurance claim' || state.selectedFormType==='nufarm') ? "application/pdf" : ".jpg,.jpeg,.png,.pdf"}
                className='NuOcrfile'
                onChange={handleFileChoice}
              />
              
              <p>Drag your files here or click in this area.</p>
              <p><h6><em>{state?.template?.split(";")[0].split(":")[1]==="application/pdf" ? `(Supported filetypes: pdf)` : `(Supported filetypes: jpg/jpeg/png)`}</em></h6>
              </p>
            </form>
          </div>
          {/* <span>{!state.imgUploadLoaded ? <p>File uploading please wait...</p> : null}</span> */}
          {state.uploadedFile.length !== 0 ? (
            <>
              <div className='fileslistaindiv'>
                <h3 className='page-header mb-4'>Uploaded Files </h3>
              </div>
              <div className='documentlist'>
                {state.uploadedFile &&
                  state.uploadedFile.map((item) => (
                    <ul>
                      <li className='selectfile'>
                        {item.name}
                        <span /* onClick={()=>closeFile()}*/>
                          <i className='fas fa-times'></i>
                        </span>
                      </li>
                      {/* <li>Document 1 <span><i className="fas fa-times"></i></span></li>
                                    <li>Document 1 <span><i className="fas fa-times"></i></span></li> */}
                    </ul>
                  ))}
              </div>
            </>
          ) : null}</div>}
          
        </div>

        <div className="col-xl-6">
          <div className="scandoumentdiv">
            {/* {state.filetype2 !== 'image/tiff'? */}
             {state.typeofFile !== 'application/pdf' && <img
             className="scandoumentdiv"
             src={state.prevImgSource}
             onLoad={onImgLoad}
             href="#"
           // alt="Loading..."
           />}
           {state.typeofFile === 'application/pdf' && <embed
                // file={prevImgSource}
                src={state.Base64String}
                width="100%"
                height="600"
                type={state.typeofFile}
                // onLoadSuccess={onImgLoad}
                onLoad={onImgLoad}
              >
                {/* <Page pageNumber={pageNumber} /> */}
              </embed>}
             
             
          </div>
        </div>
      </div>
      <div className="Sspace"></div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  ownTemplateDetails: state.template.ownTemplates,
  industryTemplates: state.template.industryTemplates,
  convertedPdfData: state.scan.convertedPdfData,
});

export default connect(mapStateToProps, {
  documentScan,
  loadTemplate,
  pdfBlobInsert,
})(UploadFile);
