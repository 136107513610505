import React, { useState } from 'react';
import { useSelector } from 'react-redux';
const PdfViewerComponent = (data, url) => {
  console.log("data-----------------------", data)
  // const [pdfUrl, setPdfUrl] = useState('');
  // const [result, setResult] = useState('');

  // const base = localStorage.getItem('base64')
  console.log("")

  return (
    <div className="container-fluid" style={{ height: '100vh', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
      <div className="row" style={{ height: '100%' }}>
        <div className="col-md-6" style={{ height: '100%', overflow: 'hidden' }}>
          <div className="mt-3" style={{ height: '100%' }}>
            <embed title="PDF Preview" src={`data:application/pdf;base64,${data.documentUrl}`} width="100%" height="100%"></embed>
          </div>
        </div>
        <div className="col-md-6" style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <h3>Result:</h3>
          <div className="result-container" style={{ flex: 1, overflowY: 'auto', border: '1px solid #ccc', padding: '1rem', marginTop: '1rem' }}>
            {data?.result?.map((page, index) => (
              <div key={index}>
                <h4 style={{ fontWeight: 'bold' }}>Page {page.page}</h4>
                <p>{page.text}</p><br/>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PdfViewerComponent;
