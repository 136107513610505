import React, {useEffect,useState} from 'react'
import { Redirect, useHistory } from "react-router-dom";
import {post} from "../src/services/Http";
import axios from "axios";


function CreateTemplateStandard() {

    let history = useHistory();
    const [state, setState] = useState({
      loading:false,
      imgloading:false
    });

    useEffect(() => {
        const jsondata = localStorage.getItem('JsonBody');
        const Bearertoken = localStorage.getItem('token');
       const imageData =localStorage.getItem('imageData');
       
       
        let axiosConfig = {
          headers: {
            "content-type": "application/json",
            "cache-control": "no-cache",
            "Authorization": `Bearer ${JSON.parse(Bearertoken).token}`
          }
        };
        if(jsondata!=undefined)
        {
           axios.post('/templates/create-standard', {
            body : {
                jsondata : JSON.parse(jsondata),
                token : JSON.parse(Bearertoken)
            },
            
          }).then(response => response.json())
            .then(responseJson => {
              console.log("responseJson",responseJson);
              
            })
            .catch((error) =>{
              console.log(" ERROR: ", error);
            });
        }
        
        if(imageData!=undefined)
        {
        //   axios.post('/templates/uploadTemplateImage-standard', {
        //   // body: imageData,
        //   body : {
        //    jsondata : JSON.parse(jsondata),
        //     imageData : JSON.parse(imageData),
        //     token : JSON.parse(Bearertoken)
        //   },
        // }).then(response => response.json())
        //   .then(responseJson => {
        //     console.log(responseJson);
            
        //   })
        //   .catch((error) =>{
        //     console.log(" ERROR: ", error);
        //   });
        
        
        }
    
    })

    const btngoback = (e) =>{
       history.push("/ocr");
      window.location.reload(false);
    }

   
    return (

        <>  
            // <Redirect to ='/superadmin-dashboard'></Redirect>
            <div>
            {btngoback}
            </div>
            
        </>
    )
}

export default CreateTemplateStandard;
