import { connect } from 'react-redux';
// import { notify } from 'react-notify-toast';
import { getUserToken } from '../services/AuthService';
import { apiDomainDevelop, apiDomainTest } from '../constants/Constants';


export const get = (url, props) => _fetch(url, 'GET', null, props);
export const post = (url, data, props, token) => _fetch(url, 'POST', data, props, token);
export const put = (url, data, props) => _fetch(url, 'PUT', data, props);
export const patch = (url, data, props) => _fetch(url, 'PATCH', data, props);
export const remove = (url, data, props) => _fetch(url, 'DELETE', data, props);

export default connect();

let apiDomain = apiDomainDevelop;

const _fetch = (url, method, data = null, props, token = null) => {
  let userToken;
  if (token !== null) {
    userToken = token;
  } else {
    userToken = getUserToken();
  }
  const bearer = `Bearer ${userToken.token}`;
  let options = {
    method,
    headers: {
      Authorization: bearer,
    },
  };
  if (method !== 'GET') {
    options = {
      ...options,
      body: data ? JSON.stringify(data) : null,
      headers: {
        ...options.headers,
        'content-type': 'application/json',
      },
    };

    const token = window.localStorage.getItem('requestVerificationToken');

    if (token) {
      options = {
        ...options,
        headers: {
          ...options.headers,
          RequestVerificationToken: token,
        },

      };
    }
  }
  return fetch(`${apiDomain}${url}`, options)
    .then((response) => _checkStatus(response, props));
};

const _checkStatus = (response) => {
  
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  return response;

};
