import {
  USER_SCANNED_RESULTS_FETCH_BEGIN,
  USER_SCANNED_RESULTS_FETCH_SUCCESS,
  USER_SCANNED_RESULTS_FETCH_FAILURE,
  USER_SCANNED_RESULTS_SEARCH_BEGIN,
  USER_SCANNED_RESULTS_SEARCH_SUCCESS,
  USER_SCANNED_RESULTS_SEARCH_FAILURE,
  ACTIVITY_lOG_BEGIN,
  ACTIVITY_lOG_SUCCESS,
  ACTIVITY_lOG_FAILURE,
  DASHBOARD_DATA_SUCCESS,
  DASHBOARD_DATA_FAILURE

} from '../actions/dashboard';


const initialState = {
  scannedResultsList: null,
  scannedResultsListLoading: false,
  scannedResultsListError: null,
  scannedSearchResultsList: null,
  scannedSearchResultsListLoading: false,
  scannedSearchResultsListError: null,
  //activity log//
  scancountsData: null,
  scancountsError: null,
  scancountsLoading: false,
  scannedRecordOfUser: [],
  netConfidencelevel:[],
  noOfRecognition:'',
  total:0,
  searchFlag:''


};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case USER_SCANNED_RESULTS_FETCH_BEGIN:
      return {
        ...state,
        scannedResultsListLoading: true,
        scannedResultsListError: null,
        
      };
    case USER_SCANNED_RESULTS_FETCH_SUCCESS:
      return {
        ...state,
        scannedResultsListLoading: false,
        scannedResultsList: payload?.data?.scannedResultsList,
        scannedResultsListError: null,
      };
    case USER_SCANNED_RESULTS_FETCH_FAILURE:
      return {
        ...state,
        scannedResultsListError: payload,
      };
    case USER_SCANNED_RESULTS_SEARCH_BEGIN:
      return {
        ...state,
        scannedSearchResultsListLoading: true,
        scannedSearchResultsListError: null,
      };
    case USER_SCANNED_RESULTS_SEARCH_SUCCESS:
      return {
        ...state,
        scannedSearchResultsListLoading: false,
        scannedSearchResultsList: payload?.data?.scannedResultsList,
        scannedSearchResultsListError: null,
      };
    case USER_SCANNED_RESULTS_SEARCH_FAILURE:
      return {
        ...state,
        scannedSearchResultsListError: payload,
      };
    //activity log//
    case ACTIVITY_lOG_BEGIN:
      return {
        ...state,
        scancountsLoading: true,
        scancountsError: null,
        total:0,
        scancountsData:[],
        searchFlagR:0
      };
    case ACTIVITY_lOG_SUCCESS:
      return {
        ...state,
        scancountsLoading: false,
        scancountsData: payload?.data?.scannedRecordOfUser,
        scancountsError: null,
        total:payload?.data?.noOfPages,
        searchFlagR:1
      };
    case ACTIVITY_lOG_FAILURE:
      return {
        ...state,
        scancountsLoading: false,
        scancountsError: payload,
        scancountsData:[],
        total:payload?.data?.noOfPages,
        searchFlagR:0
      };
      
     
    case DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        netConfidencelevel:payload.netConfidence,
        noOfRecognition:payload.recCount
      }
    case DASHBOARD_DATA_FAILURE:
      return {
        ...state,
        netConfidencelevel:'',
        noOfRecognition:''
      }    
    default:
      return state;
  }
};
