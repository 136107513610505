import {
  NON_TEMPLATED_FORM_FETCH_BEGIN,
  NON_TEMPLATED_FORM_FETCH_FAILED,
  NON_TEMPLATED_FORM_FETCH_SUCCESS,
  NON_TEMPLATED_BEGIN,
  NON_TEMPLATED_SUCCESS,
  NON_TEMPLATED_FAILED,
  TEMPLATE_LOADED,
  NON_TEMPLATED_FORM_CLEAR
} from "../actions/getNontemplateform";


const initialState = {
  data: null,
  recData: null,
  error: null,
  loading: true,
  formType:"",
  fileName:""
};

export default (state = initialState, action) => {
  const { type, payload, nufarm, dossdemo,formType,fileName,documentUrl } = action;
  
  switch (type) {

    case TEMPLATE_LOADED:
      return {
        ...state,
        template: payload,
      };

    case NON_TEMPLATED_FORM_FETCH_BEGIN:
      return {
        ...state,
        loading: true,
        recData:null
      }
    case
      NON_TEMPLATED_FORM_FETCH_SUCCESS:

      return {
        ...state,
        data: payload,
        loading: false
      };
    case NON_TEMPLATED_FORM_FETCH_FAILED:
      return {
        ...state,
        error: payload,
        loading: false
      };

    case NON_TEMPLATED_BEGIN:
      return {
        ...state,
        loading: true
      }
    case NON_TEMPLATED_SUCCESS:
      console.log("recData reducer",payload);
      return {
        ...state,
        recData: payload,
        loading: false,
        nufarm: nufarm,
        dossdemo: dossdemo,
        formType:formType,
        fileName:fileName,
        documentUrl:documentUrl
      };
    case NON_TEMPLATED_FAILED:
      return {
        ...state,
        error: payload,
        loading: false,
        formType:""
      };
      case NON_TEMPLATED_FORM_CLEAR:
        console.log("reset data",payload);
      return {
        ...state,
        recData: payload,
        formType:"",
        fileName:"",
        loading: true
      };
    default: return state;
  }
};
