import React, { useState, useEffect, useRef } from 'react';
import Event from "../Tracking";
import { getAllTemplates, alphabetFilter } from '../../actions/template'
import { TemplateTypesList } from '../../actions/templatetypes'
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { bloburl } from '../../constants/Constants'
import { useHistory } from 'react-router-dom';
import Spinner from '../layout/Spinner'
import Select from 'react-select';
import ImageLoader from 'react-loading-image';
import spinner from '../../images/loading.gif'
import BlurImageLoader from 'react-blur-image-loader';
import more from "../../images/more.png";
import Pagination from "react-pagination-js";
import "react-pagination-js/dist/styles.css";
import _ from "lodash";
import axios from "axios";
import { First } from 'react-bootstrap/esm/PageItem';
import br_image from '../../images/default.png';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

export const Template = () => {

    let history = useHistory();
    const dispatch = useDispatch();

    const { templates, error, error_, data, loading, activated, templatetypesloading, templatetypes } = useSelector((state) => ({
        templates: state.template.templates,
        error: state.template.error,
        loading: state.template.loading,
        templatetypesloading: state.TemplateTypesReducer.templatetypesloading,
        templatetypes: state.TemplateTypesReducer.templatetypes,
        data: state.TemplateTypesReducer.data,
        activated: state.TemplateTypesReducer.activated,
        error_: state.TemplateTypesReducer.error,
    }), shallowEqual);

    const [dot1, setDot1] = useState(false)
    const [delTask, setDelTask] = useState(false)
    const [name, setname] = useState("")
    const [search, setsearch] = useState([])
    const [industry, setindustry] = useState("")
    const [suggestions, setsuggestins] = useState([])
    const [typesData, setType] = useState({
        typename: "recent",
        namefilter: "ascending",
        subtype: " ",
        countrySelected: " ",
        pageNumber: 1
    })
    const [toggleTab, setToggleTab] = useState(1);
    const [searchinput, setSearch] = useState("")
    const [searchinputtext, setsearchInputtext] = useState("")
    const [pageNumber, setCurrentpage] = useState(1)
    useEffect(() => {
        dispatch(getAllTemplates())
        dispatch(TemplateTypesList())
    }, []);

    const templateBase64Array = templates?.map((each) => {
        return {
            base64: `data:${each.mimeType};base64,${each.base64}`,
            templateName: each.name,

        }
    })

    const toggleActive = (index) => {
        setCurrentpage(1)
        if(index !== 1){
            document.getElementById("Name").style.visibility = 'visible';
            }
           else{
            document.getElementById("Name").style.visibility = 'hidden';
           }
           setname("")
           setToggleTab(index)
           setSearch("")
           setsearchInputtext("")
          
    }
    /*---------function for template sub type filtering ----------*/
    const onTypeClick = (e) => {
        if (e.target.value !== " ") {
            let selectedTypeName = document.getElementsByClassName("activeTab")[0].classList[0];
            let selectedNamefilter = document.getElementById("Name").value;
            let selectedCountry = document.getElementById("country").value;
            dispatch(getAllTemplates({ "typename": selectedTypeName, "namefilter": selectedNamefilter, subtype: e.target.value, countrySelected: selectedCountry, pageNumber: 1 }))
        }
    }

    const onCountryClick = (e) => {
        console.log("country", e.target.value)
        let selectedType = document.getElementById("subtype").value;
        let selectedTypeName = document.getElementsByClassName("activeTab")[0].classList[0];
        let selectedNamefilter = document.getElementById("Name").value;
        dispatch(getAllTemplates({ "typename": selectedTypeName, "namefilter": selectedNamefilter, subtype: selectedType, countrySelected: e.target.value, pageNumber: 1 }))
    }

    /*-----------function for template type filtering ---------------*/
    const templateSelect = async (e) => {
        let selectedTemplate = e.target.className
        setType({
            typename: e.target.className
        })
        let selectedNamefilter = document.getElementById("Name").value;
        let selectedType = document.getElementById("subtype").value;
        let selectedCountry = document.getElementById("country").value;
        dispatch(getAllTemplates({ "typename": selectedTemplate, "namefilter": selectedNamefilter, subtype: selectedType, countrySelected: selectedCountry, pageNumber: 1 }))

    }

    const onDot1click = () => {
        setDot1(!dot1)
    }
    /*------------function for template name sorting --------*/
    const nameselect = (e) => {
        setType({
            namefilter: e.target.value
        })
        let selectedTypeName = document.getElementsByClassName("activeTab")[0].classList[0];
        let selectedType = document.getElementById("subtype").value;
        let selectedCountry = document.getElementById("country").value;
        dispatch(getAllTemplates({ "typename": selectedTypeName, "namefilter": e.target.value, subtype: selectedType, countrySelected: selectedCountry, pageNumber: 1 }))
    }

    const Search = (e) => {
        const regex = new RegExp(`^${e.target.value}`, "i");
        setsearchInputtext(e.target.value)
        setSearch(regex)
    }

    const nav_temp_view = (e) => {
        history.push({ 
            pathname: "/templatedetails", 
            imageurl: e.imgUrl, pages:e.pages, 
            templates: e.template, 
            templatename: e.templateName, 
            templateid: e.id });
    }

    const countryList = ["India", "United States of America"]

    // 20.230.189.213:8000
    // 104.43.211.217:8000

    const deleteForm = (e) => {
        axios.post('templates/DeleteTemplate', e)
            .then(function (response) {
                if(response.status == 200){
                    dispatch(getAllTemplates({typename: "myTemplates", namefilter: "ascending", subtype: "", countrySelected: "", pageNumber: 1}))
                }
            })
    }

    const scan = (e) => {
        history.push({ pathname: "/uploadfile", imageurl: e.imgUrl, annotationJson: JSON.parse(e.annotation), templates: e.template, templatename: e.templateName, templateid: e.id, templateType: e.templateType });
    }
    // /pagination
    const changeCurrentPage = async (number) => {
        setCurrentpage(number);
        let typesData = {
            typename: document.getElementsByClassName("activeTab")[0].classList[0],
            namefilter: document.getElementById("Name").value,
            subtype: document.getElementById("subtype").value,
            countrySelected: document.getElementById("country").value,
            pageNumber: number,
        };
        dispatch(getAllTemplates(typesData))
    }
    
    
    return (
        <>
            <div className="app-content container1">
                <div className="page-header mb-3"><h3>
                    Template</h3> <span className="page-subtitle">View the list of available document templates. You can also add new documents to your template collection.</span> </div>
                <div className="row">
                    <div className="col-xl-6">
                        <div className="menu-search ">
                            <div className="menu-search-icon"><i className="fa fa-search"></i></div>
                            <div className="menu-search-input">
                                <input type="text" className="form-control" placeholder="Search template..." value={searchinputtext} onChange={(e) => Search(e)} />
                            </div>

                        </div>
                    </div>
                    <div className="col-xl-6">
                        <div className="addtemplatediv">
                            <a href="/createtemplate"

                                className="btn btn-yellow btn-rounded btn-sm ps-5 pe-5 pt-2 pb-2 fs-14px font-weight-600"
                                onClick={() =>
                                    Event(
                                        "TemplateSelectionOwn_Build",
                                        "Build Your Own Template clicked"
                                    )
                                }>
                                <i

                                    className="fas fa-plus"></i>  {"  "}
                                Add Template
                            </a>
                        </div>
                    </div>
                </div>

                <div className="Sspace"></div>
                {/* <div className="filtermaindiv">
                    <div className="row">
                        <div className="col-sm-2">
                            <div className="addusermaindiv">
                                <div className="userinputdiv">
                                    <select className="nuocrinputText" id="country" onChange={(e) => onCountryClick(e)}>
                                        <option selected value="">All Countries</option>
                                        {countryList && countryList.map(item =>

                                            <option>{item}</option>

                                        )}

                                    </select>
                                </div>
                            </div>

                        </div>

                        <div className="col-sm-2">
                            <div className="addusermaindiv">

                                <div className="userinputdiv"
                                    style=
                                    {
                                        { float: 'left' }
                                    }
                                >
                                    <select className="nuocrinputText" id="subtype" onChange={(e) => onTypeClick(e)}>
                                        <option selected value="">All Industries</option>
                                        {templatetypes && templatetypes.map(item =>

                                            <option>{item.typename}</option>

                                        )}


                                    </select>

                                </div>

                            </div>
                        </div >

                        <div className="col-sm-2">
                            <div className="addusermaindiv">

                                <div className="userinputdiv">
                                    <select className="nuocrinputText" id = "Name"onChange={(e) => nameselect(e)}>
                                        <option value="ascending">Name (A-Z)</option>
                                        <option value="descending">Name (Z-A)</option>
                                    </select>
                                </div>

                            </div>
                        </div>

                    </div>

                </div> */}
                <div className="templatemaindiv">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="hometabdiv">
                                <ul>
                                    {/* <li onClick={() => toggleActive(1)}><a href="#" onClick={(e) => templateSelect(e)} className={toggleTab === 1 ? "recent activeTab" : "recent"}>Recent</a></li> */}
                                    {/* <li onClick={() => toggleActive(2)}><a href="#" onClick={(e) => templateSelect(e)} className={toggleTab === 2 ? "all activeTab" : "all"}>All</a></li> */}
                                    <li onClick={() => toggleActive(3)}><a href="#" onClick={(e) => templateSelect(e)} className={toggleTab === 3 ? "myTemplates activeTab" : "myTemplates"}>My Templates</a></li>
                                    {/* <li onClick={() => toggleActive(4)}><a href="#" onClick={(e) => templateSelect(e)} className={toggleTab === 4 ? "ocraTemplates activeTab" : "ocraTemplates"}>OCRA Templates</a></li> */}
                                </ul>

                            </div>

                        </div>
                    </div>



                    <div className="row">
                        {/* {loading ?  */}
                        {(templates === null) || loading ?
                            <>
                                <div className="text-center" style={{ fontSize: "11px", width: "100%", margin: "100px auto auto auto" }}>

                                    <span>Please wait...</span>
                                    <span>Do not press refresh or back button</span>
                                    <br />
                                    <br />
                                    <div><Spinner /></div>
                                </div>

                            </> :
                            <>
                                {(templates?.length === 0) ?
                                    <div className="text-center" style={{ fontSize: "11px", width: "100%", margin: "50px auto 50px auto" }}>
                                        <span style={{ fontWeight: "bold", marginTop: "100px", fontSize: "12px", marginLeft: "100px" }}>No templates available for this user!</span>

                                    </div> : 
                                    // (templates?.length !== 0 && searchinput !== "") ?
                                    //     templates.allTemplates.filter(itm => searchinput.test(itm.templateName)).map((item, value) =>
                                    //         <>
                                    //             <div className="col-sm-3">
                                    //                 <div className="card-body" style={{ display: "inline" }} >
                                    //                     <div className="tempcard" onClick={() => nav_temp_view(item)}>
                                    //                         <img
                                    //                             onLoadStart={spinner}
                                    //                             src={item.imgUrl}
                                    //                             alt={spinner}
                                    //                             style={{ width: "280px", height: "350px" }}

                                    //                         />
                                    //                     </div>

                                    //                     <div className="temdesc">
                                    //                         <label className="mb-0" onClick={() => nav_temp_view(item)} >
                                    //                             {item.templateName}
                                    //                         </label>

                                    //                     </div>
                                    //                 </div>
                                    //             </div>
                                    //         </>) :
                                    templateBase64Array?.map((item, value) =>
                                    <div className="col-sm-3">
                                        <div className="card-body" style={{ display: "inline" }}>
                                            <div className="tempcard" 
                                                /*onClick={() => nav_scan(item)}*/
                                                onClick={()=>{console.log('clicked template '+value)}}
                                                >
                                                <BlurImageLoader
                                                    src={item.base64}
                                                    preview={br_image}
                                                    fullCover={false}
                                                    maxBlurLevel={1}
                                                    transitionTime={400}
                                                    
                                                />
                                                {/* <img src={item} alt="" style={{ width: "280px", height: "350px" }} /> */}
                                            </div>

                                            <div className="temdesc">
                                                <label className="mb-0" style={{ fontSize: "13px", fontWeight: "bold" }} onClick={() => nav_temp_view(item)}>
                                                    {item.templateName}
                                                </label>
                                                        {/* {
                                                            item.templateType !== "Own Template" ?
                                                                (
                                                                    <div className="drop-more-main">
                                                                        <button
                                                                            type="button"
                                                                            className="btn more-btn dropdown-toggle"
                                                                            data-toggle="dropdown"
                                                                            aria-haspopup="true"
                                                                            aria-expanded="false"
                                                                        >
                                                                            <img src={more} alt="more" />
                                                                        </button>
                                                                        <div
                                                                            className="dropdown-menu dropdown-menu-right drop-more"
                                                                            x-placement="bottom-end"
                                                                            style={{ marginTop: "150px", marginLeft: "240px" }}
                                                                        >
                                                                            <button
                                                                                className="dropdown-item"
                                                                                type="button"
                                                                                onClick={() => scan(item)}
                                                                            >
                                                                                Scan
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                ) :
                                                                (
                                                                    <div className="drop-more-main">
                                                                        <button
                                                                            type="button"
                                                                            className="btn more-btn dropdown-toggle"
                                                                            data-toggle="dropdown"
                                                                            aria-haspopup="true"
                                                                            aria-expanded="false"
                                                                        >
                                                                            <img src={more} alt="more" />
                                                                        </button>
                                                                        <div
                                                                            className="dropdown-menu dropdown-menu-right drop-more"
                                                                            x-placement="bottom-end"
                                                                            style={{ marginTop: "150px", marginLeft: "240px" }}
                                                                        >
                                                                            <button
                                                                                className="dropdown-item"
                                                                                type="button"
                                                                                onClick={() => scan(item)}
                                                                            >
                                                                                Scan
                                                                            </button>
                                                                            <button
                                                                                className="dropdown-item deleteForm"
                                                                                type="button"
                                                                                onClick={() =>
                                                                                {
                                                                                    confirmAlert({
                                                                                        message: 'Are you sure you want to delete ' + item.templateName + '?',
                                                                                        buttons: [
                                                                                            {
                                                                                                label: 'Delete',
                                                                                                onClick: () => deleteForm(item)
                                                                                            },
                                                                                            {
                                                                                                label: 'Cancel',
                                                                                                onCancel: () => alert('Action after Cancel'),
                                                                                            }
                                                                                        ],
                                                                                        overlayClassName: "overlay-custom-class-name"
                                                                                    })
                                                                                }
                                                                                }
                                                                            >
                                                                                Delete
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                )
                                                        } */}
                                                    </div>
                                                </div>
                                            </div>

                                        )}
                            </>
                        }
                    </div>
                    {/* pagination starts*/}

                    <div id="pagination-documentSearchResult">
                        {!_.isEmpty(templates)&&typesData.typename!=="recent" && templates.allTemplates.length !==0 ? (
                            <div id="document-pagination">
                                <Pagination
                                    currentPage={pageNumber}
                                    totalSize={templates.total}
                                    sizePerPage={12}
                                    firstPageText="|⟨"
                                    lastPageText="⟩|"
                                    showFirstLastPages={true}
                                    changeCurrentPage={changeCurrentPage}
                                    theme="border-bottom"
                                />
                            </div>
                        ) : null}
                    </div>
                    {/* pagination ends*/}

                </div>

            </div>


        </>
    )

}

export default Template