import {
    DOCUMENT_SEARCH_BEGIN,
    DOCUMENT_SEARCH_SUCESS,
    DOCUMENT_SEARCH_FAILURE,
   
    SET_OPTIONS,
    SET_TEMPLATETYPE_OPTIONS,
    SET_INDUSTRYTYPE_OPTIONS,
    SET_USERS_OPTIONS,

    DATA_EDIT_BEGIN,
    DATA_EDIT_SUCESS,
    DATA_EDIT_FAILURE,
} from "../actions/DocumentSearchAction";


const initialState = {
    loading: false,
    fullresult: [],
    error: null,
    optionsloading: false,
    options1: [],
    options2: [],
    options3: [],
    options4: [],
    total: 0,
    options5: [],
    editsuccess:null,
    // blobloading:false,
    // blobData:null,
    // bloberror:null,

};

export default (state = initialState, action) => {
    switch (action.type) {
        case DOCUMENT_SEARCH_BEGIN:
            return {
                ...state,

                error: null,
                fullresult: [],
                total: 0,
                loading: true,
            };

        case DOCUMENT_SEARCH_SUCESS:

            return {
                ...state,
                loading: false,
                fullresult: action.payload.data,
                total: action.payload.total
            };
        case DOCUMENT_SEARCH_FAILURE:
            return {
                ...state,
                loading: false,
                fullresult: [],
                error: action.payload.error
            };
       
        case SET_OPTIONS:
            return {
                ...state,

                options1: action.payload,

            };
        case SET_TEMPLATETYPE_OPTIONS:
            return {
                ...state,

                options2: action.payload,

            };
        case SET_INDUSTRYTYPE_OPTIONS:
            return {
                ...state,

                options3: action.payload,

            };
        case SET_USERS_OPTIONS:
            return {
                ...state,

                options4: action.payload,

            };
       
            case DATA_EDIT_BEGIN:
                return {
                    ...state,
    
                   editsuccess:null
                };
    
            case DATA_EDIT_SUCESS:
    
                return {
                    ...state,
                   
                    editsuccess: action.payload.data,
                    
                };
            case DATA_EDIT_FAILURE:
                return {
                    ...state,
                   editsuccess:action.payload
                };

        default:
            return state;
    }
};

