import React from 'react'
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import Pagination from "react-pagination-js";
import "react-pagination-js/dist/styles.css";
import {  bloburl } from '../constants/Constants'
import { Component } from 'react';
import { fetchDocumentSearch, saveEdited, documentnameoptions, templatenameoptions, industrytypeoptions, useroptions } from '../actions/DocumentSearchAction'
import _ from "lodash";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import { viewicon, editicon } from '../constants/Constants'
import moment from 'moment';




class DocumentSearch extends Component {

  constructor() {
    super();
    this.state = {
     // start: 0,
      // end: 7,
      data:null,
      startdate: null,
      enddate: null,
      modalCurrent: 1,
      id: JSON.parse(localStorage.getItem("token")),
      currentPage: 1,
      templatetype: [
        {
          label: ["All types"],
          name: "templatetype",
          value: ""
        },
        {
          label: ["Own"],
          name: "templatetype",
          value: "own"
        }, {
          label: ["Standard"],
          name: "templatetype",
          value: "standard"
        }
      ],
      country: [
        {
          label: ["All Country"],
          name: "country",
          value: ""
        },
        {
          label: ["India"],
          name: "country",
          value: "India"
        }, {
          label: ["United States of America"],
          name: "country",
          value: "United States of America"
        }
      ],
      show: false,
      flag: true,
      viewData: {
        documentname: "",
        recognitionresult: [],
        imagefilename: "",
        document_id: ""
      },
      formData: {

        documentid: "",
        templateid: "",
        industrytypeid: "",
        userid: "",
        templatetype: "",
        country: ""
      },
      valid: true,
      readOnly: true,
      documentid_id: ""
    };
  }

  componentDidMount() {

    this.props.dispatch(documentnameoptions())
    this.props.dispatch(templatenameoptions())
    this.props.dispatch(industrytypeoptions())
    this.props.dispatch(useroptions())
    this.props.dispatch(fetchDocumentSearch({

      startdate: null,
      enddate: null,
      currentPage: 1,
      documentid: "",
      templateid: "",
      industrytypeid: "",
      userid: "",
      templatetype: "",
      country: ""
    }));
  }

  handleChange = (e) => {

    let formData = this.state.formData;
    formData[e.name] = e.value;
    this.setState({
      formData: formData,
    });
    this.setState({
      flag: true,
      valid: true,
      // currentPage: 1
    })


  };

  filterList = async (e) => {
    if(this.state.startdate !== null && this.state.enddate === null){
      this.setState({
        enddate: Date.now()
      })
    }
    this.setState({
     
      currentPage: 1
    })
    let obj = {
    
     startdate:this.state.startdate===null?this.state.startdate:moment(this.state.startdate).format('YYYY-MM-DD HH:mm:ss'),
     enddate:this.state.enddate===null?moment(Date.now()).format('YYYY-MM-DD HH:mm:ss'): moment(this.state.enddate).format('YYYY-MM-DD HH:mm:ss'),
      // currentPage: this.state.currentPage,
      currentPage: 1,
      documentid: this.state.formData.documentid,
      templateid: this.state.formData.templateid,
      industrytypeid: this.state.formData.industrytypeid,
      userid: this.state.formData.userid,
      templatetype: this.state.formData.templatetype,
      country: this.state.formData.country


    };

    const isvalidfun = this.isValid();
    if (isvalidfun) {
      await this.props.dispatch(fetchDocumentSearch(obj))

      this.setState({
        flag: false,
      });
    }
  }
  view = (data, index) => {

     this.setState({data:data})
    let recognitionresult = JSON.parse(data.recognitionresult[0].scannedresult)

    this.setState({
      show: true,
      documentid_id: data._id,
      viewData: {
        documentname: data.documentname,
        recognitionresult: recognitionresult,
        imagefilename: data.imagefilename,
        document_id: data._id,
        index: index,
       
      },
    });
}

  modalHide = () => {
    this.setState({
      show: false,
      currentPage: this.state.currentPage,
      readOnly: true,
      documentid_id: "",
      // start: 0,
      // end: 7,
       modalCurrent: 1
    });
  };

  changeCurrentPage = async (number) => {

    this.setState({
      currentPage: number,
    });
    let obj = {
      startdate: this.state.startdate===null?this.state.startdate:moment(this.state.startdate).format('YYYY-MM-DD HH:mm:ss'),
      enddate: this.state.enddate===null?this.state.enddate: moment(this.state.enddate).format('YYYY-MM-DD HH:mm:ss'),
      currentPage: number,
      documentid: this.state.formData.documentid,
      templateid: this.state.formData.templateid,
      industrytypeid: this.state.formData.industrytypeid,
      userid: this.state.formData.userid,
      templatetype: this.state.formData.templatetype,
      country: this.state.formData.country

    };

    await this.props.dispatch(fetchDocumentSearch(obj));
  };
  changeModalCurrentPage = (number) => {
    this.setState({
      modalCurrent: number,

    });
    let recognitionresult = JSON.parse(this.state.data&&this.state.data.recognitionresult[number-1].scannedresult)
    let viewdata = this.state.viewData
   viewdata.recognitionresult = recognitionresult
   this.setState({ viewData: viewdata,readOnly:true })
  }

  customFilter = (option, inputValue) => {
    const reg = new RegExp(`^${inputValue}`, "i");
    return reg.test(option.label);
  };
  isValid = () => {

    let formIsValid = true;


    if ((this.state.startdate === null && this.state.enddate !== null)) {
      formIsValid = false;
      this.setState({ valid: false })
    }


    return formIsValid;
  };

  editdocumentdata = () => {


    this.setState({
      readOnly: false,

    })
  }

  EditChange = (e, index) => {

    e.preventDefault();
    const JsonResult = this.state.viewData.recognitionresult
    const elementIndex = JsonResult.findIndex(
      (el) => el.regionAttributeKey === e.target.name
    );
    const newArray = [...this.state.viewData.recognitionresult]
    newArray[elementIndex] = {
      ...newArray[elementIndex],
      regionAttributeValue: e.target.value,
    };
    let viewdata = this.state.viewData
    viewdata.recognitionresult = newArray
    this.setState({ viewData: viewdata })
  };
  save = (documentid) => {
    let obj = {
      document_id: documentid,
      editedtext: JSON.stringify(this.state.viewData.recognitionresult),
      pagenumber:this.state.modalCurrent
    }

    this.props.dispatch(saveEdited(obj))
   
    this.setState({ readOnly: true })
    this.changeCurrentPage(this.state.currentPage)


  }
  cancel = (index) => {
   
    let viewdata = this.state.viewData

    viewdata.recognitionresult = JSON.parse(this.props.fullresult[index].recognitionresult[this.state.modalCurrent-1].scannedresult)

    this.setState({ viewData: viewdata, readOnly: true })
  }
  getDate = (datetime) => {
    let date = new Date(datetime)
    let day = date.getDate()
    let hours = date.getHours()
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    let minutes = date.getMinutes()
    minutes = minutes < 10 ? '0' + minutes : minutes;
    let v = date.toLocaleString('default', { month: 'short' })
    let currentdate = `${v} ${day}  ${hours}:${minutes} ${ampm}`

    return currentdate

  }

   stylesuser = {
    menu: base => ({
      ...base,
      width:`${(8*Math.max(...this.props.options4&&this.props.options4.map(itm=>itm.label[0].length)))+30}px`,
  
    }),
   


    menuList: (base) => ({
      ...base,
    
     padding: 0,
     background: "#fff",
  
   "::-webkit-scrollbar": {
       width: "9px"
   
      },

     "::-webkit-scrollbar-track": {
       background: "#fff"
     },
     "::-webkit-scrollbar-thumb": {
       background: "#888"
     },
     "::-webkit-scrollbar-thumb:hover": {
       background: "#555"
     }
  })}

  stylesdocumentname = {

    menu: base => ({
      ...base,
      width:`${(8*Math.max(...this.props.options1&&this.props.options1.map(itm=>itm.label[0].length)))+30}px`,
   
    }),
    menuList: (base) => ({
      ...base,
     padding: 0,
     background: "#fff",
   
   "::-webkit-scrollbar": {
       width: "9px"
   
      },

     "::-webkit-scrollbar-track": {
       background: "#fff"
     },
     "::-webkit-scrollbar-thumb": {
       background: "#888"
     },
     "::-webkit-scrollbar-thumb:hover": {
       background: "#555"
     }
  })}

  stylestemplate = {

    menu: base => ({
      ...base,
      width:`${(8*Math.max(...this.props.options2&&this.props.options2.map(itm=>itm.label[0].length)))+30}px`,
 
    }),
    menuList: (base) => ({
      ...base,
     padding: 0,
     background: "#fff",
  
   "::-webkit-scrollbar": {
       width: "9px"
   
      },

     "::-webkit-scrollbar-track": {
       background: "#fff"
     },
     "::-webkit-scrollbar-thumb": {
       background: "#888"
     },
     "::-webkit-scrollbar-thumb:hover": {
       background: "#555"
     }
  })}

  stylesindustry = {

    menu: base => ({
      ...base,
      width:`${(8*Math.max(...this.props.options3&&this.props.options3.map(itm=>itm.label[0].length)))+30}px`,
  
    }),
    menuList: (base) => ({
      ...base,
     padding: 0,
     background: "#fff",
   
   "::-webkit-scrollbar": {
       width: "9px"
   
      },

     "::-webkit-scrollbar-track": {
       background: "#fff"
     },
     "::-webkit-scrollbar-thumb": {
       background: "#888"
     },
     "::-webkit-scrollbar-thumb:hover": {
       background: "#555"
     }
  })}

  
  stylescountry = {
    menu: base => ({
      ...base,
      width:`${(8*Math.max(...this.state.country&&this.state.country.map(itm=>itm.label[0].length)))+30}px`,
  
    }),
    menuList: (base) => ({
      ...base,
     padding: 0,
     background: "#fff",
  
   "::-webkit-scrollbar": {
       width: "9px"
    },

     "::-webkit-scrollbar-track": {
       background: "#fff"
     },
     "::-webkit-scrollbar-thumb": {
       background: "#888"
     },
     "::-webkit-scrollbar-thumb:hover": {
       background: "#555"
     }
  })}

  stylestypes = {
    menu: base => ({
      ...base,
      width:`${(8*Math.max(...this.state.templatetype&&this.state.templatetype.map(itm=>itm.label[0].length)))+30}px`,
   
    }),
    menuList: (base) => ({
      ...base,
     padding: 0,
     background: "#fff",
   
   "::-webkit-scrollbar": {
       width: "9px"
   
      },

     "::-webkit-scrollbar-track": {
       background: "#fff"
     },
     "::-webkit-scrollbar-thumb": {
       background: "#888"
     },
     "::-webkit-scrollbar-thumb:hover": {
       background: "#555"
     }
  })}

  

  render() {


    const total = this.props.total
    const modalTotal = this.state.data?this.state.data.recognitionresult.length:1
   
    return (
      <div className="app-content container1">

       


          <div className="doc-search" >

           
              <h1 className='page-header mb-3'>Document Search</h1>
          

            <div className="box-content">
              <div className="box-inner">
                <div className="tab-content-style">
                  <div className="tab-pane fade show active">
                    <div className="">
                      <div className="filter-wrap border-0">
                        <label>Filter</label>
                        <div className="row no-gutters align-items-center" style={{ display: "flex", flexWrap: "wrap" }}>
                          <div className="col">
                            <div className="ml-2" style={{ fontSize: "12px" }}>
                              <div className="date-container">
                                <div className="valuecontrol">
                                  <div className="valuecontainer">


                                    <DatePicker
                                      name="startdate"

                                      selected={this.state.startdate}
                                      onChange={(date) => this.setState({
                                        startdate: date, flag: true,
                                        valid: true,
                                        // currentPage: 1
                                      })}
                                      placeholderText="From Date  "
                                      dateFormat="d MMM yyyy"
                                      peekNextMonth
                                      dropdownMode="select"
                                      autoComplete="off"
                                      maxDate={new Date()}
                                      isClearable={true}
                                    />

                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>
                          <div className="col">
                            <div className="ml-2" style={{ fontSize: "12px" }}>
                              <div className="date-container">
                                <div className="valuecontrol">
                                  <div className="valuecontainer">

                                    <DatePicker name="enddate"

                                      selected={this.state.enddate}
                                      onChange={(date) => this.setState({
                                        enddate: date, flag: true,
                                        valid: true,
                                        // currentPage: 1
                                      })}
                                      placeholderText="To Date"
                                      dateFormat="d MMM yyyy"
                                      peekNextMonth

                                      minDate={this.state.startdate}
                                      autoComplete="off"
                                      dropdownMode="select"
                                      maxDate={new Date()}
                                      isClearable={true}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <div className="ml-2" style={{ fontSize: "12px" }}>


                              <Select
                                placeholder="Document"
                                options={this.props.options1}
                                onChange={(e) => this.handleChange(e)}
                                filterOption={this.customFilter}
                                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                               styles={this.stylesdocumentname}
                                
                              />



                            </div>
                          </div>

                          <div className="col">
                            <div className="ml-2" style={{ fontSize: "12px" }}>
                              <Select
                                placeholder="Template"
                                options={this.props.options2}
                                onChange={(e) => this.handleChange(e)}
                                filterOption={this.customFilter}
                                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                styles={this.stylestemplate}
                              >


                              </Select>
                            </div>
                          </div>
                          <div className="col">
                            <div className="ml-2" style={{ fontSize: "12px" }}>
                              <Select
                                placeholder="Industry"
                                options={this.props.options3}
                                onChange={(e) => this.handleChange(e)}
                                filterOption={this.customFilter}
                                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                styles={this.stylesindustry}
                              >


                              </Select>
                            </div>
                          </div>
                          {this.state.id && this.state.id.rolename && this.state.id.rolename=== "Admin" ?
                            <div className="col">
                              <div className="ml-2" style={{ fontSize: "12px" }}>
                                <Select
                                  placeholder="User"
                                  options={this.props.options4}
                                  onChange={(e) => this.handleChange(e)}
                                  filterOption={this.customFilter}
                                  components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                  styles={this.stylesuser}
                                  
                                  
                                >


                                </Select>
                              </div>
                            </div>
                            : null}

                          <div className="col">
                            <div className="ml-2" style={{ fontSize: "12px" }}>
                              <Select
                                placeholder="Type"
                                options={this.state.templatetype}
                                onChange={(e) => this.handleChange(e)}
                                filterOption={this.customFilter}
                                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                styles={this.stylestypes}
                              >


                              </Select>
                            </div>
                          </div>

                          <div className="col">
                            <div className="ml-2" style={{ fontSize: "12px" }}>
                              <Select
                                placeholder="Country"
                                options={this.state.country}
                                onChange={(e) => this.handleChange(e)}
                                filterOption={this.customFilter}
                                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                styles={this.stylescountry}
                              >


                              </Select>
                            </div>
                          </div>


                          <div className="col text-right">
                            <div className="d-flex ml-2" style={{ fontSize: "14px" }}>

                              <button className="btn btn-secondary  border-radius-0" onClick={(e) => this.filterList(e)}>

                                Search
                                {(this.props.loading) ? (
                                  <i className="fa fa-spinner fa-spin" height='15' width='15'></i>

                                ) : (
                                  ''
                                )}
                              </button>

                            </div>
                          </div>

                        </div>
                      </div>

                      <div>


                        {!_.isEmpty(this.props.fullresult) && this.state.valid === true ? (
                          <ul id="documentSearchResult" className="m-0 p-0 document-wrap">
                            {this.props.fullresult.map((data, index) => (
                              <li key={index}>
                                <div className="d-flex space-between align-items-center">
                                  <div className="w-75">
                                    <h3 className="document-title">{data.documentname}</h3>
                                    <p className="text-gray">
                                     
                                      <span >{data.country}</span>
                                      <span className="pipe-line">{data.industrytype}</span>
                                      <span >{data.templatename}</span>
                                      <span className="pipe-line">{this.getDate(data.scanendtime)}</span>
                                    </p>
                                  </div>
                                  <div className="w-25 text-right">
                                    <button className="btn btn-outline-secondary" data-toggle="modal" data-target="#searchResultModal" onClick={() => this.view(data, index)}
                                    >View</button>

                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        )
                          : (
                            !this.state.flag && !this.props.loading &&
                            _.isEmpty(this.props.fullresult) && this.state.currentPage === 1 &&
                            (
                              <p className="text-center mt-5 mb-5">
                                No Records Found!!!
                              </p>
                            )
                          )}
                        <div >
                          {this.state.valid === false ? (
                            <p>please select from date and to date</p>
                          ) : null}
                        </div>

                        <div id="pagination-documentSearchResult">
                          {!_.isEmpty(this.props.fullresult) && this.state.valid === true ? (
                            <div id="document-pagination">
                              <Pagination
                                currentPage={this.state.currentPage}
                                totalSize={total}
                                sizePerPage={10}
                                firstPageText="|⟨"
                                lastPageText="⟩|"
                                showFirstLastPages={true}
                                changeCurrentPage={this.changeCurrentPage}
                                theme="border-bottom"
                              />
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>




          <Modal className="modal dynamic-modal-form parent-modal single-column fade"
            id="searchResultModal"
            tabIndex={-1}
            show={this.state.show}
            role="dialog"
            onHide={this.modalHide}
            aria-labelledby="exampleModalLabel"
            aria-hidden="true">


            <div>
              <Modal.Header className="modal-header custheader">

                <div className="col-9">
                  <h4 className="document-title-modal" id="modal-document-heading">
                    {this.state.viewData.documentname}
                  </h4>
                </div>
                <div className="col-3 text-right">
                  <a href={bloburl + 
                  this.state.viewData.imagefilename

                }
                   target="_blank"  title='View Document' rel="noopener noreferrer"><img src={viewicon} style={{ width: "25px", height: "25px",marginRight:"15px", cursor: "pointer" }} alt=""></img></a>
                 

                  {this.state.readOnly === true ?
                   
                    <img src={editicon} onClick={() => this.editdocumentdata()} title="Edit data" style={{ width: "25px", height: "25px",marginRight:"15px", cursor: "pointer" }} alt=""></img>
                   
                    : null}

               
                  <button
                    title="Close"
                    type="button"
                    className="btn-close"
                    aria-label="Close"
                    onClick={this.modalHide}
                  ></button>
                </div>


              </Modal.Header>
              <Modal.Body className="modal-body pt-0" style={{ overflowY: "initial !important" }} >
                <div className=" modal-body-vision pt-0 text-allign-vision1" style={{
                  overflowY: "auto", height: "70vh",
                  padding: "20px",
                  border: "1px solid #d6d2da",
                  margin: "15px",
                  borderRadius: "5px",

                }} >
                  <div className="form-main clearfix" >
                    <div className="modal-form-division ">
                      <div className="container-fluid" >
                         <ul className="document-wrap main-ul m-0 p-0">
                          {this.state.viewData && this.state.viewData.recognitionresult && this.state.viewData.recognitionresult
                            // .slice(this.state.start, this.state.end)
                            .map((item, index) => {
                              return (
                                <>
                                  <li key={index}>
                                    <p style={{ textAlign: "justify" }} className="text-gray">
                                      <span>{item.regionAttributeKey}</span>{" "}

                                    </p>

                                    <input
                                      className="anotateinput"
                                      type="text"
                                      key={index}
                                      name={item.regionAttributeKey}
                                      value={item.regionAttributeValue || ""}
                                      onChange={(e) => this.EditChange(e, index)}
                                      readOnly={this.state.readOnly}

                                    />

                                  </li></>
                              )
                            })}
                        </ul>




                      </div>
                    </div>
                  </div>
                </div>
                {this.state.readOnly === false ?
                  <div className="buttons">
                    <button className="btn btn-secondary" onClick={() => this.save(this.state.viewData.document_id)}>Save</button>
                    <button className="btn btn-primary cancel-button" onClick={() => this.cancel(this.state.viewData.index)}>Cancel</button>
                  </div> : null}
                <div id="document-model-pagination">
                    <Pagination
                      currentPage={this.state.modalCurrent}
                      totalSize={modalTotal*10}
                     // sizePerPage={7}
                    
                      firstPageText="|⟨"
                      lastPageText="⟩|"
                      showFirstLastPages={true}
                      changeCurrentPage={this.changeModalCurrentPage}
                      theme="border-bottom"
                    />
                  </div>
              </Modal.Body>

            </div>


          </Modal>
        </div>
    
    )
  }
}

const mapStateToProps = (state) => ({
  options1: state.DocumentSearchReducer.options1,
  options2: state.DocumentSearchReducer.options2,
  options3: state.DocumentSearchReducer.options3,
  options4: state.DocumentSearchReducer.options4,
  fullresult: state.DocumentSearchReducer.fullresult,
  loading: state.DocumentSearchReducer.loading,
  total: state.DocumentSearchReducer.total,
  editsuccess: state.DocumentSearchReducer.editsuccess
});
export default connect(mapStateToProps)(DocumentSearch);