import {
    CHANGE_PASSWORD_BEGIN,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAILURE,
} from "../../actions/common/ChangePasswordAction";

const initstate = {
    changepassword: '',
    changepasswordflag:'',
};
const changePasswordReducer = (state = initstate, action) => {
    // console.log("from reducer",state)
    // console.log("from reducer",action)
    switch (action.type) {
        case CHANGE_PASSWORD_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
                changepasswordflag:'',
            };

        case CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                changepassword: action.payload,
                changepasswordflag:'success'

            };

        case CHANGE_PASSWORD_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                changepasswordflag:'fail'
            };

        default:
            return state;
    }
};
export default changePasswordReducer;