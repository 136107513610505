import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { emailconfiglist, AddToemaillist, AddToConfigList, Configlist, EDITSTATUS, DELETECONFIG, Templatelist } from '../../actions/emailconfig'
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Pagination from "react-pagination-js";
import Select from "react-select";
import { Redirect, useHistory } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { bloburl } from '../../constants/Constants'


const Activeconnections = () => {

  let history = useHistory();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [configshow, configsetShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [formData, setFormData] = useState({ host: "", email: "", password: "" });
  const [buttonDisable, setButtondisable] = useState(false)
  const [errors, setError] = useState({});
  const [errobj1, setErrobj1] = useState({})
  const [configdata, setconfigdata] = useState({ senderid: "", subject: "", attachment: "", label: "" })
  const [fk_emaillist, setconfigEmail] = useState("")
  const [id, setid] = useState(null)
  const [toggleTab, setToggleTab] = useState(1);
  const [toggleDisplay, settoggledisply] = useState(true)
  const [view, setView] = useState(true);
  const [selectconfig, setselect] = useState([]);
  const [pages, setPages] = useState(null)
  const [input, setInput] = useState("")
  const [tooltipid, settooltipid] = useState("")
  const [img, setImg] = useState("")

  const { emails, loading, error, added, addloading, adderror, configadded, configaddloading, configerror, listloading, configlist, listerror, statuschange, deletedata, templatedata, templateloading } = useSelector(state => ({
    emails: state.EmailconfigReducer.emails,
    loading: state.EmailconfigReducer.loading,
    error: state.EmailconfigReducer.error,
    added: state.EmailconfigReducer.added,
    addloading: state.EmailconfigReducer.addloading,
    adderror: state.EmailconfigReducer.adderror,
    configadded: state.EmailconfigReducer.configadded,
    configaddloading: state.EmailconfigReducer.configaddloading,
    configerror: state.EmailconfigReducer.configerror,
    configlist: state.EmailconfigReducer.configlist,
    listloading: state.EmailconfigReducer.listloading,
    listerror: state.EmailconfigReducer.listerror,
    statuschange: state.EmailconfigReducer.statuschange,
    deletedata: state.EmailconfigReducer.deletedata,
    templatedata: state.EmailconfigReducer.templatedata,
    templateloading: state.EmailconfigReducer.templateloading,

  }), shallowEqual);

  useEffect(() => {
    if (!emails) dispatch(emailconfiglist({ status: toggleTab === 1 ? "active" : toggleTab === 2 ? "all" : toggleTab === 3 ? "pending" : "inactive" }))


  }

    , [emails])
  useEffect(() => {

    if (added !== null) {

      setFormData({});
      dispatch(emailconfiglist({ status: toggleTab === 1 ? "active" : toggleTab === 2 ? "all" : toggleTab === 3 ? "pending" : "inactive" }))
      setShow(false);
      setButtondisable(true)
    }
    if (statuschange !== null) {
      dispatch(Configlist({ fk_emaillists: id }))
      dispatch(emailconfiglist({ status: toggleTab === 1 ? "active" : toggleTab === 2 ? "all" : toggleTab === 3 ? "pending" : "inactive" }))
    }
    if (configadded !== null) {

      setconfigdata({ senderid: "", subject: "", attachment: "", label: "" })
      setselect([])
      setPages(null)
    }
    if (deletedata !== null) {
     
      dispatch(Configlist({ fk_emaillists: id }))
     
    }

  }

    , [added, statuschange, configadded, deletedata])

  const Close = () => {
    setShow(false);

  }

  const configClose = () => {
    configsetShow(false);
    setselect([])
    setPages(null)

  }
  const modalHide = () => {
    setShow2(false)
    setid(null)
  }
  const addNew = () => {
    setFormData({})
    setButtondisable(true)
    setShow(true);
  }

  const config = (id) => {
    setconfigdata({ senderid: "", subject: "", attachment: "", label: "" })
    setconfigEmail(id)
    setButtondisable(true)
    configsetShow(true);
    dispatch(Templatelist())
  }
  const Modal2 = (id) => {
    dispatch(Configlist({ fk_emaillists: id }))
    setid(id)
    setShow2(true);
  }

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
    setButtondisable(false)

  };
  const handleInputChangeOfConfig = (e) => {
    setconfigdata({ ...configdata, [e.target.id]: e.target.value });
    setButtondisable(false)
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const urlLink = `${window.location.protocol}//${window.location.host}`
    const isvalidfun = isValid();

    if (isvalidfun) {
      const userdetails = {
        host: formData.host,
        email: formData.email,
        password: formData.password,
        urlLink: urlLink
      }
      await dispatch(AddToemaillist(userdetails))

    }
  }


  const configvalidation = () => {
    let valid = true
    let errordata = {};
    let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    if (configdata.senderid === "" && configdata.subject === "" && configdata.attachment === "" && pages === null) {

      valid = false
      errordata["overall"] = "Provide any document filter condition"
    }
    if (configdata.senderid !== "" && !pattern.test(configdata.senderid)) {
      valid = false
      errordata["senderid"] = "Please enter valid email "
    }
    if (configdata.label === "") {
      valid = false
      errordata["label"] = "This field cannot be empty"
    }

    setErrobj1(errordata)
    return valid

  }
  const handleSubmitconfig = async (e) => {
    e.preventDefault();

    const isvalid = configvalidation();

    if (isvalid) {
      const configdetails = {
        fk_emaillist: fk_emaillist,
        config: {
          senderid: configdata.senderid,
          subject: configdata.subject,
          attachment: configdata.attachment,
          label: configdata.label,
          pages: pages
        }

      }
      dispatch(AddToConfigList(configdetails))
      configsetShow(true)
    }
  }
  const isValid = () => {
    let formIsValid = true;
    let errordata = {};
    let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    if (formData.email) {
      if (!pattern.test(formData.email)) {
        formIsValid = false;
        errordata["useremail"] = "Invalid Email Address*";
      }

    }
    setError(errordata);
    return formIsValid;
  };

  const Activation = (e, id) => {
    let value = e.target.value
    if (value === "active") {
      dispatch(EDITSTATUS({ id: id, status: "inactive" }))
    }
    else {
      dispatch(EDITSTATUS({ id: id, status: "active" }))
    }

  }

  const toggleActive = (index) => {
    setToggleTab(index)
  }
  const toggleViewShow = () => {

    settoggledisply(true)
  }
  const toggleViewHide = () => {

    settoggledisply(false)
  }
  const SelectTab = (e) => {

    if (e === 1) {
      dispatch(emailconfiglist({ status: "active" }))
    } else if (e === 2) {
      dispatch(emailconfiglist({ status: "all" }))
    } else if (e === 3) {
      dispatch(emailconfiglist({ status: "pending" }))
    } else if (e === 4) {
      dispatch(emailconfiglist({ status: "inactive" }))
    }


  }
  const selectconfigs = (e) => {

    let array = []
    if (e !== null) {
      array = e.map((i => i.value))
      setselect(array)
    } else {
      setselect([])
    }



  }

  const Delete = (id, configid) => {
    
    dispatch(DELETECONFIG({ id: id, configid: configid }))
  
  }


  const styles = {

    placeholder: base => ({
      ...base,
      fontSize: "13px",
      fontFamily: "Helvetica"
    }),
    option: (base) => ({
      ...base,
      fontSize: "13px",
      fontFamily: "Helvetica"

    })
  }
  const stylestemplate = {
    menuList: (base) => ({
      ...base,

      padding: 0,
      background: "#fff",
      height: "130px",
      "::-webkit-scrollbar": {
        width: "9px"

      },

      "::-webkit-scrollbar-track": {
        background: "#fff"
      },
      "::-webkit-scrollbar-thumb": {
        background: "#888"
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#555"
      }
    })
  }



  const chooseTemplate = (e) => {


    let Page = [...e.pages]
    for (let i = 0; i < Page.length; i++) {
      Page[i].templatename = e.name
      Page[i].fk_templates = e.value
    }
    setPages(Page)


  }

  const Showtemplate = (data) => {
    let thumbnaillocation = data && data.pages && data.pages[0].thumbnaillocation ? bloburl + data.pages[0].thumbnaillocation : ""
    settooltipid(data._id)
    setImg(thumbnaillocation)


  }





  let templatelist = templatedata && templatedata.map((template) => {
    return {

      label: (
        <>
          <span data-tip data-for="global" onMouseOver={() => Showtemplate(template)} >{[template.templatename].filter(s => s != null)}</span>

          <ReactTooltip id="global" aria-haspopup='true' isSticky="true" globalEventOff={"onclick"} role='example' effect="solid" place="left"
            style={{ height: "70vh" }}
          >
            <img src={img} alt=""></img>
          </ReactTooltip>
        </>
      ),
      value: template._id,
      name: template.templatename,
      pages: template.pages,

    }
  })







  return (<>

    <div className="app-content container1">
      <div className="dynamicForm pt-1 mt-2">
        <div className="dynamic-form-header-connections pb-1">
          <h1 className="section-title ">Active Connections</h1>
        </div>

        <div className="dynamic-form-div-activeconnections p-3">
          <div className="header">
            <div className="hometabdiv">
              <ul>
                <li onClick={() => toggleActive(1)}><a href="#" onClick={(e) => SelectTab(1)} className={toggleTab === 1 ? "recent activeTab" : "recent"}>Active</a></li>
                <li onClick={() => toggleActive(2)}><a href="#" onClick={(e) => SelectTab(2)} className={toggleTab === 2 ? "all activeTab" : "all"}>All</a></li>
                <li onClick={() => toggleActive(3)}><a href="#" onClick={(e) => SelectTab(3)} className={toggleTab === 3 ? "myTemplates activeTab" : "myTemplates"}>Pending</a></li>
                <li onClick={() => toggleActive(4)}><a href="#" onClick={(e) => SelectTab(4)} className={toggleTab === 4 ? "ocraTemplates activeTab" : "ocraTemplates"}>Inactive</a></li>
                <li className="col-12 col-md-6 mt-1 text-center text-md-right mb-2 addnew"> <button type="button"
                  onClick={addNew}
                >
                  {" "}
                  + Add New
                </button></li>
              </ul>

            </div>
            <div className="col-12 col-md-6 mt-1 text-center text-md-right mb-2">
            </div>
          </div>
          <div className="filter-div-activeconnections">
            <div className="switch-field" style={{ marginTop: "10px" }}>
              <input type="radio" id="radio-open" name="switch-open" value="open" onChange={toggleViewShow} checked={toggleDisplay} />
              <label htmlFor="radio-open">{"Email"}</label>
              <input type="radio" id="radio-close" name="switch-close" value="close" onChange={toggleViewHide} checked={!toggleDisplay} />
              <label htmlFor="radio-close">{"FTP"}</label>
            </div>
          </div>
          <hr className="m-1" />
          <ul className="dynamic-list">

            {loading && (
              <li className="text-center">
                {/* <h4>Loading...</h4> */}
              </li>
            )}
            {error && (
              <li className="text-center">
                <h4>Failed to fetch email list</h4>
              </li>
            )}
            {emails && emails.length === 0 && (
              <li className="text-center">
                <h4>Nothing to Show</h4>
              </li>
            )}

            {emails && emails.length > 0 && (
              <>

                {emails.map((data, key) => {

                  return (
                    <li className="mb-1" key={key}>
                      <div className="p-1">
                        <p>
                          <div onClick={() => Modal2(data._id)}>
                            {data.email}


                            {data.status === "active" ? (
                              <span className="active">Active</span>
                            ) : data.status === "inactive" ? (
                              <span className="inactive">Inactive</span>
                            ) : <span className="inactive">Pending</span>}
                          </div>
                        </p>
                        <div className="drop-more-main-activeconnection">
                          <button
                            type="button "
                            className="button p-2 "
                            onClick={() => config(data._id)}
                            style={{ color: "#fff", backgroundColor: "#003246", width: "100px", border: 0, fontSize: "1.1rem" }}
                          >
                            + Configure
                          </button>
                        </div>
                      </div>
                    </li>
                  )

                })}
              </>
            )}
          </ul>
        </div>
      </div>
    </div>
    <Modal className="modal health-form-modal health-view-modal medication-view-modal parent-modal staff-modal fade"
      id="addemail"
      show={show}
      onHide={Close}
      dialogClassName="modal-190w"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <Modal.Header>
        <Modal.Title id="exampleModalLabel" className="modal-header d-flex">

          <b
            className="modal-title" id="exampleModalLabel">

            {"Add New Email"}


          </b>

        </Modal.Title>
        <button type="button" title="Close" className="close-staff-close" aria-label="Close" onClick={Close}>
          <span aria-hidden="true" style={{ fontSize: "1.5rem" }}>×</span>
        </button>

      </Modal.Header>
      <Modal.Body>
        <div className="modal-body pt-0">
          <form id="staffEntityForm"
            onSubmit={(e) => handleSubmit(e)}
          >
            <div className="">
              <div className="">
                <div className="form-group">
                  <label>Host Name<span className="text-danger">*</span></label>
                  <select type="text" id="host" className="form-control" name="host" placeholder="Select host"
                    value={formData.host || ""} required autoComplete="off"
                    onChange={(e) => handleInputChange(e)}
                  >
                    <option value="">Please select</option>
                    <option value="gmail">Gmail</option>
                    <option value="Outlook">Outlook</option>
                    <option value="Yahoo">Yahoo</option>
                    <option value="AOL">AOL</option>
                  </select>
                  <div className="color">{errors["useremail"]}</div>

                </div>
              </div>

              <div className="">
                <div className="form-group">
                  <label>Email<span className="text-danger">*</span></label>
                  <input type="email" id="email" className="form-control" name="useremail" placeholder="Enter email"
                    value={formData.email || ""} required autoComplete="off"
                    onChange={(e) => handleInputChange(e)}
                  />
                  <div className="color">{errors["useremail"]}</div>

                </div>
              </div>

              <div className="">
                <div className="form-group">
                  <label>Password<span className="text-danger">*</span></label>

                  <input type="password" id="password" className="form-control" name="password" placeholder="Enter password"
                    value={formData.password || ""} required autoComplete="off"
                    onChange={(e) => handleInputChange(e)}
                  />
                </div>
              </div>
              <div className="form-footer ml-2 pl-1">
                <button type="submit" className="btn btn-secondary" disabled={buttonDisable}>Submit
                  {(addloading) ? (
                    <i className="fa fa-spinner fa-spin" height='15' width='15'></i>

                  ) : (
                    ''
                  )}

                </button>
                <button type="button" className="btn btn-primary cancel-button" data-dismiss="modal" onClick={Close} >Cancel</button>
              </div>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>




    <Modal className="modal health-form-modal health-view-modal medication-view-modal parent-modal staff-modal fade"
      id="addconfigs"
      show={configshow}
      onHide={configClose}
      dialogClassName="modal-190w"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >





      <Modal.Header>
        <Modal.Title id="exampleModalLabel" className="modal-header d-flex">
          <b className="modal-title" id="exampleModalLabel">
            {"Add Configuration"}</b>
        </Modal.Title>
        <button type="button" title="Close" className="close-staff-close" aria-label="Close" onClick={configClose}>
          <span aria-hidden="true" style={{ fontSize: "1.5rem" }} >×</span>
        </button>
      </Modal.Header>
      <Modal.Body style={{ overflowY: "initial !important" }}>
        <div className="modal-body pt-0"  >
          <div id="configcontainer" >
            <div className="" >
              <div className="">
                <div className="form-group">
                  <label>Configuration Label<span className="text-danger">*</span></label>
                  <input type="text" id="label" className="form-control p-4" name="label" placeholder="Configuration for..."
                    value={configdata.label || ""} autoComplete="off"
                    onChange={(e) => handleInputChangeOfConfig(e)}
                  />
                  <div className="color">{errobj1["label"]}</div>
                </div>
              </div>

              <Select
                placeholder="Please select"
                closeMenuOnSelect={true}
                options={[
                  { value: 'senderid', label: 'Sender Email' },
                  { value: 'subject', label: 'Subject' },
                  { value: 'attachment', label: 'Attachment' },
                  { value: 'template', label: 'Template' }
                ]}
                onChange={(e) => selectconfigs(e)}
                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                isMulti
                styles={styles}
              />
              {selectconfig.includes("senderid") ?
                <div className="">
                  <div className="form-group">
                    <label>Sender ID</label>
                    <input type="email" id="senderid" className="form-control" name="senderid" placeholder="Enter email from which you need to get documents"
                      value={configdata.senderid || ""} autoComplete="off"
                      onChange={(e) => handleInputChangeOfConfig(e)}
                    />
                    <div className="color">{errobj1["senderid"]}</div>

                  </div>
                </div> : null}
              {selectconfig.includes("subject") ?
                <div className="">
                  <div className="form-group">
                    <label>Subject</label>

                    <input type="text" id="subject" className="form-control" name="subject" placeholder="Enter Subject you want to filter"
                      value={configdata.subject || ""} autoComplete="off"
                      onChange={(e) => handleInputChangeOfConfig(e)}
                    />
                  </div>
                </div>
                : null}
              {selectconfig.includes("attachment") ?
                <div className="">
                  <div className="form-group">
                    <label>Attachment Name</label>

                    <input type="text" id="attachment" className="form-control" name="attachment" placeholder="Enter Attachment name you want to filter"
                      value={configdata.attachment || ""} autoComplete="off"
                      onChange={(e) => handleInputChangeOfConfig(e)}
                    />

                  </div>
                  <div className="color">{errobj1["overall"]}</div>
                </div>
                : null}
              {selectconfig.includes("template") ?
                <div className="">
                  <div className="form-group">
                    <label>Template</label>
                    <Select
                      placeholder="Please select"
                      options={templatelist}
                      onChange={(e) => chooseTemplate(e)}
                      menuPlacement="auto"
                      components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                      styles={stylestemplate}
                    />
                  </div>
                </div>
                : null}

            </div>
            <br />
         

            <div className="form-footer ml-2 pl-1">

              <button type="submit" className="btn btn-secondary" disabled={buttonDisable} onClick={(e) => handleSubmitconfig(e)}>Submit
                {(configaddloading) ? (
                  <i className="fa fa-spinner fa-spin" height='15' width='15'></i>

                ) : (
                  ''
                )}

              </button>
              <button type="button" className="btn btn-primary cancel-button" data-dismiss="modal" onClick={configClose} >Cancel</button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
    <Modal className="modal dynamic-modal-form parent-modal single-column fade"
      id="listconfigz"
      tabIndex={-1}
      show={show2}
      role="dialog"
      onHide={modalHide}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true">


      <div>
        <Modal.Header className="modal-header custheader">

          <div className="col-9">
            <h4 className="document-title-modal" id="modal-document-heading">
              Configurations
            </h4>
          </div>
          <div className="col-3 text-right">
            {configlist && configlist.length > 0 && configlist[0].status === "pending" ? "Pending"
              : <label class="switch" title="Status">
                <input
                  type="checkbox"

                  checked={configlist && configlist.length > 0 && configlist[0].status === "active" ? "true" : ""}
                  value={configlist && configlist.length > 0 && configlist[0].status}
                  onChange={(e) =>
                    Activation(e, configlist && configlist.length > 0 && configlist[0]._id)
                  }
                />
                <span class="slider round" ></span>
              </label>}
            &nbsp; &nbsp;
            <button
              title="Close"
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={modalHide}
            ></button>
          </div>
        </Modal.Header>
        <Modal.Body className="modal-body pt-0" style={{ overflowY: "initial !important" }} >
          <div className=" modal-body-vision pt-0 text-allign-vision1" style={{
            overflowY: "auto", height: "70vh",
            padding: "20px",
            border: "1px solid #d6d2da",
            margin: "15px",
            borderRadius: "5px",

          }} >
            <div className="form-main clearfix" >
              <div className="modal-form-division ">
                <div className="container-fluid" >
                  {configlist&&configlist.length>0&& configlist[0].configurations.length === 0 ?
                    <h4>No Configurations</h4> : null}
                  <ul className="document-wrap main-ul m-0 p-0">
                    {configlist && configlist.length > 0 && configlist[0].configurations.map((data, index) => (
                      <li key={index}>
                        <div className="d-flex space-between align-items-center">
                          <div className="w-75">
                            <h3 className="document-title">{data.configurationlabel}</h3>
                            <p className="text-gray">
                              {data.senderid ?
                                <span >{data.senderid}</span> : null}
                              {data.subjectname ? <span className={data.senderid ? "pipe-line" : ""}>{data.subjectname}</span> : null}
                              {data.attachmentname ? <span >{data.attachmentname}</span> : null}
                              {data.pages&&data.pages.length !== 0 ?
                                <span >{data.pages[0].templatename}</span> : null}
                            </p>
                          </div>
                          <div className="w-25 text-right">
                            <button className="btn btn-outline-secondary"
                              onClick={() => Delete(id, data._id)}
                            >Delete</button>

                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div id="document-model-pagination">
          </div>
        </Modal.Body>
      </div>
    </Modal>
  </>)
}

export default Activeconnections