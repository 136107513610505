import { combineReducers } from 'redux';
import auth from './auth';
import marketplace from './azure';
import template from './template';
import scan from './scan';
import navbar from './navbar';
import dashboard from './dashboard';
import result from './result';
import changePasswordReducer from '../reducers/common/ChangePasswordReducer';
import forgotPasswordReducer from '../reducers/common/Forgotpassword';
import apiKeyReducer from './apikey';
import AddUserReducer from './adduser';
import TemplateTypesReducer from './templatetypes'
import mailresetPassReducer from './common/mailresetpassword1'
import DocumentSearchReducer from './DocumentSearchReducer'
import dynamicFormReducer from "./dynamicFormReducer";
import dynamicFormsListReducer from "./dynamicFormsListReducer";
import EmailconfigReducer from './emailconfig'
import getNontemplateform from './getNontemplateform';


export default combineReducers({
  auth,
  marketplace,
  template,
  scan,
  navbar,
  dashboard,
  result,
  changePasswordReducer,
  forgotPasswordReducer,
  apiKeyReducer,
  AddUserReducer,
  TemplateTypesReducer,
  mailresetPassReducer,
  DocumentSearchReducer,
  dynamicFormReducer,
  dynamicFormsListReducer,
  EmailconfigReducer,
  getNontemplateform
  
});
