import React, {useEffect} from 'react'
import Iframe from 'react-iframe';
import { Redirect } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axios from 'axios'

const notify = (text, category) => {
    if (category === "success") {
      toast.success(text);
    } else {
      toast.error(text)
    }
  };
const CreateTemplate = (props) => {
    let history = useHistory();
    useEffect(() => {
        window.addEventListener('message', handleIframeTask);
    })
    const baseURL = `${process.env.REACT_APP_DOCKETRY_DEV_BASE_URL}`
const viaApiCall = ()=>{
    const jsondata = localStorage.getItem('JsonBody');
    const userToken = JSON.parse(localStorage.getItem('userDetails')).token;
    const imageData = localStorage.getItem('imageData');
    console.log('jsondata: ', JSON.parse(jsondata))
    console.log('Bearertoken: ', userToken)
    console.log('imageData: ', imageData)

    const key = Object.keys(JSON.parse(jsondata)._via_project._via_img_metadata)
    const regionData = JSON.parse(jsondata)._via_project._via_img_metadata[key].regions.map((each) => {
      return ({
        x: each.shape_attributes.x,
        y: each.shape_attributes.y,
        w: each.shape_attributes.width,
        h: each.shape_attributes.height,
        label: each.region_attributes.Name
      })
    })
    const createTemplatePayload = {
      base64: imageData.split(',')[1],
      documentName: JSON.parse(jsondata)._via_project._via_img_metadata[key].filename,
      description: JSON.parse(jsondata).TemplateDescription,
      boundingBox: regionData
    }

    if (jsondata !== undefined) {
      axios.post(`${baseURL}/template/create_template`, createTemplatePayload,
      {headers: {"X-Access-Token": userToken}}).then(responseJson => {
        notify("Saved successfully", "success")
        localStorage.removeItem('JsonBody');
        history.push("/templates")

      })
        .catch((error) => {
          console.log(" ERROR: ", error);
        });
    }

    if (imageData != undefined) {
      // axios.post('/templates/uploadTemplateImage', {
      //   body: {
      //     imageData: JSON.parse(imageData),
      //     token: JSON.parse(Bearertoken)
      //   },
      // }).then(responseJson => {
      //   localStorage.removeItem('JsonBody');
      // })
      //   .catch((error) => {
      //     console.log(" ERROR: ", error);
      //   });
    }
}
    const handleIframeTask = (e) => {
        var data = JSON.parse(localStorage.getItem('msgData'));
        localStorage.removeItem('msgData');
        if (data !== null) {
            localStorage.setItem("owntemplate", data.base64);
            viaApiCall()
            history.push('/templates')
        }

    }
    return (
        
        <>
            <div class="app-content container1">
            <Iframe 
                src="../NuventoOCRTemplate.html"
                width="100%"
                height="600px"
                id="myId"
                className="myClassname"
                display="initial"
                position="relative"/>
            </div>
            
        </>
    )

}

export default CreateTemplate;
