// import { post, get } from "../../services/Http";
import axios from 'axios';
export const GET_FORMDATA_BEGIN = "GET_FORMDATA_BEGIN";
export const GET_FORMDATA_SUCCESS = "GET_FORMDATA_SUCCESS";
export const GET_FORMDATA_FAILURE = "GET_FORMDATA_FAILURE";
export const SET_FORM_NAME_OPTIONS = "SET_FORM_NAME_OPTIONS";
export const CREATE_NEW_FORM_DATA = "CREATE_NEW_FORM_DATA";
export const POST_FORMDATA_BEGIN = "POST_FORMDATA_BEGIN";
export const POST_FORMDATA_SUCCESS = "POST_FORMDATA_SUCCESS";
export const POST_FORMDATA_FAILURE = "POST_FORMDATA_FAILURE";
export const DELETE_FORM_BEGIN = "DELETE_FORM_BEGIN";
export const DELETE_FORM_SUCCESS = "DELETE_FORM_SUCCESS";
export const DELETE_FORM_FAILURE = "DELETE_FORM_FAILURE";

export const getFormDataBegin = () => ({
  type: GET_FORMDATA_BEGIN,
});

export const getFormDataSuccess = (formInfo) => ({
  type: GET_FORMDATA_SUCCESS,
  payload: formInfo,
});

export const getFormDataFailure = (error) => ({
  type: GET_FORMDATA_FAILURE,
  payload: { error },
});
export const createNewFormData = (data) => ({
  type: CREATE_NEW_FORM_DATA,
  payload: { data }
})
export const postFormDataBegin = () => ({
  type: POST_FORMDATA_BEGIN,
});
export const postFormDataSuccess = (formInfo) => ({
  type: POST_FORMDATA_SUCCESS,
  payload: formInfo,
});
export const postFormDataFailure = (error) => ({
  type: POST_FORMDATA_FAILURE,
  payload: { error },
});

export const setFormNameOption = (options) => ({
  type: SET_FORM_NAME_OPTIONS,
  payload: options,
});

export const deleteFormBegin = () => ({
  type: DELETE_FORM_BEGIN,
});

export const deleteFormSuccess = (data) => ({
  type: DELETE_FORM_SUCCESS,
  payload: data,
});

export const deleteFormFailure = (error) => ({
  type: DELETE_FORM_FAILURE,
  payload: { error },
});

export function fetchDynamicFormList(params) {
  return (dispatch) => {
    dispatch(getFormDataBegin());
    return axios.post("/dynamic-forms/index", params)
      // .then(handleErrors)
      // .then((res) => res.json())
      .then((json) => {
        json = json.data
        if (params.id === "" && params.status === "") {
          let options = [];
          json.data.filter((form) => {
            if (form.dfstatus === 6) {
              options.push({ label: form.formname, value: form._id });
            }
          });
          dispatch(setFormNameOption(options));
        }
        dispatch(getFormDataSuccess(json.data));
      })
      .catch((error) => {
        dispatch(getFormDataFailure(error.message));
      });
  };
}
export function postDynamicFormData(params) {
  return (dispatch) => {
    dispatch(createNewFormData(params.formData));
    dispatch(postFormDataBegin());
    return axios.post("/dynamic-forms/create", params)
      // .then(handleErrors)
      // .then((res) => res.json())
      .then((json) => {
        dispatch(postFormDataSuccess(json));
      })
      .catch((error) => {
        dispatch(postFormDataFailure(error));
      });
  };
}

export function deleteDynamicForm(data) {
  return (dispatch) => {
    dispatch(deleteFormBegin());
    return axios.post("/dynamic-forms/delete-form", data)
      // .then(handleErrors)
      // .then((res) => res.json())
      .then((json) => {
        dispatch(deleteFormSuccess(json.data));
      })
      .catch((error) => {
        dispatch(deleteFormFailure(error));
      });
  };
}

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}
