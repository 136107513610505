import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Message from "./Message";
import { styles } from "../styles";



const ChatBotComponent = () => {
  const messageEnd = useRef(null);
  const userInputRef = useRef(null);
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState("");

  useEffect(() => {
    df_event_query("Welcome");
    userInputRef.current.autofocus = true;
  }, []);


  const handleInputKeyPress = (event) => {
    if (event.key === "Enter") {
      df_text_query(event.target.value);
      setUserInput("");
    }
  };

  const handleUserClick = () => {
    df_text_query(userInput);
    setUserInput("");
  };

  const df_text_query = async (text) => {
    let currentMessage = {
      speaker: "me",
      messageText: text,
    };
    setMessages((oldMessage) => [...oldMessage, currentMessage]);
    const res = await axios.post(
      `https://dialogflow-nuocr.azurewebsites.net/api/df_text_query`,
      { text }
    );
    let botsays = {
      speaker: "bot",
      messageText: res.data.fulfillmentText,
      url: res.data.url,
      intent: res.data.intent,
    };
    setMessages((oldMessage) => [...oldMessage, botsays]);
  };

  const df_event_query = async (event) => {
    const res = await axios.post(
      `https://dialogflow-nuocr.azurewebsites.net/api/df_event_query`,
      { event }
    );
    let says = {
      speaker: "bot",
      messageText: res.data.fulfillmentText,
      url: res.data.url,
      intent: res.data.intent,
    };
    setMessages((oldmsg) => [...oldmsg, says]);
  };

  const AlwaysScrollToBottom = () => {
    const elementRef = useRef();
    useEffect(() => elementRef.current.scrollIntoView());
    return <div ref={elementRef} />;
  };

  const handleSpeechToText = () => {
    let speak = document.getElementById("speak");
    let inputarea = document.getElementById("inputarea");
    var SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;
    var recognition = new SpeechRecognition();
    recognition.interimResults = true;
    speak.addEventListener("mousedown", function () {
      recognition.start();
      inputarea.value = "    ...speaking";
    });
    // speak.addEventListener("mouseup", function () {
    //   recognition.stop();
    //   inputarea.value = "    ...processing";
    // });
    recognition.onresult = function (e) {
      var transcript = e.results[0][0].transcript;
      // inputarea.value = transcript.replace(/table/gi, "NuOCR")
      inputarea.value = transcript.replace(/new ocr/gi, "NuOCR").replace(/new or cr/gi, "NuOCR").replace(/nausea/gi, "NuOCR");
      // console.log('input area value: ', transcript)
      // inputarea.value = transcript;
      if(e.results[0].isFinal){
        df_text_query(inputarea.value)
      }
    };
  };

  return (
    <div style={{ height: `100%`, width: `100%`, float: "right" }}>
      <div
        id="chatbot"
        style={{ height: `100%`, width: `100%`, overflow: `auto` }}
      >
        <div
          style={{
            // height: "40px",
            // lineHeight: "40px",
            position: "relative",
            // left: '35%',
            // bottom: '10px',
            // margin: '1px',
            textAlign: "center",
            zIndex: 10,
          }}
        >
          <h3>Docketry Bot</h3>
        </div>
        {messages &&
          messages.length > 0 &&
          messages.map((message, i) => {
            return (
              <Message
                speaks={message.speaker}
                text={message.messageText}
                key={i}
                url={message.url}
                intent={message.intent}
                messageErr={message?.error}
              />
            );
          })}
        <AlwaysScrollToBottom />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignContent: "center",
            borderBottom: "1px solid grey",
            paddingBottom: "0px",
          }}
        >
          <input
            ref={userInputRef}
            type="text"
            id="inputarea"
            onKeyPress={handleInputKeyPress}
            value={userInput}
            placeholder='Enter your query here'
            onChange={(e) => setUserInput(e.target.value)}
            autofocus={true}
            style={{
              borderBottom: "none",
              paddingLeft: "10px",
              paddingRight: "10px",
              width: '85%',
              border: 'none'
            }}
          />
          <button
            style={styles.chatBotButtons}
            // display="none"
            onClick={handleUserClick}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              class="bi bi-send"
              viewBox="0 0 16 16"
            >
              <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z" />
            </svg>
          </button>
          <button
            style={styles.chatBotButtons}
            id="speak"
            onClick={handleSpeechToText}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              class="bi bi-mic"
              viewBox="0 0 16 16"
            >
              <path d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z" />
              <path d="M10 8a2 2 0 1 1-4 0V3a2 2 0 1 1 4 0v5zM8 0a3 3 0 0 0-3 3v5a3 3 0 0 0 6 0V3a3 3 0 0 0-3-3z" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChatBotComponent;
