import React, { useEffect, useState } from "react";
import axios from "axios";
import Spinner from "../layout/Spinner";
import { Link, Typography, Tabs, Tab } from "@mui/material";

const Dashmin = () => {
  const [usageData, setUsageData] = useState([]);
  const [extractorusageData, setExtractorUsageData] = useState([]);
  const [nufarmData, setNufarmData] = useState([]);
  const [overAllData, setOverallData] = useState({});
  const [option, setOption] = useState("extractor");
  const [service, setService] = useState();
  const [category, setCategory] = useState("");
  const [date, setDate] = useState("");
  const [userID, setUserID] = useState("");
  const [model, setModel] = useState("");
  const [selectedTab, setSelectedTab] = useState(1);
  const [docType, setDocType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [totalAPICalls, setTotalAPICalls] = useState(0);
  const [todaysAPICalls, setTodaysAPICalls] = useState(0);
  const [cs, setCS] = useState(0);
  const [responseTime, setResponseTime] = useState(0);
  // const baseURL = `http://20.230.189.213:8000`
  const baseURL = `${process.env.REACT_APP_DOCKETRY_DEV_BASE_URL}`
  // const baseURL = `http://104.43.211.217:8000`

  // const usageURL = `https://nuocr-api.azurewebsites.net/analytics/usage`;
  const usageURL = `${baseURL}/analytics/usage`

  // const extractorusageURL = `https://nuocr-api.azurewebsites.net/analytics/extractorusage`;
  const extractorusageURL = `${baseURL}/analytics/extractor`;


  // const dashboardURL = `https://nuocr-api.azurewebsites.net/dashboard`;
  // const dashboardURL = `${baseURL}/dashboard`;

  // const nuFarmURL = `https://nuocr-api.azurewebsites.net/poc/nufarm_search`;
  const nuFarmURL = `${baseURL}/poc/nufarm_search`;

  const userDetails = JSON.parse(localStorage.getItem("userDetails"));


  useEffect(() => {
    axios
      .post(
        usageURL,
        {reverse: false},
        {
          headers: { "X-Access-token": userDetails.token },
        }
      )
      .then((res) => {
        console.log("usageData: ", res.data);
        setUsageData(res.data);
        setIsLoading(false);
      });
    axios
      .post(
        extractorusageURL,
        {reverse: true},
        {
          headers: { "X-Access-token": userDetails.token },
        }
      )
      .then((res) => {
        console.log("extractorData: ", res.data);
        setTotalAPICalls(oldVal => res.data.length)
        let today = new Date()
        let day = today.getDate();
        let month = today.getMonth()+1<10 ? `0${today.getMonth()+1}` : today.getMonth()+1<10
        let year = today.getFullYear();
        let currentDate = `${year}-${month}-${day}`
        let todaysCallArray = res.data.filter((each, id) => {
          return each.date.slice(0,10) === currentDate
        })
        setTodaysAPICalls(oldVal => todaysCallArray.length)
        let responseTimeArray = res.data.map((each, idx) => each.timeTaken)
        let csArray = res.data.map((each, id) => each.accuracy)
        const rt_avg_func = responseTimeArray => responseTimeArray.reduce( ( p, c ) => p + c, 0 ) / responseTimeArray.length;
        const cs_avg_func = csArray => csArray.reduce( ( p, c ) => p + c, 0 ) / csArray.length;
        setResponseTime(oldVal => rt_avg_func(responseTimeArray).toFixed(3))
        setCS(oldVal => (cs_avg_func(csArray)*100).toFixed(2))
        setExtractorUsageData(res.data);
        setIsLoading(false);
      });
      
    // axios
    //   .get(dashboardURL, {
    //     headers: { "X-Access-token": userDetails.token },
    //   })
    //   .then((res) => {
    //     console.log("overallData: ", res);
    //     setOverallData(res.data);
    //     setIsLoading(false);
    //   });
  }, []);

  const handleChange = (e) => {
    // console.log("option: ", e.target.value);
    if(selectedTab === 0) setOption("usage")
    if(selectedTab === 1) setOption("extractor")
    // setOption(e.target.value);
    // if(e.target.value === "nufarm"){
    //   nufarmHandler()
    // }
  };

  const handleSearchByCat = (e) => {
    if (e.key === "Enter") {
      setIsLoading(true);
      searchByCat(e.target.value);
    }
  };

  const searchByCat = (searchText) => {
    // console.log("searchByCat: ", typeof searchText);
    axios
      .post(
        usageURL,
        { category: searchText },
        {
          headers: { "X-Access-token": userDetails.token },
        }
      )
      .then((res) => {
        // console.log("usageData: ", res.data);
        setUsageData(res.data);
        setIsLoading(false);
      });
  };

  const handleSearchByDate = () => {};

  const handleSearchByModel = (e) => {
    if (e.key === "Enter") {
      setIsLoading(true);
      searchByModel(e.target.value);
    }
  };

  const searchByModel = (searchText) => {
    // console.log("searchByModel: ", searchText);
    axios
      .post(
        extractorusageURL,
        { model: searchText },
        {
          headers: { "X-Access-token": userDetails.token },
        }
      )
      .then((res) => {
        // console.log("usageData: ", res.data);
        setExtractorUsageData(res.data);
        setIsLoading(false);
      });
  };

  const nufarmHandler = async () =>{
    // console.log({ "X-Access-token": userDetails.token })
    // console.log("nuFarmURL: ", nuFarmURL)
    await axios.post(nuFarmURL, {}, {headers: { "X-Access-token": `${userDetails.token}` }}).then((res) => {
        // console.log("nuFarmData: ", res.data);
        setNufarmData(res.data);
        setIsLoading(false);
      }).catch(err => console.log('error in nufarm API: ', err));
  }

  const handleSearchByDocType = (e) => {
    if (e.key === "Enter") {
      setIsLoading(true);
      searchByDocType(e.target.value);
    }
  };

  const handleSearchByBOL = (e) => {
    if(e.key === "Enter"){
      setIsLoading(true);
      searchByBOL(e.target.value);
    }
  }

  const searchByBOL = async (searchText) => {
    let payload = searchText.includes(".jpg") ? {BOLNumber: searchText} : {BOLNumber: `${searchText}.jpg`}
    await axios.post(nuFarmURL, payload, {headers: { "X-Access-token": `${userDetails.token}` }}).then((res) => {
      // console.log("nuFarmData: ", res.data);
      setNufarmData(res.data);
      setIsLoading(false);
    }).catch(err => console.log('error in nufarm API: ', err));
  }

  const searchByDocType = (searchText) => {
    // console.log("searchByDocType: ", searchText);
    axios
      .post(
        extractorusageURL,
        { docType: searchText },
        {
          headers: { "X-Access-token": userDetails.token },
        }
      )
      .then((res) => {
        // console.log("extrtactor usageData: ", res.data);
        setExtractorUsageData(res.data);
        setIsLoading(false);
      });
  };

  const handleSearchByUserID = (e) => {
    if (e.key === "Enter") {
      setIsLoading(true);
      searchByUserID(e.target.value);
    }
  };

  const searchByUserID = (searchText) => {
    axios
      .post(
        usageURL,
        { user_id: searchText },
        {
          headers: { "X-Access-token": userDetails.token },
        }
      )
      .then((res) => {
        // console.log("usageData: ", res.data);
        setUsageData(res.data);
        setIsLoading(false);
      });
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    if(newValue === 0) setOption("usage")
    if(newValue === 1) setOption("extractor")
  };

  return (
    <>
      <div className="container-fluid pt-4 px-4">
        <div className="row g-2">
          <div className="col-sm-6 col-xl-3" style={{ padding: "10px" }}>
            <div className="bg-light rounded d-flex align-items-center justify-content-between p-4">
              <i
                class="fas fa-chart-area"
                style={{ fontSize: "40px", color: "blue" }}
              ></i>
              <div className="ms-3">
                <p className="mb-2" style={{ fontSize: "20px" }}>
                  Total API Calls
                </p>
                <h3 className="mb-0">
                  {totalAPICalls}
                  {/* {overAllData.alltime_calls} */}
                </h3>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-xl-3" style={{ padding: "10px" }}>
            <div className="bg-light rounded d-flex align-items-center justify-content-between p-4">
              <i
                class="fas fa-chart-bar"
                style={{ fontSize: "40px", color: "blue" }}
              ></i>
              <div className="ms-3">
                <p className="mb-2" style={{ fontSize: "20px" }}>
                  Today's API Calls
                </p>
                <h3 className="mb-0">
                  {todaysAPICalls}
                  {/* {overAllData.todays_calls} */}
                </h3>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-xl-3" style={{ padding: "10px" }}>
            <div className="bg-light rounded d-flex align-items-center justify-content-between p-4">
              <i
                class="fa fa-hourglass-half"
                style={{ fontSize: "40px", color: "blue" }}
              ></i>
              <div className="ms-3">
                <p className="mb-2" style={{ fontSize: "20px" }}>
                  Response Time
                </p>
                <h3 className="mb-0">
                  {responseTime} sec
                  {/* {Number(overAllData.response_time).toFixed(4)} sec */}
                </h3>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-xl-3" style={{ padding: "10px" }}>
            <div className="bg-light rounded d-flex align-items-center justify-content-between p-4">
              <i
                class="material-icons"
                style={{ fontSize: "40px", color: "blue" }}
              >
                check
              </i>
              <div className="ms-3">
                <p className="mb-4" style={{ fontSize: "20px" }}>
                  Confidence Score
                </p>
                <h3 className="mb-0">
                  {cs + "%"}
                  {/* {(100 * Number(overAllData.score)).toFixed(2) + " %"} */}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid pt-4 px-4">
        <div className="row g-4">
          <div className="col-12">
            <div className="bg-light rounded h-100 p-4">
              {/* <select
                value={option}
                onChange={handleChange}
                style={{
                  width: "20%",
                  borderRadius: "5px",
                  height: "25px",
                  borderColor: "lightgrey",
                }}
              >
                {["Extractor Dashboard", "Usage Dashboard", "NuFarm Search Dashboard"].map((item, key) => {
                  return (
                    <option key={key} value={item.split(" ")[0].toLowerCase()}>
                      {item}
                    </option>
                  );
                })}
              </select> */}
              <Typography variant="h6" align="left" sx={{ py: 1 }} color="#658cf0">
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              sx={{ textDecoration: "none" }}
            >
              <Tab
                label="Usage Data"
                sx={{
                  color: selectedTab === 0 ? "blue" : "initial",
                  fontWeight: selectedTab === 0 ? "bold" : "initial",
                }}
              />
              <Tab
                label="Extractor Data"
                sx={{
                  color: selectedTab === 1 ? "blue" : "initial",
                  fontWeight: selectedTab === 1 ? "bold" : "initial",
                }}
              />
            </Tabs>
          </Typography>
              {/* <h6 className="mb-4">Admin Dashboard</h6> */}
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    {option === "usage" ? (
                      <tr>
                        <th scope="col" style={{ textAlign: "center" }}></th>
                        <th scope="col" style={{ textAlign: "center" }}>
                          {/* <form name="search_service" method="post"> */}
                          <input
                            type="search"
                            placeholder="Search by Usage"
                            name="category"
                            id="category"
                            style={{
                              width: "120px",
                              borderRadius: "5px",
                              height: "25px",
                              borderColor: "lightgrey",
                            }}
                            value={category}
                            onKeyDown={handleSearchByCat}
                            onChange={(e) => setCategory(e.target.value)}
                          />
                          {/* <input type="submit" hidden /> */}
                          {/* </form> */}
                        </th>
                        <th scope="col" style={{ textAlign: "center" }}>
                          {/* <form name="search_date" method="post"> */}
                          <input
                            type="date"
                            placeholder="Search by Date"
                            name="date"
                            id="date"
                            style={{
                              marginLeft: "1%",
                              width: "150px",
                              borderRadius: "5px",
                              height: "25px",
                              borderColor: "lightgrey",
                            }}
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                          />
                          {/* </form> */}
                        </th>
                        <th scope="col" style={{ textAlign: "center" }}></th>
                        <th scope="col" style={{ textAlign: "center" }}></th>
                        {/* <th scope="col" style={{ textAlign: "center" }}>
                          <input
                            type="search"
                            name="user_id"
                            id="user_id"
                            value={userID}
                            onKeyDown={handleSearchByUserID}
                            onChange={(e) => setUserID(e.target.value)}
                          />
                        </th> */}
                      </tr>
                    ) : option === "extractor" ? (
                      <tr>
                        <th scope="col" style={{ textAlign: "center" }}></th>
                        <th scope="col" style={{ textAlign: "center" }}>
                          {/* <form name="search_model" action="." method="post"> */}
                          <input
                            type="search"
                            placeholder="Search by Model"
                            name="model"
                            id="model"
                            value={model}
                            style={{
                              width: "60%",
                              borderRadius: "5px",
                              height: "25px",
                              borderColor: "lightgrey",
                            }}
                            onKeyDown={handleSearchByModel}
                            onChange={(e) => setModel(e.target.value)}
                          />
                          {/* </form> */}
                        </th>
                        <th scope="col" style={{ textAlign: "center" }}></th>
                        <th scope="col" style={{ textAlign: "center" }}></th>
                        <th scope="col" style={{ textAlign: "center" }}></th>
                        <th scope="col" style={{ textAlign: "center" }}>
                          {/* <form name="search_docType" action="." method="post"> */}
                          <input
                            type="search"
                            placeholder="Search by Document"
                            name="docType"
                            id="docType"
                            style={{
                              width: "150px",
                              borderRadius: "5px",
                              height: "25px",
                              borderColor: "lightgrey",
                            }}
                            value={docType}
                            onKeyDown={handleSearchByDocType}
                            onChange={(e) => setDocType(e.target.value)}
                          />
                          {/* </form> */}
                        </th>
                        <th scope="col" style={{ textAlign: "center" }}></th>
                        <th scope="col" style={{ textAlign: "center" }}></th>
                        {/* <th scope="col" style={{ textAlign: "center" }}></th> */}
                      </tr>
                    ) : <tr>
                    <th scope="col" style={{ textAlign: "center" }}></th>
                    <th scope="col" style={{ textAlign: "center" }}>
                      {/* <form name="search_model" action="." method="post"> */}
                      <input
                        type="search"
                        placeholder="Search with BOL"
                        name="model"
                        id="model"
                        value={model}
                        style={{
                          width: "140px",
                          borderRadius: "5px",
                          height: "25px",
                          borderColor: "lightgrey",
                        }}
                        onKeyDown={handleSearchByBOL}
                        onChange={(e) => setModel(e.target.value)}
                      />
                      {/* </form> */}
                    </th>
                    <th scope="col" style={{ textAlign: "center" }}></th>
                    <th scope="col" style={{ textAlign: "center" }}></th>
                    <th scope="col" style={{ textAlign: "center" }}></th>
                    <th scope="col" style={{ textAlign: "center" }}>
                      {/* <form name="search_docType" action="." method="post"> */}
                      {/* <input
                        type="search"
                        name="docType"
                        id="docType"
                        style={{
                          width: "50%",
                          borderRadius: "5px",
                          height: "25px",
                          borderColor: "lightgrey",
                        }}
                        value={docType}
                        onKeyDown={handleSearchByDocType}
                        onChange={(e) => setDocType(e.target.value)}
                      /> */}
                      {/* </form> */}
                    </th>
                    <th scope="col" style={{ textAlign: "center" }}></th>
                    <th scope="col" style={{ textAlign: "center" }}></th>
                    {/* <th scope="col" style={{ textAlign: "center" }}></th> */}
                  </tr>}
                    {option === "usage" ? (
                      <tr>
                        <th scope="row" style={{ textAlign: "center" }}>
                          Usage ID
                        </th>
                        <th scope="col" style={{ textAlign: "center" }}>
                          Category
                        </th>
                        <th scope="col" style={{ textAlign: "center" }}>
                          Date
                        </th>
                        <th scope="col" style={{ textAlign: "center" }}>
                          Time Taken
                        </th>
                        <th scope="col" style={{ textAlign: "center" }}>
                          Usage Mode
                        </th>
                        {/* <th scope="col" style={{ textAlign: "center" }}>
                          User ID
                        </th> */}
                        <th scope="col" style={{ textAlign: "center" }}>
                          Remarks
                        </th>
                        <th scope="col" style={{ textAlign: "center" }}>
                          Status
                        </th>
                      </tr>
                    ) : option ==="extractor" ? (
                      <tr>
                        <th scope="row" style={{ textAlign: "center" }}>
                          ID
                        </th>
                        <th scope="col" style={{ textAlign: "center" }}>
                          Model
                        </th>
                        <th scope="col" style={{ textAlign: "center" }}>
                          File Name
                        </th>
                        <th scope="col" style={{ textAlign: "center" }}>
                          Number of Pages
                        </th>
                        <th scope="col" style={{ textAlign: "center" }}>
                          Accuracy
                        </th>
                        <th scope="col" style={{ textAlign: "center" }}>
                          Document Type
                        </th>
                        <th scope="col" style={{ textAlign: "center" }}>
                          Raw JSON
                        </th>
                        <th scope="col" style={{ textAlign: "center" }}>
                          Usage ID
                        </th>
                        {/* <th scope="col" style={{ textAlign: "center" }}>
                          User ID
                        </th> */}
                      </tr>
                    ) : <tr>
                    <th scope="row" style={{ textAlign: "center" }}>
                      BOL ID
                    </th>
                    <th scope="col" style={{ textAlign: "center" }}>
                      BOL Number
                    </th>
                    <th scope="col" style={{ textAlign: "center" }}>
                      PO Number
                    </th>
                    <th scope="col" style={{ textAlign: "center" }}>
                      Trip Number
                    </th>
                    <th scope="col" style={{ textAlign: "center" }}>
                      Receiver
                    </th>
                    {/* <th scope="col" style={{ textAlign: "center" }}>
                      Accuracy
                    </th> */}
                    <th scope="col" style={{ textAlign: "center" }}>
                      Date
                    </th>
                    <th scope="col" style={{ textAlign: "center" }}>
                      Delivery Number
                    </th>
                    <th scope="col" style={{ textAlign: "center" }}>
                      URL
                    </th>
                    {/* <th scope="col" style={{ textAlign: "center" }}>
                      User ID
                    </th> */}
                  </tr>}
                  </thead>
                  {isLoading ? (
                    <Spinner />
                  ) : (
                    <tbody>
                      {option === "usage"
                        ? usageData.map((each, index) => {
                            return (
                              <tr>
                                <th style={{ textAlign: "center" }}>
                                  {each.Id}
                                </th>
                                <td style={{ textAlign: "center" }}>
                                  {each.category}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {`${new Date(each.date).toLocaleDateString(
                                    "en-IN"
                                  )} | ${new Date(
                                    each.date
                                  ).getHours()}:${new Date(
                                    each.date
                                  ).getMinutes().toString().length===1 ? "0"+new Date(
                                    each.date
                                  ).getMinutes() : new Date(
                                    each.date
                                  ).getMinutes()}:${new Date(
                                    each.date
                                  ).getSeconds().toString().length===1 ? "0"+new Date(
                                    each.date
                                  ).getSeconds() : new Date(
                                    each.date
                                  ).getSeconds()}`}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {each.timeTaken}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {each.usageMode}
                                </td>
                                {/* <td style={{ textAlign: "center" }}>
                                {each.user_id}
                              </td> */}
                                <td style={{ textAlign: "center" }}>
                                  {each.remarks}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {each.status}
                                </td>
                              </tr>
                            );
                          })
                        :  option==="extractor" ? extractorusageData.map((each, index) => {
                            return (
                              <tr>
                                <th scope="row" style={{ textAlign: "center" }}>
                                  {each.Id}
                                </th>
                                <td style={{ textAlign: "center" }}>
                                  {each.model}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {each.fileName}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {each.numPages}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {(100 * Number(each.accuracy)).toFixed(2) +
                                    " %   "}
                                  {each.accuracy >= 0.8 && (
                                    <i
                                      class="fas fa-check-circle"
                                      style={{
                                        fontSize: "16px",
                                        color: "green",
                                      }}
                                    ></i>
                                  )}
                                  {each.accuracy < 0.8 && each.accuracy > 0.5 && (
                                    <i
                                      class="fas fa-exclamation-triangle"
                                      style={{
                                        fontSize: "16px",
                                        color: "#e1ad01",
                                      }}
                                    ></i>
                                  )}
                                  {each.accuracy < 0.5 && (
                                    <i
                                      class="fas fa-exclamation-triangle"
                                      style={{ fontSize: "16px", color: "red" }}
                                    ></i>
                                  )}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {each.docType}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {each.rawJson.toString()}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {each.user_id}
                                </td>
                                {/* <td style={{ textAlign: "center" }}>
                                {each.user_id}
                              </td> */}
                              </tr>
                            );
                          }) : nufarmData.map((each,idx) => {
                            return (
                              <tr>
                                <th scope="row" style={{ textAlign: "center" }}>
                                  {each.BOLId}
                                </th>
                                <td style={{ textAlign: "center" }}>
                                  {each.BOLNumber}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {each.PONumber}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {each.tripNumber}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {each.To}
                                </td>
                                {/* <td style={{ textAlign: "center" }}>
                                  {(100 * Number(each.accuracy)).toFixed(2) +
                                    " %   "}
                                  {each.accuracy >= 0.8 && (
                                    <i
                                      class="fas fa-check-circle"
                                      style={{
                                        fontSize: "16px",
                                        color: "green",
                                      }}
                                    ></i>
                                  )}
                                  {each.accuracy < 0.8 && each.accuracy > 0.5 && (
                                    <i
                                      class="fas fa-exclamation-triangle"
                                      style={{
                                        fontSize: "16px",
                                        color: "#e1ad01",
                                      }}
                                    ></i>
                                  )}
                                  {each.accuracy < 0.5 && (
                                    <i
                                      class="fas fa-exclamation-triangle"
                                      style={{ fontSize: "16px", color: "red" }}
                                    ></i>
                                  )}
                                </td> */}
                                <td style={{ textAlign: "center" }}>
                                  {`${new Date(each.date).toLocaleDateString(
                                    "en-IN"
                                  )} | ${new Date(
                                    each.date
                                  ).getHours()}:${new Date(
                                    each.date
                                  ).getMinutes().toString().length===1 ? "0"+new Date(
                                    each.date
                                  ).getMinutes() : new Date(
                                    each.date
                                  ).getMinutes()}:${new Date(
                                    each.date
                                  ).getSeconds().toString().length===1 ? "0"+new Date(
                                    each.date
                                  ).getSeconds() : new Date(
                                    each.date
                                  ).getSeconds()}`}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {each.deliveryNumber}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {each.usageId_id}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                <a href={each.url} alt={each.url}><img style={{marginLeft: '-10px'}} src={each.url} alt={each.url} target="_blank" width="50px" height="50px"/></a>{}
                              </td>
                              </tr>
                            )
                          })}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashmin;
