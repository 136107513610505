import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { getTemplateDetails } from '../../actions/template';
import Spinner from '../layout/Spinner';

const TemplateDetails = () => {
  const [state, setState] = useState({
    height: null,
    width: null,
    avg_confidence: ''
  });
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const selectedTemplate = location.imageurl
  const annotation = location.annotationJson
  const { template, prevImgSource, templateDetails, error } = useSelector((state) => { 
    return {
    template: selectedTemplate,
    prevImgSource: selectedTemplate,
    templateDetails: state.template.templateDetails,
    error: state.template.templateDetailError
  }}, shallowEqual);

  useEffect(() => {
    dispatch(getTemplateDetails(location.templateid))
  }, []);

  const onImgLoad = ({ target: img }) => {
    setState({
      ...state,
      height: img.naturalWidth,
      width: img.naturalHeight,
    });
    setNumPages(numPages);
  };
  return (
    <div className='app-content container1'>
      <h1 className='page-header mb-3'>
        Template <br />
        <small>
          View the list of available document template. You can also add new
          document to your template collection
        </small>
      </h1>
      {templateDetails === null ?
        <>
          <div className="text-center" style={{ fontSize: "11px", width: "100%", margin: "100px auto auto auto" }}>

            <span>Please wait...</span>
            <span>Do not press refresh or back button</span>
            <br />
            <br />
            <div><Spinner /></div>
          </div>
        </> :
        <>
          <h2 className='page-header mb-4 '>{templateDetails.templateName}</h2>
          <div className='row'>
            <div className='col-xl-6'>
              <div className='row'>
                <div className='col-sm-6'>
                  <div className='templatecard'>
                    <div className='row'>
                      <div className='col-12'>
                        {/* <div className='Sspace'></div> */}
                        <div className='templatecardhead text-center'>Avg. Confidence</div>
                        <div className='templatecardvalue2 text-center'>{(Math.round((templateDetails.confidencelevel * 1000) / 10) / 100).toFixed(2)}%</div>
                        {/* <div className='Sspace'></div> */}
                      </div>
                      {/* <div className='col-4'>
                        <div className='Sspace'></div>
                      </div> */}
                    </div>
                    <div className='divider'></div>
                    <div className='row'>
                      <div className='col-6 m-auto'>
                        <div className='Sspace'></div>
                        <div className='templatecardvalue text-center'>{templateDetails.points}</div>
                        <div className='templatecardvaluedesc text-center'>Data Points</div>
                        <div className='Sspace'></div>
                      </div>
                      <div className='col-6 m-auto'>
                        <div className='Sspace'></div>
                        <div className='templatecardvalue1 text-center'>{templateDetails.timesUsed}</div>
                        <div className='templatecardvaluedesc text-center'>Times Used</div>
                        <div className='Sspace'></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-sm-6'>
                  <div className='templatecard'>
                    <div className='row'>
                      <div className='col-12'>
                        {/* <div className='Sspace'></div> */}
                        <div className='templatecardhead text-center'>Country</div>
                        <div className='templatecardvaluedesc text-center'>
                          {templateDetails.country}
                        </div>
                        <div className='Sspace'></div>
                      </div>
                    </div>
                    <div className='divider'></div>
                    <div className='row'>
                      <div className='col-12'>
                        <div className='templatecardhead text-center'>Industry Type</div>
                        <div className='templatecardvaluedesc text-center'>
                          {templateDetails.industryType}
                        </div>
                        <div className='Sspace'></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='Sspace'></div>
              <h2 className='page-header mb-4'>
                Annotations <br />
                <small>
                  {/* This document is the city library membership application form */}
                </small>
              </h2>
              <div className='dashbaordgriddiv2'>
                <table style={{ width: "100%", display: "block" }}>
                  <thead >
                    <tr style={{ position: "sticky" }}>
                      <td className='tablehead' style={{ background: "#003246", color: "white", borderRight: "1px solid white", width: "352.75px" }}>Data Points</td>

                      <td className='tablehead' style={{ background: "#003246", color: "white", width: "338.75px" }}>Confidence Level</td>
                    </tr>
                  </thead>
                  <tbody style={{ minHeight: "39.5px", maxHeight: "400px", overflowY: "scroll", display: "block" }} id="confidenceTab">

                    {templateDetails.pointsConfidence.length !== 0 ?
                      templateDetails.pointsConfidence.map((item) => {
                        return (
                          <tr>
                            <td className='tabledesc' style={{ width: "339.75px", borderRight: "1px solid #ccc", borderLeft: "1px solid #ccc" }}>{item.point}</td>
                            <td
                              className='tabledesc'
                              style={{ width: "338.25px", color: ' #1f6bff', fontWeight: 'bold' }}
                            >
                              {(Math.round((item.averageConfidence * 1000) / 10) / 100).toFixed(2)}%
                            </td>
                          </tr>
                        )

                      })
                      :
                      <tr>
                        <td className='tabledesc'>No Points</td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>
            </div>
            <div className='col-xl-6 justify-content-center'>
              <div className='templategraph justify-content-center'>
                <img
                  className="scandoumentdiv m-auto"
                  src={prevImgSource}
                  onLoad={onImgLoad}
                  href="#"
                  alt="Loading..."
                />
              </div>       
            </div>
          </div>
          <div className='Sspace'></div>

          <div className='Sspace'></div>
        </>
      }
    </div>
  );
};


export default TemplateDetails;
