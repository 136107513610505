import {
  FETCH_FORM_BEGIN,
  FETCH_FORM_SUCCESS,
  FETCH_FORM_FAIL,
  POST_FORM_BEGIN,
  POST_FORM_SUCCESS,
  POST_FORM_FAIL,
} from "../actions/DynamicFormActions/DyamicFormAction";
const initstate = {
  fetchLoading: false,
  error: null,
  formData: null,
  postFormLoading: false,
  postError: null,
  postStatus: null,
};
export default (state = initstate, action) => {
  switch (action.type) {
    case FETCH_FORM_BEGIN:
      return {
        ...state,
        fetchLoading: true,
        error: null,
      };
    case FETCH_FORM_SUCCESS:
      return {
        ...state,
        fetchLoading: false,
        formData: action.payload,
      };
    case FETCH_FORM_FAIL:
      return {
        ...state,
        fetchLoading: false,
        error: action.payload.error,
      };
    case POST_FORM_BEGIN:
      return {
        ...state,
        postFormLoading: true,
        postError: null,
      };
    case POST_FORM_SUCCESS:
      return {
        ...state,
        postFormLoading: false,
        postStatus: action.payload,
      };
    case POST_FORM_FAIL:
      return {
        ...state,
        postFormLoading: false,
        postError: action.payload,
      };
    default:
      return state;
  }
};
