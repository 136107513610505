
import { toast } from "react-toastify";
import axios from 'axios';
export const FORGOT_PASSWORD_BEGIN = 'FORGOT_PASSWORD_BEGIN';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';







export const postforgotpassword = (data) => async dispatch => {
   
  try {
      dispatch({
          type: FORGOT_PASSWORD_BEGIN
      })
      
      const res = await axios.post('/forgotpassword',data)
   
      dispatch({
          type: FORGOT_PASSWORD_SUCCESS,
          payload: res.data.message
      })
    res.data.message==="success"?toast.success(res.data.success,{autoClose:4000}):toast.error(res.data.error,{autoClose:4000})

     
  } catch (err) {
   
      dispatch({
          type: FORGOT_PASSWORD_FAILURE,
          payload: err
      })
     
      toast.error("Failed to send reset mail",{autoClose:4000})
  }

}


