import React from 'react'
import {Table} from 'react-bootstrap';

const DGPOCTable = ({columns, data, index}) => {

    console.log('inside DGPOCTable: ', columns, data, index)
  return (
    <>
        <Table striped bordered hover style={{borderColor: 'black', marginTop: '10px'}}>
      <thead>
        <tr>
            {columns?.map((eachColumn, key) => {

                return <th style={{fontSize: '11px', borderColor: 'black'}}>{eachColumn}</th>
            })}
        </tr>
      </thead>
      <tbody>
        {data&&data.map((eachData, idx) => {
            return (<tr>{eachData.map((eachVal, id) => {
                return (<td style={{fontSize: '9px',  borderColor: 'black'}}>{eachVal}</td>)
            })}</tr>)
        })}
      </tbody>
    </Table>
    </>
  )
}

export default DGPOCTable