import {
  LOGIN_BEGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGOUT,
  USER_LOADED,
  USER_UNLOAD
} from "../actions/auth";
import { setAuthToken } from "../utils/setAxiosConfig";
import { STATUS_SUCCESS,
  STATUS_FAIL,} from '../actions/adduser'

const initialState = {
  token: null,
  isAuthenticated: null,
  error: null,
  loading: false,
  status:null,
  role : null
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
      }
    case USER_UNLOAD:
      return {
        ...state,
        isAuthenticated: false
      }
    case LOGOUT:
      localStorage.clear();
      return {
        ...state,
        token: null,
        isAuthenticated: false,
      };
    case LOGIN_BEGIN:
        return {
            ...state,
            loading: true
        }
    case LOGIN_SUCCESS:
      console.log('LOGIN_SUCCESS token: ', payload)
      localStorage.setItem("userDetails", JSON.stringify(payload));
      localStorage.setItem("token", JSON.stringify(payload.token));
      setAuthToken(payload.token);
      return {
        ...state,
        isAuthenticated: true,
        token: payload.token,
        loading: false,
        status:"active",
        // role : "Admin",
        // status:payload.status,
        role : payload.rolename
      };
    case LOGIN_FAILED:
      return {
        ...state,
        error: "LOGIN FAILED",
        toastVariable:"yes",
        loading: false
      };
      case STATUS_SUCCESS:
       
        return {
          ...state,
          status: "active"
        //  status:payload
        };
      case STATUS_FAIL:
        return {
          ...state,
         status:"failed to load status"
        };

    default: return state;
  }
};
