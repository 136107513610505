import React from 'react'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import { Confirm } from '../../actions/emailconfig';
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";

const ConfirmPage = () => {
    const dispatch = useDispatch();
    let history=useHistory()
    const { confirm } = useSelector(state => ({
       
        confirm: state.EmailconfigReducer.confirm,
    
      }), shallowEqual);
const handleclik=()=>{
  
    let Id = window.location.href.toLowerCase();
    // Id = Id.replace(/%/g, "#")
    Id = Id.split("/")[4];
    const resetObj = {
        UUiD: Id,
       
    };
   
   dispatch(
        Confirm({
            resetObj,
        })
    );
}


    return (<>
        {confirmAlert({
            message: 'Are you sure you want to configure this email with Docketry ?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => handleclik()
                },
                {
                    label: 'No',
                    onCancel: () => alert('Action after Cancel'),
                }
            ],
            overlayClassName: "overlay-custom-class-name"
        })

        }</>)

}
export default ConfirmPage