/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import { Stage, Layer, Image, Rect } from 'react-konva';
import CsvDownloader from 'react-csv-downloader';
import { ToastContainer, toast } from 'react-toastify';
import Spinner from '../layout/Spinner';
import { updateResult } from '../../actions/scan';
import ReactPaginate from 'react-paginate';
import Extracting from '../data_recognition/extracting';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';

const PER_PAGE = 1;

function IntelligentRecognition({
  result: { demoData, demoLoading, template },
  updateResult,
}) {
  let history = useHistory();
  const location = useLocation();
  const templatetype_ = location.templatetype;
  const templatename_ = location.templatename;
  const templateid_ = location.templateid;

  const [box, setBox] = useState({
    x: null,
    y: null,
    width: null,
    height: null,
    shouldDraw: false,
  });

  const [currentPage, setCurrentPage] = useState(0);
  const [ResData, setData] = useState({
    res: [],
    NumOfPages: null,
  });

  const [ResData2, setData2] = useState([]);
  const [dataArray, setDataArray] = useState([]);
  const [dataNum, setDataNum] = useState(true);
  const [docs, setDocs] = useState([]);
  const [docNumber, setDocNumber] = useState(1);

  const [state, setState] = useState({
    MutiPage: false,
    loading: false,
    isSaved: false,
    resultId_: [],
    templateType: null,
  });

  useEffect(() => {
  }, [state.isSaved]);

  useEffect(() => {
    loadCommentsFromServer();
    //  saveAfterScan();
  }, [demoData]);

  useEffect(() => {
    saveAfterScan()
  }, [demoData]);

  const handleChange2 = (e) => {
    setDocNumber(e.value)
  };

  function saveAfterScan() {
    if (demoData && state.isSaved === false && dataNum) {
      console.log("template",template);
      console.log("demoData",demoData);
      let arrLen = template.templateData.length;
      let resultArray = []
      for (let i = 0; i < arrLen; i++) {
        resultArray.push({
          "savedresult": JSON.parse(demoData[i].result),
          "page": i + 1
        })
      }
      const previmg = new window.Image();
      previmg.src = demoData[0].bs64;
      const base = location.pdfbs64
      
      let body = {
        scannedResultImage: demoData[0].file_name,
        base64: base === null ? demoData[0].bs64 : base,
        result: resultArray,
        numOfPages: template.pageCount,
        formtype:(template.formtype==='license')?'License':'Cheque'
      };
      console.log("body@92",body);
      axios.post('/result/saveIn', body).then(function (response) {
        if (response.status == 200) {
          notify('Result Saved', 'success');
          const result_ = response.data;
          const resultId = result_._id;
          setState({
            ...state,
            loading: false,
            // isSaved: true,
            templateType: demoData[0].type,
          });
          state.resultId_.push(resultId);
        } else {
          notify('Something happened');
        }
      });
    }
  }

  function loadCommentsFromServer() {
    if (demoData && dataNum) {
      console.log("demoData",demoData);
      console.log("template",template);

      let resultObj = [];
      let arrLen = template.templateData.length;
      for (let i = 0; i < arrLen; i++) {
        const previmg = new window.Image();
        previmg.src = demoData[i].bs64;

        resultObj.push({
          "base64": demoData[i].bs64,
          "RecognitionData": demoData[i].result,
          "previmg": previmg,
        });

      }
      setData({ ...ResData, res: resultObj, NumOfPages: template.pageCount });
      ResData2.push({
        "res": resultObj,
        "NumOfPages": template.pageCount
      })

      dataArray.push(demoData);
      docs.push({
        label: demoData[0].file_name,
        // label: demoData[0].uploadedFile,
        name: "doc",
        value: (dataArray.length)
      })
    }
  }

  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }

  const downloadFile = async (data, filename) => {
    //const {myData} = this.state; // I am assuming that "this.state.myData"
    const fileName = filename;
    const json = data;
    const blob = new Blob([json], { type: 'application/json' });
    const href = await URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.download = fileName + '.json';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  function SaveAsCSV(data) {
    data = data.map(function (obj) {
      obj['AttributeKey'] = obj['regionAttributeKey']; // Assign new key
      obj['AttributeValue'] = obj['regionAttributeValue'];
      delete obj['regionAttributeKey'];
      delete obj['regionAttributeValue']; // Delete old key
      return obj;
    });
    return data;
  }
  console.log("ResData2",ResData2);
  const offset = currentPage * PER_PAGE;
  console.log("offset",offset);
  const currentPageData = ResData2
    .map((item, index) =>
      item.res.slice(offset, offset + PER_PAGE)
        .map((it, index2) =>
          index == (docNumber - 1) ?
            (
              <div
                className='row'
                style={{ justifyContent: 'space-between', paddingLeft: '15px' }}
              >
                <div className='col-sm-12'>
                  <div className='topiconIntelligent' >
                  
                      <div className="topIconsRight">
                      <li>
                        <button
                          className='btn btn-yellow1 btn-rounded btn-sm ps-5 pe-5 pt-2 pb-2 fs-14px font-weight-600'
                          type='button'
                          onClick={(e) => save(e, it.RecognitionData, currentPage, index)}
                        >
                          {' '}
                          <i className='far fa-check-circle' style={{ paddingRight: '10px' }}>

                            {state.loading && (
                              <span
                                className='spinner-border spinner-border-sm'
                                role='status'
                                aria-hidden='true'
                              ></span>
                            )}
                           
                            {' '}
                          </i>
                          Save Changes
                        </button>
                      </li >
                      
                      <li>
                        {/* <div> */}
                        <button
                          className='btn btn-yellow1 btn-rounded btn-sm ps-5 pe-5 pt-2 pb-2 fs-14px font-weight-600'
                          type='button'
                          style={{ paddingLeft: '10px', width: '133.84px', alignContent: 'center' }}
                          id='dropdownMenuButton'
                          data-toggle='dropdown'
                        >
                          <i
                            className='fas fa-download'
                            style={{ paddingRight: '10px' }}
                          >
                            {' '}
                          </i>
                          Download
                        </button>
                        <div className='dropdown-menu'>
                          <CsvDownloader
                            datas={SaveAsCSV(JSON.parse(it.RecognitionData))}
                            filename={demoData[0].type}
                          >
                            <a className='dropdown-item' href='#'>
                              Export as CSV
                            </a>
                          </CsvDownloader>
                          <a
                            className='dropdown-item'
                            href='#'
                            role='presentation'
                            onClick={(e) =>
                              downloadFile(it.RecognitionData, demoData[0].type)
                            }
                          >
                            Export as JSON
                          </a>
                        </div>
                        {/* </div> */}
                      </li>
                      </div>
                  </div>
                </div>
                <div className='col-sm-12'>
                  <div className='demoscandoumentheaderdiv'>
                    {`Result of ${demoData[0].type === 'cheque' ? 'Cheque Leaf' : 'License'
                      }`}
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='demoscandoumentdiv'>
                    <img src={demoData[0].bs64} alt='' width={700} height={900} />
                  </div>
                </div>

                <div className='col-md-4' style={{ marginRight: '35px' }}>
                  <div className='demoscandoumentdiv2'>
                    {JSON.parse(it.RecognitionData).map((el) => (
                      <>
                        <div className='pagelabel'>{el.regionAttributeKey}</div>
                        <div className='anotateinputdiv'>
                          <input
                            className='anotateinput'
                            type='text'
                            name={el.regionAttributeKey}
                            value={el.regionAttributeValue || ''}
                            onChange={(e) => handleChange(e, index)}
                            onBlur={(e) => {
                              setBox({ ...box, shouldDraw: false });
                            }}
                          />
                        </div>
                      </>
                    ))}
                  </div>
                  <br />

                  <br />
                </div>
              </div>
            ) :
            (
              null
            )
        )
      //Row here
    );
  //HERE -------
  console.log("currentPageData",currentPageData);
  const pageCount = Math.ceil(ResData.NumOfPages / PER_PAGE);
  console.log("pageCount",pageCount);

  const notify = (text, category) => {
    if (category === 'success') {
      toast.success(text);
    } else {
      toast.warning(text);
    }
  };

  const handleChange = (e, index) => {
    e.preventDefault();
    const JsonResult = JSON.parse(ResData2[index].res[currentPage].RecognitionData)
    const elementIndex = JsonResult.findIndex(
      (el) => el.regionAttributeKey === e.target.name
    );
    const newArray = [...JSON.parse(ResData2[index].res[currentPage].RecognitionData)]

    newArray[elementIndex] = {
      ...newArray[elementIndex],
      regionAttributeValue: e.target.value,
    };

    ResData2[index].res[currentPage].RecognitionData = JSON.stringify(newArray)
    setDataNum(false);
    setState({
      ...state,
      isSaved: false
    });

  };

  const save = async (e, res, page, index) => {
    e.preventDefault();
    let body = {
      resultID: state.resultId_[index],
      updatedResult: res,
      TemplateType: state.templateType,
      pageNumber: page + 1
    };
    if (state.isSaved == false) {
      axios.post('/result/updatedIn', body).then(function (response) {
        if (response.status == 200) {
          notify('Saved Changes', 'success');
          setState({
            ...state,
            loading: false,
            isSaved: true,
          });
        } else {
          notify('Something happened');
        }
      });
    } else {
      console.log('there are no updates');
      notify('Already Saved');
    }
  };
  const btngoback = (e) => {
    history.push('/ocr');
    window.location.reload(false);
  };

  return (
    <Fragment>
      {demoLoading ? (
        <>
          <Extracting isIntelligent={true} />
        </>
      ) : (
        <>
          <div className='app-content container1'>
            <h1 className='page-header mb-3'>
              Review
              <br />
              <small>Your document is being recognized by Docketry </small>
            </h1>
            <div className="ml-2" style={{ fontSize: "12px" ,  width: "300px" }}>
              {
                (ResData2.length !== 1) ?
                  (
                    <Select
                      placeholder="Documents"
                      options={docs}
                      onChange={(e) => handleChange2(e)}
                      defaultValue={docs[0]}
                    >
                    </Select>
                  ) :
                  (
                    null
                  )
              }
            </div>
            <div className='row'>
              <div id='react-paginate'>
                {currentPageData}
                {pageCount !== 1 ? (
                  <ReactPaginate
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination'}
                    previousLinkClassName={'pagination__link'}
                    nextLinkClassName={'pagination__link'}
                    disabledClassName={'pagination__link--disabled'}
                    activeClassName={'active'}
                  />
                ) : null}
              </div>
            </div>

            <div className='Sspace'></div>
          </div>
        </>
      )}
    </Fragment>
  );
}

IntelligentRecognition.propTypes = {
  result: PropTypes.object,
  updateResult: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  result: state.scan,
});

export default connect(mapStateToProps, { updateResult })(
  IntelligentRecognition
);
