import React, { useState, useRef } from 'react'
import { changePassword } from '../../actions/common/ChangePasswordAction'
import { connect, useDispatch } from "react-redux";
import {FiEye} from 'react-icons/fi'
import {FaEyeSlash} from 'react-icons/fa'

import { Form, Row, Col, Button, InputGroup } from 'react-bootstrap'

const ChangePassword = () => {
    const userData = JSON.parse(localStorage.getItem("userDetails"));
    const dispatch = useDispatch()
    const [state, setState] = useState({
        password: '',
        confirmPassword: '',
        currentPassword: '',
        errors: {},
        message1: '*Please enter valid password!',
        message2: '*Please enter valid password!',
        message3: '*Password does not match!',
        msg1Show: false,
        msg2Show: false,
        msg3Show: false,
        passwordIsValid: false,
        show1: true,
        show2: true,
        show3: true
    })
    const [showPassword, setShowPassword] = useState(false)
    const [showCurrentPassword, setShowCurentPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const passwordRef = useRef('')
    const currentPasswordRef = useRef('')
    const confirmPasswordRef = useRef('')
    // const regex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,20})");

    const handleShow = (e, data) => {

        if (data == "show1") {
            if (state.show1 === true) {
                document.getElementsByName('currentPassword')[0].type = 'text';
            }
            else {
                document.getElementsByName('currentPassword')[0].type = 'password';
            }
            setState({
                ...state,
                show1: !state.show1
            })
        }
        else if (data == "show2") {
            if (state.show2 === true) {
                document.getElementsByName('password')[0].type = 'text';
            }
            else {
                document.getElementsByName('password')[0].type = 'password';
            }
            setState({
                ...state,
                show2: !state.show2
            })
        }
        else {
            if (state.show3 === true) {
                document.getElementsByName('confirmPassword')[0].type = 'text';
            }
            else {
                document.getElementsByName('confirmPassword')[0].type = 'password';
            }
            setState({
                ...state,
                show3: !state.show3
            })
        }
    }

    const handleChange=(e)=>{
     setState({
         ...state,
      [e.target.name] : e.target.value,
      msg1Show:false,
      msg3Show:false
     })
    }

    const handlePasswordChange = (data, refParameter) => {
        setState((oldState) => {
            switch (refParameter?.current?.id) {
                case "currentpassword":
                    return ({...oldState, currentPassword: data})
                case "password":
                    return ({...oldState, password: data})
                case "confirmpassword":
                    return ({...oldState, confirmPassword: data})
                default:
                    return;
            }
        })
    }
   
    const toggleCurrentPasswordShow = () => {
        console.log('inside toggleCurrentPasswordShow')
        setShowCurentPassword(oldVal => !oldVal)
     }

     const togglePasswordShow = () => {
        console.log('inside togglePasswordShow')
        setShowPassword(oldVal => !oldVal)
     }
    
     const toggleConfirmPasswordShow = () => { 
        console.log('inside toggleConfirmPasswordShow')
        setShowConfirmPassword(oldVal => !oldVal)
     }

    const validateData = () => {
        const regex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,20})");
        let newPass = state.password
        let confirmPass = state.confirmPassword
        if(state.password!==''){
            if (
                !(regex.test(state.password))
            ) {
                setState({
                    ...state,
                    msg1Show:true
                })
                return false
            }else{
                if(confirmPass !== newPass){
                    setState({
                        ...state,
                        msg3Show:true
                    })
                    return false
                }
                else{
                    setState({
                        ...state,
                        msg3Show:false,
                        msg1Show:false
                    })
                   
                    return true
                }

            }
        }
    }

    const handleUpdate = (event) => {
        console.log('inside handleUpdate')
        // if (validateData()) {
            event.preventDefault();
            dispatch(changePassword({ 
                username: userData.username,
                password: state.currentPassword, 
                new_password: state.password },  
                {
                    headers: { "X-Access-token": userData.token },
                  }))
            setState({
                password: '',
                confirmPassword: '',
                currentPassword: '',
            })
        // }
        // else {
        //     event.preventDefault();
        // }
    }

    return (
        <>
            <div className="app-content container1">
                <div className="changePassword">
                    <div className="changePassHead mb-4">
                        <h3 className="page-header mb-3">Change Your Password</h3>
                        <hr />
                    </div>
                    <div className="changePassContent">
                        <Form>
                            <Row>
                                <Form.Group as={Col} md="12"  controlId="currentPassword" style={{display: "flex", flexDirection: 'row', justifyContent: 'start', alignItems: 'center'}}>
                                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center', alignSelf: 'stretch'}}>
                                    <span><Form.Label><b>Current Password</b></Form.Label></span>
                                    
                                    {/* <InputGroup style={{display: 'inline-block'}}> */}
                                        <input 
                                            type={showCurrentPassword ? "text" : "password"} 
                                            maxLength={20}
                                            value={state.currentPassword}
                                            ref={currentPasswordRef}
                                            placeholder="Current Password"
                                            onChange={e => handlePasswordChange(e.target.value, currentPasswordRef)}
                                            required 
                                            id="currentpassword"
                                            style={{
                                                padding: '6px 6px',
                                                width: '250px',
                                                fontSize: '16px',
                                                fontFamily: 'caption',
                                                borderColor: '#e1e1e1',
                                                borderRadius: '6px',
                                                marginLeft: '20px'
                                                }}/>
                                            <button style={{backgroundColor: 'transparent', border: 'none', marginLeft: '-30px', paddingRight: '2px', borderRadius: '6px' }}>
                                                {showCurrentPassword && <FiEye className='reveal' onClick={toggleCurrentPasswordShow} style={{fontSize: '22px'}}/>}
                                                {!showCurrentPassword && <FaEyeSlash className='reveal' onClick={toggleCurrentPasswordShow} style={{fontSize: '22px'}}/>}
                                            </button>
                                      
                                    
                                    </div>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="12" controlId="password" style={{display: "flex", flexDirection: 'row', justifyContent: 'start', alignItems: 'center', alignSelf: 'stretch'}}>
                                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center'}}>
                                    <Form.Label><b>New Password</b></Form.Label>
                                    <input 
                                            type={showPassword ? "text" : "password"} 
                                            maxLength={20}
                                            value={state.password}
                                            ref={passwordRef}
                                            placeholder="New Password"
                                            onChange={e => handlePasswordChange(e.target.value, passwordRef)}
                                            required
                                            id="password" 
                                            style={{
                                                padding: '6px 6px',
                                                width: '250px',
                                                fontSize: '16px',
                                                fontFamily: 'caption',
                                                borderColor: '#e1e1e1',
                                                borderRadius: '6px',
                                                marginLeft: '40px'
                                                }}/>
                                            <button style={{backgroundColor: 'transparent', border: 'none', marginLeft: '-30px', paddingRight: '2px', borderRadius: '6px' }}>
                                                {showPassword && <FiEye onClick={togglePasswordShow} className='reveal' style={{fontSize: '22px'}}/>}
                                                {!showPassword && <FaEyeSlash onClick={togglePasswordShow} className='reveal' style={{fontSize: '22px'}}/>}
                                            </button>
                                            </div>
                                    <span className="passErrMsg">{state.msg1Show ? state.message1 : null}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="12" controlId="confirmPassword" style={{display: "flex", flexDirection: 'row', justifyContent: 'start', alignItems: 'center', alignSelf: 'stretch'}}>
                                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center'}}>
                                    <Form.Label><b>Confirm Password</b></Form.Label>
                                    <input 
                                            type={showConfirmPassword ? "text" : "password"} 
                                            maxLength={20}
                                            value={state.confirmPassword}
                                            placeholder="Confirm Password"
                                            ref={confirmPasswordRef}
                                            onChange={e => handlePasswordChange(e.target.value, confirmPasswordRef)}
                                            required 
                                            id="confirmpassword"
                                            style={{
                                                padding: '6px 6px',
                                                width: '250px',
                                                fontSize: '16px',
                                                fontFamily: 'caption',
                                                borderColor: '#e1e1e1',
                                                borderRadius: '6px',
                                                marginLeft: '20px'
                                                }}/>
                                            <button style={{backgroundColor: 'transparent', border: 'none', marginLeft: '-30px', paddingRight: '2px', borderRadius: '6px' }}>
                                                {showConfirmPassword && <FiEye className='reveal' onClick={toggleConfirmPasswordShow}  style={{fontSize: '22px'}}/>}
                                                {!showConfirmPassword && <FaEyeSlash className='reveal' onClick={toggleConfirmPasswordShow}  style={{fontSize: '22px'}}/>}
                                                
                                            </button>
                                            </div>
                                    <span className="passErrMsg">{state.msg3Show ? state.message3 : null}</span>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="10" controlId="formMessage" className="text-primary">
                                    <Form.Label>**For password, must use minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character</Form.Label>
                                </Form.Group>
                            </Row>
                            <Button type="submit" id="changePassBtn" onClick={(event) => handleUpdate(event)}>Update</Button>
                        </Form>
                    </div>
                </div>
            </div>

        </>
    )


}

const mapStateToProps = (state) => ({
    ChangePassword: state.changePasswordReducer.changepassword, changepasswordflag: state.changePasswordReducer.changepasswordflag
});

export default connect(mapStateToProps, { changePassword })(ChangePassword);