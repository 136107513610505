import React from 'react';

const TemplateEdit = () => {
    return (
        <div class="app-content container1">
            <h1 class="page-header mb-3">
                Template -  Edit <br /><small>Edit the annotations of the data points you want to modify</small></h1>
            <div class="row">
                <div class="col-xl-6">
                    <div class="Sspace"></div>
                    <h2 class="page-header mb-4">
                        Edit Template <br /></h2>
                    <div class="dashbaordgriddiv">
                        <table width="100%" >
                            <tbody>
                                <tr>
                                    <td class="tablehead">Data Points</td>
                                    <td class="tablehead">Confidence Level</td>
                                </tr>
                                <tr>
                                    <td class="tabledesc-select">Serial Number</td>
                                    <td class="tabledesc-select" style= {{color: '#1f6bff' ,fontWeight: 'bold'}}>96.3%</td>
                                </tr>
                                <tr>
                                    <td class="tabledesc">Serial Number</td>
                                    <td class="tabledesc" style= {{color: '#1f6bff' ,fontWeight: 'bold'}}>96.3%</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="Sspace"></div>
                    <div class="row">
                        <div class="col-3">
                            <div class="addtemplatediv">
                                <a href="#" class="btn btn-yellow btn-rounded btn-sm ps-5 pe-5 pt-2 pb-2 fs-14px font-weight-600"> Save</a>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="addtemplatediv">
                                <a href="#" class="btn btn-cancel btn-rounded btn-sm ps-5 pe-5 pt-2 pb-2 fs-14px font-weight-600">Cancel</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6">
                    <div class="templategraph">
                    </div>
                </div>
            </div>
            <div class="Sspace"></div>
            <div class="Sspace"></div>
        </div>
    );
};

export default TemplateEdit;
