// import { act } from "react-dom/test-utils";
import {
  GET_FORMDATA_BEGIN,
  GET_FORMDATA_SUCCESS,
  GET_FORMDATA_FAILURE,
  SET_FORM_NAME_OPTIONS,
  CREATE_NEW_FORM_DATA,
  POST_FORMDATA_BEGIN,
  POST_FORMDATA_SUCCESS,
  POST_FORMDATA_FAILURE,
  DELETE_FORM_BEGIN,
  DELETE_FORM_FAILURE,
  DELETE_FORM_SUCCESS,
} from "../actions/DynamicFormActions/GetDynamicFormsAction";

import {
  GET_MODELS_NAMES_BEGIN,
  GET_MODELS_NAMES_FAILURE,
  GET_MODELS_NAMES_SUCCESS,
  GET_SCHEMA_NAMES_BEGIN,
  GET_SCHEMA_NAMES_FAILURE,
  GET_SCHEMA_NAMES_SUCCESS,
  GET_OPTIONS_BEGIN,
  GET_OPTIONS_FAILURE,
  GET_OPTIONS_SUCCESS,
  GET_SCHEMA_NAMES_TWO_BEGIN,
  GET_SCHEMA_NAMES_TWO_FAILURE,
  GET_SCHEMA_NAMES_TWO_SUCCESS,
  CHECK_FORM_NAME_BEGIN,
  CHECK_FORM_NAME_FAILURE,
  CHECK_FORM_NAME_SUCCESS,
  CLEAR_FORM_NAME,
} from "../actions/DynamicFormActions/GetDynamicFormsNamesAction";

const initstate = {
  loading: false,
  loading_entity: false,
  formList: null,
  error: null,
  postResponse: null,
  options: [],
  formData: null,
  newPostLoading: false,
  postError: null,
  deleteResponse: [],
  modelNames: [],
  schemaNames: [],
  schemaNamesTwo: [],
  optionsNames: [],
  checkName: null,
};

export default (state = initstate, action) => {
  switch (action.type) {
    case GET_FORMDATA_BEGIN:
      return {
        ...state,
        loading: true,
        formList: null,
        error: null,
      };

    case GET_FORMDATA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        formList: action.payload,
      };

    case GET_FORMDATA_FAILURE:
      return {
        ...state,
        loading: false,
        formList: null,
        error: action.payload.error,
      };

    case SET_FORM_NAME_OPTIONS:
      return {
        ...state,
        options: action.payload,
      };
    case CREATE_NEW_FORM_DATA:
      return {
        ...state,
        formData: action.payload.data,
      };
    case POST_FORMDATA_BEGIN:
      return {
        ...state,
        newPostLoading: true,
      };
    case POST_FORMDATA_SUCCESS:
      return {
        ...state,
        newPostLoading: false,
        postResponse: action.payload,
      };
    case POST_FORMDATA_FAILURE:
      return {
        ...state,
        newPostLoading: false,
        postError: "Failed to create new form",
      };
    case DELETE_FORM_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_FORM_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteResponse: action.payload,
      };
    case DELETE_FORM_FAILURE:
      return {
        ...state,
        loading: false,
        deleteResponse: [],
        error: action.payload.error,
      };
    case GET_MODELS_NAMES_BEGIN:
      return {
        ...state,
        loading_entity: true,
        error: null,
      };
    case GET_MODELS_NAMES_SUCCESS:
      return {
        ...state,
        loading_entity: false,
        modelNames: action.payload,
      };
    case GET_MODELS_NAMES_FAILURE:
      return {
        ...state,
        loading_entity: false,
        modelNames: [],
        error: action.payload.error,
      };
    case GET_SCHEMA_NAMES_BEGIN:
      return {
        ...state,
        loading_entity: true,
        error: null,
      };
    case GET_SCHEMA_NAMES_SUCCESS:
      return {
        ...state,
        loading_entity: false,
        schemaNames: action.payload,
      };
    case GET_SCHEMA_NAMES_FAILURE:
      return {
        ...state,
        loading_entity: false,
        schemaNames: [],
        error: action.payload.error,
      };
    case GET_SCHEMA_NAMES_TWO_BEGIN:
      return {
        ...state,
        loading_entity: true,
        error: null,
      };
    case GET_SCHEMA_NAMES_TWO_SUCCESS:
      return {
        ...state,
        loading_entity: false,
        schemaNamesTwo: action.payload,
      };
    case GET_SCHEMA_NAMES_TWO_FAILURE:
      return {
        ...state,
        loading_entity: false,
        schemaNamesTwo: [],
        error: action.payload.error,
      };
    case GET_OPTIONS_BEGIN:
      return {
        ...state,
        loading_entity: true,
        error: null,
      };
    case GET_OPTIONS_SUCCESS:
      return {
        ...state,
        loading_entity: false,
        optionsNames: action.payload,
      };
    case GET_OPTIONS_FAILURE:
      return {
        ...state,
        loading_entity: false,
        optionsNames: [],
        error: action.payload.error,
      };
    case CHECK_FORM_NAME_BEGIN:
      return {
        ...state,
        loading_entity: true,
        error: null,
      };
    case CHECK_FORM_NAME_SUCCESS:
      return {
        ...state,
        loading_entity: false,
        checkName: action.payload,
      };
    case CHECK_FORM_NAME_FAILURE:
      return {
        ...state,
        loading_entity: false,
        checkName: null,
        error: action.payload.error,
      };
    case CLEAR_FORM_NAME:
      return {
        ...state,
        checkName: null,
      };
    default:
      return state;
  }
};
