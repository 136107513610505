import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getAllTemplates, alphabetFilter } from '../../actions/template'
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { bloburl } from '../../constants/Constants'
import { useHistory } from 'react-router-dom';
import { TemplateTypesList } from '../../actions/templatetypes'
import Spinner from '../layout/Spinner'
import BlurImageLoader from 'react-blur-image-loader';
import Select from 'react-select';
import template from '../../reducers/template';
import Pagination from "react-pagination-js";
import _ from "lodash";
import br_image from '../../images/default.png';
export const DataRecognition = () => {

    const userData = JSON.parse(localStorage.getItem("userDetails"));
    let history = useHistory();
    const dispatch = useDispatch();

    const { templates, error, data, loading, activated, templatetypesloading, templatetypes } = useSelector((state) => ({
        templates: state.template.templates,
        error: state.template.error,
        loading: state.template.loading,
        templatetypesloading: state.TemplateTypesReducer.templatetypesloading,
        templatetypes: state.TemplateTypesReducer.templatetypes,
        data: state.TemplateTypesReducer.data,
        activated: state.TemplateTypesReducer.activated,
        error: state.TemplateTypesReducer.error,
    }), shallowEqual);

    console.log('templates: ', templates)

    const templateBase64Array = templates?.map((each) => {
        return {
            base64: `data:${each.mimeType};base64,${each.base64}`,
            templateName: each?.name,
            documentId: each?.id,
        }
    })


    // console.log('templateBase64Array: ', templateBase64Array)

    const [dot1, setDot1] = useState(false)
    const [name, setname] = useState("acsending")
    const [search, setsearch] = useState([])
    const [industry, setindustry] = useState("")
    const [suggestions, setsuggestins] = useState([])
    const [typesData, setType] = useState({
        typename: "recent",
        namefilter: "ascending",
        subtype: " ",
        countrySelected: " ",
        pageNumber: 1
    })
    const [toggleTab, setToggleTab] = useState(1);
    const [searchinput, setSearch] = useState("")
    const [searchinputtext, setsearchInputtext] = useState("");
    const [pageNumber, setCurrentpage] = useState(1)
    useEffect(() => {
        // dispatch(getAllTemplates(typesData))
        // dispatch(TemplateTypesList())
        dispatch(getAllTemplates())
    }, []);


    /*---function for tab toggling and highlighting start-----*/

    const toggleActive = (index) => {
        // console.log('inside toggleActive: ', document.getElementById("Name"))
        // if (index !== 1) {
        //     document.getElementById("Name").style.visibility = 'visible';
        // }
        // else {
        //     document.getElementById("Name").style.visibility = 'hidden';
        // }
        // setname("")
        // setToggleTab(index)
        // setSearch("")
        // setsearchInputtext("")
        // setCurrentpage(1)

    }
    /*---function for tab toggling and highlighting end-----*/

    /*---function for template sub type filtering start-----------*/
    const onTypeClick = (e) => {

        if (e.target.value !== " ") {
            let searchInput = document.getElementById("searchInputData").value;
            let selectedTypeName = searchInput === "" ? document.getElementsByClassName("activeTab")[0].classList[0] : "Search";
            let selectedNamefilter = document.getElementById("Name").value;
            let selectedCountry = document.getElementById("country").value;
            dispatch(getAllTemplates({ "typename": selectedTypeName, "searchInput": searchInput, "namefilter": selectedNamefilter, subtype: e.target.value, countrySelected: selectedCountry, pageNumber: 1 }))
        }

    }

    /*---function for template sub type filtering end-----------*/

    /*----function for template type filtering start--------*/

    const templateSelect = async (e) => {
        // let selectedTemplate = e.target.className
        // setType({
        //     typename: e.target.className
        // })
        // document.getElementById("searchInputData").value = "";
        // let selectedNamefilter = document.getElementById("Name").value;
        // let selectedType = document.getElementById("subtype").value;
        // let selectedCountry = document.getElementById("country").value;
        // dispatch(getAllTemplates({ "typename": selectedTemplate, "searchInput": "", "namefilter": selectedNamefilter, subtype: selectedType, countrySelected: selectedCountry, pageNumber: 1 }))

    }
    /*---function for template type filtering end-----------*/

    const onDot1click = () => {
        setDot1(!dot1)
    }

    /*---function for template name sorting start-----------*/
    const nameselect = (e) => {
        setType({
            namefilter: e.target.value
        })
        let searchInput = document.getElementById("searchInputData").value;
        let selectedTypeName = searchInput === "" ? document.getElementsByClassName("activeTab")[0].classList[0] : "Search";
        let selectedType = document.getElementById("subtype").value;
        let selectedCountry = document.getElementById("country").value;
        dispatch(getAllTemplates({ "typename": selectedTypeName, "searchInput": searchInput, "namefilter": e.target.value, subtype: selectedType, countrySelected: selectedCountry, pageNumber: 1}))
    }
    /*---function search by input..........................*/
    // const Search = (e) => {

    //     const regex = new RegExp(`\\b${e.target.value}`, "i");

    //     setsearchInputtext(e.target.value)
    //     setSearch(regex)
    // }

    /*------------ function for search start---------------*/
    const handleSearch = () => {
        // document.getElementById("Name").style.visibility = 'visible';
        // setToggleTab(2);
        // let searchInput = document.getElementById("searchInputData").value;
        // let selectedCountry = document.getElementById("country").value;
        // let selectedNamefilter = document.getElementById("Name").value;
        // if (templatetypes.some((item) => item.typename === searchInput)) {
        //     document.getElementById("subtype").value = searchInput;
        //     dispatch(getAllTemplates({ "typename": "Search", "searchInput": searchInput, "namefilter": selectedNamefilter, "subtype": searchInput, "countrySelected": selectedCountry, "pageNumber": 1 }))
        // }
        // else {
        //     let selectedType = document.getElementById("subtype").value;
        //     dispatch(getAllTemplates({ "typename": "Search", "searchInput": searchInput, "namefilter": selectedNamefilter, "subtype": selectedType, "countrySelected": selectedCountry, "pageNumber": 1 }))
        // }
    }
    /*------------ function for search end---------------*/
    const nav_scan = (e) => {
        console.log('inside nav_scan: ', e)
        let Pages = []
        // Pages = e.pages
        // let ann = [];
        // for (let i = 0; i < Pages.length; i++) {
        //     ann.push(JSON.parse(Pages[i].annotation))
        // }
        history.push({ 
            pathname: "/uploadfile", 
            imageBase64Url: e.base64,
            documentId: e.documentId,
            // annotationJson: (ann), 
            // templates: e.template, 
            templatename: e.templateName, 
            // templateid: e.id, 
            // templateType: e.templateType
        });

    }

    const nav_temp_view = (e) => {
        history.push({ pathname: "/templatedetails", imageurl: e.imgUrl, annotationJson: JSON.parse(e.annotation), templates: e.template, templatename: e.templateName, templateid: e.id });
    }

    // const countryList = ["India", "United States of America"]

    // const onCountryClick = (e) => {
    //     // console.log(document.getElementsByClassName("activeTab"));
    //     let searchInput = document.getElementById("searchInputData").value;
    //     let selectedType = document.getElementById("subtype").value;
    //     let selectedTypeName = searchInput === "" ? document.getElementsByClassName("activeTab")[0].classList[0] : "Search";
    //     let selectedNamefilter = document.getElementById("Name").value;
    //     dispatch(getAllTemplates({ 
    //         "typename": selectedTypeName, 
    //         "searchInput": searchInput, 
    //         "namefilter": selectedNamefilter, 
    //         subtype: selectedType, 
    //         countrySelected: e.target.value, 
    //         pageNumber: pageNumber }))
    // }

    // /pagination
    const changeCurrentPage = async (number) => {
        // setCurrentpage(number);

        // let typesData = {
        //     typename: document.getElementsByClassName("activeTab")[0].classList[0],
        //     searchInput: document.getElementById("searchInputData").value,
        //     namefilter: document.getElementById("Name").value,
        //     subtype: document.getElementById("subtype").value,
        //     countrySelected: document.getElementById("country").value,
        //     pageNumber: number,

        // };
        // dispatch(getAllTemplates(typesData))
    }
    return (
        <>
            <div className="app-content container1" style={{backgroundColor: '#e1e2e2'}}>
                <div className="breadcrumbmaindiv">
                    Choose Template
                </div>
                <div className="page-header mb-3">
                    <h3>
                        Data Recognition</h3 > <span className="page-subtitle " >Upload your files containing data to be extracted and we will do the rest.</span>
                </div>

                <div className="row d-flex">
                    <div className="col-xl-4">
                        <div className="menu-search ">
                            <div className="menu-search-icon" style={{ color: "#a2a5ab" }}><i className="fa fa-search"></i></div>
                            <div className="menu-search-input">
                                <input type="text" className="form-control" id="searchInputData" placeholder="Search here..." />
                            </div>

                        </div>
                    </div>
                    <button className="dataRecSearchBtn" onClick={() => handleSearch()}>Search</button>
                </div>

                <div className="Sspace"></div>
                {/* <div className="filtermaindiv">
                    <div className="row">
                        <div className="col-sm-2">
                            <div className="addusermaindiv">
                                <div className="userinputdiv">
                                    <select className="nuocrinputText" id="country" onChange={(e) => onCountryClick(e)}>
                                        <option selected value="">All Countries</option>
                                        {countryList && countryList.map(item =>

                                            <option>{item}</option>

                                        )}

                                    </select>
                                </div>
                            </div>

                        </div>

                        <div className="col-sm-2">
                            <div className="addusermaindiv">

                                <div className="userinputdiv"
                                    style=
                                    {
                                        { float: 'left' }
                                    }
                                >
                                    <select className="nuocrinputText" id="subtype" onChange={(e) => onTypeClick(e)}>
                                        <option selected value="">All Industries</option>
                                        {templatetypes && templatetypes.map(item =>

                                            <option>{item.typename}</option>

                                        )}


                                    </select>

                                </div>

                            </div>
                        </div >

                        <div className="col-sm-2">
                            <div className="addusermaindiv">

                                <div className="userinputdiv">
                                    <select className="nuocrinputText" id="Name" onChange={(e) => nameselect(e)}>
                                        <option value="ascending">Name (A-Z)</option>
                                        <option value="descending">Name (Z-A)</option>
                                    </select>
                                </div>

                            </div>
                        </div>

                    </div>

                </div> */}
                <div className="templatemaindiv">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="hometabdiv">
                                <ul>
                                    {/* <li onClick={() => toggleActive(1)}><a href="#" onClick={(e) => templateSelect(e)} className={toggleTab === 1 ? "recent activeTab" : "recent"}>Recent</a></li> */}
                                    {/* <li onClick={() => toggleActive(2)}><a href="#" onClick={(e) => templateSelect(e)} className={toggleTab === 2 ? "all activeTab" : "all"}>All</a></li> */}
                                    <li onClick={() => toggleActive(3)}><a href="#" onClick={(e) => templateSelect(e)} className={toggleTab === 3 ? "myTemplates activeTab" : "myTemplates"}>My Templates</a></li>
                                    {/* <li onClick={() => toggleActive(4)}><a href="#" onClick={(e) => templateSelect(e)} className={toggleTab === 4 ? "ocraTemplates activeTab" : "ocraTemplates"}>OCRA Templates</a></li> */}
                                </ul>

                            </div>

                        </div>
                    </div>



                    <div className="row" >


                        {/* search input mapping starts...................*/}
                        {(templates === null) ?
                            <>
                                <div className="text-center" style={{ fontSize: "12px", width: "100%", margin: "100px auto auto auto" }}>

                                    <span>Please wait...</span>
                                    <span>Do not press refresh or back button</span>
                                    <br />
                                    <br />
                                    <div><Spinner /></div>
                                </div>

                            </> :
                            <>
                                {(templates?.length === 0) ?
                                    <div className="text-center" style={{ fontSize: "12px", width: "100%", margin: "50px auto 50px auto" }}>
                                        <span style={{ fontWeight: "bold", marginTop: "100px", fontSize: "12px", marginLeft: "100px" }}>No templates available for this user!</span>

                                    </div> :
                                    templateBase64Array?.map((item, value) =>
                                        <div className="col-sm-3">
                                            <div className="card-body" style={{ display: "inline" }}>
                                                <div className="tempcard" 
                                                    // /*onClick={() => nav_scan(item)}*/
                                                    onClick={()=>{
                                                        console.log('clicked template '+value);
                                                        nav_scan(item)}}
                                                    >
                                                    <BlurImageLoader
                                                        src={item.base64}
                                                        preview={br_image}
                                                        fullCover={false}
                                                        maxBlurLevel={1}
                                                        transitionTime={400}
                                                    />
                                                    {/* <img src={item} alt="" style={{ width: "280px", height: "350px" }} /> */}
                                                </div>

                                                <div className="temdesc">
                                                    <label className="mb-0" style={{ fontSize: "13px", fontWeight: "bold" }} onClick={() => nav_scan(item)}>
                                                        {item.templateName}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                            </>
                        }
                        {/* search input mapping ends...................*/}
                    </div>
                    {/* pagination starts*/}

                    <div id="pagination-documentSearchResult">
                        {!_.isEmpty(templates) && typesData.typename !== "recent" && templates.allTemplates.length !== 0 ? (
                            <div id="document-pagination">
                                <Pagination
                                    currentPage={pageNumber}
                                    totalSize={templates.total}
                                    sizePerPage={12}
                                    firstPageText="|⟨"
                                    lastPageText="⟩|"
                                    showFirstLastPages={true}
                                    changeCurrentPage={changeCurrentPage}
                                    theme="border-bottom"
                                />
                            </div>
                        ) : null}
                    </div>
                    {/* pagination ends*/}

                </div>

            </div>


        </>
    )

}

export default DataRecognition