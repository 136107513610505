// import { post, get } from "../../services/Http";
import axios from 'axios';

export const GET_MODELS_NAMES_BEGIN = "GET_MODELS_NAMES_BEGIN";
export const GET_MODELS_NAMES_SUCCESS = "GET_MODELS_NAMES_SUCCESS";
export const GET_MODELS_NAMES_FAILURE = "GET_MODELS_NAMES_FAILURE";

export const GET_SCHEMA_NAMES_BEGIN = "GET_SCHEMA_NAMES_BEGIN";
export const GET_SCHEMA_NAMES_SUCCESS = "GET_SCHEMA_NAMES_SUCCESS";
export const GET_SCHEMA_NAMES_FAILURE = "GET_SCHEMA_NAMES_FAILURE";

export const GET_SCHEMA_NAMES_TWO_BEGIN = "GET_SCHEMA_NAMES_TWO_BEGIN";
export const GET_SCHEMA_NAMES_TWO_SUCCESS = "GET_SCHEMA_NAMES_TWO_SUCCESS";
export const GET_SCHEMA_NAMES_TWO_FAILURE = "GET_SCHEMA_NAMES_TWO_FAILURE";

export const GET_OPTIONS_BEGIN = "GET_OPTIONS_BEGIN";
export const GET_OPTIONS_SUCCESS = "GET_OPTIONS_SUCCESS";
export const GET_OPTIONS_FAILURE = "GET_OPTIONS_FAILURE";

export const CHECK_FORM_NAME_BEGIN = "CHECK_FORM_NAME_BEGIN";
export const CHECK_FORM_NAME_SUCCESS = "CHECK_FORM_NAME_SUCCESS";
export const CHECK_FORM_NAME_FAILURE = "CHECK_FORM_NAME_FAILURE";

export const CLEAR_FORM_NAME = "CLEAR_FORM_NAME";

export const getModelNamesBegin = () => ({
  type: GET_MODELS_NAMES_BEGIN,
});

export const getModelNamesSuccess = (data) => ({
  type: GET_MODELS_NAMES_SUCCESS,
  payload: data,
});

export const getModelNamesFailure = (error) => ({
  type: GET_MODELS_NAMES_FAILURE,
  payload: { error },
});

export const getSchemaNamesBegin = () => ({
  type: GET_SCHEMA_NAMES_BEGIN,
});

export const getSchemaNamesSuccess = (data) => ({
  type: GET_SCHEMA_NAMES_SUCCESS,
  payload: data,
});

export const getSchemaNamesFailure = (error) => ({
  type: GET_SCHEMA_NAMES_FAILURE,
  payload: { error },
});

export const getSchemaNamesTwoBegin = () => ({
  type: GET_SCHEMA_NAMES_TWO_BEGIN,
});

export const getSchemaNamesTwoSuccess = (data) => ({
  type: GET_SCHEMA_NAMES_TWO_SUCCESS,
  payload: data,
});

export const getSchemaNamesTwoFailure = (error) => ({
  type: GET_SCHEMA_NAMES_TWO_FAILURE,
  payload: { error },
});

export const getOptionsBegin = () => ({
  type: GET_OPTIONS_BEGIN,
});

export const getOptionsSuccess = (data) => ({
  type: GET_OPTIONS_SUCCESS,
  payload: data,
});

export const getOptionsFailure = (error) => ({
  type: GET_OPTIONS_FAILURE,
  payload: { error },
});

export const checkFormNameBegin = () => ({
  type: CHECK_FORM_NAME_BEGIN,
});

export const checkFormNameSuccess = (data) => ({
  type: CHECK_FORM_NAME_SUCCESS,
  payload: data,
});

export const checkFormNameError = (error) => ({
  type: CHECK_FORM_NAME_FAILURE,
  payload: { error },
});

export const clearFormName = () => ({
  type: CLEAR_FORM_NAME
})

export function getModelNames() {
  return (dispatch) => {
    dispatch(getModelNamesBegin());
    return axios.get("/dynamic-forms/get/models")
      // .then(handleErrors)
      // .then((res) => res.json())
      .then((json) => {
        dispatch(getModelNamesSuccess(json));
      })
      .catch((error) => {
        dispatch(getModelNamesFailure(error));
      });
  };
}

export function getSchemaNames(data) {
  return (dispatch) => {
    dispatch(getSchemaNamesBegin());
    return axios.post("/dynamic-forms/get_schema", data)
      // .then(handleErrors)
      // .then((res) => res.json())
      .then((json) => {
        dispatch(getSchemaNamesSuccess(json));
      })
      .catch((error) => {
        dispatch(getSchemaNamesFailure(error));
      });
  };
}

export function getSchemaNamesTwo(data) {
  return (dispatch) => {
    dispatch(getSchemaNamesTwoBegin());
    return axios.post("/dynamic-forms/get_schema", data)
      // .then(handleErrors)
      // .then((res) => res.json())
      .then((json) => {
        dispatch(getSchemaNamesTwoSuccess(json));
      })
      .catch((error) => {
        dispatch(getSchemaNamesTwoFailure(error));
      });
  };
}

export function getOptionNames(data) {
  return (dispatch) => {
    dispatch(getOptionsBegin());
    return axios.post("/dynamic-forms/get_options", data)
      // .then(handleErrors)
      // .then((res) => res.json())
      .then((json) => {
        dispatch(getOptionsSuccess(json));
      })
      .catch((error) => {
        dispatch(getOptionsFailure(error));
      });
  };
}

export function checkFormName(data) {
  return (dispatch) => {
    dispatch(checkFormNameBegin());
    return axios.post("/dynamic-forms/check-form-name", data)
      // .then(handleErrors)
      // .then((res) => res.json())
      .then((json) => {
        dispatch(checkFormNameSuccess(json));
      })
      .catch((error) => {
        dispatch(checkFormNameError(error));
      });
  };
}

export function clearNameState() {
  return (dispatch) => {
    dispatch(clearFormName());
  }
}

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}
