import React, { useState, useEffect, useRef } from "react";
import "./chat.css"; // Import CSS file for styling
import SendIcon from "@mui/icons-material/Send";
import { Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import dummyProfile from "../../../images/Group1857.png";
import botAvatar from "../../../images/botimage.jpg";
import LinearProgress from "@mui/material/LinearProgress";

// Chat component for displaying messages and sending new messages
const ChatBotClientComponent = ({ messages, onSendMessage }) => {
  console.log("messages", messages);
  const messagesEndRef = useRef(null);
  const [newMessage, setNewMessage] = useState();
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);
  // Function to handle sending new messages

  const handleSendMessage = () => {
    if (newMessage.trim() !== "") {
      // Call the onSendMessage function with the new message
      onSendMessage(newMessage, "user");
      setNewMessage("");
    }
  };

  const parseMessage = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.split(urlRegex).map((part, index) =>
      urlRegex.test(part) ? (
        <span key={index}>
          <a href={part} target="_blank" rel="noopener noreferrer">Click here</a> for more info.
        </span>
      ) : (
        part
      )
    );
  };

  return (
    <div className="chat-window">
      {/* Heading for the chat window */}
      <div
        style={{
          backgroundColor: "#007bff",
          borderRadius: "0 0 0 0",
          padding: "10px 0",
          textAlign: "center",
          marginBottom: "25px",
        }}
      >
        <Typography variant="h5" style={{ margin: "0", color: "#fff" }}>
          Chat Box
        </Typography>
      </div>
      {/* Chat messages container */}
      <div className="chat-messages">
        {messages.map((message, index) => (
          <div
            key={index}
            className={`message-container ${message.sender === "user" ? "user-message" : "bot-message"
              } ${message.text === "" ? "empty" : ""}`} // Add 'empty' class if text is empty
          >
            {/* Avatar */}
            {message.sender === "user" ?
              <>
                <div className="avatar-container">
                  <Avatar
                    src={message.sender === "user" ? dummyProfile : botAvatar}
                    alt="Avatar"
                  />
                </div>

                <div className="message-content">
                  {parseMessage(message.text)}
                </div></> :
              <>

                <div className="message-content">
                  {parseMessage(message.text)}
                </div>
                <div className="avatar-container">
                  <Avatar
                    src={message.sender === "user" ? dummyProfile : botAvatar}
                    alt="Avatar"
                  />

                </div>
              </>}

          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
      {/* Text input field for typing messages */}
      <div className="input-container" style={{ marginBottom: "15px" }}>
        {/* Text input field for typing messages */}
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              handleSendMessage();
            }
          }}
          placeholder="Type your message..."
          className="message-input"
          style={{
            borderRadius: "10px", border: "#8ff0f3", '::placeholder': {
              color: '#20c997', // Change placeholder color here
            }
          }}
        />
        {/* Send button to send messages */}
        <button onClick={handleSendMessage} className="send-button">
          <SendIcon style={{ fontSize: "20px" }} />{" "}
          {/* Adjust the fontSize value as needed */}
        </button>
      </div>
    </div>
  );
};

export default ChatBotClientComponent;
