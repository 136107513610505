import axios from 'axios';
import { toast } from "react-toastify";

export const EMAIL_SUCCESS = "EMAIL_SUCCESS";
export const EMAIL_BEGIN = "EMAIL_BEGIN";
export const EMAIL_FAILED = "EMAIL_FAILED";
export const ADD_SUCCESS = "ADD_SUCCESS";
export const ADD_BEGIN = "ADD_BEGIN";
export const ADD_FAILED = "ADD_FAILED";
export const CONFIG_ADD_SUCCESS = "CONFIG_ADD_SUCCESS";
export const CONFIG_ADD_BEGIN = "CONFIG_ADD_BEGIN";
export const CONFIG_ADD_FAILED = "CONFIG_ADD_FAILED";
export const CONFIG_SUCCESS = "CONFIG_SUCCESS";
export const CONFIG_BEGIN = "CONFIG_BEGIN";
export const CONFIG_FAILED = "CONFIG_FAILED";
export const STATUS_EDIT_SUCCESS = "STATUS_EDIT_SUCCESS";
export const STATUS_EDIT_BEGIN = "STATUS_EDIT_BEGIN";
export const STATUS_EDIT_FAILED = "STATUS_EDIT_FAILED";
export const CONFIRM_SUCCESS = "CONFIRM_SUCCESS";
export const CONFIRM_BEGIN = "CONFIRM_BEGIN";
export const CONFIRM_FAILED = "CONFIRM_FAILED";
export const DELETE_SUCCESS = "DELETE_SUCCESS";
export const DELETE_BEGIN = "DELETE_BEGIN";
export const DELETE_FAILED = "DELETE_FAILED";
export const TEMPLATE_SUCCESS = "TEMPLATE_SUCCESS";
export const TEMPLATE_BEGIN = "TEMPLATE_BEGIN";
export const TEMPLATE_FAILED = "TEMPLATE_FAILED";


export const AddToemaillist = (data) => async dispatch => {


    try {
        dispatch({
            type: ADD_BEGIN
        })

        const res = await axios.post('/userlist/addemails', data)

        dispatch({
            type: ADD_SUCCESS,
            payload: res.data
        })
        // toast.success(res.data.message, { autoClose: 6000 })
        res.data.message==="success"?toast.success(res.data.success,{autoClose:4000}):toast.error(res.data.error,{autoClose:4000})
    } catch (err) {

        dispatch({
            type: ADD_FAILED,
            payload: err
        })
        toast.error("failed to add new email Please try again", { autoClose: 6000 })
    }

}



export const emailconfiglist = (data) => async dispatch => {


    try {
        dispatch({
            type: EMAIL_BEGIN
        })

        const res = await axios.post('/userlist/emails', data)

        dispatch({
            type: EMAIL_SUCCESS,
            payload: res.data
        })

    } catch (err) {

        dispatch({
            type: EMAIL_FAILED,
            payload: err
        })
    }

}
export const AddToConfigList = (data) => async dispatch => {


    try {
        dispatch({
            type: CONFIG_ADD_BEGIN
        })

        const res = await axios.post('/userlist/addconfigurations', data)

        dispatch({
            type: CONFIG_ADD_SUCCESS,
            payload: res.data
        })
        toast.success(res.data.message, { autoClose: 6000 })
    } catch (err) {

        dispatch({
            type: CONFIG_ADD_FAILED,
            payload: err
        })
        toast.error("failed to add new configuration Please try again", { autoClose: 4000 })
    }

}

export const Configlist = (data) => async dispatch => {


    try {
        dispatch({
            type: CONFIG_BEGIN
        })

        const res = await axios.post('/userlist/listconfigs', data)

        dispatch({
            type: CONFIG_SUCCESS,
            payload: res.data
        })


    } catch (err) {

        dispatch({
            type: CONFIG_FAILED,
            payload: err
        })
    }

}

export const EDITSTATUS = (data) => async dispatch => {


    try {
        dispatch({
            type: STATUS_EDIT_BEGIN
        })

        const res = await axios.post('/userlist/connectionstatus', data)

        dispatch({
            type: STATUS_EDIT_SUCCESS,
            payload: res.data
        })
        toast.success(res.data.message, { autoClose: 6000 })
      
    } catch (err) {

        dispatch({
            type: STATUS_EDIT_FAILED,
            payload: err
        })
        toast.error("failed to edit status Please try again", { autoClose: 4000 })
    }

}


export const Confirm = (data) => async dispatch => {


    try {
        dispatch({
            type: CONFIRM_BEGIN
        })

        const res = await axios.post('/userlist/connectionconfirm', data)

        dispatch({
            type: CONFIRM_SUCCESS,
            payload: res.data
        })
        toast.success(res.data.message, { autoClose: 6000 })
    } catch (err) {

        dispatch({
            type: CONFIRM_FAILED,
            payload: err
        })
        toast.error("failed to edit status Please try again", { autoClose: 4000 })
    }

}





export const DELETECONFIG = (data) => async dispatch => {


    try {
        dispatch({
            type: DELETE_BEGIN
        })

        const res = await axios.post('/userlist/delete/configuration', data)

        dispatch({
            type: DELETE_SUCCESS,
            payload: res.data
        })
        toast.success(res.data.message, { autoClose: 6000 })
        // res.data.error?toast.error(res.data.error): toast.success(res.data.message,{autoClose:6000})
    } catch (err) {

        dispatch({
            type: DELETE_FAILED,
            payload: err
        })
        toast.error("failed to delete configuration Please try again", { autoClose: 4000 })
    }

}


export const Templatelist = (data) => async dispatch => {


    try {
        dispatch({
            type: TEMPLATE_BEGIN
        })

        const res = await axios.post('/userlist/templatelist', data)
       

        dispatch({
            type: TEMPLATE_SUCCESS,
            payload: res.data.template
              
        })


    } catch (err) {

        dispatch({
            type: TEMPLATE_FAILED,
            payload: err
        })
    }

}