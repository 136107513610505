import axios from "axios";
import { useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

export const INDUSTRY_TEMPLATE_LOAD = "INDUSTRY_TEMPLATE_LOAD";
export const GET_OWNTEMPLATE_DETAILS_BEGIN = 'GET_OWNTEMPLATE_DETAILS_BEGIN';
export const GET_OWNTEMPLATE_DETAILS_SUCCESS = 'GET_OWNTEMPLATE_DETAILS_SUCCESS';
export const GET_OWNTEMPLATE_DETAILS_FAILURE = 'GET_OWNTEMPLATE_DETAILS_FAILURE';

export const GET_ALL_TEMPLATES_BEGIN = 'GET_ALL_TEMPLATES_BEGIN';
export const GET_ALL_TEMPLATES_SUCCESS = 'GET_ALL_TEMPLATES_SUCCESS';
export const GET_ALL_TEMPLATES_FAILURE = 'GET_ALL_TEMPLATES_FAILURE';

export const TEMPLATE_DETAILS_BEGIN = 'TEMPLATE_DETAILS_BEGIN';
export const TEMPLATE_DETAILS_SUCCESS = 'TEMPLATE_DETAILS_SUCCESS';
export const TEMPLATE_DETAILS_FAILURE = 'TEMPLATE_DETAILS_FAILURE';



export const getOwnTemplates = () => async dispatch => {
    
    try {
        const res = await axios.get('/templates/getowntemplates');
        dispatch({
            type: GET_OWNTEMPLATE_DETAILS_SUCCESS,
            payload: res.data
        })
    } catch (err) {
        dispatch({
            type: GET_OWNTEMPLATE_DETAILS_FAILURE,
            payload: err
        })
    }
}

export const getIndustryTemplate = () => async dispatch => {
    try {
        const res = await axios.get('/templates/getIndustryTemplates');
        dispatch({
            type: INDUSTRY_TEMPLATE_LOAD,
            payload: res.data
        })
    } catch (err) {
        console.error(err);
    }
}

export const getAllTemplates = () => async dispatch => {
    
    const urlAllTemplates = `${process.env.REACT_APP_DOCKETRY_DEV_BASE_URL}/template/fetch_template`
    // const urlAllTemplates = `http://20.230.189.213:8000/template/fetch_template`   //VM address shared by Jigar    
        // const urlAllTemplates = `https://104.43.211.217:8000/template/fetch_template`   //VM address shared by Jigar
        // const urlAllTemplates = `https://nuocr-api.azurewebsites.net/template/fetch_template`
        const tokenToSend = await JSON.parse(localStorage.getItem("token"))
    console.log('inside getAllTemplates: ', urlAllTemplates, {headers: {"X-Access-Token": tokenToSend}})
    try {
        dispatch({
                type: GET_ALL_TEMPLATES_BEGIN,
                
            })
        const res = await axios.get(urlAllTemplates, {headers: {"X-Access-Token": tokenToSend}})
        // const res =  await axios.post('/templates/getAllTemplates', data)
        console.log('response fetch_template: ', res)
        if (res.status === 200) {
            dispatch({
                type: GET_ALL_TEMPLATES_SUCCESS,
                payload: res.data
            })
                }
                // else {
                //     dispatch({
                //         type: GET_ALL_TEMPLATES_SUCCESS,
                //         payload: null
                //     })
                // }
    }catch(err){

        dispatch({
                    type: GET_ALL_TEMPLATES_FAILURE,
                    payload: err
                })

    }
}

export const alphabetFilter = (data) => async dispatch => {
    dispatch({
        type: GET_ALL_TEMPLATES_SUCCESS,
        payload: data
    })
}


export const getTemplateDetails = (id) => async dispatch => {
    dispatch({
        type: TEMPLATE_DETAILS_BEGIN,
        payload: null
    })
    await axios.get(`/templatedetails/${id}`).then((res) => {
        dispatch({
            type: TEMPLATE_DETAILS_SUCCESS,
            payload: res.data.data.templateDetails
        })

    }).catch((err) => {
        dispatch({
            type: TEMPLATE_DETAILS_FAILURE,
            payload: err
        })
    })


}