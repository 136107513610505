import {
  INDUSTRY_TEMPLATE_LOAD,
  GET_OWNTEMPLATE_DETAILS_BEGIN,
  GET_OWNTEMPLATE_DETAILS_SUCCESS,
  GET_OWNTEMPLATE_DETAILS_FAILURE,
  GET_ALL_TEMPLATES_BEGIN,
  GET_ALL_TEMPLATES_SUCCESS,
  GET_ALL_TEMPLATES_FAILURE,                            
  TEMPLATE_DETAILS_SUCCESS,
  TEMPLATE_DETAILS_FAILURE,
  TEMPLATE_DETAILS_BEGIN

} from "../actions/template";

const initialState = {
  errorIndustryTemplates: null,
  errorOwnTemplates: null,
  industryTemplates: null,
  ownTemplates: [],
  templates:null,
  error:null,
  templateDetails:null,
  templatesDetailError:null,
  loading: false
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_OWNTEMPLATE_DETAILS_BEGIN:
      return {
        ...state,
        errorOwnTemplates: null,
      };

    case GET_OWNTEMPLATE_DETAILS_SUCCESS:
      return {
        ...state,
        ownTemplates: action.payload.finalResult,
      };

    case GET_OWNTEMPLATE_DETAILS_FAILURE:
      return {
        ...state,
        errorOwnTemplates: action.payload.error,
      };
    case INDUSTRY_TEMPLATE_LOAD:
      return {
        ...state,
        industryTemplates: payload,
      };
      case GET_ALL_TEMPLATES_BEGIN:
        return {
          ...state,
          loading : true,
          templates: null
        };
        case GET_ALL_TEMPLATES_SUCCESS:
        return {
          ...state,
          templates: payload,
          loading : false
        };
  
      case GET_ALL_TEMPLATES_FAILURE:
        return {
          ...state,
          error: action.payload.error,
          loading: false
        };
        case TEMPLATE_DETAILS_BEGIN:
          return {
            ...state,
            templateDetails:action.payload
          };
        case TEMPLATE_DETAILS_SUCCESS:
          return {
            ...state,
            templateDetails: action.payload
          };
    
        case TEMPLATE_DETAILS_FAILURE:
          return {
            ...state,
            templateDetailError: action.payload,
          };
    default:
      return state;
  }
};
