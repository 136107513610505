import React, { Component, useState } from "react";
import Modal from "react-bootstrap/Modal";

import { getUserToken } from "../../services/AuthService";
import { connect } from "react-redux";

import { event } from "react-ga";

import moment from "moment";

import  notification from "../../images/notification.png";
//import Editnotification from "../public/AnnouncementEdit";
import "../../assets/css/navigation.css"
class Notification extends React.Component {
    state =
        {
            openAnnModal: false,
            setopenAnnModal: false,
            schools: [],
            schoolName: [],
            schoolGrades: [],
            createddate: null,
            createdby: null,
            title: null,
            enddate: null,
            fk_schools: [],
            grade: [],
            audience: [],
            descriptions: null,
            arr: [],
            viewresult: [],
            show: false,
            edititem: []
        }

    openmodal = (item) => {
        this.setState({
            show: true,
            setShow: true,
            edititem: item,
            
        })
    }
    closemodal = () => {
        this.setState({
            show: false,
            setShow: false
        })
    }


    openannouncement = () => {
        this.setState({
            openAnnModal: true,
            setopenAnnModal: true
        })

    }
    closeannouncement = () => {
        this.setState({
            openAnnModal: false,
            setopenAnnModal: false
        })
    }
    componentDidMount = () => {
        let school = getUserToken().schools
        let schoolId = [];
        // school.map((item) => {
        //     schoolId.push(item.fk_school)
        // })

        // const idObj1 = {
        //     fk_schools: schoolId[0]
        // }
        // this.props.dispatch(fetchAnnouncementViewInfo(idObj1));
        // this.props.dispatch(fetchSchoolGrades());
      
    };

    componentWillReceiveProps = (props) => {
        

        if (props.viewresult.length !== 0) {
            this.setState({
                viewresult: props.viewresult.msgCode,
            });
        }
    };

    render() {

        let listView = []
        if (this.state.viewresult.length !== 0) {
            this.state.viewresult.map((item) => {
                listView.push(
                    <a className="dropdown-item clearfix" href="#"><span onClick={() => this.openmodal(item)} className="head">{item.title}</span><br /> <p className="text-muted d-inline-block m-0"><small>{item.descriptions}</small></p><p className="text-muted inactive float-right m-0"><small> {moment(item.createdAt).format("DD MMM YYYY")} </small></p></a>
                )
            })
        } else {
            listView = <p className="ml-3"><b>No Records Found</b></p>
        }

        return (
            <>

                <a className="nav-link" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <img src={notification} alt="noun_notification_3376247" />
                </a>


                <div className="dropdown-menu notify-dropdown dropdown-menu-right" aria-labelledby="navbarDropdown">
               
           
                    <div className="top-div">
               
                        <h5 className="mb-1 font-weight-bold clearfix">Notifications<span onClick={this.openannouncement} data-toggle="modal" data-target="#addAnnouncement" className="float-right pt-1">+ Add new announcement</span></h5>
                    </div>
           
                    {listView}
                   
                </div>

                <Modal
                    className="modal common-modal fade"
                    show={this.state.openAnnModal}
                    onHide={this.state.setopenAnnModal}
                    dialogClassName="modal-190w"
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    {/* <Announcements closemodal={this.closeannouncement}>

                    </Announcements> */}
                </Modal>


                <Modal
                    className="modal common-modal fade"
                    show={this.state.show}
                    onHide={this.state.setShow}
                    dialogClassName="modal-190w"
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    {/* <Editnotification closemodalview={this.closemodal} edit={this.state.edititem} >

                    </Editnotification> */}
                </Modal>
            </>

        )
    }
}
const mapStateToProps = (state) => ({

    // schoolGrades: state.schoolGradesReducer.schoolGrades,
    // announcement: state.announcementInfoReducer.announcementresult,
    // viewresult: state.announcementInfoReducer.viewresult,
    // updateresult: state.announcementInfoReducer.updateresult

});
export default connect(mapStateToProps)(Notification)