import axios from 'axios';
import {setBaseUrl, setAuthToken} from '../utils/setAxiosConfig'
import { toast } from "react-toastify";
export const USER_LOADED = "USER_LOADED"
export const USER_UNLOAD = "USER_UNLOAD"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_BEGIN = "LOGIN_BEGIN";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGOUT = "LOGOUT";

export const loadUser = () => dispatch => {
    setBaseUrl();
    console.log('loadUser: ', localStorage.getItem("userDetails"))
    if(localStorage.getItem("token")) {
        let token = JSON.parse(localStorage.getItem("userDetails"))?.token;
        setAuthToken(token);
        dispatch({
            type: USER_LOADED
        })
    }
    else {
        dispatch({
            type: USER_UNLOAD
        })
    }
}

export const logout = () => dispatch => {
    dispatch({
        type: LOGOUT
    })
}

export const login = (data) => async dispatch => {
//   console.log('login payload: ', data)
    try {
        dispatch({
            type: LOGIN_BEGIN
        })
        // let url = 'https://nuocr-api.azurewebsites.net/login'     //cloud flask server URL 
        // let url = `https://aida-demo-api.azurewebsites.net/login`   //demo flask environment
        // let url = `http://104.43.211.217:8000/login`     // vm
        // let url = `http://20.230.189.213:8000/login`     // vm
        // let url = 'http://localhost:5000/login'                      //local flask server URL
        let url = `${process.env.REACT_APP_DOCKETRY_DEV_BASE_URL}/login`
        const res = await axios.post(url, data);
        // const res = await axios.post('/login', data);
       
        dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data
        })
     console.log('response after login: ', res.data)
        toast.success("Successfully Signed In",{autoClose:4000})
    } catch (err) {
       console.log('error in action auth: ', err)
        dispatch({
            type: LOGIN_FAILED,
            payload: err
        })
        toast.error(err?.response?.data?.error,{autoClose:4000})
      localStorage.clear();
      window.location.href = "/";
    }
}