import axios from 'axios';

export const USER_DETAILS_FETCH_BEGIN = 'USER_DETAILS_FETCH_BEGIN';
export const USER_DETAILS_FETCH_SUCCESS = 'USER_DETAILS_FETCH_SUCCESS';
export const USER_DETAILS_FETCH_FAILURE = 'USER_DETAILS_FETCH_FAILURE';
export const USER_ROLES_FETCH_BEGIN = 'USER_ROLES_FETCH_BEGIN';
export const USER_ROLES_FETCH_SUCCESS = 'USER_ROLES_FETCH_SUCCESS';
export const USER_ROLES_FETCH_FAILURE = 'USER_ROLES_FETCH_FAILURE';

export const getUserDetails = (id) => async (dispatch) => {
  console.log('getUserDetails id and dispatch: ', id, dispatch)
  
  try {
    dispatch({
      type: USER_DETAILS_FETCH_BEGIN,
    });
    // const res = await axios.get(`/dashboard/user/${id}`);
    const res = await JSON.parse(localStorage.getItem('userDetails')).userDetails
    console.log('res: ', res)
    dispatch({
      type: USER_DETAILS_FETCH_SUCCESS,
      // payload: res.data,
      payload: res
    });
  } catch (error) {
    console.log('error at axios', error);
    dispatch({
      type: USER_DETAILS_FETCH_FAILURE,
      payload: error.message,
    });
  }
};

export const getUserRoles = (id) => async (dispatch) => {
  console.log('getUserRoles id and dispatch: ', id, dispatch)
  try {
    dispatch({
      type: USER_ROLES_FETCH_BEGIN,
    });
    // const res = await axios.get(`/dashboard/user/roles/${id}`);
    const res = await JSON.parse(localStorage.getItem('userDetails')).userDetails
    dispatch({
      type: USER_ROLES_FETCH_SUCCESS,
      payload: res.role,
    });
  } catch (error) {
    console.log('error at axios', error);
    dispatch({
      type: USER_ROLES_FETCH_FAILURE,
      payload: error.message,
    });
  }
};
