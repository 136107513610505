import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
const FormPreview = (props) => {
  const [formData, setFormData] = useState(props.data);
  const handleNext = () => {
    props.preview();
    props.schedule();
  };
  const InputTypeElement = ({ data, index }) => {
    let type = data.type;
    if (type === "text" || type === "email") {
      return (
        <input
          type={type}
          className="form-control"
          placeholder={data.placeholder}
          name={data.label}
          required={data.required}
          disabled
        />
      );
    } else if (type === "date") {
      return (
        <DatePicker
          dateFormat="dd MMM yyyy"
          required={data.required}
          name={data.label}
          placeholderText={data.placeholder}
          className="form-control datepicker"
          disabled
        />
      );
    } else if (type === "file") {
      return (
        <div className="form-group">
          <input type={type} className="form-control-file" disabled />
        </div>
      );
    } else if (type === "radio" || type === "checkbox") {
      console.log("data radio:",data)
      return (
        <>
          {
          data.options.map((item, key) => (
            <div className="form-check mr-2" key={key}>
              <input
                className="form-check-input h-auto"
                type={type}
                name={data.label}
                value={item.name}
                required={data.required}
                disabled
              />
              <label className="form-check-label">{item.label}</label>
            </div>
          ))}
        </>
      );
    } else {
      return (
        <>
          <select
            className="form-control"
            name={data.label}
            required={data.required}
            disabled
          >
            <option>Select..</option>
            {data.options.map((item, key) => (
              <option key={key}>{item.label}</option>
            ))}
          </select>
        </>
      );
    }
  };
  return (
    <>
      {formData && (
        <form>
          <div className="d-flex flex-wrap justify-content-center">
            {formData.form_fields.length > 0 && (
              <>
                {formData.form_fields.map((item, key) => (
                  <div className="form-group preview-input" key={key}>
                    <label className="label-custom">{item.label}</label>
                    {item.required && (
                      <span className="required-asterix">*</span>
                    )}
                    <div className="form-inline">
                      <InputTypeElement data={item} index={key} />
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
          <div className="form-inline">
            <div className="main-bottom top-btns-div mt-5 d-flex">
              <button
                type="button"
                className="btn btn-primary mr-2"
                onClick={handleNext}
              >
                Next
              </button>
              <button
                type="button"
                className="btn btn-primary cancek-btn"
                onClick={props.closeModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      )}
    </>
  );
};
export default FormPreview;
