import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { Stage, Layer, Image, Rect } from "react-konva";
import CsvDownloader from "react-csv-downloader";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "../layout/Spinner";
import { updateResult } from "../../actions/scan";
import ReactPaginate from "react-paginate";
import Extracting from "../data_recognition/extracting";
import { useLocation } from "react-router-dom";
import { useHistory } from 'react-router-dom';
import Select from 'react-select';


const PER_PAGE = 1;

function TemplateReview({
  result: { data, loading, template, scanloading },
  updateResult,

}) {

  let history = useHistory();
  const location = useLocation();
  const templatetype_ = location.templatetype
  const templatename_ = location.templatename
  const templateid_ = location.templateid
  const [box, setBox] = useState({
    x: null,
    y: null,
    width: null,
    height: null,
    shouldDraw: false,
  });

  const [currentPage, setCurrentPage] = useState(0);
  const [ResData, setData] = useState({
    res: [],
    NumOfPages: null
  });
  const [ResData2, setData2] = useState([]);
  const [dataArray, setDataArray] = useState([]);
  const [dataNum, setDataNum] = useState(true);
  const [docs, setDocs] = useState([]);
  const [docNumber, setDocNumber] = useState(1);
  const [takeJson, setTakejson] = useState(false);
  const [state, setState] = useState({
    MutiPage: false,
    loading: false,
    isSaved: false,
    resultId_: [],
    templateType: null
  });

  useEffect(() => {
  }, [state.isSaved]);
  useEffect(() => {
    loadCommentsFromServer();
    saveAfterScan()
  }, [data]);
  // useEffect(() => {
  //   saveAfterScan()
  // }, [ResData2]);

  const handleChange2 = (e) => {
    setDocNumber(e.value)
  };

  /*========================== Function for Copy JSON to Clipboard Start ============================*/

  const copyToClipboard = (text) => {
    var textField = document.createElement('textarea')
    textField.innerText = text
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
    alert('copied to clipboard!')
  }
  const copyJson = (data) => {
    var jsonCopy = document.getElementById('pre' + data).innerText;
    copyToClipboard(jsonCopy)

  }
  /*========================== Function for Copy JSON to Clipboard End ==============================*/

  /*============================== Function for JSON model show Start  ==============================*/

  const handleJsonModelShow = (data) => {
    console.log('inside handleJsonModelShow: ', data)
    let element = document.getElementById(data);
    element.classList.add('jsonModelActive');
  }

  /*============================== Function for JSON model show End ==================================*/

  /*============================== Function for JSON model close Start  ==============================*/

  const handleJsonModelClose = (data) => {
    console.log('inside handleJsonModelClose: ', data)
    let element = document.getElementById(data);
    element.classList.remove('jsonModelActive');
    element.classList.add('jsonmodel');
  }

  /*============================== Function for JSON model close End  ==============================*/

  /*============================== Function for JSON Creation Start ================================*/

  const handleJsonModel = (data1, data2, index3) => {
    console.log('inside handleJsonModel: ', data1, data2, index3)
  try{
    data = data2.split(/\n/g);
    let PlydishObj = {};
    let obj2 = new Map();
    let obj3 = new Map();
    if (data2.includes(':\n')) {
      let count = 0;
      let obj4 = new Map();
      let keys = [];
      let values = []
      let data5 = data2.split(/:\n/g);
      for (let i = 0; i < data5.length; i++) {
        if(i==0){
          if(data5[0].includes('/n')){
            let c = data[0].split(/\n/g)
            if(c.length === 2){
              keys.push(`${'Key'+(count+1)}`)
              values.push(c[0])
              count=count+1
              keys.push(c[1])
            }
            else{
              keys.push(`${'Key'+(count+1)}`)
              values.push(c[0])
              count=count+1
              for(let n=1;n<c.length-1;n++){
                keys.push(`${'Key' + (count + 1)}`)
                values.push(c[n])
                count = count + 1
              }
              keys.push(c[c.length-1])
            }
          }
          else{
            keys.push(data5[0])
          }
        }
        else if(i=== data5.length-1){
          if(data5[i].includes('\n')){
           let d = data5[i].split(/\n/g)
           if(d.length === 2){
             values.push(d[0])
             keys.push(`${'Key'+(count+1)}`)
             values.push(d[1])
           }
           else{
             values.push(d[0])
             for(let p=1;p<d.length-1;p++){
               keys.push(`${'Key'+(count+1)}`)
               values.push(d[p])
               count=count+1
             }
             keys.push(`${'Key'+(count+1)}`)
             values.push(d[d.length-1])
             count=count+1
           }
          }
          else{
            if(keys.length > values.length){
              values.push(data5[i])
            }
            else{
              keys.push(`${'Key'+(count+1)}`)
              values.push(data5[i])
            }
          }
        }
        else if ((i % 2) === 0 && i !== 0) {
          if (data5[i].includes('\n')) {
            let a = data5[i].split(/\n/g);
            console.log("a:",a)
            if(a.length === 2){
               values.push(a[0])
               keys.push(a[1])
            }
            else{
              values.push(a[1]);
              for (let j = 1; j < a.length-1; j++) {
                keys.push(`${'Key' + (count + 1)}`)
                values.push(a[j])
                count = count + 1
              }
              keys.push(a[a.length-1])
            }
            
            a = [];
          }
          else {
            console.log("oddvalue:",data5[i])
            keys.push(data5[i])
          }
        }
        else {
          if (data5[i].includes('\n')) {
            let b = data5[i].split(/\n/g);
            console.log("b:",b)
            console.log("b-length:",b.length)
            if(b.length === 2){
              values.push(b[0])
              keys.push(b[1])
            }
            else{
              values.push(b[0])
              for (let k = 1; k < b.length-1; k++) {
                keys.push(`${'key' + (count + 1)}`)
                values.push(b[k])
                count = count + 1
              }
              keys.push(b[b.length-1])
            }
            b=[]
          }
          else {
            values.push(data5[i])
          }
        }
      }
      for (let m = 0; m < keys.length; m++) {
        obj4.set(keys[m], values[m])
      }
      obj4.forEach((value, key) => {
        PlydishObj[key] = value
      })

      let obj5 = new Map();
      obj5.set('OCRA', [PlydishObj]);

      let jsonData = {};

      obj5.forEach((value, key) => {
        jsonData[key] = value
      })

      return (
        <pre id={'pre' + index3}>{JSON.stringify(jsonData, undefined, 4)}</pre>
      )
     /* ===================================*/
      // for (let i = 0; i < data.length; i++) {

      // let count = 0;
      // if (i == 0 && (!(data[i].includes(':')))) {
      //   obj2.set(`${'Key' + (count + 1)}`, data[i])
      //   count = count + 1;
      // }
      // else if ((data[i].includes(':') === false) && (data[i - 1].includes(':') === false)) {
      //   obj2.set(`${'Key' + (count + 1)}`, data[i])
      //   count = count + 1;
      // }
      // else if ((data[i].includes(':') && data[i].indexOf(':') !== data[i].length - 1)) {
      //   obj2.set(`${'Key' + (count + 1)}`, data[i])
      //   count = count + 1;
      // }
      // else {
      //     if (data[i].indexOf(':') === data[i].length - 1) {
      //       keys.push(data[i].slice(0, data[i].length - 1))
      //     }
      //     else if (data[i - 1].indexOf(':') === data[i - 1].length - 1) {
      //       values.push([data[i]])
      //     }
      //     else {
      //       values[values.length - 1].push(data[i])
      //     }
      //   // }

      // }
      // console.log("keys:",keys)
      // console.log("values:",values)
      // for (let i = 0; i < keys.length; i++) {
      //   if (values[i].length === 1) {
      //     obj2.set(keys[i], values[i][0])
      //   }
      //   else {
      //     obj2.set(keys[i], values[i])
      //   }
      // }
      // obj2.forEach((value, key) => {
      //   obj3[key] = value
      // })

      // obj4.set(data1.replace(/\n/g, ''), obj3);

      // obj4.forEach((value, key) => {
      //   PlydishObj[key] = value
      // })

      // let obj5 = new Map();
      // obj5.set('OCRA', [PlydishObj]);

      // let jsonData = {};

      // obj5.forEach((value, key) => {
      //   jsonData[key] = value
      // })

      // return (
      //   <pre id={'pre' + index3}>{JSON.stringify(jsonData, undefined, 4)}</pre>
      // )
    }
    else {
      for (let i = 0; i < data.length; i++) {
        obj3.set('Key'.concat(i + 1), data[i])
      }

      let obj4 = {};

      obj3.forEach((value, key) => {
        obj4[key] = value
      })

      obj2.set(data1.replace(/\n/g, ''), obj4);

      obj2.forEach((value, key) => {
        PlydishObj[key] = value
      })

      let obj5 = new Map();
      obj5.set('OCRA', [PlydishObj]);

      let jsonData = {};

      obj5.forEach((value, key) => {
        jsonData[key] = value
      })
      console.log('return of handleJsonModel: ', JSON.stringify(jsonData, undefined, 4))

      return (
        <pre id={'pre' + index3}>{JSON.stringify(jsonData, undefined, 4)}</pre>
      )
    }
  }catch(error){
    console.log(error)
  }
  }
  /*============================== Function for JSON Creation End ================================*/

  function saveAfterScan() {
    if (data && state.isSaved === false && dataNum) {
      let arrLen = template.templateData.length;
      let resultArray = [];
      for (let i = 0; i < arrLen; i++) {
        resultArray.push({
          "savedresult": JSON.parse(data[i].result),
          "page": i + 1
        })
      }
      // for (let i = 0; i < arrLen; i++) {
      const previmg = new window.Image();
      previmg.src = data[0]?.bs64;
      const base = location.pdfbs64


      let body = {
        templateName: data[0]?.template[0].templatename,
        templateid: data[0]?.template[0]._id,
        scannedResultImage: data[0]?.uploadedFile,
        base64: base === null ? data[0]?.bs64 : base,
        result: resultArray,
        numOfPages: template.pageCount,
        timeTaken: data[0]?.timeTaken
      };



      if (data[0]?.templatetype === "Industry Template") {
        axios.post('/result/save', body)
          .then(function (response) {
            if (response.status === 200) {
              notify("Result Saved", "success");
              const result_ = response.data
              const resultId = result_._id
              setState({
                ...state,
                loading: false,
                // isSaved: true,
                templateType: data[0]?.templatetype
              });
              state.resultId_.push(resultId);
            }
            else {
              notify("Something happened");
            }

          }
          )

      } else if (data[0]?.templatetype === "Own Template" || data[0]?.templatetype === "My Template") {

        axios.post('/result/saveOwnTemplate', body)
          .then(function (response) {
            if (response.status === 200) {
              notify("Result Saved", "success");
              const result_ = response.data
              const resultId = result_._id
              setState({
                ...state,
                loading: false,
                // isSaved: true,
                templateType: data[0].templatetype
              });
              state.resultId_.push(resultId);
            }
            else {
              notify("Something happened");
            }

          })
      }
    }
  }

  function loadCommentsFromServer() {

    if (data && dataNum) {
      let resultObj = [];
      let arrLen = template.templateData.length;
      for (let i = 0; i < arrLen; i++) {
        const previmg = new window.Image();
        previmg.src = data[i].bs64;

        resultObj.push({
          "Via_Metadata": data[i]?.template[0]?.pages[i]?.annotation,
          "base64": data[i].bs64,
          "RecognitionData": data[i].result,
          "previmg": previmg,
        });
      }

      setData({ ...ResData, res: resultObj, NumOfPages: template.pageCount });
      let resData2Temp = [];
      resData2Temp.push({
        "res": resultObj,
        "NumOfPages": template.pageCount
      })
      setData2(resData2Temp);
      setDataArray(...data)
      

      let docsTemp = []
      docsTemp.push({
        label: data[0]?.uploadedFile,
        name: "doc",
        value: (dataArray.length)
      })
      setDocs(...docsTemp)
    }
  }

  function handlePageClick({ selected: selectedPage }) {
    
    setCurrentPage(selectedPage);
  }

  const downloadFile = async (data, filename) => {
    const fileName = filename;
    const json = data;
    const blob = new Blob([json], { type: 'application/json' });
    const href = await URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.download = fileName + ".json";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function SaveAsCSV(data) {
    data = data?.map(function (obj) {
      obj['AttributeKey'] = obj['regionAttributeKey']; // Assign new key 
      obj['AttributeValue'] = obj['regionAttributeValue'];
      delete obj['regionAttributeKey'];
      delete obj['regionAttributeValue']; // Delete old key 
      return obj;

    });
    return data;

  }

  // "[{\"confidence\":97.2,\"regionAttributeKey\":\"Carrier Name\\n\",\"regionAttributeValue\":\"ImGUARD Insurance Company\"}]\n"

  // "{\"_via_settings\":{\"ui\":{\"annotation_editor_height\":25,\"annotation_editor_fontsize\":0.8,\"leftsidebar_width\":18,\"image_grid\":{\"img_height\":80,\"rshape_fill\":\"none\",\"rshape_fill_opacity\":0.3,\"rshape_stroke\":\"yellow\",\"rshape_stroke_width\":2,\"show_region_shape\":true,\"show_image_policy\":\"all\"},\"image\":{\"region_label\":\"__via_region_id__\",\"region_color\":\"__via_default_region_color__\",\"region_label_font\":\"10px Sans\",\"on_image_annotation_editor_placement\":\"NEAR_REGION\"}},\"core\":{\"buffer_size\":18,\"filepath\":{},\"default_filepath\":\"\"},\"project\":{\"name\":\"BHC_Sample_01\"}},\"_via_img_metadata\":{\"TPA Assignment Form_1.jpeg370623\":{\"filename\":\"TPA Assignment Form_1.jpeg\",\"size\":370623,\"regions\":[{\"shape_attributes\":{\"name\":\"rect\",\"x\":298,\"y\":480,\"width\":347,\"height\":33},\"region_attributes\":{\"Name\":\"Carrier Name\\n\"}}],\"file_attributes\":{}}},\"_via_attributes\":{\"region\":{\"Name\":{\"type\":\"text\",\"description\":\"\",\"default_value\":\"\"}},\"file\":{}}}"


  const offset = currentPage * PER_PAGE;
  const currentPageData = () => {
    console.log('inside currentPageData: ', ResData2)
    return ResData2.map((item, index) =>
      item?.res.slice(offset, offset + PER_PAGE).map((it, index2) =>
        index === docNumber - 1 ? (
          <div className="row">
            <div className="col-sm-12">
              <div className="topicon">
                <div className="topIconsLeft" style={{ width: "300px" }}>
                  {ResData2.length !== 1 ? (
                    <Select
                      id="documentList"
                      placeholder="Documents"
                      options={docs}
                      onChange={(e) => handleChange2(e)}
                      defaultValue={docs[0]}
                    ></Select>
                  ) : null}
                </div>
                {it.Via_Metadata && <div className="topIconsRight">
                  <button
                    id="reviewSaveBtn"
                    key={index2}
                    onClick={(e) =>
                      save(e, it.RecognitionData, currentPage, index)
                    }
                  >
                    <a href="#">
                      {" "}
                      <i className="far fa-check-circle text-white">
                        {state.loading && (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        )}
                        &nbsp;&nbsp;&nbsp;
                      </i>
                    </a>
                    Save Changes
                  </button>
                  <div>
                    <button
                      id="reviewDownloadBtn"
                      style={{ paddingLeft: "0px" }}
                      data-toggle="dropdown"
                    >
                      <i
                        className="fas fa-download"
                        style={{ paddingRight: "10px" }}
                      >
                        {" "}
                      </i>
                      Download
                    </button>
                    <div className="dropdown-menu">
                      <CsvDownloader
                        datas={SaveAsCSV(JSON.parse(it.RecognitionData))}
                        filename={template.templatename}
                      >
                        <a className="dropdown-item" href="#">
                          Export as CSV
                        </a>
                      </CsvDownloader>
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={(e) =>
                          downloadFile(
                            it.RecognitionData,
                            template.templatename
                          )
                        }
                      >
                        Export as JSON
                      </a>
                    </div>
                  </div>
                </div>}
              </div>
            </div>
            <div className="custom1">
              <div className="scandoumentdiv">
                {it.Via_Metadata ? 
                <Stage width={865.55} height={1200}>
                <Layer>
                  <Image
                    image={it.previmg}
                    scaleY={
                      template.imgData.y_scale && template.imgData.x_scale < 1
                        ? template.imgData.y_scale
                        : template.imgData.x_scale > 1.4
                        ? template.imgData.y_scale
                        : 1
                    }
                    scaleX={
                      template.imgData.x_scale && template.imgData.x_scale < 1
                        ? template.imgData.x_scale * 0.88
                        : template.imgData.x_scale > 1.4
                        ? template.imgData.y_scale
                        : 1
                    }
                    // scaleY={(template.imgData.y_scale)*0.89}
                    // scaleX={(template.imgData.x_scale)*0.86}
                    // scaleY={template.imgData.y_scale * (template.imgData.y_scale / (template.imgData.y_scale + template.imgData.x_scale))}
                    // scaleX={template.imgData.x_scale * (template.imgData.x_scale / (template.imgData.y_scale + template.imgData.x_scale))}
                    // offsetX={0}
                    offsetY={0}
                  />
                  
                  {box.shouldDraw ? (
                    <Rect
                      x={
                        box.x *
                        (template.imgData.x_scale &&
                        template.imgData.x_scale < 1
                          ? template.imgData.x_scale * 0.88
                          : template.imgData.x_scale > 1.4
                          ? template.imgData.y_scale
                          : 1)
                      }
                      y={
                        box.y *
                        (template.imgData.y_scale &&
                        template.imgData.x_scale < 1
                          ? template.imgData.y_scale
                          : template.imgData.x_scale > 1.4
                          ? template.imgData.y_scale
                          : 1)
                      }
                      // x={box.x * (template.imgData.x_scale)*0.86}
                      // y={box.y * (template.imgData.y_scale)*0.89}
                      // scaleY={template.imgData.y_scale * (template.imgData.y_scale / (template.imgData.y_scale + template.imgData.x_scale))}
                      // scaleX={template.imgData.x_scale * (template.imgData.x_scale / (template.imgData.y_scale + template.imgData.x_scale))}
                      width={box.width}
                      height={box.height}
                      stroke="red"
                      shadowBlur={10}
                      scaleX={
                        template.imgData.x_scale &&
                        template.imgData.x_scale < 1
                          ? template.imgData.x_scale * 0.88
                          : template.imgData.x_scale > 1.4
                          ? template.imgData.y_scale
                          : 1
                      }
                      scaleY={
                        template.imgData.y_scale &&
                        template.imgData.x_scale < 1
                          ? template.imgData.y_scale
                          : template.imgData.x_scale > 1.4
                          ? template.imgData.y_scale
                          : 1
                      }
                      // scaleY={(template.imgData.y_scale)*0.89}
                      // scaleX={(template.imgData.x_scale)*0.86}
                      // scaleY={template.imgData.y_scale * (template.imgData.y_scale / (template.imgData.y_scale + template.imgData.x_scale))}
                      // scaleX={template.imgData.x_scale * (template.imgData.x_scale / (template.imgData.y_scale + template.imgData.x_scale))}
                      offsetX={0}
                      offsetY={0}
                    />
                  ) : null}
                </Layer>
              </Stage>
                : <h2>Incomplete Annotation</h2> }
              </div>
            </div>
            {it.Via_Metadata && <div className="custom2">
              <div className="scandoumentdiv2">
                {JSON.parse(it.RecognitionData).map((el, index3) => (
                  <>
                    <div className="pagelabel text-primary font-weight-bold">
                      {el.regionAttributeKey}
                    </div>
                    {
                    // el.regionAttributeValue.includes("\n") ? (
                      <>
                        <button
                          id="jsonBtn"
                          onClick={() => handleJsonModelShow(index3)}
                        >
                          JSON
                        </button>
                        {/*=========================== JOSN MODEL START=========================== */}
                        <div className="jsonModel" id={index3}>
                          <button
                            id="jsonCopyBtn"
                            onClick={() => copyJson(index3)}
                          >
                            Copy &#10063;
                          </button>
                          <button
                            id="modelCloseBtn"
                            onClick={() => handleJsonModelClose(index3)}
                          >
                            &#10005;
                          </button>
                          <br />
                          <hr />
                          <div className="pt-0 p-3 jsonContentArea">
                            {handleJsonModel(
                              el.regionAttributeKey,
                              el.regionAttributeValue,
                              index3
                            )}
                          </div>
                        </div>
                        {/*============================ JSON MODEL END =============================*/}
                      </>
                    // ) : (
                    //   ["NO DATA FOUND"]
                    // )
                    }

                    <div className="anotateinputdiv">
                      {
                        <textarea
                          className="anotateinput"
                          type="text"
                          key={index3}
                          rows={
                            el.regionAttributeValue.includes("\n")
                              ? el.regionAttributeValue.split(/\n/g).length
                              : 1
                          }
                          name={el.regionAttributeKey}
                          value={el.regionAttributeValue || ""}
                          onChange={(e) => handleChange(e, index)}
                          onFocus={(e) => {
                            console.log('textarea clicked e: ', e)
                            console.log('textarea clicked it.Via_Metadata: ', it.Via_Metadata)
                            drawBox(e, it.Via_Metadata);
                          }}
                          onBlur={(e) => {
                            setBox({ ...box, shouldDraw: false });
                          }}
                        />
                      }
                    </div>
                  </>
                ))}
              </div>
            </div>}
          </div>
        ) : null
      )
    );
  };


  const pageCount = Math.ceil(ResData.NumOfPages / PER_PAGE);
  const notify = (text, category) => {
    if (category === "success") {
      toast.success(text, { autoClose: 4000 });
    } else {
      toast.warning(text, { autoClose: 4000 });
    }
  };

  const handleChange = (e, index) => {
    e.preventDefault();
    const JsonResult = JSON.parse(ResData2[index].res[currentPage].RecognitionData)
    const elementIndex = JsonResult.findIndex(
      (el) => el.regionAttributeKey === e.target.name
    );
    const newArray = [...JSON.parse(ResData2[index].res[currentPage].RecognitionData)]

    newArray[elementIndex] = {
      ...newArray[elementIndex],
      regionAttributeValue: e.target.value,
    };
    ResData2[index].res[currentPage].RecognitionData = JSON.stringify(newArray)
    setDataNum(false);
    setState({
      ...state,
      isSaved: false
    });

  };

  const drawBox = (e, via_Obj) => {
    const ann = JSON.parse(via_Obj)
    let boxDrawings = Object.values(ann._via_img_metadata)[0]
      .regions;
      console.log('boxDrawings in drawBox function: ', boxDrawings)
    boxDrawings.forEach((item) => {
      if (item.region_attributes.Name === e.target.name) {
        setBox({
          ...box,
          x: item.shape_attributes.x,
          y: item.shape_attributes.y,
          width: item.shape_attributes.width,
          height: item.shape_attributes.height,
          shouldDraw: true,
        });
      }
    });
  };

  const save = async (e, res, page, index) => {
    e.preventDefault();
    let body = {
      resultID: state.resultId_[index],
      updatedResult: res,
      TemplateType: state.templateType,
      pageNumber: page + 1
    }
    if (state.isSaved == false) {
      if (state.templateType == "Industry Template") {
        axios.post('/result/updated', body)
          .then(function (response) {
            if (response.status == 200) {
              notify("Saved Changes", "success");
              setState({
                ...state,
                loading: false,
                isSaved: true,
              });

            }
            else {
              notify("Something happened");
            }

          })

      } else if (state.templateType === "Own Template" || state.templateType === "My Template") {
        axios.post('/result/updatedOwn', body)
          .then(function (response) {
            if (response.status === 200) {
              notify("Saved Changes", "success");
              setState({
                ...state,
                loading: false,
                isSaved: true,
              });

            }
            else {
              notify("Something happened");
            }

          })
      }

    } else {
      notify("Already Saved");

    }
  };
  const btngoback = (e) => {
    history.push("/ocr");
    window.location.reload(false);
  }

  return (
    <Fragment>
      {loading ? (
        <>
          <Extracting />
        </>
      ) : (
        <>
          <div className="app-content container1">
            <div className="breadcrumbmaindiv">
              <a href="/data-recognition">Choose Template </a> -
              <a href="/uploadfile">Upload Document </a> -
              <a href="/extracting">Extract </a>-
              <a href="#">Review </a>
            </div>

            <div className="page-header mb-3">
              <h3>
                Review</h3 > <span className="page-subtitle " >Your document is being recognized by Docketry</span>
            </div>
            <div className="row">
              <div id="react-paginate">
                {/* <h1>Recognized Image Come Here</h1> */}
                {currentPageData()}
                {/* {(currentPageData !== []) ? currentPageData() : ['NO DATA']} */}
                {
                  pageCount !== 1 ? (
                    <ReactPaginate
                      pageCount={pageCount}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      previousLinkClassName={"pagination__link"}
                      nextLinkClassName={"pagination__link"}
                      disabledClassName={"pagination__link--disabled"}
                      activeClassName={"active"}
                    />
                  ) : null
                }

              </div>
            </div>
          </div>
        </>
      )}
    </Fragment>
  );
};

TemplateReview.propTypes = {
  result: PropTypes.object,
  updateResult: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  result: state.scan,
});

export default connect(mapStateToProps, { updateResult })(TemplateReview);
