import React, {useState, useRef, useEffect} from "react";
import SittingBot from "./SittingBot";
import ChatWindow from "./ChatWindow";


const BotEngine = () => {
    const ref = useRef(null);
    const [visible, setVisible] = useState(false)

    useEffect(() => {
      const handleClickOutside = (event) => {
        if(ref.current && !ref.current.contains(event.target)){
            setVisible(false)
        }
    }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [ref])
    

    return (
        <div ref={ref}>
            <ChatWindow 
                visible={visible}
            />
            <SittingBot
                onClick={() => setVisible(!visible)}
                style={{position: 'fixed', right: '25px', bottom: '20px'}} 
            />
        </div>
    )
}

export default BotEngine;

