import React, {useState} from 'react'
import {styles} from '../styles';
import ChatBotComponent from './ChatBotComponent';
import '../botstyles.css'

const ChatWindow = ({visible}) => {
    // const [visible, setVisible] = useState(false)
  return (
    <div
        className='transition-2'
        style={visible ? {
            ...styles.chatWindow,
            ...{opacity: visible ? '1' : '0'}
        } : {
          ...styles.chatWindowNotInUse,
          ...{opacity: visible ? '1' : '0'}
      }}
    >
      <ChatBotComponent />

    </div>
//     <div
//     className='transition-2'
//     style={{
//       ...styles.chatWindow,
//       ...{opacity: visible ? '1' : '0'}
//   }}
// >
//   <ChatBotComponent />

// </div>
  )
}

export default ChatWindow